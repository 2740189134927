/*eslint-disable*/
import {ajaxGet, ajaxPost} from "@/utilities";
import {EventBus} from "@/event-bus";


const actions = {
    getAdminsWithRoles({rootState}) {
        return ajaxGet(rootState.baseUrl + `/admin/acl-management/admin-list`)
    },
    getRoles({rootState}) {
        return ajaxGet(rootState.baseUrl + `/admin/acl-management/role/list`)
    },
    attachRoleToUser({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/acl-management/admin-role/create`, payload)
    },
    attachPermissionToRole({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/acl-management/role-permission/create`, payload)
    },
    getPermissionList({rootState}){
        return ajaxGet(rootState.baseUrl + `/admin/acl-management/permission/list`)
    }

    //permission/list
}
export default {actions}
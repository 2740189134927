var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"my-8"},[_c('v-card-text',[_c('TableOptions',{attrs:{"hasExport":true,"searchFilters":_vm.searchFilters},on:{"applyFilters":_vm.applyFilters}})],1),_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.orders,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"search":_vm.search},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.states[item.state])+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"position":"relative"}},[(item.needsToRequestSession)?_c('div',[_c('a',{staticClass:"new-payment-badge",staticStyle:{"text-decoration":"none"},attrs:{"target":"_blank","href":'clients/'+item.user_id}},[_vm._v("new")])]):_vm._e(),_c('v-chip',{attrs:{"href":'clients/'+item.user_id}},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":'clients/'+item.user_id},on:{"click":function($event){$event.preventDefault();return _vm.getClientProfile(item.user_id)}}},[_vm._v(_vm._s(item.user))])])],1)]}},{key:"item.with_warning",fn:function(ref){
var item = ref.item;
return [(item.with_warning)?_c('v-btn',{attrs:{"color":"warning","rounded":"","fab":"","x-small":"","elevation":"0"}},[_c('v-icon',{attrs:{"color":"white","size":"20"}},[_vm._v("mdi-alert")])],1):_vm._e()]}},{key:"item.packages",fn:function(ref){
var item = ref.item;
return [(item.packages.length)?_c('td',{staticClass:"px-0",staticStyle:{"border":"none"}},_vm._l((item.packages),function(el){return _c('div',{key:el.title,staticClass:"text--secondary"},[_vm._v(" "+_vm._s(el.title)+" "),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(el.count)+" ")],1)}),0):(item.cart!=null)?_c('td',{staticClass:"px-0",staticStyle:{"border":"none"}},_vm._l((item.cart.packages),function(el){return _c('div',{key:el.title,staticClass:"text--secondary"},[_vm._v(" "+_vm._s(el.title)+" "),_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-close")]),_vm._v(" "+_vm._s(el.pivot.quantity)+" ")],1)}),0):_vm._e()]}},{key:"item.wallet",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.walletRecord != null ? item.walletRecord.amount : 0)+" ")]}},{key:"item.final_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.final_amount).toLocaleString())+" ")]}}])})],1),(!_vm.fiveSession)?_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":_vm.total},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
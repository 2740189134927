<template>
    <v-card class="elevation-0">
        <v-card-title class="pb-0 mb-0">ثبت زمان پیشنهادی</v-card-title>
        <v-divider></v-divider>
        <v-tour name="tour" :steps="tourSteps"
                :options="myOptions"
        ></v-tour>
        <v-card dense class="warning">
            <v-card-text class="px-4 ma-0 white--text text-justify" :class="{'caption':$vuetify.breakpoint.smAndDown}">
            <span>
                لطفا همه زمانهای مناسب برای برگزاری جلسه آینده را به زمان محلی خود را وارد نمایید، هرچه تعداد بیشتری
            زمان وارد کنید، امکان برگزاری سریع تر جلسه را به تیم پشتیبانی میدهید.
            </span>
            </v-card-text>
        </v-card>

        <v-card-text data-v-step="5" class="pt-0 mt-0">
            <v-row justify="center" align="center">
                <v-col cols="12" sm="12" md="7">
                    <div class="text-center" data-v-step="6">
                        <v-chip
                                :small="$vuetify.breakpoint.smAndDown"
                                class="mb-2 smooth-transition"
                                style="display: inline-block"
                                @click="isJalali = false"
                        >
                            تقویم میلادی
                        </v-chip>
                        <div style="display: inline-block">
                            <v-switch
                                    :dense="$vuetify.breakpoint.smAndDown"

                                    class="ma-3 mx-1 "
                                    v-model="isJalali"
                                    hide-details
                                    color="primary"
                            >
                            </v-switch>
                        </div>
                        <v-chip
                                :small="$vuetify.breakpoint.smAndDown"
                                @click="isJalali = true"
                                class="mb-2 smooth-transition"
                                style="display: inline-block"
                        >
                            تقویم شمسی
                        </v-chip>
                    </div>

                    <div data-v-step="8">
                        <AdvancedDatePicker v-if="isJalali" v-model="date"

                                            :eventArray="gregoryEvents"
                                            :min="min"
                                            :styles="'font-family: IranSans;padding:0 5px;'"
                        >
                            <template v-slot:prev-icon>
                                <v-btn fab x-small class="elevation-0">
                                    <v-icon fab small class="grey--text">
                                        mdi-chevron-right
                                    </v-icon>
                                </v-btn>
                            </template>

                            <template v-slot:next-icon>
                                <v-btn fab x-small class="elevation-0">
                                    <v-icon fab class="grey--text">
                                        mdi-chevron-left
                                    </v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:small-modal>


                            </template>
                        </AdvancedDatePicker>

                        <v-date-picker v-else v-model="date"
                                       :min="minGregory"
                                       first-day-of-week="6"
                                       class="elevation-0"
                                       :events="gregoryEvents"
                                       :width="'100%'"
                        ></v-date-picker>
                    </div>
                </v-col>
                <v-col cols="12" sm="12" md="5">
                    <v-form ref="addToTimesForm">

                        <v-row>
                            <v-col cols="12">
                                <ChangeTzForm data-v-step="7" :timezone="profile.tz"
                                              @tzChange="tzChangeResetTimes"></ChangeTzForm>
                            </v-col>
                        </v-row>

                        <v-row data-v-step="9">
                            <v-col cols="3"
                                   sm="6"
                                   class="pa-1 pa-sm-1"
                                   v-for="(period,index) in periods"
                                   :key="index" @click="selectPeriod(period)">
                                <v-hover v-slot:default="{ hover }">
                                    <v-card class="cursor-pointer smooth-color"
                                            :class="{'info':isInCurrentTime(currentTime,period),'grey' : !isInCurrentTime(currentTime,period)}"
                                            :elevation="hover ? 10 : 1"
                                            style="cursor: pointer;"
                                    >
                                        <!--<v-fade-transition>-->
                                        <!--<template v-if="currentTime != null">-->

                                        <!--<v-overlay-->

                                        <!--v-if="currentTime.periods.includes(period)"-->
                                        <!--absolute-->
                                        <!--color="#000">-->
                                        <!--<v-chip class="green cursor-pointer">-->
                                        <!--<span>انتخاب شد</span>-->
                                        <!--<v-icon class="ma-1" small>fas-->
                                        <!--fa-check-->
                                        <!--</v-icon>-->
                                        <!--</v-chip>-->
                                        <!--</v-overlay>-->

                                        <!--</template>-->
                                        <!--</v-fade-transition>-->
                                        <v-row
                                                align="end"
                                                class="fill-height">
                                            <v-col
                                                    align-self="start"
                                                    class="pa-0"
                                                    cols="12">
                                            </v-col>
                                            <v-col>
                                                <v-list-item
                                                        color="rgba(0, 0, 0, .4)"
                                                        class="py-0"
                                                        dark
                                                >
                                                    <v-list-item-content class="py-0">
                                                        <v-list-item-title
                                                                class="black--text">
                                                            <span class="white--text">{{period.title}} </span>
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-hover>
                            </v-col>
                        </v-row>

                    </v-form>
                </v-col>
            </v-row>

            <!--<v-list dense>-->
            <!--<v-list-item v-for="(time,index) in dates" :key="index" dense>-->
            <!--<v-chip-group column dense >-->
            <!--<v-chip class="primary mx-1" small>{{time.date}}-->
            <!--</v-chip>-->
            <!--<template v-for="(timePeriod,index) in time.periods">-->
            <!--<v-chip :key="index" class="mx-1 info" close small-->
            <!--@click:close="removeTime(time,timePeriod)">-->
            <!--{{timePeriod.title}}-->
            <!--</v-chip>-->
            <!--</template>-->

            <!--</v-chip-group>-->
            <!--<v-btn fab text @click="removeTimeRecord(time)">-->
            <!--<v-icon class="red&#45;&#45;text mx-5" small>fas fa-trash-->
            <!--</v-icon>-->
            <!--</v-btn>-->
            <!--</v-list-item>-->
            <!--</v-list>-->
            <v-data-table
                    mobile-breakpoint="400"
                    :items="datesWithPeriods"
                    :headers="headers"
                    dense
                    hide-default-footer
            >
                <template v-slot:item="{item}">
                    <tr class="pa-0 ma-0" @click="selectDayByTable(item)">
                        <td>
                            <template v-if="isJalali">
                                {{item.date| toJalali}}
                            </template>
                            <template v-else>
                                {{item.date}}
                            </template>
                        </td>
                        <td>
                            <template v-for="(timePeriod,index) in item.periods">
                                <v-chip :key="index" class="ma-1 info" close small
                                        @click:close="removeTime(item,timePeriod)">
                                    {{timePeriod.title}}
                                </v-chip>
                            </template>
                        </td>
                        <td>
                            <v-btn fab x-small text @click="removeTimeRecord(item)">
                                <v-icon class="red--text mx-5" small>fas fa-trash
                                </v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card-text>

        <v-card-actions>
            <v-btn text class="danger" @click="$emit('done')">بستن</v-btn>
            <v-spacer></v-spacer>
            <v-btn text class="primary" @click="addToTimes" data-v-step="10">ثبت</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    /*eslint-disable*/
    import AdvancedDatePicker from '@/components/datepicker/AdvancedDatePicker'
    import {EventBus} from "../event-bus";
    import {mapGetters} from "vuex";
    import momentJalali from 'moment-jalaali';
    import ChangeTzForm from '@/components/ChangeTzFormComponent'

    export default {
        components: {
            AdvancedDatePicker,
            ChangeTzForm
        },
        props: {
            message: {
                default: 'زمانهای پیشنهادی شما با موفقیت به ثبت رسیدند'
            },
            tour: {
                default: false,
            },
            removeTimeAction: {
                default: 'clientRemoveTimes'
            },
            addTimeAction: {
                default: 'clientPostConsultInfo'
            },
            profile: {
                default() {
                    return this.$store.getters.getUserInfo
                }
            }
        },
        computed: {
            datesWithPeriods() {
                return this.dates.filter((item) => {
                    if (item.periods.length) {
                        return item;
                    }
                });
            }
        },
        methods: {
            showTour() {
                // setTimeout(() => {
                //     this.$tours['tour'].start()
                // }, 300)

            },
            tzChangeResetTimes() {
                this.fillCalendar(this.profile.timebooks);
                this.gregoryEvents = [];
                this.newDates = [];
            },
            fillCalendar(timebooks) {


                timebooks.map((item) => {
                    item.date = item.date.replace(/\//g, '-');
                    this.gregoryEvents.push(item.date);
                    item.periods.map((period) => {
                        let end = period.end == '00:00' ? '24:00' : period.end;
                        period.compact = period.start + ' - ' + end;
                        period.from = period.start;
                        period.to = period.end;
                        let origPrd = this.periods.find(prd => prd.compact == period.compact);
                        if (origPrd != undefined) {
                            period.title = origPrd.title;
                        }
                    })
                });

                this.dates = timebooks;
                this.arrayEvents = [];
            },
            getOrCreateNewDataDay(dates, date) {
                let record = dates.find(item => date == item.date);
                if (record == undefined) {
                    let newRec = {
                        date: date,
                        periods: []
                    };
                    dates.push(newRec);
                    record = dates.find(item => date == item.date);
                }
                return record;

            },
            removePeriod(period) {
                if (period.id != null) {
                    this.itemsToRemove.push(period.id);
                }
            },
            isInCurrentTime(currentTime, period) {

                return currentTime.periods.find((item) => {
                    if (item.compact == period.compact) {
                        return item;
                    }
                });
                // return item;
                //
                // if (item != undefined)
                //     return true;
                // return false;
            },
            removeTime(time, timePeriod) {

                this.removePeriod(timePeriod);
                time.periods.splice(time.periods.indexOf(timePeriod), 1);
                if (!time.periods.length) {
                    this.removeTimeRecord(time);
                }
            },
            resetCurrentTime() {
                this.currentTime = {
                    date: '',
                    periods: []
                };
                this.date = null;
            },
            selectDayByTable(item) {

                if (this.isJalali) {
                    this.date = momentJalali(item.date, 'YYYY-MM-DD').format('jYYYY-jMM-jDD');
                } else {

                    this.date = item.date;
                }
            },
            removeTimeRecord(time) {
                time.periods.forEach((period) => {
                    this.removePeriod(period);
                });

                this.gregoryEvents.splice(this.gregoryEvents.indexOf(time.date), 1);
                this.dates.splice(this.dates.indexOf(time), 1);
                this.resetCurrentTime();
            },
            selectPeriod(period) {


                if (this.currentTime.date == '' || this.currentTime.date == null || this.currentTime.date == 'Invalid date') {
                    EventBus.$emit('notify', 'red', undefined, 'لطفا ابتدا تاریخ مورد نظر را انتخاب نمایید');
                    return;
                }
                let dayDate = this.currentTime.date;
                let periodInCurrentDay = this.isInCurrentTime(this.currentTime, period);


                let currentDayCopy = this.getOrCreateNewDataDay(this.newDates, dayDate);
                let periodInCurrentDayCopy = this.isInCurrentTime(currentDayCopy, period);

                if (periodInCurrentDay == undefined) {

                    this.currentTime.periods.push(period);
                    currentDayCopy.periods.push(period);

                    if (this.gregoryEvents.find(date => date == dayDate) == undefined) {
                        this.gregoryEvents.push(dayDate);
                    }

                } else {

                    currentDayCopy.periods.splice(currentDayCopy.periods.indexOf(periodInCurrentDayCopy), 1);
                    this.currentTime.periods.splice(this.currentTime.periods.indexOf(periodInCurrentDay), 1);
                    this.removePeriod(periodInCurrentDay);

                    if (!this.currentTime.periods.length) {
                        this.gregoryEvents.splice(this.gregoryEvents.indexOf(dayDate), 1);
                        this.resetCurrentTime();
                    }

                }

            },
            normalizeData(dates = []) {

                let data = {timebooks: []};
                dates.forEach(day => {
                    let start = "";
                    let end = "";

                    data.timebooks.push({'date': day.date.replace(/-/g, '/'), 'periods': []});

                    day.periods.forEach(time => {
                        start = time.from;
                        end = time.to;
                        data.timebooks.forEach(item => {
                            item.periods.push({'start': start, 'end': end})
                        })
                    })
                });
                return data;

            },
            addToTimes() {

                let removeTimes = new Promise((resolve) => {
                    if (this.itemsToRemove.length) {
                        this.$store.dispatch(this.removeTimeAction, {times: this.itemsToRemove}).finally(() => {
                            this.itemsToRemove = [];
                            resolve(true);
                        });
                    } else {
                        resolve(true);
                    }
                });

                removeTimes.finally(() => {
                    this.sendTimes();
                });
            },
            sendTimes() {
                let dates = this.newDates.filter((item) => {
                    if (item.periods.length) {
                        return item;
                    }
                });


                if (!dates.length) {
                    EventBus.$emit('notify', 'red', undefined, 'لطفا حداقل یک روز و زمانهای آن را انتخاب نمایید');
                    return;
                }

                let processed = this.normalizeData(dates);

                this.$store.dispatch(this.addTimeAction, processed).then(() => {
                    this.newDates = [];
                    this.$emit('getProfile');
                    this.$emit('done');
                    EventBus.$emit('notify', 'green', undefined, this.message);
                })
            },
        },

        beforeDestroy() {

        },
        mounted() {

            if (this.tour) {
                // this.showTour();
            }

            this.fillCalendar(this.profile.timebooks);


            // this.arrayEvents = [...Array(6)].map(() => {
            //     const day = Math.floor(Math.random() * 30)
            //     const d = new Date()
            //     d.setDate(day)
            //     return d.toISOString().substr(0, 10)
            // })

        },
        data() {
            return {
                tourSteps: [
                    {
                        target: '[data-v-step="5"]',
                        params: {
                            enableScrolling: true,
                            placement: 'bottom'
                        },
                        header: {
                            title: 'ثبت زمان',
                        },
                        content: `زمان های مد نظر خود را انتخاب نمایید`
                    },
                    {
                        target: '[data-v-step="6"]',
                        params: {
                            enableScrolling: true,
                            placement: 'top'
                        },
                        header: {
                            title: 'نوع تقویم',
                        },
                        content: `میتوانید از تقویم شمسی یا میلادی استفاده نمایید`
                    },
                    {
                        target: '[data-v-step="7"]',
                        params: {
                            enableScrolling: true,
                            placement: 'top'
                        },
                        header: {
                            title: 'زمان محلی',
                        },
                        content: `در صورتی که زمان محلی خود را به درستی ثبت نکرده اید، آن را تصحیح کنید`
                    },
                    {
                        target: '[data-v-step="8"]',
                        params: {
                            enableScrolling: true,
                            placement: 'top'
                        },
                        header: {
                            title: 'انتخاب روز',
                        },
                        content: `بر روی روز مورد نظر خود کلیک کنید`
                    },
                    {
                        target: '[data-v-step="9"]',
                        params: {
                            enableScrolling: true,
                            placement: 'top'
                        },
                        header: {
                            title: 'انتخاب بازه',
                        },
                        content: `بازه مورد نظر خود را انتخاب کنید`
                    },
                    {
                        target: '[data-v-step="10"]',
                        params: {
                            enableScrolling: true,
                            placement: 'right'
                        },
                        header: {
                            title: 'ثبت',
                        },
                        content: `بعد از ثبت همه زمانهای خود، بر روی دکمه ثبت کلیک کنید`
                    },
                ],
                myOptions: {
                    debug: true,
                    labels: {
                        buttonSkip: 'بستن',
                        buttonPrevious: 'قبلی',
                        buttonNext: 'بعدی',
                        buttonStop: 'پایان'
                    }
                },
                itemsToRemove: [],
                headers: [
                    {
                        text: 'تاریخ',
                        value: 'date',
                        sortable: true,
                        align: 'center',
                    },
                    {
                        text: 'ساعت ها',
                        value: 'times',
                        sortable: false,
                        align: 'right',
                    },
                    {
                        text: '',
                        value: 'actions',
                        sortable: false,
                        align: 'center',
                    }
                ],
                min: new momentJalali().format('jYYYY-jMM-jDD'),
                minGregory: new momentJalali().format('YYYY-MM-DD'),
                gregoryEvents: [],
                arrayEvents: null,
                showAddDialog: true,
                isJalali: false,
                date: null,
                dates: [],
                newDates: [],
                currentTime: {
                    date: '',
                    periods: []
                },

                periods: [
                    {title: 'صبح', from: '08:00', to: '12:00', compact: '08:00 - 12:00'},
                    {title: 'ظهر', from: '12:00', to: '16:00', compact: '12:00 - 16:00'},
                    {title: 'عصر', from: '16:00', to: '20:00', compact: '16:00 - 20:00'},
                    {title: 'شب', from: '20:00', to: '24:00', compact: '20:00 - 24:00'},
                ],
            }
        },
        watch: {
            tour() {
                if (this.tour) {
                    // this.showTour();
                }
            },
            isJalali(newVal) {
                if (newVal) {
                    if (this.date != null) {
                        this.date = momentJalali(this.date, 'YYYY-MM-DD').format('jYYYY-jMM-jDD')
                    }
                } else {
                    if (this.date != null) {
                        this.date = momentJalali(this.date, 'jYYYY-jMM-jDD').format('YYYY-MM-DD')
                    }
                }
            },
            date() {
                let date;
                if (this.isJalali) {
                    date = momentJalali(this.date, 'jYYYY-jMM-jDD').format('YYYY-MM-DD');
                } else {
                    date = this.date;
                }

                let record = this.dates.find(item => date == item.date);
                if (record == undefined) {
                    let record = {
                        date: date,
                        periods: []
                    };
                    this.dates.push(record);
                }
                record = this.dates.find(item => date == item.date);
                this.currentTime = record;


            }
        },
        filters: {
            toJalali(input) {
                return momentJalali(input, 'YYYY-MM-DD').format('jYYYY-jMM-jDD')
            }
        }
    }
</script>

<style scoped>

    .smooth-color {
        -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -ms-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
    }
</style>
<template>
  <v-card min-height="600">
    <v-dialog
        v-model="addCycle"
        max-width="700"
        persistent
    >
      <AddNewCycle
          :clientId="profile.id != null ? profile.id : $route.params.clientId "
          :isAdmin="true"
          :addCycleAction="'adminAddClientNewCycle'"
          @cycleAdded="getProfile"
          :dialog="true"
          @close="addCycle=false"
      ></AddNewCycle>
    </v-dialog>
    <v-window v-model="step">
      <v-window-item :value="1">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-card-title>
                دوره های درمان
                <v-spacer></v-spacer>
                <v-btn class="elevation-0" color="primary" small @click="addCycle=true"
                       v-if="checkRoleAndPermissions('admin.user.cycle.add')">
                  افزودن دوره جدید
                  <v-icon class="ma-1" small>fas fa-plus</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row v-if="cycles != null">
            <v-col v-for="cycle in cycles" :key="cycle.id" cols="4">
              <v-card>
                <v-card-title>
                  موضوع دوره :
                  {{ cycle.heading }}
                </v-card-title>
                <v-card-subtitle>
                  تعداد کل جلسات {{ cycle.sessionCount }}
                </v-card-subtitle>
                <v-card-actions>

                  <v-btn
                      color="purple"
                      text
                      @click="requestSession(cycle)"
                  >
                    درخواست جلسه
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                      text
                      color="primary"
                      @click="showDetails(cycle)"
                  >
                    جزئیات
                    <v-icon>{{
                        detailsToShow.includes(cycle.id) ? 'mdi-chevron-up' :
                            'mdi-chevron-down'
                      }}
                    </v-icon>
                  </v-btn>
                </v-card-actions>

                <v-expand-transition>
                  <div v-show="detailsToShow.includes(cycle.id)">
                    <v-divider></v-divider>

                    <v-card-text>
                      {{ cycle.problem_description }}
                    </v-card-text>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-col>
          </v-row>
          <!--<v-row>-->
          <!--<v-col cols="12">-->
          <!--<v-pagination-->
          <!--v-model="page"-->
          <!--:length="total"-->
          <!--&gt;</v-pagination>-->
          <!--</v-col>-->
          <!--</v-row>-->

        </v-container>
      </v-window-item>
      <v-window-item :value="2">
        <v-container v-if="selectedCycle!=null">
          <v-row>
            <v-col>
              <v-card-title>
                درخواست جلسه برای دوره
                <span v-if="cycles.length>1">
                                    <v-select
                                        :items="cycles"
                                        v-model="selectedCycle"
                                        label="دوره"
                                        return-object
                                        item-text="heading"
                                        item-value="id"
                                        outlined
                                        dense
                                        class="mt-7 mx-3"
                                    ></v-select>
                                </span>
                <span v-else class="mx-1 font-weight-bold">
                                    {{ selectedCycle.heading }}
                                </span>
                <v-spacer></v-spacer>

                <v-btn class="elevation-0" color="primary" small
                       @click="step=1">
                  <v-icon>mdi-chevron-right</v-icon>
                  بازگشت
                </v-btn>

              </v-card-title>

              <v-card class="elevation-0 mx-5 pt-0 mt-0">
                <v-card-title>
                  <div>شرح موضوع:</div>
                </v-card-title>
                <v-card-text class="mx-5">
                  {{ selectedCycle.problem_description }}
                </v-card-text>
              </v-card>

              <v-card-actions class="ma-5">
                <v-spacer></v-spacer>

              </v-card-actions>
              <v-divider></v-divider>

              <v-stepper v-model="e1">
                <v-stepper-header>
                  <v-stepper-step :complete="e1 > 1" step="1">تعداد جلسات</v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step :complete="e1 > 2" step="2">انتخاب مشاور</v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="3">ثبت زمان</v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-card
                        class="elevation-0"
                    >
                      <v-card-title>
                        به چند جلسه مشاوره احتیاج دارید؟

                      </v-card-title>

                      <v-card-text>
                        <v-row align="center" justify="center">
                          <v-col cols="12" lg="4" md="6" sm="8" class="text-center">
                            <v-form
                                ref="sessionCountForm"
                            >
                              <v-text-field

                                  outlined
                                  label="تعداد جلسات"
                                  placeholder="تعداد جلسات را وارد کنید"
                                  v-model="sessionCount"
                                  :rules="sessionCountRules"
                              ></v-text-field>
                              <v-row>
                                <v-col>
                                  <v-checkbox

                                      v-model="indebtUser"
                                      label="بدهکار کردن کاربر"
                                  >
                                  </v-checkbox>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-col>
                        </v-row>

                      </v-card-text>
                      <!--<v-card-subtitle class="my-0 py-1 " v-if="maxSessions != 0">-->
                      <!--* با توجه به بسته های خریداری شده شما، حداکثر مجاز به انتخاب-->
                      <!--{{maxSessions}} جلسه با هر مشاوری می باشید-->
                      <!--</v-card-subtitle>-->
                      <!--<template v-if="hasDoctorPackages">-->

                      <!--<v-card-subtitle class="py-1" v-for="pack in drPacks"-->
                      <!--:key="pack.id">-->
                      <!--{{maxDoctorsSessionText(pack)}}-->
                      <!--</v-card-subtitle>-->

                      <!--</template>-->


                      <v-card-actions class="mt-4">
                        <v-btn
                            color="primary"
                            @click="nextStep(2)"
                        >
                          ادامه
                        </v-btn>
                      </v-card-actions>
                    </v-card>


                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <v-card
                        class="mb-12 elevation-0"
                    >
                      <v-row>
                        <v-col cols="12">
                          <template v-if="!mustSelectDoctor">
                            لطفا یک گزینه را انتخاب نمایید و سپس بر روی دکمه ادامه کلیک کنید
                          </template>
                          <template v-else>
                            با توجه به نوع بسته های شما، باید از بین مشاور های موجود یکی را
                            انتخاب نمایید
                          </template>

                        </v-col>
                      </v-row>
                      <v-row class="text-center" justify="center">
                        <v-col cols="2"
                               v-if="canRequestNewDoctors"
                               class="text-center">
                          <v-card
                              class="text-center "
                              @click="selectNewDoctor"
                          >
                            <v-card-text>
                              <v-overlay
                                  v-if="newDoctor != false"
                                  absolute
                                  z-index="0"
                                  opacity=".6"

                                  color="rgba(0,250,150,.4)"
                              >

                              </v-overlay>

                              <template
                                  v-if="newDoctor != false"
                              >
                                <v-btn
                                    fab
                                    small
                                    v-if="newDoctor"
                                    class="green cursor-pointer green-check elevation-0"
                                >
                                  <v-icon class="white--text" small>
                                    fas fa-check
                                  </v-icon>

                                </v-btn>
                              </template>

                              <v-avatar
                                  :size="100"
                                  class="elevation-2"
                              >


                                <v-img
                                    v-if="platform.logo != null"
                                    :src="platform.logo"
                                ></v-img>
                              </v-avatar>
                            </v-card-text>

                            <v-card-text class="text-center title pa-0 ma-0">
                              <span>دکتر جدید</span>
                            </v-card-text>
                            <v-card-text>


                            </v-card-text>

                          </v-card>

                        </v-col>
                        <v-col v-for="doctor in doctors" :key="doctor.id" cols="2"
                               class="text-center">
                          <v-card
                              class="text-center "
                              @click="selectDoctor(doctor)"
                          >
                            <v-card-text>
                              <v-overlay
                                  v-if="selectedDoctor != null"
                                  absolute
                                  z-index="0"
                                  opacity=".6"
                                  :value="selectedDoctor.id == doctor.id"
                                  color="rgba(0,250,150,.4)"
                              >

                              </v-overlay>

                              <template
                                  v-if="selectedDoctor != null"
                              >
                                <v-btn
                                    fab
                                    small
                                    v-if="selectedDoctor.id == doctor.id"
                                    class="green cursor-pointer green-check elevation-0"
                                >
                                  <v-icon class="white--text" small>
                                    fas fa-check
                                  </v-icon>
                                </v-btn>
                              </template>


                              <v-avatar
                                  :size="100"
                                  class="elevation-2"
                              >

                                <v-img
                                    v-if="doctor.avatar != null"
                                    :src="doctor.avatar"
                                ></v-img>
                              </v-avatar>
                            </v-card-text>

                            <v-card-text class="text-center title pa-0 ma-0">
                              <span>{{ doctor.full_name }}</span>
                            </v-card-text>
                            <v-card-text>
                              {{ doctor.profession }}
                            </v-card-text>
                            <v-card-subtitle v-if="doctor.hasOwnProperty('total')">
                              تعداد بسته های شما با مشاور: {{ doctor.total }}
                            </v-card-subtitle>
                          </v-card>

                        </v-col>
                        <v-col cols="12">
                          <v-pagination
                              v-model="page"
                              :length="total"
                          ></v-pagination>
                        </v-col>
                      </v-row>

                      <!--<v-card-subtitle class="my-0 py-1 " v-if="maxSessions != 0">-->
                      <!--* با توجه به بسته های خریداری شده شما، حداکثر مجاز به انتخاب-->
                      <!--{{maxSessions}} جلسه با هر مشاوری می باشید-->
                      <!--</v-card-subtitle>-->
                      <!--<template v-if="hasDoctorPackages">-->

                      <!--&lt;!&ndash;<v-card-subtitle class="py-1" v-for="pack in drPacks"&ndash;&gt;-->
                      <!--&lt;!&ndash;:key="pack.id">&ndash;&gt;-->
                      <!--&lt;!&ndash;{{maxDoctorsSessionText(pack)}}&ndash;&gt;-->
                      <!--&lt;!&ndash;</v-card-subtitle>&ndash;&gt;-->

                      <!--</template>-->
                    </v-card>

                    <v-btn
                        color="primary"
                        @click="finalStep"
                    >
                      ادامه
                    </v-btn>

                    <v-btn @click="e1=1" text>قبلی</v-btn>
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <v-card
                        class="elevation-0"
                    >
                      <template v-if="selectedDoctor != null">

                        <v-row align="center" justify="center" v-if="doctorTimes!=null">
                          <v-col cols="12" class="text-center">
                            <template v-if="Object.keys(doctorTimes).length">
                              زمان مورد نظر خود را انتخاب نمایید
                            </template>
                            <template v-else>
                              درخواست جلسه شما به ثبت رسید، مشاور شما در حال حاضر زمان
                              خالی ندارد، لطفا تا خالی شدن زمان
                              مشاور صبر کنید
                              و بعدا اقدام به ثبت زمان نمایید
                            </template>

                          </v-col>

                        </v-row>
                        <template v-if="Object.keys(doctorTimes).length">
                          <DoctorTimes @timeSelected="selectTime"
                                       :maxSelectSessionTime="maxSelectSessionTime"
                                       :doctorTimes="doctorTimes"></DoctorTimes>
                        </template>


                      </template>
                      <template v-else>

                        <v-card-text class="green--text text-center title font-weight-bold">
                          <v-btn fab class="green white--text elevation-2 ma-4">

                            <v-icon>
                              fas fa-check
                            </v-icon>

                          </v-btn>
                          <div>
                            درخواست شما ثبت شد
                          </div>
                        </v-card-text>
                        <v-card-text class="text-center font-weight-bold ">
                          <p>
                            پس از انتخاب مشاور توسط متخصصین ما، از طریق
                            ایمیل به شما اطلاع رسانی می شود تا نسبت به انتخاب زمان جلسه
                            اقدام
                            نمایید.
                          </p>
                        </v-card-text>

                      </template>

                    </v-card>


                    <v-btn
                        color="primary"
                        @click="saveTimeForRequest"
                    >
                      <template v-if="!completed">
                        <span>ثبت</span>
                      </template>
                      <template v-else>
                        <span>بازگشت به استپ اول</span>
                      </template>
                    </v-btn>


                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>
    </v-window>

  </v-card>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "../../event-bus";
import {mapGetters} from "vuex";
import DoctorTimes from '@/components/clients/DoctorTimes'
import AddNewCycle from '@/components/clients/AddNewCycle'

export default {
  components: {
    DoctorTimes,
    AddNewCycle
  },
  props: ['profile'],
  data() {
    return {
      indebtUser: false,
      canRequestNewDoctors: false,
      detailsToShow: [],
      addCycle: false,
      doctors: [],
      sessionCount: 1,
      e1: 1,
      selectedCycle: null,
      selectedDoctor: null,

      // selectedCycle: {
      //     heading: "فردی",
      //     id: 1,
      //     problem_description: "مشکلات",
      //     sessionCount: 1,
      //     state: "requested"
      // },

      step: 1,
      show: false,
      cycles: [],
      page: 1,
      total: 1,
      doctorTimes: {},
      selectedSessionTime: null,
      selectedSessionTimes: [],
      maxSelectSessionTime: 0,
      sessions: null,
      completed: false,
      newDoctor: false,
      doctorsMatchMaking: [{
        "id": 1,
        "user_id": 27,
        "name": "\u062f\u06a9\u062a\u0631 \u06a9\u0627\u0645\u0631\u0627\u0646",
        "family": "\u062f\u06a9\u062a\u0631 \u0645\u0639\u0644\u0645",
        "full_name": "\u062f\u06a9\u062a\u0631 \u06a9\u0627\u0645\u0631\u0627\u0646 \u062f\u06a9\u062a\u0631 \u0645\u0639\u0644\u0645",
        "en_name": "Dr Moallem",
        "avatar": null,
        "timezone": "UTC",
        "platform_id": 3
      }, {
        "id": 2,
        "user_id": 462,
        "name": "\u0622\u0646\u0627\u0647\u06cc\u062a\u0627",
        "family": "\u0631\u062d\u0645\u0646\u06cc",
        "full_name": "\u0622\u0646\u0627\u0647\u06cc\u062a\u0627 \u0631\u062d\u0645\u0646\u06cc",
        "en_name": null,
        "avatar": null,
        "timezone": "UTC",
        "platform_id": 3
      }, {
        "id": 3,
        "user_id": 463,
        "name": "\u0645\u0648\u0646\u0627",
        "family": "\u0686\u0631\u0627\u063a\u06cc",
        "full_name": "\u0645\u0648\u0646\u0627 \u0686\u0631\u0627\u063a\u06cc",
        "en_name": null,
        "avatar": null,
        "timezone": "UTC",
        "platform_id": 3
      }, {
        "id": 4,
        "user_id": 464,
        "name": "\u0645\u062d\u0633\u0646",
        "family": "\u0645\u06cc\u0631\u0632\u0627\u06cc\u06cc",
        "full_name": "\u0645\u062d\u0633\u0646 \u0645\u06cc\u0631\u0632\u0627\u06cc\u06cc",
        "en_name": null,
        "avatar": null,
        "timezone": "UTC",
        "platform_id": 3
      }]
    }
  },
  created() {
    if (this.$route.name == "CrmUserInfo") {
      this.$store.dispatch('getUserProfile', {
        userid: this.$route.params.clientId
      }).then((resp) => {
        this.profile = resp.data.client
      })
    } else {
      this.profile = this.$store.getters.getClientProfile
    }
    this.cycles = this.profile.cycles;
    if (this.cycles != null) {
      if (this.cycles.length == 1) {
        this.selectedCycle = this.cycles[0];
        this.requestSession(this.selectedCycle);
      }
    }
  },
  computed: {
    hasPackages() {
      return this.hasDoctorPackages || this.hasGlobalPackages;
    },
    hasGlobalPackages() {
      return this.profile.wallet.global_packages > 0 ? this.profile.wallet.global_packages : false;
    },
    mustSelectDoctor() {
      return this.profile.wallet.global_packages == 0;
    },
    ...mapGetters({
      // profile: 'getUserInfo',
      platform: 'getPlatform',
      allDoctors: 'getAdminAllDoctors'
    }),
    sessionCountRules() {
      return [
        value => !!value || 'تعداد جلسات را وارد نمایید.',
        value => (value > 0) || 'تعداد جلسات حداقل باید 1 باشد',
        value => (value <= this.profile.wallet.total) || this.indebtUser || 'تعداد جلسات درخواستی باید از جلسات خریداری شده شما کمتر باشد',
      ];
    },
    maxSessions() {
      return this.profile.wallet.global_packages;
    },
    hasDoctorPackages() {
      return this.profile.wallet.drPackages.length;
    },

    drPacks() {
      return this.profile.wallet.drPackages;
    }
  },
  methods: {
    getProfile() {
      this.cycles = []
      this.$store.dispatch('getUserProfile', {
        userid: this.$router.currentRoute.params.clientId
      }).then((data) => {
        EventBus.$emit('clientProfileUpdated');
        this.userInfo = data.data.client;
        this.cycles = this.userInfo.cycles;
        if (this.userInfo.birthDate == null) {
          this.userInfo.birthDate = null;
        } else {
          this.userInfo.birthDate = moment(this.userInfo.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
        }
      })
    },
    selectNewDoctor() {
      this.selectedDoctor = null;

      this.newDoctor = !this.newDoctor;
    },
    maxDoctorsSessionText(doctor) {
      let text = '';
      text += `حداکثر مجاز به انتخاب ${this.profile.wallet.global_packages + doctor.total} جلسه با دکتر ${doctor.full_name} می باشید. `;
      return text;
    },
    showDetails(cycle) {
      if (this.detailsToShow.includes(cycle.id)) {
        this.detailsToShow.splice(this.detailsToShow.indexOf(cycle.id), 1);
      } else {
        this.detailsToShow.push(cycle.id);
      }
    },
    nextStep(step) {
      switch (step) {
        case 1:
          break;
        case 2:
          if (!this.hasPackages && !this.indebtUser) {
            EventBus.$emit('notify', 'red', undefined, 'کاربر هیچ بسته ای ندارد');
            return;
          }

          if (this.$refs.sessionCountForm.validate()) {
            this.e1++;
            //-----------------
            // if (this.doctors.length) {
            //     this.e1++;
            // } else {
            //     this.finalStep();
            // }

          }
          break;
        case 3:
          break;
      }

    },
    selectTime(hours) {
      // if (this.selectedSessionTimes.includes(hour)) {
      //     this.selectedSessionTimes.splice(this.selectedSessionTimes.indexOf(hour), 1);
      // } else {
      //     if (this.selectedSessionTimes < this.maxSelectSessionTime) {
      //         this.selectedSessionTimes.push(hour)
      //     } else {
      //         EventBus.$emit('notify', 'red', undefined, `شما نمیتوانید بیشتر از${this.maxSelectSessionTime} ساعت انتخاب کنید`);
      //     }
      // }

      this.selectedSessionTimes = hours;

    },
    saveTimeForRequest() {
      if (this.selectedDoctor != null) {

        if (!this.selectedSessionTimes.length && Object.keys(this.doctorTimes).length) {

          EventBus.$emit('notify', 'red', undefined, 'لطفا یکی از زمانهای مشاور را انتخاب نمایید');

        } else if (!this.selectedSessionTimes.length && Object.keys(this.doctorTimes).length == 0) {

          this.$router.push('sessions');

        } else {

          let payload = {
            times: this.selectedSessionTimes,
            sessions: this.sessions
          };
          let data = {
            payload,
            id: this.profile.id
          };

          this.$store.dispatch('adminSelectMultipleSessionRequestTime', data).then((resp) => {
            EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
          });
        }

      } else {
        // this.$router.push('sessions');
        if (this.$route.name == "CrmUserInfo") {
          EventBus.$emit('returnWindow')
        }
        this.e1 = 1;
      }
    },
    selectDoctor(doctor) {
      this.newDoctor = false;
      if (this.selectedDoctor == doctor) {
        this.selectedDoctor = null;
      } else {
        this.selectedDoctor = doctor;
      }
    },
    requestSession(cycle) {
      this.selectedCycle = cycle;
      this.step = 2;


    },
    // getCycles() {
    //     this.$store.dispatch('getCycles', {page: this.page}).then((resp) => {
    //         this.cycles = resp.data.cycles;
    //
    //         // if (this.cycles.length == 1) {
    //         //     this.selectedCycle = this.cycles[0];
    //         //     this.requestSession(this.selectedCycle);
    //         //     // this.step = 2;
    //         // }
    //     })
    // },
    getDoctors(cycle) {
      return new Promise((resolve) => {
        this.$store.dispatch('adminGetClientCycleDoctors', {
          cycleId: cycle.id,
          userId: this.profile.id,
          indebt: this.indebtUser,
          page: this.page
        }).then((resp) => {

          this.doctors = resp.data.results.doctors;
          this.total = resp.data.results.pagination.total_pages;

          this.selectedDoctor = null;
          this.newDoctor = false;

          if (this.doctors.length == 1) {
            this.selectedDoctor = this.doctors[0];
          }

          if (resp.data.selected_doctor != null) {
            this.selectedDoctor = this.doctors.find(doctor => doctor.id == resp.data.selected_doctor.id);
          }


          this.canRequestNewDoctors = resp.data.can_request_new_doctor;
          resolve();
        })
      })

    },
    finalStep() {
      if (!this.newDoctor && this.selectedDoctor == null) {
        EventBus.$emit('notify', 'red', undefined, 'لطفا یک گزینه را انتخاب نمایید');
        return;
      }

      // if (this.mustSelectDoctor) {
      //
      //     if (this.selectedDoctor == null) {
      //         EventBus.$emit('notify', 'red', undefined, 'لطفا ابتدا یکی از مشاوران را انتخاب نمایید');
      //         return;
      //     }
      //
      // }

      let options = {
        "cycle_id": this.selectedCycle.id,
        "session_count": this.sessionCount,
        "user_id": this.profile.id,
        'indebt': this.indebtUser
      };

      if (this.selectedDoctor != null) {
        options.doctor_id = this.selectedDoctor.id;

      } else {
        this.completed = true;
      }

      this.$store.dispatch('adminSendSessionRequest', options).then((resp) => {
        // this.$store.dispatch('getUserInfo');
        if (this.selectedDoctor == null) {
          this.completed = true;
        }

        if (resp.data.hasOwnProperty('doctorTimes')) {
          this.doctorTimes = resp.data.doctorTimes;
        }

        if (resp.data.hasOwnProperty('sessions')) {
          this.maxSelectSessionTime = resp.data.sessions.length;
          this.sessions = resp.data.sessions;
        }
        if (this.$route.name == 'CrmUserInfo') {
          this.$emit('updateWindow', 0)
          this.$nextTick().then(() => {
            this.e1 = 3;
          });
        } else {
          this.$nextTick().then(() => {
            this.e1 = 3;
          });
        }
      });
    }
  },
  watch: {
    '$route.params.clientId'() {
      this.profile = this.$store.getters.getClientProfile
      console.log(this.profile, 'sdfsfsdfsdfsdf')
    },
    indebtUser(val) {

      if (val)
        this.getDoctors(this.selectedCycle)

    },
    'profile.cycles'() {
      this.cycles = this.profile.cycles;
      if (this.cycles.length == 1) {
        this.selectedCycle = this.cycles[0];
        this.requestSession(this.selectedCycle);
      }
    },
    selectedCycle() {
      console.log('changed');
      this.getDoctors(this.selectedCycle).then(() => {
      })
    },

    step() {
      if (this.step == 1) {
        this.e1 = 1;
        this.newDoctor = false;
        this.sessionCount = 1;
        this.selectedDoctor = null;
      }

    },
    page() {
      // this.getCycles();
      this.getDoctors(this.selectedCycle);
    },

  }
}
</script>

<style scoped>
.green-check {
  position: absolute !important;
  top: -10px !important;
  right: -10px !important;
}

</style>

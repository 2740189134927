<template>
    <div>
        <v-dialog
                v-model="showDialog"
                width="500"
                class="pa-0 ma-0"
        >
            <v-card class="elevation-0 pa-0 ma-0">
                <v-card-title
                        class="headline grey lighten-2"
                        primary-title
                >
                    <span>ثبت زمان ها</span>
                </v-card-title>
                <v-card-text v-if="selected!=null" class="pa-0 ma-0">
                    <v-data-table :headers="headers" :items="selected.dateTime"
                                  class="elevation-0 pa-0 ma-0"
                                  mobile-breakpoint="400"
                                  :page.sync="page"
                                  :items-per-page="itemsPerPage"
                                  hide-default-footer
                                  @page-count="pageCount = $event"

                    >
                        <template v-slot:item.fullStartDate="{item}">
                            <v-row class="pa-0 ma-0">
                                <v-col class="pa-0 ma-0">
                                    <v-chip class="pa-1 ma-0">
                                        {{getSessionDate(item.fullStartDate)}}
                                    </v-chip>

                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.startHour="{item}">
                            <v-row dense class="pa-0 ma-0">
                                <v-col cols="6" dense class="pa-1">
                                    <v-text-field outlined hide-details dense label="دقیقه" v-model="item.startMinute">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6" dense class="pa-1">
                                    <v-text-field outlined hide-details dense label="ساعت" v-model="item.startHour">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.endHour="{item}">
                            <v-row dense class="pa-0 ma-0">
                                <v-col cols="6" dense class="pa-1">
                                    <v-text-field outlined hide-details dense label="دقیقه" v-model="item.endMinute">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6" dense class="pa-1">
                                    <v-text-field outlined hide-details dense label="ساعت" v-model="item.endHour">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </template>

                        <template v-slot:item.actions="{ item }">
                            <v-icon
                                    @click="removeSelectedTime(item)"
                            >
                                mdi-delete
                            </v-icon>
                        </template>

                    </v-data-table>
                    <v-pagination v-model="page" :length="pageCount"></v-pagination>

                </v-card-text>


                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                            class="green white--text"
                            @click="saveCycle"
                            :disabled="savingTimes"

                    >
                        ذخیره
                    </v-btn>
                    <v-btn @click="showDialog = false"
                           text
                    ><span>بستن</span></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-container class="pa-0">
            <v-container class="pa-0">
                <v-card class="elevation-1 pa-0" >
                    <v-card-text>
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header v-slot="{ open }">
                                    <v-row>
                                        <v-col cols="12" class="mb-3">
                                            اطلاعات کاربر
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            نام و نام خانوادگی :
                                            {{profile.firstname}}
                                            {{profile.lastname}}
                                        </v-col>
                                        <v-col cols="12" md="6" class="text--secondary">
                                            {{profile.email}}
                                        </v-col>

                                        <v-col cols="12" md="6" class="text--secondary">
                                            موبایل :
                                            <span style="display:inline-block;direction: ltr; text-align: left">{{profile.cel}}</span>
                                        </v-col>

                                        <v-col cols="12" md="6"
                                               class="text--secondary"
                                        >
                                            زمان محلی :
                                            {{profile.tz}}
                                        </v-col>

                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col cols="12" md="3" sm="6">
                                            <v-text-field label="نام" hide-details outlined dense read-only
                                                          :value="profile.firstname"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" sm="6">
                                            <v-text-field label="نام خانوادگی" hide-details outlined dense read-only
                                                          :value="profile.lastname"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" sm="6">
                                            <v-text-field label="نام و نام خانوادگی" hide-details outlined dense
                                                          read-only
                                                          :value="profile.full_name"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" sm="6">
                                            <v-text-field label="تاهل" hide-details outlined dense read-only
                                                          :value="profile.married"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" sm="6">
                                            <v-text-field label="تاریخ تولد" hide-details outlined dense read-only
                                                          :value="profile.birthDate"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" sm="6">
                                            <v-text-field label="ایمیل" hide-details outlined dense read-only
                                                          :value="profile.email"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" sm="6">
                                            <v-text-field label="زمان محلی" hide-details outlined dense read-only
                                                          :value="profile.tz"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" sm="6" v-if="profile.country != null">
                                            <v-text-field label="کشور" hide-details outlined dense read-only
                                                          :value="profile.country.name"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                        <v-row class="mt-4">
                            <v-col class="d-flex" lg="4" cols="12" sm="6">
                                <v-select
                                        :items="cycles"
                                        :value="'id'"
                                        v-model="selectedCycleId"
                                        label="دوره درمان"
                                        item-value="id"
                                        item-text="heading"
                                        :disabled="isRequesting"
                                        dense
                                        outlined
                                        @change="cycleChanged"
                                >
                                </v-select>
                            </v-col>
                            <v-col class="d-flex" lg="4" cols="12" sm="6">
                                <v-autocomplete
                                        v-model="selectedCounsellor"
                                        :items="allCounsellors"
                                        :loading="isLoading"
                                        item-value="doctor_profile.id"
                                        item-text="full_name"
                                        outlined
                                        hide-details
                                        dense
                                        label="مشاور"
                                        :disabled="isRequesting"
                                ></v-autocomplete>

                            </v-col>

                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small class="primary">
                            <a :href="'/fa/panel/clients/'+ profile.id" style="text-decoration: none;" target="_blank"
                               class="white--text">پروفایل مراجعه کننده</a>
                            <v-icon x-small class="mx-1"> fas fa-long-arrow-alt-left</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-container>

            <v-row class="mb-4">
                <!--<v-col cols="12">-->
                <!--موضوع مشاوره:-->
                <!--<v-chip class="mx-3" v-if="consultCat!=''">-->
                <!--{{consultCat}}-->
                <!--</v-chip>-->
                <!--</v-col>-->
                <v-col cols="12" v-if="selectedCycle!=null">
                    <div>شرح مشکل :</div>
                    <div class="px-3 mt-3">{{selectedCycle.problem_description}}</div>

                </v-col>
            </v-row>
            <v-row justify="center">

                <v-col class="py-0">
                    <v-switch class="ma-0"
                              inset
                              v-model="userTimesCheckbox"
                              label="کاربر"
                              color="blue"
                              @change="calcUserTimes"
                    ></v-switch>

                </v-col>
                <v-col class="py-0">
                    <v-switch class="ma-0"
                              inset
                              v-model="counselorTimesCheckbox"
                              label="مشاور"
                              color="green accent-3"
                              @change="calcCounselorTimes"
                    ></v-switch>

                </v-col>
                <v-col class="py-0">
                    <v-switch class="ma-0"
                              inset
                              v-model="suggestedTimesCheckbox"
                              label="پیشنهادی"
                              color="purple"
                              @change="calcSuggestedTimes"
                    ></v-switch>

                </v-col>

                <v-col class="py-0">
                    <v-switch class="ma-0"
                              inset
                              v-model="sessionTimesCheckbox"
                              label="جلسات"
                              color="grey darken-3"
                              @change="calcSessionTimes"
                    ></v-switch>

                </v-col>
                <v-col class="py-0">
                    <v-switch class="ma-0"
                              inset
                              v-model="showMutual"
                              label="مشترک"
                              color="light-green accent-3"
                              @change="mutualTimes"
                    ></v-switch>
                </v-col>

            </v-row>


            <!--<md-dialog :md-active.sync="showDialog" class="time-select-dialog" style="overflow: scroll">-->
            <!--<md-dialog-title class="dialog-title">ثبت زمان ها</md-dialog-title>-->

            <!--<div class="time-select-dialog-times">-->
            <!--&lt;!&ndash;<ul>&ndash;&gt;-->
            <!--&lt;!&ndash;<li v-for="time in selected.dateTime">{{time}}</li>&ndash;&gt;-->
            <!--&lt;!&ndash;</ul>&ndash;&gt;-->
            <!--<div>-->
            <!--<md-table>-->
            <!--<md-table-row>-->
            <!--<md-table-cell md-numeric>ردیف</md-table-cell>-->
            <!--<md-table-cell>تاریخ شروع</md-table-cell>-->
            <!--<md-table-cell>ساعت شروع</md-table-cell>-->
            <!--<md-table-cell>ساعت پایان</md-table-cell>-->
            <!--</md-table-row>-->


            <!--<template v-if="selected != null">-->
            <!--<md-table-row v-for="(time,index) in selected.dateTime" class="time-row"-->
            <!--:key="time.fullStartDate">-->
            <!--<md-table-cell class="mytablecells" md-numeric>{{index+1}}</md-table-cell>-->
            <!--<md-table-cell class="mytablecells">{{getSessionDate(time) | toJalali}}</md-table-cell>-->
            <!--<md-table-cell class="start-time mytablecells">-->
            <!--<input type="text" class="start-hour" placeholder="HH" v-model="time.startHour"> :-->
            <!--<input type="text" class="start-minutes" placeholder="MM"-->
            <!--v-model="time.startMinute">-->
            <!--</md-table-cell>-->
            <!--<md-table-cell class="mytablecells">-->
            <!--<input type="text" class="start-hour" placeholder="HH" v-model="time.endHour"> :-->
            <!--<input type="text" class="start-minutes" placeholder="MM" v-model="time.endMinute">-->
            <!--</md-table-cell>-->
            <!--<md-table-cell class="mytablecells">-->
            <!--<button @click="removeSelectedTime(time)" class="remove-time">X</button>-->
            <!--</md-table-cell>-->

            <!--</md-table-row>-->
            <!--</template>-->
            <!--</md-table>-->
            <!--</div>-->
            <!--</div>-->

            <!--<md-dialog-actions class="md-alignment-center text-center">-->
            <!--<md-button class="md-primary" @click="showDialog = false">بستن</md-button>-->
            <!--<md-button class="md-primary" @click="saveCycle" :disabled="savingTimes">ذخیره</md-button>-->
            <!--</md-dialog-actions>-->
            <!--</md-dialog>-->

            <v-dialog
                    ref="dialog"
                    v-model="pickerDialog"
                    width="290px"
            >
                <!--:return-value.sync="date"-->
                <v-date-picker v-model="date" scrollable
                               locale="fa"
                               first-day-of-week="6"
                >
                    <v-btn color="primary" @click="goToDate">تایید</v-btn>
                    <v-btn text color="red" @click="pickerDialog = false">بستن</v-btn>
                    <!--@click="$refs.dialog.save(date)"-->
                </v-date-picker>
            </v-dialog>

            <v-row class="pa-0">
                <v-col cols="12" class="pa-0">
                    <v-card class="elevation-0 pa-0" outlined
                            ref="calendar"
                    >
                        <v-card-title class="py-1 grey darken-2">
                            <!--@click="pickerDialog = true"-->
                            <v-btn class="px-1 mx-1"
                                   small
                                   :class="{'blue white--text':isCurrentWeek(startDate)}"
                            >

                                {{startDate.format("YYYY-M-DD") | toJalali}}

                            </v-btn>
                            <v-btn @click="goToCurrentWeek" class="pa-1 px-2 mx-1"
                                   :class="{'blue white--text':isCurrentWeek(startDate)}"
                                   small
                            >
                                <span v-if="!isCurrentWeek(startDate)"> رفتن به </span>
                                <span> هفته فعلی </span>
                            </v-btn>
                            <!--<v-chip class="pa-1 px-2 mx-1"-->
                            <!--label small>-->
                            <!--{{endDate.format("YYYY-M-DD") | toJalali}}-->
                            <!--</v-chip>-->

                            <v-spacer></v-spacer>

                            <v-btn small fab class="elevation-0 mx-1" color="grey darken-1 white--text"
                                   @click="lastWeek()">
                                <v-icon small>fas fa-long-arrow-alt-right</v-icon>
                            </v-btn>
                            <v-btn small fab class="elevation-0 mx-1" color="grey darken-1 white--text"
                                   @click="nextWeek()">
                                <v-icon small>fas fa-long-arrow-alt-left</v-icon>
                            </v-btn>
                        </v-card-title>

                        <v-card-text class="pa-0">
                            <v-card class="elevation-0">
                                <v-card id="flex-container" class="d-flex flex-row elevation-0"
                                        @mouseleave="mouseOut">

                                    <div class="calendar-overlay" :class="{'visible':isRequesting}">
                                        <div class="loading-holder"></div>
                                    </div>

                                    <!--Hours-->
                                    <v-card class="flex-col elevation-0" tile>
                                        <v-card tile class="elevation-0 flex-item hour-heading day-hour pt-7" ripple>
                                            روز/ساعت
                                        </v-card>
                                        <v-card tile v-for="hour in hours" class="flex-item hour-heading elevation-0"
                                                :class="{'axis':(hour== currentItemTime) ? true:false}" :key="hour">
                                            {{hour}}
                                        </v-card>
                                    </v-card>


                                    <v-card class="flex-col elevation-0 table-column" v-for="(time, index) in times"
                                            :key="index"
                                            tile>
                                        <v-card tile
                                                class="flex-item hour-heading  elevation-0 pt-2"
                                                ripple
                                                :class="{
                                                'heading-axis': (time.date == currentItemDate) ? true : false,
                                                'grey darken-3':currentDay == time.date
                                                }">

                                            <div class="breakable-word heading-text">
                                                {{time.dayName}}
                                            </div>
                                            <div class="breakable-word heading-text">
                                                {{time.date | toJalali}}
                                            </div>
                                            <div class="breakable-word heading-text">
                                                {{time.date | removeDashes}}
                                            </div>
                                        </v-card>
                                        <v-card

                                                tile
                                                class="flex-item hour-heading fixed-heading elevation-0 pt-2"
                                                ripple

                                                :class="{
                                                'heading-axis': (time.date == currentItemDate) ? true : false,
                                                'grey darken-3':currentDay == time.date,
                                                'sticky-visible':showStickyMenu
                                                }">

                                            <div class="breakable-word heading-text">
                                                {{time.dayName}}
                                            </div>
                                            <div class="breakable-word heading-text">
                                                {{time.date | toJalali}}
                                            </div>
                                            <div class="breakable-word heading-text">
                                                {{time.date | removeDashes}}<!--{{time.date | toJalali}}-->
                                            </div>
                                        </v-card>

                                        <v-card v-for="(hour,newIndex) in time.hours"
                                                v-bind:class="{
                                                        'blue accent-3 darken-2': hour.customerTime && !hour.consellorTime && !hour.sessionTime && !hour.notAvailable && !hour.isSelected,
                                                        'green accent-3 counsellor-time': hour.consellorTime && !hour.customerTime && !hour.sessionTime && !hour.notAvailable && !hour.isSelected,
                                                        'light-green accent-3': hour.isMutual && !hour.sessionTime && !hour.notAvailable && !hour.isSelected,
                                                        'grey darken-3': hour.sessionTime && !hour.notAvailable && !hour.isSelected,
                                                        'purple': hour.suggestedTime && !hour.customerTime && !hour.sessionTime && !hour.consellorTime && !hour.notAvailable && !hour.isSelected,
                                                        'grey lighten-1': hour.notAvailable,
                                                        'pink lighten-1': hour.isSelected,
                                                 }"
                                                @click="toggleTime(hour)"
                                                :key="newIndex"
                                                tile
                                                class="elevation-0 flex-item day-hour-bg"


                                        >

                                            <template v-if="hour.tooltip!= null && sessionTimesCheckbox">
                                                <div class="tooltip"
                                                     :class="{'tooltip-visible':hour.tooltipVisible}">
                                                    {{hour.tooltip.firstname}}
                                                    {{hour.tooltip.lastname}}
                                                </div>
                                            </template>
                                        </v-card>
                                    </v-card>

                                </v-card>
                            </v-card>

                        </v-card-text>
                        <v-card-actions>
                            <v-btn @click="showSelectedTimes" class="primary px-8" text>ثبت</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
    /*eslint-disable*/
    import moment from "moment-jalaali";
    import {EventBus} from "../../event-bus";

    export default {
        props: ['customer', 'activeCounselor', 'profile', 'cycles'],
        componenets: {
            moment
        },
        data() {
            return {
                showStickyMenu: false,

                user: {},
                isLoading: false,
                consultCat: null,
                consultDesc: null,
                selectedCycleId: null,
                selectedCycle: null,
                date: null,
                pickerDialog: false,
                pageCount: null,
                itemsPerPage: 5,
                page: 1,
                hourFactor: 2,
                minutesToAdd: 45,
                hours: [],
                times: [],
                radio: false,
                showMutual: true,
                timeGroups: {
                    customerTimes: [],
                    counselorTimes: [],
                    sessionTimes: [],
                    mutual: [],
                    suggestedTimes: []
                },
                showDialog: false,
                selected: null,
                showSnackbar: false,
                position: 'left',
                duration: 2000,
                isInfinity: false,
                snackbarMessage: "",
                allCounsellors: [],
                selectedCounsellor: "",
                userId: "",
                userProfile: null,
                userTimesCheckbox: true,
                sessionTimesCheckbox: true,
                suggestedTimesCheckbox: true,
                counselorTimesCheckbox: true,
                consultantProblem: '',
                problemCategoty: '',
                hasCycle: false,
                staticCurrentWeek: moment().week(),
                currentWeek: moment().week(),
                weeks: [],
                startDate: '',
                endDate: '',
                snackbarClass: '',
                snackbarDuration: 3,
                clientsTimes: null,
                currentItemTime: null,
                currentItemDate: null,
                isRequesting: false,
                savingTimes: false,
                currentDay: null,
                startOfCurrentWeek: moment().startOf('day').format('YYYY-MM-DD'),
                currentStartOfWeekIndex: moment().week(),


            }
        },
        mounted() {
            this.showSticky();

        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
            EventBus.$emit('setLoading', true);
            if (this.cycles.length) {
                this.selectedCycle = this.cycles[0];
                this.selectedCycleId = this.cycles[0].id;
                this.cycleChanged();
            }

            this.createHours();

            this.userId = this.$route.params.clientId;
            this.createWeekDays(this.currentWeek);

            this.getAllCounsellors().then(() => {

                if (this.activeCounselor != null) {
                    if (this.selectedCycleId != null) {
                        this.getCycle('&counselorId=' + this.selectedCounsellor + '&cycleId=' + this.selectedCycleId).then(() => {
                            this.processTimes(this.clientsTimes);
                        });
                    } else {
                        this.getCycle('&counselorId=' + this.selectedCounsellor).then(() => {
                            this.processTimes(this.clientsTimes);
                        });
                    }

                } else {
                    if (this.selectedCycleId != null) {

                        this.getCycle('&cycleId=' + this.selectedCycleId).then(() => {
                            this.processTimes(this.clientsTimes);
                        });

                    } else {

                        this.getCycle('').then(() => {
                            this.processTimes(this.clientsTimes);
                        });

                    }
                }
            });
            // this.selectedCounsellor = this.activeCounselor;


        },
        methods: {
            handleScroll() {
                this.showSticky();
            },
            showSticky() {
                let rect = this.$refs.calendar.$el.getBoundingClientRect();
                let bodyRect = document.body.getBoundingClientRect();
                let offset = rect.top - bodyRect.top;

                if (window.scrollY > offset) {
                    if (!this.showStickyMenu) {
                        this.showStickyMenu = true;
                    }
                } else {
                    if (this.showStickyMenu) {
                        this.showStickyMenu = false;
                    }
                }

                //
                // console.log('Element is ' + offset + ' vertical pixels from <body>');
                // // console.log(window.scrollY, this.$refs.calendar.$el.getBoundingClientRect().top);
                // if (window.scrollY > this.$refs.calendar.$el.getBoundingClientRect().top) {
                //
                // } else {
                //
                // }
            },
            getClientProfile(id) {
                this.$router.push({name: 'clientDetail', params: {clientId: id}});
            },
            cycleChanged() {

                this.selectedCycle = this.cycles.find((cycle) => cycle.id == this.selectedCycleId);

                this.consultCat = "";
                this.consultDesc = "";

                if (this.selectedCycle != undefined) {
                    // this.selectedCounsellor = this.selectedCycle.counselorId;
                }
            },
            isCurrentWeek(date) {
                return moment(date).format('YYYY-MM-DD') == this.startOfCurrentWeek;
            },
            goToCurrentWeek() {
                this.currentWeek = this.currentStartOfWeekIndex;
            },

            createHours() {
                var startDate = moment().startOf('day');
                var t = this.hourFactor * 24;

                while (t--) {
                    this.hours.push(startDate.format('HH:mm'));
                    startDate.add(60 / this.hourFactor, 'minute');
                }

                // var fromIndex = 2; // 01:00am
                // var toDates = dates.slice(fromIndex+1);

            },
            showTimes(items, store, hasTooltip, mutual = null) {
                let self = this;

                items.forEach(function (item) {

                    let startDate = moment(item.start).format("YYYY-M-DD");
                    let startTime = moment(item.start).format("HH:mm");
                    let endDate = moment(item.end).format("YYYY-M-DD");
                    let endTime = moment(item.end).format("HH:mm");

                    var timeGroup = {
                        start: {
                            day: "",
                            dayIndex: "",
                            hour: "",
                            hourIndex: ""
                        },
                        between: [],
                        end: {
                            day: "",
                            dayIndex: "",
                            hour: "",
                            hourIndex: ""
                        }
                    };

                    //look through all the cells of the calendar for each customer record in day
                    self.times.forEach(function (timeItem) {

                        if (timeItem.date == startDate) {

                            timeGroup.start.day = timeItem;

                            // timeGroup.start.dayIndex = timeIndex;
                            timeItem.hours.forEach(function (hourItem, hourIndex) {


                                // if (hourItem.time == null) {
                                //     console.log(timeItem.date, hourItem.time, startTime);
                                // }

                                if (hourItem.time == startTime) {

                                    if (!hourItem.timeId.includes(item.id)) {
                                        hourItem.timeId.push(item.id);
                                    }


                                    timeGroup.start.hour = hourItem;
                                    if (mutual != null) {
                                        hourItem.mutual[mutual] = true;
                                    }

                                    if (hasTooltip) {
                                        timeGroup.start.hour.tooltipVisible = true;
                                        timeGroup.start.hour.tooltip = item.user;
                                    }
                                }

                            });
                        }
                        if (moment(timeItem.date, "YYYY-M-DD").isSameOrAfter(moment(startDate, "YYYY-M-DD")) && moment(timeItem.date, "YYYY-M-DD").isSameOrBefore(moment(endDate, "YYYY-M-DD"))) {
                            timeItem.hours.forEach(function (hourItem, hourIndex) {
                                if (moment(hourItem.fullTime).isSameOrAfter(moment(item.start)) && moment(hourItem.fullTime).isBefore(moment(item.end))) {

                                    let betweenTime = {};
                                    if (mutual != null) {
                                        hourItem.mutual[mutual] = true;
                                    }

                                    if (!hourItem.timeId.includes(item.id)) {
                                        hourItem.timeId.push(item.id);
                                    }

                                    betweenTime.hour = hourItem;
                                    betweenTime.day = timeItem;


                                    timeGroup.between.push(betweenTime);

                                }
                            });
                        }

                        // if (timeItem)
                        //end time of one record in customer day
                        // if (timeItem.date == endDate) {
                        //     timeGroup.end.day = timeItem;
                        //
                        //     // timeGroup.end.dayIndex = timeIndex;
                        //     timeItem.hours.forEach(function (hourItem, hourIndex) {
                        //
                        //         if (hourItem.time == endTime) {
                        //             hourItem.mutual.customer = true;
                        //             timeGroup.end.hour = hourItem;
                        //         }
                        //     });
                        // }

                    });
                    if (timeGroup.start.hour != "") {
                        store.push(timeGroup);
                    }
                });
            },
            calculateMutuals() {
                let self = this;
                this.times.forEach(function (day, dayIndex) {
                    day.hours.forEach(function (hour, hourIndex) {
                        if (hour.mutual.counselor == true && hour.mutual.customer == true) {
                            self.timeGroups.mutual.push({
                                hour, day
                            });
                        }
                    });
                });
            },
            processTimes(res) {

                var self = this;

                //foreach day
                let item = res;
                // res.forEach(function (item, index) {
                this.showTimes(item.customerTimes, self.timeGroups.customerTimes, false, 'customer');
                this.showTimes(item.sessionTimes, self.timeGroups.sessionTimes, true, null);
                this.showTimes(item.timebooksShareTimes, self.timeGroups.suggestedTimes, false, null);
                this.showTimes(item.counselorTimes, self.timeGroups.counselorTimes, false, 'counselor');

                this.calculateMutuals();

                // this.showMutual = true;
                // this.userTimesCheckbox = true;
                // this.sessionTimesCheckbox = true;
                // this.counselorTimesCheckbox = true;
                // this.suggestedTimesCheckbox = true;
                this.mutualTimes();
                this.calcUserTimes();
                this.calcSessionTimes();
                this.calcCounselorTimes();
                this.calcSuggestedTimes();

            },
            toggleTime(item) {
                console.log(item);

                if (item.notAvailable) {
                    this.$store.commit('setSnackbar', {show: true, type: 'notReservable'});
                } else {
                    if (!item.sessionTime) {
                        item.isSelected = !item.isSelected;
                    } else {
                        this.$store.commit('setSnackbar', {show: true, type: 'notReservable'});
                    }
                }


            },
            getCycle: function (counselor) {
                let self = this;

                return new Promise((resolve, reject) => {
                    this.isRequesting = true;
                    let data = '?customerId=' + this.userId + counselor;

                    let userId = this.$route.params.userId;
                    let cycleId = this.selectedCycleId;
                    let doctorId = this.selectedCounsellor;
                    let sessionId = this.$route.params.requestId;
                    let options = {userId, cycleId, sessionId, doctorId};
                    // EventBus.$emit('setLoading', true);
                    this.$store.dispatch('getCycle', options)
                        .then((res) => {

                            console.log(res);
                            this.clientsTimes = res;
                            // this.cycle = res.cycle;

                            if (res['session_details'] != null) {
                                if (this.selectedCounsellor == "") {
                                    this.selectedCounsellor = res['session_details']['doctorId'];
                                }
                            }
                            // if (res.cycle != null) {
                            //
                            //
                            //     // if (res.cycle.hasOwnProperty('consultcat')) {
                            //     //     if (res.cycle.consultcat != "") {
                            //     //         this.consultCat = res.cycle.consultcat
                            //     //     } else {
                            //     //         this.consultCat = "";
                            //     //     }
                            //     // }
                            //     // if (res.cycle.hasOwnProperty('consultdesc')) {
                            //     //     if (res.cycle.consultdesc != "") {
                            //     //
                            //     //         this.consultDesc = res.cycle.consultdesc;
                            //     //     } else {
                            //     //         this.consultDesc = "";
                            //     //     }
                            //     // }
                            //
                            //
                            // } else {
                            //     this.consultCat = "";
                            //     this.consultDesc = "";
                            // }


                            resolve();
                        }).catch(err => {
                        console.log(err)
                    }).finally(() => {
                        this.isRequesting = false;
                        EventBus.$emit('setLoading', false);
                    });
                });
            },
            calcSessionTimes() {
                var time;
                time = this.timeGroups.sessionTimes;

                if (this.sessionTimesCheckbox) {
                    time.forEach(function (item, index) {
                        item.start.hour.sessionTime = true;
                        item.start.hour.isStart = true;
                        item.between.forEach(function (betweenTime, index) {
                            betweenTime.hour.sessionTime = true;
                            betweenTime.hour.isContinious = true;
                        });
                    });
                } else {
                    time.forEach(function (item, index) {
                        item.start.hour.sessionTime = false;
                        item.start.hour.isStart = false;
                        item.between.forEach(function (betweenTime, index) {
                            betweenTime.hour.sessionTime = false;
                            betweenTime.hour.isContinious = false;
                        });
                    });
                }
            },
            calcUserTimes() {
                var time;
                time = this.timeGroups.customerTimes;

                if (this.userTimesCheckbox) {
                    time.forEach(function (item, index) {
                        item.start.hour.customerTime = true;
                        // item.start.hour.consellorTime = false;
                        item.start.hour.isStart = true;
                        item.between.forEach(function (betweenTime, index) {
                            betweenTime.hour.customerTime = true;
                            // betweenTime.hour.consellorTime = false;
                            betweenTime.hour.isContinious = true;
                        });
                    });
                    if (this.counselorTimesCheckbox) {
                        this.timeGroups.mutual.forEach(function (item, index) {
                            item.hour.isMutual = true;
                        });
                    }
                } else {

                    this.timeGroups.customerTimes.forEach(function (item, index) {
                        item.start.hour.customerTime = false;
                        item.start.hour.isStart = false;
                        item.between.forEach(function (hourItem, hourIndex) {
                            hourItem.hour.customerTime = false;
                            hourItem.hour.isContinious = false;
                        })
                    });
                    if (!this.showMutual) {
                        this.timeGroups.mutual.forEach(function (item, index) {
                            item.hour.isMutual = false;
                        });
                    }
                }
            },

            calcCounselorTimes() {
                var time;
                var self = this;
                time = this.timeGroups.counselorTimes;

                if (this.counselorTimesCheckbox) {
                    time.forEach(function (item, index) {
                        item.start.hour.consellorTime = true;
                        // item.start.hour.customerTime = false;
                        item.start.hour.isStart = true;

                        item.between.forEach(function (betweenTime, index) {
                            betweenTime.hour.consellorTime = true;
                            // betweenTime.hour.customerTime = false;
                            betweenTime.hour.isContinious = true;
                        });
                    });

                    if (this.userTimesCheckbox) {
                        this.timeGroups.mutual.forEach(function (item, index) {
                            item.hour.isMutual = true;
                        });
                    }
                } else {

                    this.timeGroups.counselorTimes.forEach(function (item, index) {
                        item.start.hour.consellorTime = false;
                        item.start.hour.isStart = false;
                        item.between.forEach(function (hourItem, hourIndex) {
                            hourItem.hour.consellorTime = false;
                            hourItem.hour.isContinious = false;
                        });
                    });

                    if (!this.showMutual) {
                        this.timeGroups.mutual.forEach(function (item, index) {
                            item.hour.isMutual = false;
                        });
                    }
                }
            },
            calcSuggestedTimes() {
                var time;
                time = this.timeGroups.suggestedTimes;

                if (this.suggestedTimesCheckbox) {
                    time.forEach(function (item, index) {
                        item.start.hour.suggestedTime = true;
                        item.start.hour.isStart = true;
                        item.between.forEach(function (betweenTime, index) {
                            betweenTime.hour.suggestedTime = true;
                            betweenTime.hour.isContinious = true;
                        });
                    });
                } else {
                    time.forEach(function (item, index) {
                        item.start.hour.suggestedTime = false;
                        item.start.hour.isStart = false;
                        item.between.forEach(function (betweenTime, index) {
                            betweenTime.hour.suggestedTime = false;
                            betweenTime.hour.isContinious = false;
                        });
                    });
                }
            },
            calcTimes() {
                var time;
                var self = this;
                this.timeGroups.customerTimes.forEach(function (item, index) {

                    // console.log(item);
                    item.start.hour.customerTime = false;

                    item.start.hour.isStart = false;
                    // item.end.hour.customerTime = false;
                    // item.end.hour.isEnd = false;

                    item.between.forEach(function (hourItem, hourIndex) {
                        hourItem.hour.customerTime = false;
                        hourItem.hour.isContinious = false;
                    })

                });

                this.timeGroups.counselorTimes.forEach(function (item, index) {
                    item.start.hour.consellorTime = false;
                    item.start.hour.isStart = false;
                    // item.end.hour.consellorTime = false;
                    // item.end.hour.isEnd = false;

                    item.between.forEach(function (hourItem, hourIndex) {
                        hourItem.hour.consellorTime = false;
                        hourItem.hour.isContinious = false;
                    });
                });


                switch (this.radio) {
                    case "client" :
                        time = this.timeGroups.customerTimes;
                        break;
                    case "counsellor" :
                        time = this.timeGroups.counselorTimes;
                        break;
                    case "common" :
                        time = this.timeGroups.mutual;
                        break;
                    default :
                }

                if (this.radio == "client" || this.radio == "counsellor") {


                    time.forEach(function (item, index) {

                        if (self.radio == "client") {
                            item.start.hour.customerTime = true;
                            item.start.hour.consellorTime = false;
                        } else if (self.radio == "counsellor") {
                            item.start.hour.consellorTime = true;
                            item.start.hour.customerTime = false;
                        }
                        // item.start.hour.customerTime = true;
                        item.start.hour.isStart = true;

                        item.between.forEach(function (betweenTime, index) {
                            // betweenTime.hour.customerTime = true;
                            if (self.radio == "client") {
                                betweenTime.hour.customerTime = true;
                                betweenTime.hour.consellorTime = false;
                            } else if (self.radio == "counsellor") {
                                betweenTime.hour.consellorTime = true;
                                betweenTime.hour.customerTime = false;
                            }

                            betweenTime.hour.isContinious = true;
                        });

                        // if (self.radio == "client") {
                        //     item.end.hour.customerTime = true;
                        //     item.end.hour.consellorTime = false;
                        // } else if (self.radio == "counsellor") {
                        //     item.end.hour.customerTime = false;
                        //     item.end.hour.consellorTime = true;
                        // }

                        // item.end.hour.customerTime = true;
                        // item.end.hour.isEnd = true;

                    });

                    // this.timeGroups.counselorTimes.forEach(function (item, index) {
                    //     item.start.hour.consellorTime = true;
                    //     item.start.hour.isStart = true;
                    //
                    //     item.between.forEach(function (betweenTime, index) {
                    //         betweenTime.hour.consellorTime = true;
                    //         betweenTime.hour.isContinious = true;
                    //     });
                    //
                    //     item.end.hour.consellorTime = true;
                    //     item.end.hour.isEnd = true;
                    //
                    // });
                } else if (this.radio == "common") {

                    time.forEach(function (item, index) {
                        item.hour.isMutual = true;
                    });


                }
            },
            mutualTimes() {
                if (this.userTimesCheckbox && this.counselorTimesCheckbox) {
                    if (!this.showMutual) {
                        this.showMutual = false;
                    }
                } else {
                    if (this.showMutual) {
                        this.timeGroups.mutual.forEach(function (item, index) {
                            item.hour.isMutual = true;
                        });
                    } else if (!this.showMutual) {
                        this.timeGroups.mutual.forEach(function (item, index) {
                            item.hour.isMutual = false;
                        });
                    }
                }

            },
            showSelectedTimes: function () {

                if (this.selectedCounsellor != "") {

                    this.selected = {
                        counselor: this.selectedCounsellor,
                        customer: this.customer,
                        dateTime: []
                    };

                    var self = this;


                    this.weeks.forEach((days) => {
                        days.forEach(function (time, index) {
                            time.hours.forEach(function (hour, index) {

                                if (hour.isSelected == true) {

                                    let startHour = moment(hour.fullTime);
                                    let endHour = moment(hour.fullTime);

                                    endHour.add(45, 'minutes');

                                    var ob = {
                                        fullStartDate: startHour.format('YYYY-MM-DD HH:mm'),
                                        startDate: startHour.format('YYYY MM DD'),
                                        dashedStartDate: startHour.format('YYYY-MM-DD'),
                                        startHour: startHour.format('HH'),
                                        startMinute: startHour.format('mm'),
                                        endDate: endHour.format('YYYY MM DD'),
                                        dashedEndDate: endHour.format('YYYY-MM-DD'),
                                        endHour: endHour.format('HH'),
                                        endMinute: endHour.format('mm'),
                                        fullEndDate: endHour.format('YYYY-MM-DD HH:mm'),
                                        hour: hour
                                    };
                                    self.selected.dateTime.push(ob);
                                }

                            });

                        });
                    });


                    this.showDialog = true;
                    // if (this.selected.dateTime.length != 0) {
                    //
                    //     this.showDialog = true;
                    //     // this.selected = selected;
                    //
                    // } else {
                    //     this.$store.commit('setSnackbar', {show: true, type: 'noTimeChosen'});
                    // }
                } else {
                    this.$store.commit('setSnackbar', {show: true, type: 'chooseCounselor'});
                }


            },
            getSessionDate: function (fullStartDate) {
                return moment(fullStartDate).format('jYYYY-jMM-jDD');
            },
            getSessionTime: function (item) {
                return moment(item).format('HH:mm');
            },
            getSessionEndTime(time) {

                let tempTime = moment(time);
                return tempTime.add(45, 'minutes').format('HH:mm');
            },
            saveCycle() {

                // let requestId = this.$route.params.requestId;
                this.savingTimes = true;


                var time = {
                    // customerId: this.selected.customer,
                    // counselorId: this.selected.counselor,
                    counselorId: this.selectedCounsellor,
                    // cycleId: this.selectedCycleId,
                    dateTimes: [],
                    requestId: this.$route.params.requestId
                };


                this.selected.dateTime.forEach(function (item, index) {

                    item.fullEndDate = moment(item.fullEndDate).set({
                        'hour': item.endHour,
                        'minute': item.endMinute
                    }).format('YYYY-MM-DD HH:mm');
                    item.fullStartDate = moment(item.fullStartDate).set({
                        'hour': item.startHour,
                        'minute': item.startMinute
                    }).format('YYYY-MM-DD HH:mm');

                    time.dateTimes.push({
                        start: item.fullStartDate,
                        end: item.fullEndDate
                    });

                });


                this.$store.dispatch('saveCycleTimes', time).then((res) => {

                    this.removeAllSelectedTimes();
                    this.showDialog = false;
                    // this.selected = [];
                    this.showSnackbar = true;

                    this.$store.commit('setSnackbar', {show: true, type: 'success'});
                    // this.summonSnackbar({
                    //     class: 'md-success',
                    //     message: 'عملیات با موفقیت انجام شد',
                    //     snackbarDuration: 3
                    // })

                }).catch((err) => {
                    console.log(err);
                    this.showSnackbar = true;
                    this.snackbarMessage = 'پاسخی از سرور دریافت نشد';
                }).finally(() => {
                    this.savingTimes = false;
                });


            },
            getAllCounsellors: function () {
                // var data = '?usertype=counselor';
                this.isLoading = true;
                return new Promise((resolve, reject) => {
                    this.$store
                        .dispatch("getAllDoctors")
                        .then(res => {
                            this.allCounsellors = res.data.doctors;
                            resolve();
                        })
                        .catch(err => {
                            console.log(err);
                        }).finally(() => {
                        this.isLoading = false;
                    });
                });

            }
            ,
            counselorChanged: function () {


                this.removeCounselorTimes(true).then(() => {
                    // this.$store.commit('setSnackbar', {show: true, type: 'success'});
                    if (this.selectedCounsellor != "") {
                        if (this.selectedCycleId != null) {

                            this.getCycle('&counselorId=' + this.selectedCounsellor + '&cycleId=' + this.selectedCycleId).then(() => {
                                this.processTimes(this.clientsTimes);
                            });

                        } else {

                            this.getCycle('&counselorId=' + this.selectedCounsellor).then(() => {
                                this.processTimes(this.clientsTimes);
                            });

                        }

                    }

                });

                // this.$store.dispatch('getCounselorTimes', this.selectedCounsellor.id).then(resp => {
                //     console.log(resp);
                // }).catch(err => {
                //     console.log(err);
                // });
            }
            ,
            removeCounselorTimes(remove) {
                var self = this;
                return new Promise((resolve, reject) => {

                    this.weeks.forEach((day, index) => {
                        day.forEach((item) => {

                            item.hours.forEach((hour) => {
                                hour.isMutual = false;
                                hour.isSelected = false;
                                hour.sessionTime = false;
                                hour.suggestedTime = false;
                                hour.tooltip = null;
                                hour.mutual.counselor = false;
                                hour.mutual.customer = false;

                            })
                        });
                    });


                    this.timeGroups.mutual = [];

                    this.timeGroups.sessionTimes.forEach((item) => {
                        item.start.hour.sessionTime = false;
                        item.start.hour.isStart = false;
                        // item.end.hour.consellorTime = false;
                        // item.end.hour.isEnd = false;

                        item.between.forEach(function (hourItem) {
                            hourItem.hour.sessionTime = false;
                            hourItem.hour.isContinious = false;
                        });
                    });

                    this.timeGroups.counselorTimes.forEach(function (item) {
                        item.start.hour.consellorTime = false;
                        item.start.hour.isStart = false;
                        // item.end.hour.consellorTime = false;
                        // item.end.hour.isEnd = false;

                        item.between.forEach(function (hourItem) {
                            hourItem.hour.consellorTime = false;
                            hourItem.hour.isContinious = false;
                        });
                    });


                    // console.log("----------------------START-'DONE'----------------------");
                    // console.log('DONE');
                    // console.log("+-+-+-+-+-+-+-+-+-+-+-+-END-'DONE'+-+-+-+-+-+-+-+-+-+-+-");

                    //
                    // console.log("----------------------START-'CLEANING'----------------------");
                    // console.log('CLEANING');
                    // console.log("+-+-+-+-+-+-+-+-+-+-+-+-END-'CLEANING'+-+-+-+-+-+-+-+-+-+-+-");
                    this.timeGroups.mutual.forEach((item, index) => {
                        item.hour.isMutual = false;
                        item.hour.mutual.counselor = false;
                        item.hour.mutual.customer = false;
                        item.day.hours.forEach((hour, index) => {
                            hour.isMutual = false;
                            hour.mutual.counselor = false;
                            hour.mutual.customer = false;
                        });
                    });


                    this.timeGroups.suggestedTimes.forEach(function (item) {
                        item.start.hour.suggestedTime = false;

                        item.between.forEach(function (hourItem) {
                            hourItem.hour.suggestedTime = false;
                        });

                    });

                    if (remove) {
                        this.timeGroups.counselorTimes = [];
                        this.timeGroups.customerTimes = [];
                        this.timeGroups.sessionTimes = [];
                        this.timeGroups.suggestedTimes = [];
                    }

                    resolve();
                });
                // this.times.forEach(function (day, dayIndex) {
                //
                //     day.hours.forEach(function (hour, hourIndex) {
                //         if (hour.mutual.counselor == true && hour.mutual.customer == true) {
                //
                //             console.log("----------------------START-hour----------------------");
                //             console.log(hour);
                //             console.log("+-+-+-+-+-+-+-+-+-+-+-+-END-hour+-+-+-+-+-+-+-+-+-+-+-");
                //             self.timeGroups.mutual.push({
                //                 hour, day
                //             });
                //
                //         }
                //     });
                // });

            }
            ,
            createWeekDays(start) {
                this.date = moment().format('YYYY-MM-DD');

                // this.currentDay = moment().add(1, 'days').format('YYYY-M-DD');
                this.currentDay = moment().format('YYYY-M-DD');
                return new Promise((resolve, reject) => {

                    let week = (start - this.staticCurrentWeek) * 7;


                    this.startDate = moment().add(week, 'days');
                    this.endDate = moment().add(week + 7, 'days');
                    // this.currentDay = moment().format('YYYY-M-DD');

                    if (this.weeks[this.currentWeek] == undefined) {

                        this.weeks[this.currentWeek] = [];

                        for (var i = 0; i < 7; i++) {
                            var date = {};

                            let weekDay = moment().add(week, 'days').add(i, "days").startOf('day');
                            date.date = weekDay.format("YYYY-M-DD");
                            date.fullDate = weekDay.format();

                            date.isAfterToday = moment(date.date).isAfter(this.currentDay);
                            date.hours = [];
                            date.dayName = weekDay.format('ddd');
                            let startOfDay = Number(weekDay.format('H'));
                            let skipHours = startOfDay * this.hourFactor;


                            for (let j = 0; j < 24 * this.hourFactor; j++) {

                                let timeObject = {};
                                timeObject.timeId = [];
                                timeObject.isSelected = false;
                                timeObject.customerTime = false;
                                timeObject.consellorTime = false;
                                timeObject.sessionTime = false;
                                timeObject.suggestedTime = false;
                                timeObject.tooltip = null;
                                timeObject.tooltipVisible = false;
                                timeObject.isContinious = false;
                                timeObject.isStart = false;
                                timeObject.isEnd = false;
                                timeObject.isMutual = false;
                                timeObject.mutual = {
                                    customer: false,
                                    counselor: false
                                };

                                if (skipHours > 0) {
                                    timeObject.notAvailable = true;
                                    timeObject.fullTime = null;
                                    timeObject.time = null;
                                    skipHours--;
                                } else {
                                    if (j > 0 && j > startOfDay * this.hourFactor) {
                                        timeObject.fullTime = weekDay.add(30, 'minutes').format();
                                        timeObject.time = weekDay.format('HH:mm');

                                    } else {
                                        timeObject.fullTime = weekDay.format();
                                        timeObject.time = weekDay.format('HH:mm');
                                    }
                                }


                                timeObject.date = weekDay.format('YYYY-MM-DD');
                                date.hours.push(timeObject);
                            }
                            this.weeks[this.currentWeek].push(date);
                        }
                    }
                    this.times = this.weeks[this.currentWeek];
                    resolve();
                });
            }
            ,
            nextWeek() {
                this.currentWeek++;

            }
            ,
            goToDate() {

                let x = moment(this.date).week();
                //
                // if (x > 1) {
                //     x--;
                // } else {
                //     x = 52;
                // }
                //
                // this.pickerDialog = false;
                // this.currentWeek = x;
            }
            ,
            lastWeek() {
                // if (this.currentWeek > 0) {
                this.currentWeek--;
                // }
            }
            ,
            summonSnackbar(obj) {
                this.showSnackbar = true;
                this.snackbarMessage = obj.message;
                this.snackbarClass = obj.class;
                this.snackbarDuration = obj.snackbarDuration;
                setTimeout(() => {
                    this.showSnackbar = false;
                }, this.snackbarDuration * 1000);
            }
            ,
            hourClass(hour) {
                // return ;
                // return {
                //     'is-selected': hour.isSelected,
                //     'flex-item': true,
                //     'day-hour-bg': true,
                //     'is-continuous': hour.isContinious,
                //     'customer-time': hour.customerTime,
                //     'counsellor-time': hour.consellorTime,
                //     'mutual': hour.isMutual,
                //     'session-time': hour.sessionTime,
                //     'suggested-time': hour.suggestedTime,
                //     'hour-axis': hour.time == this.currentItemTime ? true : false,
                //     // 'vertical-axis': hour.date == this.currentItemDate ? true : false,
                //     // 'axis-target':(hour.time == this.currentItemTime) && (hour.date == this.currentItemDate)
                // };
            }
            ,
            mouseOver(hour) {

                // console.log(hour.time);
                this.currentItemTime = hour.time;
                this.currentItemDate = moment(hour.date, 'YYYY-MM-DD').format('YYYY-M-DD');

                if (hour.tooltip != null) {
                    hour.tooltipVisible = true;
                }

            }
            ,
            mouseOut(hour) {
                this.currentItemDate = null;
                this.currentItemTime = null;
                // console.log('removing');
                if (hour.tooltip != null) {
                    hour.tooltipVisible = false;
                }
            }
            ,
            removeAllSelectedTimes() {
                this.selected.dateTime.forEach((item, index) => {
                    item.hour.isSelected = false;
                });

                this.selected.dateTime = [];
            }
            ,
            removeSelectedTime(time) {

                time.hour.isSelected = false;
                this.selected.dateTime.splice(this.selected.dateTime.indexOf(time), 1);


                if (this.selected.dateTime.length == 0) {
                    this.showDialog = false;
                }

            }


        },
        computed: {

            headers() {
                return [
                    {text: 'تاریخ شروع', align: 'center', sortable: true, value: 'fullStartDate'},
                    {text: 'ساعت شروع', align: 'center', sortable: true, value: 'startHour'},
                    {text: 'ساعت پایان', align: 'center', sortable: true, value: 'endHour'},
                    {text: 'عملیات', value: 'actions', sortable: false},


                ];
            }
        },
        filters: {
            toJalali(date) {
                return moment(date, "YYYY-M-DD").format('jYYYY-jMM-jDD');
            },
            removeDashes(date) {
                return moment(date, "YYYY-M-DD").format('YYYY-MM-DD');

            }
        },
        watch: {
            selectedCounsellor() {

                this.counselorChanged();
            },
            currentWeek() {
                this.createWeekDays(this.currentWeek).then(() => {
                    this.processTimes(this.clientsTimes);
                });
            }
        }
    };


</script>
<style scoped>

    .flex-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;

    }

    .flex-item {

        /*flex-grow: 1;*/
        cursor: pointer;

        /*margin: 1px;*/
        /*margin-top: 1px;*/
        -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -ms-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        /*border-radius: 5px;*/

        text-align: center;
        padding: 10px;

        border-bottom: 1px solid rgba(50, 50, 50, .1);
    }

    .day-hour-bg:hover {

        /*background-position: 100% 100%;*/
        position: relative;

        -webkit-box-shadow: 0 3px 7px -3px rgba(0, 0, 0, .4);
        -moz-box-shadow: 0 3px 7px -3px rgba(0, 0, 0, .4);
        box-shadow: 0 3px 7px -3px rgba(0, 0, 0, .4);
    }

    .day-hour-bg {
        color: white;
        /*-webkit-box-shadow: 0 0px 3px 0px rgba(0, 0, 0, .4);*/
        /*-moz-box-shadow: 0 0px 3px 0px rgba(0, 0, 0, .4);*/
        /*box-shadow: 0 0px 3px 0px rgba(0, 0, 0, .4);*/
        /*background: linear-gradient(45deg, rgba(0, 170, 30, 1), rgba(0, 200, 200, 1) 50%);*/
        /*background-size: 300%;*/
        /*background-position: 0 100%;*/
    }

    .hour-heading span {
        display: inline-block;
        /*height: 20px;*/
    }

    .hour-heading {
        color: white;
        padding: 0;
        line-height: 20px;
        font-size: .8em;
        background-color: rgba(97, 97, 97, 1);

        border-bottom: 1px solid rgba(250, 250, 250, .3);
    }

    @media screen and (max-width: 768px) {
        .flex-item {
            margin: 1px;
        }
    }

    .flex-col {

        flex-grow: 1;
        display: flex;
        flex-direction: column;
        border-left: 1px solid rgba(50, 50, 50, .3);
        border-top: 1px solid rgba(50, 50, 50, .3);

    }

    .flex-col:last-of-type {
        border-left: unset;
    }

    .both-time {
        /*background-color: rgba(0, 200, 70, 1);*/
    }

    .selected-day-time {
        /*background-color: red;*/
    }

    .is-continuous {
        /*background-color: blue;*/
        /*margin-top: 0;*/
        /*margin-bottom: 0;*/
        /*padding-bottom: 11px;*/
        /*border: none!important;*/
        /*border-radius: 0;*/
    }

    .is-continuous.start {
        /*border-top-right-radius: 5px;*/
        /*border-top-left-radius: 5px;*/
    }

    .is-continuous.end {
        /*border-bottom-right-radius: 5px;*/
        /*border-bottom-left-radius: 5px;*/
    }

    .customer-time {
        /*background-color: rgba(0, 120, 200, 1);*/
        /*background-color: #535CD8 !important;*/
        border-bottom: 1px solid rgba(250, 250, 250, .5);
    }

    .counsellor-color {
        background-color: #3FB27E !important;
        color: white !important;
    }

    .session-color {
        background-color: rgba(120, 120, 120, 1);
        color: white;
    }

    .mutual-color {
        background-color: rgba(50, 250, 140, 1) !important;
        color: white;
    }

    .customer-color {
        background-color: #535CD8 !important;
        color: white;
    }

    .checkbox-labels {
        margin-bottom: 5px;
        min-width: 60px;
        display: inline-block;
        text-align: center;
        font-weight: normal;
        font-family: IranSans;
        cursor: pointer;
        border: 1px solid rgba(0, 0, 0, .3);
        border-radius: 5px;
    }

    @media screen and (min-width: 600px) {
        .checkbox-labels {
            padding: 5px 15px;
        }
    }

    .counsellor-time {
        /*background-color: rgba(180,100,10,1);*/
        /*background: linear-gradient(left,red, blue);*/
        /*background-color: #3FB27E !important;*/
        border-bottom: 1px solid rgba(250, 250, 250, .5) !important;
    }

    /*.counsellor-time.customer-time {*/
    /*background-color: rgba(0, 150, 0, 1);*/
    /*}*/
    .mutual {
        /*background-color: rgba(50, 250, 140, 1) !important;*/
        border-bottom: 1px solid rgba(250, 250, 250, .5);
        /*margin-top: 0;*/
        /*margin-bottom: 0;*/
        /*padding: 13px;*/
        /*border: none;*/
        /*border-radius: 0;*/
    }

    .is-selected {
        /*background-color: rgba(230, 100, 150, 1) !important;*/
        border-bottom: 1px solid rgba(250, 250, 250, .5);
    }

    /*.mutual.is-selected {*/
    /*background-color: rgba(0, 150, 50, 1);*/
    /*}*/

    /*.counsellor-time.is-selected {*/
    /*background-color: rgba(0, 150, 50, 1);*/
    /*}*/

    /*.customer-time.is-selected {*/
    /*background-color: rgba(0, 150, 50, 1);*/
    /*}*/

    .md-dialog {
        max-width: 768px;
    }

    .time-select-dialog {
        padding: 10px;
    }

    .time-select-dialog .dialog-title {
        text-align: right !important;
        direction: rtl !important;
        float: right !important;
        justify-content: end !important;
    }

    .start-hour, .start-minutes {
        width: 40px;
        padding: 10px;
        display: inline-block;
        text-align: center;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, .4);
        margin: 5px 10px;
    }

    @media screen and (max-width: 1036px) {
        .start-hour, .start-minutes {
            margin: 5px 5px;
        }
    }

    @media screen and (max-width: 875px) {
        .start-hour, .start-minutes {
            margin: 0;
            padding: 10px 5px;
        }
    }

    @media screen and (max-width: 360px) {
        .start-hour, .start-minutes {
            margin: 0;
            padding: 5px 0px;
            width: 30px;
        }
    }

    .day-hour {
        padding: 20px 0;
    }

    @media screen and (max-width: 513px) {
        .day-hour {
            padding: 30px 0;
        }
    }

    .mytablecells {
        padding: 0;
        margin: 0;
    }

    .centered-text {
        text-align: center !important;
    }

    .md-snackbar-content .centered-text {
        margin: 0 auto;
    }

    .mutual-checkbox {
        /*padding: 10px;*/
        width: 20px;
        height: 20px;
        display: none;

    }

    .calendar-controls {
        /*margin: 5px 0;*/
        /*padding: 0 10px;*/
    }

    .buttons {
        display: flex;
        align-items: start;
    }

    .button-right {
        border: 1px solid #0abb87;
        border-left: none;
        border-radius: 0 2.5px 2.5px 0;
    }

    .button-left {
        border: 1px solid #0abb87;
        border-radius: 2.5px 0 0 2.5px;
    }

    .button-icon {
        color: #0abb87;
    }

    .active-arrow {
        cursor: pointer;
    }

    .active-arrow:hover {
        background: #0abb87;
    }

    .active-arrow:hover .button-icon {
        color: white !important;
    }

    .disabled-arrow {
        cursor: not-allowed;
        background: #80808045;
    }

    .disabled-arrow .button-icon {
        color: #707070 !important;
    }

    .timespan {
        margin: 5px 10px;
    }

    .session-time {
        /*background-color: rgba(120, 120, 120, 1) !important;*/
        border-bottom: 1px solid rgba(250, 250, 250, .5);
    }

    .customer-time.session-time {
        background-color: rgba(120, 120, 120, 1);
    }

    .customer-time.counsellor-time.session-time {
        background-color: rgba(120, 120, 120, 1);
    }

    .calendar-control-buttons {
        margin-top: 25px;

    }

    .current-week-btn {
        display: inline-block;
        padding: 2px 10px;
        background-color: rgb(0, 150, 200);
        color: white;
        border-radius: 3px;
        cursor: pointer;
    }

    .tooltip {
        position: absolute;
        width: 100%;
        background-color: rgba(0, 0, 0, .2);
        left: 0;
        bottom: 0;
        z-index: 10;
        border-radius: 3px;
        height: 0px;
        -webkit-transition: all .2s ease-out;
        -moz-transition: all .2s ease-out;
        -ms-transition: all .2s ease-out;
        -o-transition: all .2s ease-out;
        transition: all .2s ease-out;
        overflow: hidden;

    }

    .tooltip-visible {
        height: 21px !important;
        opacity: 1 !important;
    }

    .day-hour-bg {
        position: relative;
    }

    .danger-text {
        background-color: red;
    }

    .axis {
        background-color: rgba(0, 0, 0, .75);
    }

    .hour-axis {
        background-color: rgba(0, 0, 0, .1);
    }

    .vertical-axis {
        background-color: rgba(0, 0, 0, .2);
    }

    .axis-target {
        background-color: rgba(150, 0, 0, .5);
    }

    .heading-axis {
        background-color: rgba(0, 0, 0, .75) !important;
    }

    .calendar-container {
        position: relative;
    }

    .calendar-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        top: 0;
        left: 0;
        z-index: 15;
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        -ms-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        opacity: 0;
        display: none;

    }

    .visible {
        opacity: 1;
        display: block;
    }

    .loading-holder {
        width: 80px;
        height: 80px;
        /*background-image: url('/img/loaderimg.gif');*/
        border-radius: 100px;
        left: calc(50% - 40px);
        top: calc(50% - 40px);
        position: absolute;
        background-size: 100%;
        -webkit-background-size: 100%;

    }

    .remove-time {
        border: none;
        cursor: pointer;
        color: red;
        background-color: rgba(255, 0, 0, .1);
        padding: 4px 7px;
        border-radius: 5px;
    }

    .suggested-time {
        background-color: rgba(200, 100, 250, 1) !important;
        border-bottom: 1px solid rgba(250, 250, 250, .8);
    }

    .suggested-color {
        background-color: rgba(200, 100, 250, 1) !important;
        color: white !important;
    }

    .not-available {
        /*background-color: rgba(100,100,100,1);*/
        background-position: center center;
        background-size: 40%;
        /*background-image: url('/img/pattern.png');*/
    }

    .breakable-word {
    }

    .table-column {
        position: relative;
    }

    .fixed-heading {
        position: fixed;
        top: 65px;
        z-index: 10;
        width: 9.6%;
        margin: 0 auto;
        -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -ms-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        opacity: 0;
    }

    .sticky-visible {
        opacity: 1;
    }

    @media screen and (max-width: 526px) {
        .hour-heading .heading-text {
            font-size: .6em!important;
        }
        .hour-heading.day-hour{
            padding: 20px 0;
        }
        .fixed-heading {
            width: 10.8%;
        }
    }


    @media screen and (min-width: 1250px) {

        .fixed-heading {
            /*width: 12%;*/
            width:151px;
        }
    }

    @media screen and (min-width:968px)  and (max-width: 1250px) {

        .fixed-heading {
            width:116px;
        }
    }

    @media screen and (min-width:526px)  and (max-width: 968px) {

        .fixed-heading {
            width: 12%;
        }
    }

</style>
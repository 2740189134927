<template>
  <div>
    <v-card-text v-if="clientProfile!=null">
      <v-expansion-panels v-model="panels" >
        <v-expansion-panel :key="0">
          <v-expansion-panel-header>
            <p class="font-weight-bold" style="display: block">
              اطلاعات کاربر</p>
          </v-expansion-panel-header>
          <v-row class="mx-2" v-if="newProfile != null" dense>
            <v-col cols="6">
              <p>
                نام و نام خانوادگی :
                {{ newProfile.full_name }}
              </p>
            </v-col>
            <v-col cols="6">
              <p>
                ایمیل :
                {{ newProfile.email }}
              </p>
            </v-col>
            <v-col cols="6">
              <p>
                موبایل :
                <span dir="ltr">{{ newProfile.cel }}</span>
              </p>
            </v-col>
            <v-col cols="6">
              <p>
                زمان محلی :
                <span dir="ltr">{{ newProfile.tz }}</span>
              </p>
            </v-col>
            <v-col cols="6" lg="4">
              <p>
                جلسات خریداری شده :
                <span>
                  {{ newProfile.packageDetails.all }}
                </span>
              </p>
            </v-col>
            <v-col cols="6" lg="4">
              <p>
                جلسات برگزار شده :
                <span>
                  {{ newProfile.packageDetails.used }}
                </span>
              </p>
            </v-col>
            <v-col cols="6" lg="4">
              <p>
                جلسات باقی ماند :
                <span>
                  {{ newProfile.packageDetails.available }}
                </span>
              </p>
            </v-col>
          </v-row>
          <v-expansion-panel-content>
            <v-form ref="profileForm">
              <v-row v-if="newProfile != null">
                <v-col cols="3">
                  <v-text-field outlined label="نام :" dense v-model="newProfile.firstname" :rules="requiredRules"/>
                </v-col>
                <v-col cols="3">
                  <v-text-field outlined label="نام خانوادگی :" dense v-model="newProfile.lastname"
                                :rules="requiredRules"/>
                </v-col>
                <v-col cols="3">
                  <v-select
                      dense
                      v-model="newProfile.married"
                      :items="married"
                      item-text="title"
                      item-value="value"
                      :label="$vuetify.lang.t('$vuetify.profileCivilStatus')"
                      :rules="requiredRules"
                      outlined
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      :value="toJalali(birth_date)"
                      outlined
                      label="تاریخ تولد"
                      prepend-icon="event"
                      readonly
                      @click="showDate=true"
                      :rules="requiredRules"
                      dense
                  >
                  </v-text-field>
                  <AdminDatePicker @close="showDate=false"
                                   :dialog="showDate"
                                   v-model="birth_date">
                  </AdminDatePicker>
                </v-col>
                <v-col cols="3">
                  <v-text-field outlined label="ایمیل :" dense v-model="newProfile.email" :rules="requiredRules"/>
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                      menu-props="auto"
                      :label="$vuetify.lang.t('$vuetify.profileTZ')"
                      v-model="newProfile.tz"
                      :items="timezones"
                      item-value="value"
                      dense
                      hide-details
                      single-line
                      outlined
                      :rules="requiredRules"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="3">
                  <v-autocomplete
                      v-model="newProfile.country"
                      :items="countries"
                      item-text="name"
                      item-value="id"
                      menu-props="auto"
                      dense
                      :label="$vuetify.lang.t('$vuetify.profileCountry')"
                      hide-details
                      single-line
                      :rules="requiredRules"
                      outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-btn @click="saveProfile" small color="primary" class="font-weight-bold"> ثبت</v-btn>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <br>
  </div>
</template>

<script>

/*eslint-disable*/
import timezones from "@/data/timezones";
import {EventBus} from "@/event-bus";
import momentJalali from "moment-jalaali";
import AdminDatePicker from "@/components/AdminDatePicker";


export default {
  props: {
    taskId: {
      required: false,
      default: null,
    },
    clientProfile: Object,
  },
  components: {
    AdminDatePicker
    // AdminDemographyComponent,
  },
  created() {
    this.getCountries();
    this.birth_date = this.clientProfile.birthDate
    this.newProfile = this.clientProfile
    console.log(this.newProfile, 'new loggier')


  },
  data() {
    return {
      panels: null,
      modal: false,
      birth_date: null,
      showDate: false,
      tabs: 0,
      panel: [],
      // clientProfile: null,
      seleted: [],
      countries: [],
      newProfile: {},
      country_id: 0,
      timezones: timezones,
      married: [
        {value: "single", title: 'مجرد'},
        {value: "married", title: 'متاهل'},
        {value: "in_relationship", title: 'رابطه عاطفی'},
        {value: "divorced", title: 'طلاق'},
        {value: "widowed", title: 'بیوه'},
        {value: "complicated", title: 'سایر'},
      ],
      rules: {
        required: value => !!value || 'پر کردن این فیلد الزامی است',
      }
    }
  },
  methods: {

    saveProfile() {
      if (!this.$refs.profileForm.validate()) {
        return;
      }
      let data = {
        birth_date: this.birth_date,
        name: this.newProfile.firstname,
        family: this.newProfile.lastname,
        mobile: this.newProfile.cel,
        timezone: this.newProfile.tz,
        "city": this.newProfile.city,
        "country_id": this.newProfile.country,
        "marital_state": this.newProfile.married,
        "education_level": this.newProfile.edu,
        "job": this.newProfile.job,
      };

      if ((typeof this.newProfile.country) == "object") {
        console.log('it is an object');
        if (this.newProfile.country.hasOwnProperty('id')) {
          console.log('has id');
          data.country_id = this.newProfile.country.id;
        } else {
          data.country_id = this.newProfile.country;
        }
      }

      if (this.taskId != null) {
        Object.assign(data, {
          'task_id': this.taskId
        })
      }
      EventBus.$emit('setLoading', true);
      this.$store.dispatch('saveUserProfile', {
        userId: this.$route.params.clientId,
        data
      }).then(() => {
        this.snackbar = true;
        this.$store.dispatch('getUserInfo').then(() => {
          this.seleted = []
          if (this.taskId != null) {
            this.$store.dispatch('doneTask', {
              id: this.$route.query.taskId,
              status: "done",
            }).then(() => {
              EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
              EventBus.$emit('updateClientInfo')
              this.$router.back()
              EventBus.$emit('returnWindow')
            })
          } else {
            EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
            EventBus.$emit('updateClientInfo')
          }

        });
      }).catch(() => {
        EventBus.$emit('clientProfileSaveError');
      }).finally(() => {
        EventBus.$emit('setLoading', false);
      });


    },
    selectForEdit(key) {
      console.log(this.seleted.indexOf(key))
      if (this.seleted.indexOf(key) == -1) {
        this.seleted.push(key)
        console.log(this.seleted)
      }
    },

    toJalali(date) {
      if (date) {
        return momentJalali(date, "YYYY-M-DD").format('jYYYY/jMM/jDD');
      }
      return date;
    },
    getCountries() {
      this.$store.dispatch('getCountries').then((resp) => {
        console.log(this.countries, 'sfsdfsdfdfsdf')
        this.countries = resp.data.countries;
      });
    }
  },

  watch: {
    // '$route'() {
    //   this.getClientInfo()
    // },
    'newProfile.country'(val) {
      this.countries.forEach((item) => {
        if (item.name == val) {
          this.country_id = item.id
        }
      })
    },
    '$route'() {
      this.getCountries();
    },
  },
  // updated() {
  //   // this.birth_date = this.clientProfile.birthDate
  //   this.newProfile = this.clientProfile
  // },
  computed: {
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
  }
}
</script>

<style scoped>

</style>
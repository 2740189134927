var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"my-8"},[_c('v-card-title',{ref:"adminReports"},[_vm._v(" گزارش پیش‌مشاوره "),_c('v-spacer'),_c('TableOptions',{attrs:{"hasExport":true,"searchFilters":_vm.searchFilters},on:{"applyFilters":_vm.applyFilters}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.users,"mobile-breakpoint":"300"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticStyle:{"cursor":"pointer"},attrs:{"to":{name:'clientDetail',params:{clientId:item.id}}}},[_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.family)+" ")])]}},{key:"item.refer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.refer.slice(0,25))+" ")]}},{key:"item.country_name",fn:function(ref){
var item = ref.item;
return [(item.country_name)?[_vm._v(" "+_vm._s(item.country_name)+" ")]:[(item.country)?[_vm._v(" "+_vm._s(item.country.name)+" ")]:_vm._e()]]}},{key:"item.familiar_by",fn:function(ref){
var item = ref.item;
return [(item.hasOwnProperty('document') && item.document)?[(item.document.hasOwnProperty('familiar_by'))?[_vm._v(" "+_vm._s(item.document.familiar_by)+" ")]:_vm._e()]:[_vm._v(" "+_vm._s(item.familiar_by)+" ")]]}},{key:"item.pre_session_date",fn:function(ref){
var item = ref.item;
return [(item.pre_session_date)?[_vm._v(" "+_vm._s(item.pre_session_date)+" ")]:_vm._e()]}},{key:"item.pre_session_status",fn:function(ref){
var item = ref.item;
return [(item.pre_session_status == 'used')?[_vm._v(" برگزار شده ")]:(item.pre_session_status == 'canceled')?[_vm._v(" لغو‌شده ")]:(item.pre_session_status == 'date_fixed')?[_vm._v(" در انتظار برگزاری ")]:[_vm._v(" "+_vm._s(item.pre_session_status)+" ")]]}},{key:"item.register_method",fn:function(ref){
var item = ref.item;
return [(item.from_presession_landing)?[_vm._v(" لندینگ پیش‌مشاوره ")]:(item.from_psytest)?[_vm._v(" تست‌ها ")]:[_vm._v(" پنل ")]]}},{key:"item.document.birth_date",fn:function(ref){
var item = ref.item;
return [(item.document.birth_date)?[_vm._v(" "+_vm._s(_vm.calculateAge(item.document.birth_date))+" ")]:_vm._e()]}},{key:"item.document.gender",fn:function(ref){
var item = ref.item;
return [(item.document.gender == 'male')?[_vm._v(" مرد ")]:(item.document.gender == 'female')?[_vm._v(" زن ")]:[_vm._v(" "+_vm._s(item.document.gender)+" ")]]}},{key:"item.pre_session_agent",fn:function(ref){
var item = ref.item;
return [(item.pre_session_agent)?[_vm._v(" "+_vm._s(item.pre_session_agent.name)+" "+_vm._s(item.pre_session_agent.family)+" ")]:_vm._e()]}},{key:"item.purchased_after",fn:function(ref){
var item = ref.item;
return [(item.purchased_after)?[_c('v-chip',{attrs:{"color":"success"}},[_vm._v(" خرید بعد از جلسه ")])]:[(item.package_count == 0)?[_c('v-chip',{attrs:{"color":"accent"}},[_vm._v(" بدون خرید ")])]:[_c('v-chip',{attrs:{"color":"warning"}},[_vm._v(" خرید قبل از جلسه ")])]]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.isDownloading(item))?_c('v-btn',{staticClass:"my-2 elevation-1",attrs:{"color":"primary","fab":"","small":""},on:{"click":function($event){return _vm.downloadReport(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-download")])],1):_c('div',{staticStyle:{"min-width":"50px"}},[_c('v-progress-linear',{attrs:{"query":""},model:{value:(_vm.getDownloading(item).value),callback:function ($$v) {_vm.$set(_vm.getDownloading(item), "value", $$v)},expression:"getDownloading(item).value"}})],1)]}}])})],1)],1),_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":_vm.total},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div>
        <Navbar></Navbar>
        <v-btn fab small class="back-to-top" v-if="scrollFabIsVisible" @click="$vuetify.goTo(0)">
            <v-icon>keyboard_arrow_up</v-icon>
        </v-btn>
        <router-view></router-view>
        THIS IS THE MAIN LAYOUT FOR WEBSITE
        <myfooter></myfooter>
    </div>
</template>

<script>
    // @ is an alias to /src
    import Navbar from '@/components/Navbar.vue';
    import Myfooter from '@/components/Myfooter'

    export default {
        name: 'mainlayout',
        components: {
            Navbar,
            Myfooter
        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        mounted() {

        },
        data() {
            return {
                lang: "",
                path: this.$route.name,
                drawer: true,
                // pageIsScrolled: false,
                scrollFabIsVisible: false,

            };
        },
        computed: {},
        watch: {},
        methods: {
            handleScroll() {
                if (window.scrollY > 100 && !this.scrollFabIsVisible) {

                    this.scrollFabIsVisible = true;

                } else if (window.scrollY <= 100 && this.scrollFabIsVisible) {

                    this.scrollFabIsVisible = false;

                }

            }
        }
    }
</script>

<style>
    .back-to-top {
        position: fixed;
        right: 20px;
        bottom: 20px;
        z-index: 2;
    }
</style>
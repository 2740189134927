<template>
  <div class="white fill-height notePage" style="overflow: scroll">
    <v-container>
    <div class="d-flex">
      <div>
        <h2 class="font-weight-bold mb-5 mt-2">یاداشت‌ها </h2>
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-btn fab text @click="NoteDialogHandler(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
    <v-divider></v-divider>
      <div v-for="(item , i) in notes" :key="i" class="singleNote mt-10" @mouseenter="selectingNote(item.id)">
        <h4>
          <v-btn fab elevation="0" small class="ml-2">
            <v-icon>mdi-account</v-icon>
          </v-btn>
          <span class="font-weight-bold">
            {{item.account_manager.full_name}}
          </span>
          در
          <span class="">
            ({{item.created_at | toJalali}})
          </span>
          <v-fade-transition>
            <v-btn color="mr-5" class="red--text" text  x-small elevation="0" v-if="selected == item.id" >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-fade-transition>
        </h4>
        <div class="line primary mt-2"></div>
        <div class="desc mt-5">
          {{item.description}}
        </div>
      </div>
    </v-container>
    <div class="addArea">
      <v-dialog
          v-model="addNoteDialog"
          persistent
          :fullscreen="$vuetify.breakpoint.smAndDown"
          :max-width='$vuetify.breakpoint.mdAndUp ? 500 : "" '
          transition="dialog-bottom-transition"
      >
        <AddNoteCRM @addDialogHandler="addDialogHandler($event)" @afterAddNote="getNotes"/>
      </v-dialog>
    </div>
    <v-btn fab fixed bottom left color="primary" @click="addDialogHandler(true)">
      <v-icon>
        mdi-plus
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import AddNoteCRM from "@/components/crm/note/addNoteCRM";
import moment from "moment-jalaali";
export default {
  components: {AddNoteCRM},
  data() {
    return {
      notes: [],
      selected:null,
      addNoteDialog:false,
    }
  },
  methods:{
    selectingNote(id){
      this.selected = id
    },
    addDialogHandler(val){
      this.addNoteDialog = val
    },
    NoteDialogHandler(val){
      this.$emit('NoteDialogHandler',{
        target:'NoteDialogHandler',
        status:val,
      })
    },
    getNotes(){
      this.$store.dispatch('getNotesClient',{
        clientId:this.$route.params.clientId
      }).then((resp) => {
        this.notes = resp.data.user_crm_note.data
      })
    }
  },
  mounted() {
    this.getNotes();
  },
  filters: {
    toJalali(date) {
      if (date) {
        return moment(date, "YYYY-M-DD").format('jYYYY/jMM/jDD');
      }
      return date;
    },
  },
}
</script>

<style scoped>
.notePage {
  position: relative;
  padding: 50px;
}

.notePage .closeBtn {
  position: absolute;
  top: 20px;
  left: 20px;
}

.singleNote {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  border: 1px solid #ededed;
  padding: 20px;
  border-radius: 10px;
}
.line{
  width: 100px;
  height: 3px;
  border-radius: 10px;
}
.notePage .desc{
  line-height: 25pt;
}
</style>
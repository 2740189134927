<template>
  <div>
    <template v-if="file_type == 'jpg'">
      <ImageTelegramLoader :message_id="message_id" :file_type="file_type"/>
    </template>
    <template v-else-if="file_type == 'mp4'">
      <VideoTelegramLoader :message_id="message_id" :file_type="file_type"/>
    </template>
    <template v-else-if="file_type == 'oga'">
      <VoiceTelegramLoader :message_id="message_id" :file_type="file_type"/>
    </template>
    <template v-else>
      <FileTelegramLoader :message_id="message_id" :file_type="file_type" :file_name="file_name"/>
    </template>
  </div>
</template>

<script>
/*eslint-disable*/
import Axios from "axios";
import ImageTelegramLoader from "@/components/Admin/telegram/ImageTelegramLoader";
import VideoTelegramLoader from "@/components/Admin/telegram/VideoTelegramLoader";
import VoiceTelegramLoader from "@/components/Admin/telegram/VoiceTelegramLoader";
import FileTelegramLoader from "@/components/Admin/telegram/FileTelegramLoader";

export default {
  components: {FileTelegramLoader, VoiceTelegramLoader, VideoTelegramLoader, ImageTelegramLoader},
  props: {
    message_id: Number,
    file_type: String,
    file_name: String,
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {

  }
}
</script>

<style scoped>

</style>
<template>
  <v-container class="overflow-x-hidden">
    <v-form ref="profileForm">
      <v-row class="justify-center center align-center text-center justify-center" style="position: relative">
        <v-col cols="12" lg="12" class="text-center">
          <v-avatar width="150" height="150">
            <!--                        <img src='./../../../assets/avatar-default.png' v-if="isNew || !userInfo.avatar"/>-->
            <img
                style="object-fit: cover"
                :src="imgUrl"
                alt="avatar"
            >
          </v-avatar>
        </v-col>

        <v-col cols="12" sm="6" class="addImg">
          <input ref="imgInput" class="d-none" type="file" @change="onFileSelected"
                 accept="image/gif, image/jpeg, image/png">
          <v-btn small class="mx-2" fab dark color="primary" @click="$refs.imgInput.click()">
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card-text class="pa-0 font-weight-bold">
            {{ $vuetify.lang.t('$vuetify.profileInformationTitle') }}
          </v-card-text>
        </v-col>
      </v-row>
      <v-row class="px-md-10">
        <v-col cols="12" sm="6" md="6">
          <v-text-field
              v-model="userInfo.name"
              outlined
              :label="$vuetify.lang.t('$vuetify.profileFirstname')"
              :rules="requiredRules"

          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
              v-model="userInfo.family"
              outlined
              :label="$vuetify.lang.t('$vuetify.profileLastname')"
              :rules="requiredRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
              v-model="userInfo.en_name"
              outlined
              label="نام انگلیسی"
              :rules="requiredRules"

          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
              v-model="userInfo.en_family"
              outlined
              label="نام خانوادگی انگلیسی"
              :rules="requiredRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
              v-model="userInfo.mobile"
              outlined
              style="direction: ltr"
              :label="$vuetify.lang.t('$vuetify.profileMobile')"
              :rules="requiredRules"
          ></v-text-field>
        </v-col>
        <!--                <v-col cols="12" sm="6" md="6">-->
        <!--                    <v-text-field-->
        <!--                            disabled-->
        <!--                            v-model="userInfo.email"-->
        <!--                            outlined-->
        <!--                            :label="$vuetify.lang.t('$vuetify.profileEmail')"-->
        <!--                    ></v-text-field>-->
        <!--                </v-col>-->
        <v-col cols="12" sm="6" md="6">
          <v-text-field
              :readonly="$router.currentRoute.name == 'profile'"
              v-model="userInfo.email"
              outlined
              :label="$vuetify.lang.t('$vuetify.profileEmail')"
              :rules="emailRules"
          ></v-text-field>
        </v-col>
        <!--                <v-col cols="12" sm="6" md="6" v-if="isNew">-->
        <!--                    <v-select-->
        <!--                            :items="speciality"-->
        <!--                            item-value="val"-->
        <!--                            item-text="cap"-->
        <!--                            v-model="userInfo.speciality"-->
        <!--                            outlined-->
        <!--                            :label="$vuetify.lang.t('$vuetify.profileSpeciality')"-->
        <!--                            :rules="requiredRules"-->
        <!--                    ></v-select>-->
        <!--                </v-col>-->
        <v-col cols="12" sm="6" md="6">
          <v-autocomplete
              prepend-inner-icon="map"
              outlined
              v-model="userInfo.timezone"
              :items="timezones"
              item-value="value"
              menu-props="auto"
              :label="$vuetify.lang.t('$vuetify.profileTZ')"
              hide-details
              single-line
              :rules="requiredRules"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="6" v-if="isNew">
          <v-text-field
              v-model="userInfo.password"
              outlined
              :label="$vuetify.lang.t('$vuetify.passwordLabel')"
              :rules="passwordRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6" v-if="isNew">
          <v-text-field
              v-model="confirmPassword"
              outlined
              :label="$vuetify.lang.t('$vuetify.passwordConfirmationLabel')"
              :rules="passwordConfirmRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-select
              v-model="userInfo.marital_state"
              :items="married"
              item-text="title"
              item-value="value"
              :label="$vuetify.lang.t('$vuetify.profileCivilStatus')"
              outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-select
              v-model="userInfo.gender"
              :items="genders"
              item-text="title"
              item-value="value"
              label="جنسیت"
              outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-select
              v-model="userInfo.religious"
              :items="religious"
              item-text="title"
              item-value="value"
              label="مذهب"
              outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
              v-model="userInfo.resume_link"
              outlined
              label="لینک رزومه"
              :rules="requiredRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
              v-model="userInfo.skype_id"
              outlined
              label="آی دی اسکایپ"
              :rules="requiredRules"
          ></v-text-field>
        </v-col>


        <v-col cols="12" sm="6" md="6">
          <v-text-field
              v-model="userInfo.psy_sys_number"
              outlined
              label="شماره نطام پزشکی"
          ></v-text-field>
        </v-col>


        <v-col cols="12" sm="6" md="6">
          <v-text-field
              v-model="userInfo.post"
              outlined
              label="سمت"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
              v-model="userInfo.university"
              outlined
              label="دانشگاه"
          ></v-text-field>
        </v-col>


        <v-col cols="12" sm="6" md="6">

          <v-text-field
              outlined
              :value="userInfo.birth_date | toJalali"
              :label="'تاریخ تولد'"
              prepend-icon="event"
              readonly
              @click="showDialog=true"
          ></v-text-field>
          <AdminDatePicker @close="showDialog=false"
                           :dialog="showDialog"
                           @input="showDialog = false"
                           v-model="userInfo.birth_date"
          ></AdminDatePicker>
        </v-col>


        <v-col cols="12" sm="6" md="6">
          <v-text-field
              v-model="userInfo.ltv_rate"
              outlined
              label="ltv_rate"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
              v-model="userInfo.churn_rate"
              outlined
              label="نرخ ریزش"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
              v-model="userInfo.average_time_between_sessions"
              outlined
              label="میانگین زمان بین جلسات"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
              v-model="userInfo.rating_by_clients"
              outlined
              label="امتیاز کاربران (1 تا 5)"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
              v-model="userInfo.empathy_compassion_score"
              outlined
              label="empathy_compassion_score"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-select
              v-model="userInfo.age_group"
              outlined
              :items="ages"
              item-text="title"
              item-value="key"
              label="بازه سنی"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-select
              v-model="userInfo.major_field"
              outlined
              :items="categories"
              item-text="title"
              item-value="key"
              label="دسته‌بندی"
          ></v-select>
        </v-col>

      </v-row>
      <!--<v-row>-->
      <!--<v-col cols="12">-->
      <!--<v-card-text class="pa-0 font-weight-bold">-->
      <!--{{$vuetify.lang.t('$vuetify.profileExplanation')}}-->
      <!--</v-card-text>-->
      <!--</v-col>-->
      <!--</v-row>-->
      <!--<v-row class="px-md-10">-->
      <!--<v-col cols="12">-->
      <!--<v-textarea-->
      <!--rows="1"-->
      <!--outlined-->
      <!--v-model="userInfo.description"-->
      <!--&gt;</v-textarea>-->
      <!--</v-col>-->
      <!--</v-row>-->

      <template v-if="!adding && checkRoleAndPermissions('admin.doctor.resume.show')">
        <v-row>
          <v-col cols="12">
            <v-card-text class="pa-0 font-weight-bold">
              {{ $vuetify.lang.t('$vuetify.profileResume') }}
            </v-card-text>
          </v-col>
        </v-row>

        <v-row class="px-md-10" v-if="checkRoleAndPermissions('admin.doctor.resume.add')">
          <v-col cols="12">
            <v-text-field
                label="افزودن به رزومه"
                v-model="newItem.title"
                outlined
            >
              <template v-slot:append>
                <v-fade-transition leave-absolute>
                  <v-chip class="mb-3" label color="primary" @click="addToResume">
                    <v-icon>mdi-plus</v-icon>
                  </v-chip>
                </v-fade-transition>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="px-md-10" v-for="(item, index) in resume" :key="`resume-${index}`">
          <v-col cols="12" class="py-0">
            <v-card
                class="grow py-0"
                flat
            >
              <v-card-actions class="py-0">
                <v-container class="px-0 py-0">
                  <v-row class="my-0">
                    <v-col sm="8" md="8">
                      <p class="text--secondary my-0">{{ item.title }}</p>
                    </v-col>
                    <v-col sm="4" md="4" class="text-end">
                      <v-icon class="mx-2" color="success" @click="editResume(item)"
                              v-if="checkRoleAndPermissions('admin.doctor.resume.update')">
                        mdi-square-edit-outline
                      </v-icon>
                      <v-icon class="mx-2" color="red" @click="selToDelete(item)"
                              v-if="checkRoleAndPermissions('admin.doctor.resume.delete')">mdi-close
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

      </template>
      <!--            <v-snackbar-->
      <!--                    :color="snackbarType"-->
      <!--                    id="snackbar-calendar"-->
      <!--                    bottom-->
      <!--                    v-model="snackbar"-->
      <!--                    :timeout="timeout"-->
      <!--            >-->
      <!--                {{ text }}-->
      <!--                <v-btn-->
      <!--                        fab-->
      <!--                        :color="snackbarType"-->
      <!--                        @click="snackbar = false"-->
      <!--                >-->
      <!--                    <v-icon>{{ iconType }}</v-icon>-->
      <!--                </v-btn>-->
      <!--            </v-snackbar>-->
      <!--                <Snackbar :type="type" :snackbar="snackbar"></Snackbar>-->
    </v-form>

    <v-dialog
        v-model="dialogDeleteConfirm"
        max-width="500px"
    >
      <v-card>
        <v-card-text>
          <h3 class="pb-0 pt-5 text-center">از حذف این مورد از رزومه اطمینان دارید؟</h3>
          <v-spacer></v-spacer>
        </v-card-text>
        <v-card-actions class="justify-center py-5">
          <v-btn color="primary" @click="removeResume(delResume)">
            بله
          </v-btn>
          <v-btn color="primary" outlined @click="dialogDeleteConfirm = false">
            خیر
          </v-btn>
        </v-card-actions>

        <v-checkbox
            class="py-2 px-2"
            v-model="notAsk"
            label="دوباره این سوال را نپرس"
            color="primary"
            :value="notAsk"
            hide-details
        ></v-checkbox>
      </v-card>
    </v-dialog>

    <AlertsComponent></AlertsComponent>
  </v-container>
</template>

<script>
/*eslint-disable*/
import {EventBus} from '../../../event-bus.js';
import countries from "../../../data/countries";
import timezones from "../../../data/timezones";
import moment from 'moment-timezone';
import momentJalali from 'moment-jalaali';
import AlertsComponent from "@/components/AlertsComponent";
import AdminDatePicker from '@/components/AdminDatePicker';
// import Snackbar from "../../Snackbar";

export default {
  beforeDestroy() {
    this.$store.dispatch('setEditingDoctorId', null)
    EventBus.$off('saveProfileEvent', this.saveProfile);
    EventBus.$off('resetProfileEvent', this.resetProfile);
  },
  created() {

    this.adding = this.$router.currentRoute.name == 'addCounselors' ? true : false;
    if (this.$router.currentRoute.name == 'profile') {
      this.admin = false;
    } else {
      this.admin = true;
      this.counselorId = this.$router.currentRoute.params.counselorId;
    }

    if (!this.adding) {
      this.getResume();
    }
    this.getUserInfo();

    let x = JSON.parse(JSON.stringify(timezones));
    this.timezones = x.map((item) => {
      item.text = item.text + ' ' + moment.tz(item.text).format('Z');
      return item;
    });

    EventBus.$on('saveProfileEvent', this.saveProfile);
    EventBus.$on('resetProfileEvent', this.resetProfile);
  },
  components: {
    AlertsComponent,
    AdminDatePicker

    // Snackbar
  },
  data() {
    return {
      ages: [
        {
          title: '20-30',
          key: '20_30'
        },
        {
          title: '30-40',
          key: '30_40'
        },
        {
          title: '40-50',
          key: '40_50'
        },
        {
          title: '50-60',
          key: '50_60'
        },
        {
          title: '60 به بالا',
          key: '60_and_up'
        },
      ],
      categories: [
        {
          title: 'فردی',
          key: 'individual',
        },
        {
          title: 'زوج\n',
          key: 'couple',
        },
        {
          title: 'خانواده\n',
          key: 'family',
        },
        {
          title: 'کودک \n',
          key: 'child',
        },
        {
          title: 'نوجوان\n',
          key: 'teenager',
        },
      ],
      showDialog: false,
      married: [
        {value: "single", title: 'مجرد'},
        {value: "married", title: 'متاهل'},
        {value: "in_relationship", title: 'رابطه عاطفی'},
        {value: "divorced", title: 'طلاق'},
        {value: "widowed", title: 'بیوه'},
        {value: "complicated", title: 'سایر'},
      ],
      emailRules: [
        v => !!v || this.$vuetify.lang.t('$vuetify.emailErrRequired'),
        v => /.+@.+\..{2}/.test(v) || this.$vuetify.lang.t('$vuetify.emailErrValid'),
      ],
      adding: false,
      admin: null,
      mode: 'add',
      newItem: {
        id: null,
        title: null
      },
      dialogDeleteConfirm: false,
      notAsk: false,
      avatarImg: '',
      newImage: null,
      genders: [
        {title: 'مرد', value: 'MALE'},
        {title: 'زن', value: 'FEMALE'},
      ],
      religious: [
        {title: 'مذهبی', value: 1},
        {title: 'غیر مذهبی', value: 0},
      ],
      // gender: '',
      countries,
      states: ['Tehran', 'Qazvin', 'Ahvaz'],
      timezones: timezones,
      city: '',
      education: [
        {value: "none", title: 'فاقد مدرک'},
        {value: "diploma", title: 'دیپلم'},
        {value: "associate", title: 'فوق دیپلم'},
        {value: "bachelor", title: 'لیسانس'},
        {value: "mastery", title: 'فوق لیسانس'},
        {value: "Doctorate", title: 'دکتری'}
      ],
      knway: [
        {value: "google", title: "جست و جو در  گوگل"},
        {value: "twitter", title: "توییتر"},
        {value: "instagram", title: "اینستاگرام"},
        {value: "podcast", title: "پادکست"},
        {value: "bannerads", title: "تبلیغات بنری"},
        {value: "friends", title: "معرفی دوستان و آشنایان"},
        {value: "site", title: "سایت"},
        {value: "other", title: "موارد دیگر"},
      ],
      speciality: [
        {cap: "اختلالات شخصیت", val: "اختلالات شخصیت"},
        {cap: "خانواده، ازدواج، زوج درمانی", val: "خانواده، ازدواج، زوج درمانی"},
        {cap: "فردی", val: "فردی"},
        {cap: "کودک و نوجوان، رشد، فرزند پروری", val: "کودک و نوجوان، رشد، فرزند پروری"},
        {cap: "مهارت های زندگی", val: "مهارت های زندگی"}
      ],
      userInfo: {},
      resume: [],
      delResume: null,
      locale: this.$store.getters.getLocal,
      modal: false,
      snackbar: false,
      type: 'success',
      // timeout: 2000,
      confirmPassword: '',
      counselorId: ''
    };
  },
  computed: {
    passwordRules() {
      return [
        v => !!v || this.$vuetify.lang.t('$vuetify.passwordErrRequired'),
        v => v.length >= 8 || 'حداقل طول کلمه عبور 8 کاراکتر می باشد'
      ];
    },
    passwordConfirmRules() {
      return [
        v => !!v || 'پر کردن فیلد تکرار پسورد الزامیست',
        v => (v === this.userInfo.password) || 'کلمه عبور و تکرار آن یکسان نمی باشند'
      ];
    },
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
    isNew() {
      return this.$router.currentRoute.name == 'addCounselors' ? true : false;
    },
    imgUrl() {
      if (!this.userInfo.hasOwnProperty('avatar') && this.newImage == '') {
        return require('./../../../assets/avatar-default.png');
      } else if (this.newImage) {
        return this.newImage;
      } else {
        return this.userInfo.avatar;
      }
    }
  },
  methods: {
    getResume() {
      if (!this.admin) {
        this.$store.dispatch('counselorGetResume').then((resp) => {
          this.resume = resp.data;
          this.newItem.id = '';
          this.newItem.title = '';
        })
      } else {

        this.$store.dispatch('adminCounselorGetResume', this.counselorId).then((resp) => {
          this.resume = resp.data;
          this.newItem.id = '';
          this.newItem.title = '';
        })
      }
    },
    getUserInfo() {
      if (this.$router.currentRoute.name == 'profile') {
        EventBus.$emit('setLoading', true);
        this.$store.dispatch('getUserInfo').then(() => {
          this.userInfo = this.$store.getters.getUserInfo;
        }).finally(() => {
          EventBus.$emit('setLoading', false);
        })
      } else if (this.$router.currentRoute.name == 'counselorProfile') {
        EventBus.$emit('setLoading', true);
        this.$store.dispatch('getCounselorProfile', {
          userid: this.$router.currentRoute.params.counselorId
        }).then((data) => {
          this.userInfo = data.data.doctor;
          this.$store.dispatch('setEditingDoctorId', this.userInfo.id)
          this.setResumeLinkAndSkypeId(data.data.doctor)
        }).finally(() => {
          EventBus.$emit('setLoading', false);
        })
      } else if (this.$router.currentRoute.name == 'addCounselors') {
        this.userInfo = {}
      }

    },
    setResumeLinkAndSkypeId(profile) {
      console.log(profile.doctor_profile.skype_link)
      this.userInfo.skype_id = profile.doctor_profile.skype_id
      this.userInfo.resume_link = profile.doctor_profile.resume_link
      this.userInfo.post = profile.doctor_profile.post
      this.userInfo.university = profile.doctor_profile.university
      this.userInfo.psy_sys_number = profile.doctor_profile.psy_sys_number
      this.userInfo.ltv_rate = profile.doctor_profile.ltv_rate
      this.userInfo.churn_rate = profile.doctor_profile.churn_rate
      this.userInfo.average_time_between_sessions = profile.doctor_profile.average_time_between_sessions
      this.userInfo.rating_by_clients = profile.doctor_profile.rating_by_clients
      this.userInfo.empathy_compassion_score = profile.doctor_profile.empathy_compassion_score
      this.userInfo.age_group = profile.doctor_profile.age_group
      this.userInfo.major_field = profile.doctor_profile.major_field
    },
    saveProfile() {

      let data = {
        "name": this.userInfo.name,
        "family": this.userInfo.family,
        "mobile": this.userInfo.mobile,
        "timezone": this.userInfo.timezone,
        "marital_state": this.userInfo.marital_state,
        "religious": this.userInfo.religious,
        "gender": this.userInfo.gender,
        "birth_date": this.userInfo.birth_date,
        "en_name": this.userInfo.en_name,
        "en_family": this.userInfo.en_family,
        "resume_link": this.userInfo.resume_link,
        "skype_id": this.userInfo.skype_id,
        "psy_sys_number": this.userInfo.psy_sys_number,
        "post": this.userInfo.post,
        "university": this.userInfo.university,
        "ltv_rate": this.userInfo.ltv_rate,
        "churn_rate": this.userInfo.churn_rate,
        "average_time_between_sessions": this.userInfo.average_time_between_sessions,
        "rating_by_clients": this.userInfo.rating_by_clients,
        "empathy_compassion_score": this.userInfo.empathy_compassion_score,
        "age_group": this.userInfo.age_group,
        "major_field": this.userInfo.major_field,
      };


      if (this.$refs.profileForm.validate()) {

        if (this.$router.currentRoute.name == 'profile') {

          // console.log('counselor is saving', this.userInfo);
          //
          if (this.newImage != null) data.avatar = this.newImage;
          this.$store.dispatch('saveCounselorProfile', data).then(() => {
            this.$store.dispatch('getUserInfo').then(() => {
              this.userInfo = this.$store.getters.getUserInfo;
            })
            this.snackbar = true;
            this.type = 'success'
            this.$store.commit('setSnackbar', {show: true, type: 'success'});
          });

          this.snackbar = true;
        } else if (this.$router.currentRoute.name == 'counselorProfile') {
          console.log('admin is saving', this.userInfo);

          // let data = {
          //     "name": this.userInfo.firstname,
          //     "family": this.userInfo.lastname,
          //     "mobile": this.userInfo.cel,
          //     "timezone": this.userInfo.tz
          // };

          if (this.newImage != null) data.avatar = this.newImage;

          this.$store.dispatch('AdminSaveCounselorProfile', {
            userid: this.$router.currentRoute.params.counselorId,
            data
          }).then((resp) => {
            this.setResumeLinkAndSkypeId(resp.data.doctor)
            EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');

          });
        } else if (this.$router.currentRoute.name == 'addCounselors') {


          if (this.userInfo.password == this.confirmPassword) {


            let data = {
              name: this.userInfo.name,
              family: this.userInfo.family,
              mobile: this.userInfo.mobile,
              email: this.userInfo.email,
              gender: this.userInfo.gender,
              speciality: this.userInfo.speciality,
              timezone: this.userInfo.timezone,
              password: this.userInfo.password,
              password_confirmation: this.confirmPassword,
              marital_state: this.userInfo.marital_state,
              "birth_date": this.userInfo.birth_date,
              "religious": this.userInfo.religious,
              "en_name": this.userInfo.en_name,
              "en_family": this.userInfo.en_family,
              "resume_link": this.userInfo.resume_link,
              "skype_id": this.userInfo.skype_id,
              "psy_sys_number": this.userInfo.psy_sys_number,
              "post": this.userInfo.post,
              "university": this.userInfo.university,
              "ltv_rate": this.userInfo.ltv_rate,
              "churn_rate": this.userInfo.churn_rate,
              "average_time_between_sessions": this.userInfo.average_time_between_sessions,
              "rating_by_clients": this.userInfo.rating_by_clients,
              "empathy_compassion_score": this.userInfo.empathy_compassion_score,
              "age_group": this.userInfo.age_group,
              "major_field": this.userInfo.major_field,
              // "psy_sys_number": this.userInfo.psy_sys_number,
              // "post": this.userInfo.post,
              // "university": this.userInfo.university
              // description: this.userInfo.description,
              // resume: this.userInfo.resume,
            };

            if (this.newImage != null) data.avatar = this.newImage;

            EventBus.$emit('setLoading', true);
            this.$store.dispatch('addNewCounselor', data).then((resp) => {

              EventBus.$emit('notify', 'green', undefined, 'مشاور با موفقیت اضافه شد');
              this.$router.push({name: "counselors"})

            }).finally(() => {
              EventBus.$emit('setLoading', false);
            });
          } else {
            // EventBus.$emit('notify', 'red', undefined, 'کلمه عبو');
          }
        }
      }
    },
    resetProfile() {
      console.log('Reseting');
      this.userInfo = {};
    },
    addToResume() {

      if (!this.admin) {

        if (this.mode == 'add') {
          let item = {
            title: this.newItem.title
          };
          EventBus.$emit('setLoading', true);
          this.$store.dispatch('counselorAddToResume', item).then(() => {
            EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
            this.getResume();
            this.newItem.id = '';
            this.newItem.title = '';
          }).finally(() => {
            EventBus.$emit('setLoading', false);
          });
        } else if (this.mode == 'edit') {
          let data = {
            id: this.newItem.id,
            payload: {
              title: this.newItem.title
            }
          };

          EventBus.$emit('setLoading', true);
          this.$store.dispatch('counselorEditResumeItem', data).then(() => {
            EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
          }).finally(() => {
            this.model = 'add';
            this.getResume();
            EventBus.$emit('setLoading', false);
          });
        }

        // if (!this.userInfo.hasOwnProperty('resume')) this.userInfo.resume = [];
        // if (this.resume !== '') this.userInfo.resume.unshift(this.resume);
        // this.resume = '';
      } else {

        if (this.mode == 'add') {
          let data = {
            doctorId: this.counselorId,
            payload: {
              title: this.newItem.title
            }
          };
          EventBus.$emit('setLoading', true);
          this.$store.dispatch('adminCounselorAddToResume', data).then(() => {
            EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
            this.getResume();
            this.newItem.id = '';
            this.newItem.title = '';
          }).finally(() => {
            EventBus.$emit('setLoading', false);
          });
        } else if (this.mode == 'edit') {
          let data = {
            itemId: this.newItem.id,
            doctorId: this.counselorId,
            payload: {
              title: this.newItem.title
            }
          };

          EventBus.$emit('setLoading', true);
          this.$store.dispatch('adminCounselorEditResumeItem', data).then(() => {
            EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
          }).finally(() => {
            this.model = 'add';
            this.getResume();
            EventBus.$emit('setLoading', false);
          });
        }
      }
    },
    selToDelete(item) {
      this.removeResume(item);
      if (this.notAsk) {
      } else {
        this.dialogDeleteConfirm = true;
        this.delResume = item;
      }
    },
    removeResume(item) {
      if (!this.admin) {
        EventBus.$emit('setLoading', true);
        this.$store.dispatch('counselorRemoveFromResume', item.id).then((resp) => {
          EventBus.$emit('notify', 'green', undefined, 'با موفقیت حذف شد.');
          this.getResume();
        }).finally(() => {
          EventBus.$emit('setLoading', false);
        });
        this.dialogDeleteConfirm = false;

      } else {
        EventBus.$emit('setLoading', true);
        this.$store.dispatch('adminCounselorRemoveFromResume', {
          doctorId: this.counselorId,
          itemId: item.id
        }).then((resp) => {
          EventBus.$emit('notify', 'green', undefined, 'با موفقیت حذف شد.');
          this.getResume();
        }).finally(() => {
          EventBus.$emit('setLoading', false);
        });
        this.dialogDeleteConfirm = false;
      }

    },
    editResume(item) {
      this.mode = 'edit';
      this.newItem = item;
    },
    onFileSelected(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]).then(resp => {
        this.avatarImg = resp;
        this.newImage = resp;
        this.userInfo.avatar = resp;
      });
    },
    createImage(file) {
      return new Promise((resolve) => {
        let reader = new FileReader();
        reader.onload = e => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    },
  },

  filters: {
    toJalali(date) {
      if (date) {
        return momentJalali(date, "YYYY-M-DD").format('jYYYY/jMM/jDD');
      }
      return date;
    },
  }
}
</script>

<style>
.v-dialog {
  overflow-y: hidden;
}
</style>

<style scoped>
.addImg {
  position: absolute;
  right: 250px;
  bottom: 0
}

@media screen and (max-width: 768px) {
  .addImg {
    right: 150px;
    bottom: 0
  }
}

@media screen and (max-width: 500px) {
  .addImg {
    right: auto;
    bottom: 0;
    left: 40px;
  }
}
</style>

<template>
  <div>
    <v-dialog width="500px" v-model="modalAddComment">
      <v-card>
        <v-card-title>
          افزودن یاداشت
        </v-card-title>
        <v-card-text>
          <v-form ref="addNoteForm">
            <v-textarea class="" :rules="requiredRules" v-model="note" outlined dense label="یادداشت">
            </v-textarea>
          </v-form>
          <v-btn color="primary" @click="addNotes">
            ثبت
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="addCommentsDialog"
        fullscreen
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-container>
          <div style="position: absolute;bottom: 20px;left: 20px" @click="modalAddComment = true">
            <v-btn color="primary" elevation="0" fab>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
          <div class="noteArea">
            <div class="d-flex">
              <div>
                <h2 class="font-weight-bold mb-5 mt-2">یاداشت‌ها </h2>
              </div>
              <v-spacer></v-spacer>
              <div>
                <v-btn fab text @click="addCommentsDialog=false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
            <hr>
            <v-fade-transition mode="out-in">
              <div class="notes mt-5" v-if="!noteLoader">
                <div class="note" v-for="(note , i ) in notes" :key="i">
                  <div class="d-flex justify-space-between">
                    <div class="dateNote pt-1">
                      {{ note.created_at | dateFilter }}
                    </div>
                    <div class="accountManagerNote">
                      {{ note.account_manager.full_name }}
                      <template v-if="profile.id == note.account_manager.id ">
                        <v-btn x-small fab elevation="0" text @click="deleteNote(note.id)">
                          <v-icon size="15" color="red">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                    </div>
                  </div>
                  <div class="noteContent">
                    {{ note.description }}
                  </div>
                </div>
                <v-pagination :length="pagination.total" v-model="pagination.page"></v-pagination>
              </div>
              <div v-else>
                <div class="mt-10 text-center">
                  <v-progress-circular indeterminate></v-progress-circular>
                </div>
              </div>
            </v-fade-transition>
          </div>
        </v-container>
      </v-card>
    </v-dialog>

    <v-container v-show="$router.currentRoute.name != 'addCounselors'">
      <v-card>
        <v-card-title>
          <v-btn color="primary" :to="{name: 'counselorCalendar'}">ثبت زمان</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" elevation="0" fab small class="ml-1" @click="openModalNotes">
            <v-icon>mdi-note</v-icon>
          </v-btn>
          <ChangePassword v-if="checkRoleAndPermissions('admin.doctor.changepassword')"
                          :changePasswordAction="'adminChangeCounselorPassword'"
                          :userId="$route.params.counselorId"></ChangePassword>
        </v-card-title>
      </v-card>


    </v-container>

    <counselor-profile></counselor-profile>
  </div>
</template>

<script>
import CounselorProfile from "../../components/profiles/counselorProfile";
import ChangePassword from '@/components/ChangePassword';
import {EventBus} from "@/event-bus";
import {mapGetters} from "vuex";
import momentJalali from "moment-jalaali";

export default {
  data() {
    return {
      modalAddComment: false,
      note: null,
      addCommentsDialog: false,
      noteLoader: true,
      pagination: {
        total: 1,
        page: 1,
      }
    }
  },
  created() {
    this.getNotes()
  },
  methods: {
    deleteNote(id) {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('deleteNote', id).then(() => {
        EventBus.$emit('notify', 'green', undefined, 'با موفقیت به حذف شد');
        this.getNotes()
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    openModalNotes() {
      this.addCommentsDialog = true
    },
    addNotes() {
      if (this.$refs.addNoteForm.validate()) {
        this.$store.dispatch('addNoteClient', {
          "user_id": this.editingDoctor,
          "description": this.note
        }).then(() => {
          this.modalAddComment = false
          this.$refs.addNoteForm.reset()
          EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
          this.getNotes()
        })
      }
    },
    getNotes() {
      this.noteLoader = true
      if (this.editingDoctor) {
        this.$store.dispatch('getNotesClient', {
          page: this.pagination.page,
          clientId: this.editingDoctor
        }).then((resp) => {
          this.notes = resp.data.user_crm_note.data
          this.pagination.total = resp.data.user_crm_note.last_page
        }).finally(() => {
          this.noteLoader = false
        })
      }
    },
  },
  computed: {

    ...mapGetters({
      editingDoctor: 'getEditingDoctorId',
      'profile': 'getUserInfo'
    }),
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
  },
  watch: {
    editingDoctor(val) {
      if (val != null) {
        this.getNotes()
      }
    },
    'pagination.page'() {
      this.getNotes()
    }
  },
  components: {
    CounselorProfile,
    ChangePassword
  },
  filters: {
    dateFilter(val) {
      if (val) {
        return momentJalali(val).format('jYYYY/jMM/jDD HH:mm')
      }
    }
  }
}
</script>

<style>
.accountManagerNote {
  font-size: 12px;
}

.dateNote {
  font-size: 12px;
}

.note {
  border: 1px solid #ededed;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
}

.noteTime {
  font-size: 13px;
  color: grey;
}

.noteContent {
  margin-top: 5px;
}

.noteArea {
  height: 95vh;
  overflow-y: auto;
}
</style>
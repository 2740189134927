/*eslint-disable*/
import login from "@/views/Login";

export default {
    created() {

    },
    methods: {
        getQueryParametersFromURL() {
            const url = window.location.href;
            const queryString = url.split('?')[1];
            if (!queryString) {
                return {};
            }

            const queryParameters = {};
            const pairs = queryString.split('&');

            pairs.forEach(pair => {
                const [key, value] = pair.split('=');
                if (value) {
                    queryParameters[key] = decodeURIComponent(value);
                }
            });

            return queryParameters;
        },
        prepareNewUrlAndUpdatePage(queries, page, need_update = true) {
            let route = this.$route.path
            if (!queries.page) {
                queries.page = 1
            }
            if (Object.keys(queries).length > 0) {
                route += '?'
                Object.keys(queries).forEach(item => {
                    if (queries[item]) {
                        let value = queries[item].toString().replaceAll('+', '%2B')
                        if (item == 'page') {
                            if (need_update) {
                                route += `${item}=${(page)}&`
                            } else {
                                route += `page=1&`
                            }
                        } else {
                            route += `${item}=${value}&`
                        }
                    }
                })
            }
            if (route.endsWith("&")) {
                route = route.slice(0, -1);
            }
            return route
        },
        pushNewQueryToUrl(url) {
            window.history.pushState("", "", url);
        },
        getPageFromUrl() {
            let queries = this.getQueryParametersFromURL()
            if (queries.hasOwnProperty('page')) {
                return queries['page']
            }
            return 1
        },
        pushPageToUrl(page, need_update = true) {
            let queries = this.getQueryParametersFromURL()
            let finalUrl = this.prepareNewUrlAndUpdatePage(queries, page, need_update)
            this.pushNewQueryToUrl(finalUrl)
        },
        handlerPagination(apiCall = () => {}) {
            console.log(arguments)
            this.pushPageToUrl(this.page)
            apiCall()
        },
        resetPaginationInApplyFilterIfNeeded(options) {
            if (options.reset_pagination) {
                this.page = 1;
                this.pushPageToUrl(1, false)
            }
        },
        fillPageState(){
            this.page = parseInt(this.getPageFromUrl())
        }
    }
}
/*eslint-disable*/
import {ajaxGet, ajaxPost, ajaxPut} from "@/utilities";
import {EventBus} from "@/event-bus";


const actions = {
    acceptAlgorithm({rootState}, payload) {
        return ajaxGet(rootState.baseUrl + `/admin/matchmaking/${payload.id}/accept-match-making`)
    },
    rejectAlgorithm({rootState}, payload) {
        return ajaxGet(rootState.baseUrl + `/admin/matchmaking/${payload.id}/reject-match-making`)
    },
    matchMakingResults({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/matchmaking/results?page=${payload.page}`, payload.options, true).then((data) => data)
        // return ajaxPost(rootState.baseUrl + `/admin/matchmaking/results?page=${payload.page}`)
    },
    getMatchMakingTest({rootState}) {
        return ajaxGet(rootState.baseUrl + `/admin/matchmaking/test`).then((data) => data)
    },
    storeMatchMakingTest({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/matchmaking/test`, payload).then((data) => data)
    },
    editMatchMakingTest({rootState}, payload) {
        return ajaxPut(rootState.baseUrl + `/admin/matchmaking/test`, payload).then((data) => data)
    },
    getMatchMakingTestQuestions({rootState}, payload) {
        return ajaxGet(rootState.baseUrl + `/admin/matchmaking/question?test_id=${payload.test_id}&with_options=${payload.withOptions}`,).then((data) => data)
    },
    storeMatchMakingTestQuestions({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/matchmaking/question`, payload).then((data) => data)
    },
    editMatchMakingTestQuestions({rootState}, payload) {
        return ajaxPut(rootState.baseUrl + `/admin/matchmaking/question`, payload).then((data) => data)
    },
    getMatchMakingTestOption({rootState}, payload) {
        return ajaxGet(rootState.baseUrl + `/admin/matchmaking/question/option/question?option_id=${payload.option_id}&test_id=${payload.test_id}`,).then((data) => data)
    },
    editMatchMakingOption({rootState}, payload) {
        return ajaxPut(rootState.baseUrl + `/admin/matchmaking/question/option/`, payload).then((data) => data)
    },
    insertMatchMakingOption({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/matchmaking/question/option/`, payload).then((data) => data)
    },
    attachQuestionToOptions({rootState}, payload) {
        return ajaxPost(rootState.baseUrl + `/admin/matchmaking/question/option/question`, payload).then((data) => data)
    }
}
export default {actions}
<template>
  <div class="px-3  ">
    <MarketingDashboard v-if="checkRoleAndPermissions('admin.marketing_dashboard')"/>
    <admin-dashboard v-else></admin-dashboard>
    <!--    <BlocksDashboard/>-->
  </div>
</template>

<script>
/*eslint-disable*/

import adminDashboard from '@/components/dashboards/adminDashboard.vue';
import MarketingDashboard from "@/components/dashboards/marketingDashboard";
import TaskListMenu from "@/components/TaskManager/TaskListMenu";
import BlocksDashboard from "@/components/dashboards/BlocksDashboard";

export default {
  components: {
    BlocksDashboard,
    TaskListMenu,
    MarketingDashboard,
    adminDashboard,
  },
  data() {
    return {
      drawer: true
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <div v-if="videoUrl">
      <video style="width: 100%" controls>
        <source :src="videoUrl" type="video/mp4"/>
      </video>
    </div>
  </div>
</template>

<script>
import Axios from "axios";

export default {
  name: "VideoTelegramLoader",
  props: {
    message_id: Number,
    file_type: String,
  },
  data() {
    return {
      videoUrl: null,
      baseUrl: process.env.VUE_APP_BASE_URL,
    }
  },
  methods: {
    getFileContent() {
      Axios({
        method: 'POST',
        url: this.baseUrl + '/admin/telegram/load-telegram-file',
        withCredentials: true,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        data: {
          file_message_id: this.message_id
        },
        responseType: 'blob',
      }).then((resp) => {
        let blob = new Blob([resp.data], {type: 'video/mp4'}); // Adjust the type as per your image type

        // Create a URL for the Blob object
        this.videoUrl = URL.createObjectURL(blob);

        console.log(this.videoUrl)

      })
    },
  },
  mounted() {
    this.getFileContent()
  }
}
</script>

<style scoped>

</style>
<template>

    <v-dialog scrollable max-width="920" v-model="dialogSwitch">

        <v-card>
            <v-card-title>
                افزودن پرداخت
                <v-divider></v-divider>
            </v-card-title>
            <v-card-text>
                <v-form ref="addPaymentForm">
                    <v-row>

                        <v-col cols="12" md="4" lg="3" sm="6">
                            <v-select
                                    v-model="payment.payment_method_id"
                                    :items="filteredPaymentMethods"
                                    label="متد"
                                    :loading="paymentMethodsLoading"
                                    item-text="title"
                                    item-value="id"
                                    outlined

                                    :rules="requiredRules"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="4" lg="3" sm="6">
                            <v-text-field
                                    v-if="isFocused"
                                    outlined
                                    label="مبلغ پرداختی"
                                    :rules="requiredRules"
                                    @blur="isFocused=false"
                                    v-model="payment.final_amount"

                            >
                            </v-text-field>
                            <v-text-field
                                    v-else-if="!isFocused"
                                    outlined
                                    label="مبلغ پرداختی"
                                    :rules="requiredRules"
                                    @focus="isFocused=true"
                                    :value="payment.final_amount | toLocaleOrFixed(payment)"
                                    style="font-weight: bold"
                            >
                            </v-text-field>
                        </v-col>


                        <v-col cols="12" md="4" lg="3" sm="6">

                            <v-text-field
                                    outlined
                                    :value="payment.date | toJalali"
                                    label="تاریخ پرداخت"
                                    :rules="requiredRules"
                                    prepend-icon="event"
                                    readonly
                                    @click="modal=true"
                            ></v-text-field>
                            <AdminDatePicker @close="modal=false" :dialog="modal"
                                             v-model="payment.date"></AdminDatePicker>
                        </v-col>


                        <v-col cols="12" md="4" lg="3" sm="6">
                            <v-text-field
                                    outlined
                                    label="کد انتقال"
                                    v-model="payment.transaction_code"
                            >

                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" lg="3" sm="6">
                            <v-text-field
                                    outlined
                                    label="کد پیگیری"
                                    v-model="payment.ref_id"
                            >

                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" lg="3" xl="3">
                            <v-text-field
                                    outlined
                                    label="توضیحات"
                                    v-model="payment.information"
                            >
                            </v-text-field>
                        </v-col>

                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="error" @click="closeModal">لغو</v-btn>
                <v-btn class="primary" @click="addPayment">افزودن پرداخت</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import AdminDatePicker from '@/components/AdminDatePicker';
    import moment from 'moment-jalaali';
    import {mapGetters} from 'vuex';

    export default {

        components: {
            AdminDatePicker
        },
        props: {
            show: {
                default: false
            },
            factor: {}
        },
        created() {
            console.log(this.factor);
            this.dialogSwitch = this.show;
            this.getAllPaymentMethods();
        },
        computed: {
            ...mapGetters({
                currencies: 'getCurrencies'
            }),
            filteredPaymentMethods() {
                return this.paymentMethods.filter(item => item.code == this.factor.currency_code);
            },
            requiredRules() {
                return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
            },
        },
        methods: {
            closeModal() {
                this.dialogSwitch = false;
            },
            getAllPaymentMethods() {
                this.paymentMethodsLoading = true;
                this.$store.dispatch('getAllPaymentMethods',{}).then((resp) => {
                    this.paymentMethods = resp.data;

                }).finally(() => {
                    this.paymentMethodsLoading = false;
                })
            },
            addPayment() {

                if (this.$refs.addPaymentForm.validate()) {
                    this.$store.dispatch('adminAddPaymentToCart', {
                        cart_id: this.factor.id,
                        payload: this.payment
                    }).then(() => {
                        this.$emit('added');
                    })
                }
            }
        },
        data() {
            return {
                modal: false,
                paymentMethods: [],
                paymentMethodsLoading: false,
                isFocused: false,
                dialogSwitch: false,
                payment: {
                    date: null,
                    transaction_code: '',
                    ref_id: '',
                    method: '',
                    final_amount: null,
                    information: '',
                },
                // currencies: [
                //     {title: 'دلار', value: 'dollar'},
                //     {title: 'تومان', value: 'rial'},
                //     {title: 'دلار استرالیا', value: 'aud'},
                //     {title: 'دلار کانادا', value: 'cad'},
                //     {title: 'پوند', value: 'gbp'},
                //     {title: 'یورو', value: 'euro'},
                // ],
            }
        },
        filters: {
            toLocaleOrFixed(value) {
                let output = Number(value).toLocaleString();
                return output != 0 ? output : null;
            },
            toJalali(date) {
                if (date) {
                    return moment(date, "YYYY-M-DD").format('jYYYY/jMM/jDD');
                }
                return date;
            },
        },
        watch: {
            show(newVal) {
                if (newVal) {
                    this.dialogSwitch = true;
                } else {
                    this.dialogSwitch = false;
                }
            },

            dialogSwitch(val) {
                if (!val) {
                    this.$emit('closed');
                }
            }

        },

    }
</script>

<style scoped>

</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('TableOptions',{attrs:{"hasExport":true,"searchFilters":_vm.searchFilters},on:{"applyFilters":_vm.applyFilters}}),_c('v-btn',{staticClass:"mb-5 mr-3",attrs:{"elevation":"0","outlined":!_vm.show_diff_time,"small":"","color":_vm.show_diff_time ? 'primary' : ''},on:{"click":function($event){_vm.show_diff_time = !_vm.show_diff_time}}},[_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[(_vm.show_diff_time)?[_vm._v("mdi-check")]:[_vm._v("mdi-cross")]],2),_vm._v(" نمایش فاصله زمانی ")],1),_c('v-data-table',{attrs:{"headers":_vm.preparedHeader,"items":_vm.data,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.brith_data",fn:function(ref){
var item = ref.item;
return [(item.last_document)?[_vm._v(" "+_vm._s(_vm._f("dateFilter")(item.last_document.birth_date))+" ")]:_vm._e()]}},{key:"item.assigned_doctor_status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.assigned_doctor_status === 'active' ? 'فعال' : 'غیر فعال')+" ")]}},{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{name:'clientDetail',params:{clientId:item.id}}}},[_vm._v(" "+_vm._s(item.full_name)+" ")])]}},{key:"item.marital_status",fn:function(ref){
var item = ref.item;
return [(item.last_document)?[_vm._v(" "+_vm._s(_vm.maritalStatus(item.last_document.marital_status))+" ")]:_vm._e()]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [(item.last_document && item.last_document.country)?[_vm._v(" "+_vm._s(item.last_document.country.name)+" ")]:_vm._e()]}},{key:"item.gender",fn:function(ref){
var item = ref.item;
return [(item.last_document)?[_vm._v(" "+_vm._s(item.last_document.gender)+" ")]:_vm._e()]}},{key:"item.knway",fn:function(ref){
var item = ref.item;
return [(item.encryptedData && item.encryptedData.client)?[_vm._v(" "+_vm._s(_vm.kenwaySource(item.encryptedData.client.knway))+" ")]:_vm._e()]}},{key:"item.detach_reason",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.stopReasons[item.detach_reason])+" ")]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":_vm.pagination.total},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-container>

        <v-tabs v-model="tabs"
                fixed-tabs>
            <v-tab href="#tab-1">اطلاعات کاربر</v-tab>
            <v-tab href="#tab-2">دوره های درمان</v-tab>
            <v-tab href="#tab-3">جلسات</v-tab>
            <v-tabs-slider></v-tabs-slider>
        </v-tabs>
        <v-tabs-items v-model="tabs">
            <v-tab-item value="tab-1">
                <v-card>
                    <v-card-title>اطلاعات کاربر</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row class="grow">
                            <v-col>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="grey--text">{{
                                            $vuetify.lang.t('$vuetify.profileFirstname') }}:
                                            <strong class="black--text">{{ client.name }}</strong></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title class="grey--text">{{
                                            $vuetify.lang.t('$vuetify.profileLastname') }}:
                                            <strong class="black--text">{{ client.family }}</strong></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title class="grey--text">{{ $vuetify.lang.t('$vuetify.profileAge')
                                            }}: <strong
                                                    class="black--text">{{ client.age }}</strong></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <!--<v-list-item two-line>-->
                                <!--<v-list-item-content>-->
                                <!--<v-list-item-title class="grey--text">شرح موضوع مشاوره: <strong class="black--text">{{-->
                                <!--client.desc }}</strong></v-list-item-title>-->
                                <!--</v-list-item-content>-->
                                <!--</v-list-item>-->


                            </v-col>

                            <v-col>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title class="grey--text">{{
                                            $vuetify.lang.t('$vuetify.profileCountry') }}:
                                            <strong class="black--text">{{ client.country }}</strong>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item two-line v-if="client.hasOwnProperty('marital_state')">
                                    <v-list-item-content>
                                        <v-list-item-title class="grey--text">
                                            {{ $vuetify.lang.t('$vuetify.profileCivilStatus') }}: <strong
                                                class="black--text">{{
                                            client.married ? 'متاهل' : 'مجرد' }}</strong></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <!--<v-list-item two-line>-->
                                <!--<v-list-item-content>-->
                                <!--<v-list-item-title class="grey--text">دسته بندی: <strong class="black--text">{{ client.cat-->
                                <!--}}</strong></v-list-item-title>-->
                                <!--</v-list-item-content>-->
                                <!--</v-list-item>-->
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item value="tab-2">
                <v-card>
                    <v-card-title>دوره های درمان</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-expansion-panels>
                            <v-expansion-panel
                                    v-for="cycle in cycles" :key="cycle.id">
                                <v-expansion-panel-header>
                                    موضوع : {{cycle.heading}}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <div>شرح :</div>
                                    {{cycle.problem_description}}
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item value="tab-3">
                <v-card class="elevation-1">
                    <v-card-title>جلسات</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text v-if="sessions.length">
                        <v-row justify="center">
                            <v-col cols="12" sm="8" md="6" lg="4" v-for="(session,index) in sessions" :key="index">
                                <SessionCard :mode="'doctor'" :factor="false" :session="session"></SessionCard>
                            </v-col>
                        </v-row>

                        <div class="text-center">
                            <v-pagination
                                    v-model="page"
                                    :length="total"
                            ></v-pagination>
                        </div>
                    </v-card-text>
                    <v-card-text class="text-center" v-else>
                        جلسه ای وجود ندارد
                    </v-card-text>
                </v-card>
            </v-tab-item>

        </v-tabs-items>
    </v-container>

    <!--<v-container v-if="client">-->
    <!--<v-card-->
    <!--class="mx-auto"-->
    <!--tile-->
    <!--&gt;-->
    <!--<v-card-title>-->
    <!--اطلاعات مراجع-->
    <!--</v-card-title>-->
    <!--<v-divider></v-divider>-->
    <!--<v-row class="grow">-->
    <!--<v-col>-->
    <!--<v-list-item>-->
    <!--<v-list-item-content>-->
    <!--<v-list-item-title class="grey&#45;&#45;text">{{ $vuetify.lang.t('$vuetify.profileFirstname') }}:-->
    <!--<strong class="black&#45;&#45;text">{{ client.name }}</strong></v-list-item-title>-->
    <!--</v-list-item-content>-->
    <!--</v-list-item>-->

    <!--<v-list-item two-line>-->
    <!--<v-list-item-content>-->
    <!--<v-list-item-title class="grey&#45;&#45;text">{{ $vuetify.lang.t('$vuetify.profileLastname') }}:-->
    <!--<strong class="black&#45;&#45;text">{{ client.family }}</strong></v-list-item-title>-->
    <!--</v-list-item-content>-->
    <!--</v-list-item>-->

    <!--<v-list-item two-line>-->
    <!--<v-list-item-content>-->
    <!--<v-list-item-title class="grey&#45;&#45;text">{{ $vuetify.lang.t('$vuetify.profileAge') }}: <strong-->
    <!--class="black&#45;&#45;text">{{ client.age }}</strong></v-list-item-title>-->
    <!--</v-list-item-content>-->
    <!--</v-list-item>-->

    <!--&lt;!&ndash;<v-list-item two-line>&ndash;&gt;-->
    <!--&lt;!&ndash;<v-list-item-content>&ndash;&gt;-->
    <!--&lt;!&ndash;<v-list-item-title class="grey&#45;&#45;text">شرح موضوع مشاوره: <strong class="black&#45;&#45;text">{{&ndash;&gt;-->
    <!--&lt;!&ndash;client.desc }}</strong></v-list-item-title>&ndash;&gt;-->
    <!--&lt;!&ndash;</v-list-item-content>&ndash;&gt;-->
    <!--&lt;!&ndash;</v-list-item>&ndash;&gt;-->


    <!--</v-col>-->

    <!--<v-col>-->
    <!--<v-list-item>-->
    <!--<v-list-item-content>-->
    <!--<v-list-item-title class="grey&#45;&#45;text">{{ $vuetify.lang.t('$vuetify.profileCountry') }}:-->
    <!--<strong class="black&#45;&#45;text">{{ client.country }}</strong></v-list-item-title>-->
    <!--</v-list-item-content>-->
    <!--</v-list-item>-->

    <!--<v-list-item two-line v-if="client.hasOwnProperty('marital_state')">-->
    <!--<v-list-item-content>-->
    <!--<v-list-item-title class="grey&#45;&#45;text">-->
    <!--{{ $vuetify.lang.t('$vuetify.profileCivilStatus') }}: <strong class="black&#45;&#45;text">{{-->
    <!--client.married ? 'متاهل' : 'مجرد' }}</strong></v-list-item-title>-->
    <!--</v-list-item-content>-->
    <!--</v-list-item>-->

    <!--&lt;!&ndash;<v-list-item two-line>&ndash;&gt;-->
    <!--&lt;!&ndash;<v-list-item-content>&ndash;&gt;-->
    <!--&lt;!&ndash;<v-list-item-title class="grey&#45;&#45;text">دسته بندی: <strong class="black&#45;&#45;text">{{ client.cat&ndash;&gt;-->
    <!--&lt;!&ndash;}}</strong></v-list-item-title>&ndash;&gt;-->
    <!--&lt;!&ndash;</v-list-item-content>&ndash;&gt;-->
    <!--&lt;!&ndash;</v-list-item>&ndash;&gt;-->
    <!--</v-col>-->
    <!--</v-row>-->
    <!--<v-divider></v-divider>-->
    <!--<v-card v-if="cycles.length">-->
    <!--<v-card-title>-->
    <!--دوره های درمان-->
    <!--</v-card-title>-->
    <!--<v-card-text>-->
    <!--<v-expansion-panels>-->
    <!--<v-expansion-panel-->
    <!--v-for="cycle in cycles" :key="cycle.id"-->
    <!--&gt;-->
    <!--<v-expansion-panel-header>-->
    <!--موضوع : {{cycle.heading}}-->
    <!--</v-expansion-panel-header>-->
    <!--<v-expansion-panel-content>-->
    <!--<div>شرح :</div>-->
    <!--{{cycle.problem_description}}-->
    <!--</v-expansion-panel-content>-->
    <!--</v-expansion-panel>-->
    <!--</v-expansion-panels>-->
    <!--</v-card-text>-->
    <!--</v-card>-->
    <!--&lt;!&ndash;<v-col v-for="cycle in cycles" :key="cycle.id" cols="12">&ndash;&gt;-->
    <!--&lt;!&ndash;<v-card></v-card>&ndash;&gt;-->
    <!--&lt;!&ndash;{{cycle.heading}}&ndash;&gt;-->
    <!--&lt;!&ndash;{{cycle.problem_description}}&ndash;&gt;-->
    <!--&lt;!&ndash;</v-col>&ndash;&gt;-->


    <!--</v-card>-->
    <!--</v-container>-->
</template>

<script>
    import {EventBus} from "../../event-bus";
    import SessionCard from '@/components/sessions/SessionCard';

    export default {
        components: {
            SessionCard
        },
        created() {
            this.getClientSessions();
        },
        props: {
            client: Object,
            cycles: {
                default: () => {
                    return []
                }
            },
        },
        data() {
            return {
                tabs: 'tab-1',
                sessions: [],
                page: 1,
                total: 1
            }
        },
        methods: {
            getClientSessions() {
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('getCounselorClientSessions', {
                    clientId: this.client.id,
                    page: this.page
                }).then((resp) => {
                    this.total = resp.data.pagination.total_pages;
                    this.sessions = resp.data.sessions;
                }).finally(() => {
                    EventBus.$emit('setLoading', false);
                })
            }
        },
        watch: {
            page() {
                this.getClientSessions();
            },
        }
    }
</script>

<style>

</style>
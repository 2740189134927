<template>
    <v-card>
        <v-card-title>
            افزودن دوره جدید
        </v-card-title>

        <v-card-text>
            <v-form
                    ref="addCycleForm"
            >
                <v-col class="d-flex" cols="12" sm="6">

                    <v-select
                            :items="categories"
                            v-model="title"
                            label="موضوع مشاوره"
                            outlined
                            dense
                            :rules="[rules.requiredRule]"
                    ></v-select>
                </v-col>

                <v-textarea
                        v-model="description"
                        outlined
                        label="شرح مساله"
                        :rules="[rules.requiredRule]"
                ></v-textarea>
            </v-form>
        </v-card-text>
        <v-card-actions v-if="dialog">
            <v-btn class="primary" @click="addNewCycle">ثبت</v-btn>
            <v-btn class="red white--text" @click="close">بستن</v-btn>
        </v-card-actions>

    </v-card>
</template>

<script>
    /*eslint-disable*/
    import {EventBus} from "../../event-bus";
    import {mapGetters} from 'vuex';


    export default {
        props: {
            dialog: {
                default: false
            },
            getProfile: {
                default: true
            },
            addCycleAction: {
                default: 'addNewCycle'
            },
            isAdmin: {
                default: false
            },
            clientId: {
                default: null
            },
            taskId:{
              default:null,
              required:false,
            }
        },
        data() {
            return {
                title: null,
                description: null,
                categories: [
                    'فردی',
                    'زوج',
                    'خانواده',
                    'کودک و نوجوان',
                    'سکس تراپی',
                    'روانپزشکی'
                ],
                rules: {
                    requiredRule: v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired'),
                    lengthRule: v => v.length >= 20 || 'حداقل 20 کاراکتر',

                }
            }
        },
        computed: {
            ...mapGetters({
                profile: 'getUserInfo'
            })
        },
        created() {
            EventBus.$on('postConsultationInfoEvent', this.addNewCycle);
        },
        beforeDestroy() {
            EventBus.$off('postConsultationInfoEvent', this.postConsultation);
        },
        methods: {
            addNewCycle() {
                if (this.$refs.addCycleForm.validate()) {
                    let payload;

                    // if (this.profile.cycles.length == 0) {
                    //     payload.requestFirstSession = true;
                    // }
                    if (this.isAdmin) {
                        payload = {
                            id: this.clientId,
                            payload: {
                                title: this.title,
                                description: this.description,
                            }
                        }
                    } else {
                        payload = {
                            title: this.title,
                            description: this.description,
                        };
                    }
                    if (this.taskId != null){
                      Object.assign(payload.payload,{
                        'task_id':this.taskId,
                      })
                    }
                    this.$store.dispatch(this.addCycleAction, payload).then(() => {
                        EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
                        if (this.getProfile) {
                            this.$store.dispatch('getUserInfo').then(() => {
                                this.$emit('cycleAdded');
                                this.close();
                            });
                        } else {
                            this.$emit('cycleAdded');
                            this.close();
                        }


                    });
                } else {
                    Event.$emit('postConsultationInfoError');
                }
            },
            close() {
                this.$emit('close');
            }
        }
    }
</script>

<style scoped>

</style>
<template>
    <v-card>
        <v-card-title>
            کیف پول
            <v-spacer></v-spacer>
            <v-chip class="mx-1"
                    :class="{'white--text red' : walletItem.balance <0,'blue white--text':walletItem.balance>0}"
                    v-for="(walletItem,index) in wallet"
                    :key="walletItem"> {{index|resolveCurrency}}
                {{walletItem.balance | resolveBalance(index)}}
            </v-chip>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>

            <v-form ref="addWalletRecordForm">

                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <v-text-field
                                outlined
                                hide-details
                                label="مبلغ"
                                v-model="record.amount"
                                :rules="requiredRules"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-select
                                outlined
                                hide-details
                                label="ارز"
                                :items="currencies"
                                v-model="record.currency"
                                item-text="title"
                                item-value="value"
                                :rules="requiredRules"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <v-select
                                outlined
                                hide-details
                                label="نوع"
                                item-text="title"
                                item-value="value"
                                :items="types"
                                :rules="requiredRules"
                                v-model="record.type"
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea v-model="record.description" label="توضیحات" outlined height="100"></v-textarea>
                    </v-col>
                </v-row>

            </v-form>

        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="error" @click="close()">لغو</v-btn>
            <v-btn class="primary" @click="sendRequest">اعمال</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {EventBus} from "../../../event-bus";
    import {resolveCurrency} from "../../../utilities";
    // import {mapGetters} from 'vuex';
    export default {
        props: ['userId'],
        created() {
            let client = this.$store.getters.getClientProfile;
            this.wallet = client.monetary_wallet;
        },
        computed: {
            // ...mapGetters({
            //     currencies:'getCurrencies'
            // }),
            requiredRules() {
                return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
            },
        },
        data() {
            return {
                wallet: null,
                currencies: [
                    {title: 'تومان', value: 'rial'},
                    {title: 'دلار', value: 'dollar'},
                ],
                types: [
                    {title: 'شارژ', value: 'charge'},
                    {title: 'کسر', value: 'use'},
                ],
                record: {
                    user_id: this.userId,
                    amount: null,
                    currency: null,
                    type: null,
                    description: ""
                }
            }
        },
        methods: {
            close(res = 'close') {
                this.$emit(res);
            },
            sendRequest() {
                if (this.$refs.addWalletRecordForm.validate()) {
                    this.$store.dispatch('adminUserWalletRequest', {
                        userId: this.userId,
                        payload: this.record
                    }).then(() => {
                        EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
                        this.close('done');
                    })
                }
            }
        },
        filters: {
            resolveCurrency(input) {
                return resolveCurrency(input);

            },
            resolveBalance(input, currency) {
                if (currency == 'dollar') {
                    return input.toFixed(2)
                } else {
                    return input.toFixed(0);
                }

            }
        }
    }
</script>

<style scoped>

</style>
<template>
  <div>
    <v-container>
      <v-card class="mt-5">
        <v-card-title>
          تاریخچه جورسازی
        </v-card-title>
        <v-card-text>
          <div class="historyContext pa-5" v-if="items.length != 0">
            <div class="historyItem" v-for="(item , i) in Object.keys(items)" :key="i">
              <div class="dateHistory font-weight-bold">
                تاریخ جورسازی:
                {{ item | dateFilter }}
              </div>
              <div class="doctorHistory">
                <ul class="mr-5">
                  <template v-for="(section , n) in items[item]">
                    <li :key="n" v-if="section.doctor.hasOwnProperty('user')">
                      {{ section.doctor.user.full_name }}
                    </li>
                  </template>

                </ul>
              </div>
            </div>

          </div>
          <div v-else>
            این کاربر تاریخچه جور سازی ندارد
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import moment from "moment-jalaali";
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      items: [],
    }
  },
  mounted() {
    this.getHistory()
  },
  methods: {
    getHistory() {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('getMatchHistory', this.$route.params.clientId).then((resp) => {
        this.items = resp.data.history_of_matched_therapists
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    }
  },
  filters: {
    dateFilter(date) {
      return moment(date).format('YYYY/MM/DD - jYYYY/jMM/jDD')
    }
  }
}
</script>

<style scoped>
.dateHistory {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.historyItem {
  border: 1px solid #dddddd;
  border-radius: 10px;
  margin-bottom: 25px;
  padding: 15px;
}
</style>
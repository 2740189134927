<template>

    <v-container>
        <v-card>
            <v-card-text>
                <v-form ref="profileForm">
                    <v-row>
                        <v-col cols="12">
                            <v-card-text class="pa-0 font-weight-bold">
                                {{$vuetify.lang.t('$vuetify.profileInformationTitle')}}
                            </v-card-text>
                        </v-col>
                    </v-row>
                    <v-row class="px-md-10">
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                    v-model="userInfo.firstname"
                                    outlined
                                    :label="$vuetify.lang.t('$vuetify.profileFirstname')"
                                    :rules="requiredRules"

                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                    v-model="userInfo.lastname"
                                    outlined
                                    :label="$vuetify.lang.t('$vuetify.profileLastname')"
                                    :rules="requiredRules"
                            ></v-text-field>
                        </v-col>


                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                    v-model="userInfo.email"
                                    outlined
                                    :label="$vuetify.lang.t('$vuetify.profileEmail')"
                                    :rules="emailRules"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                    v-model="userInfo.cel"
                                    outlined
                                    :label="$vuetify.lang.t('$vuetify.profileMobile')"
                                    :rules="requiredRules"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-select
                                    v-model="userInfo.married"
                                    :items="married"
                                    item-text="title"
                                    item-value="value"
                                    :label="$vuetify.lang.t('$vuetify.profileCivilStatus')"
                                    outlined
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-dialog
                                    ref="dialog"
                                    v-model="modal"
                                    :return-value.sync="userInfo.birthDate"
                                    persistent
                                    width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            outlined
                                            v-model="formatedBD"
                                            :value="userInfo.birthDate"
                                            :label="$vuetify.lang.t('$vuetify.profileBirthDate')"
                                            prepend-icon="event"
                                            readonly
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                        v-model="userInfo.birthDate"

                                        :max="new Date().toISOString().substr(0, 10)"
                                        scrollable locale="fa"
                                        first-day-of-week="6">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                                    <v-btn text color="primary" @click="$refs.dialog.save(userInfo.birthDate)">OK
                                    </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-select
                                    :items="education"
                                    item-text="title"
                                    item-value="value"
                                    v-model="userInfo.edu"
                                    outlined
                                    :label="$vuetify.lang.t('$vuetify.profileEdu')"

                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                    v-model="userInfo.job"
                                    outlined
                                    :label="$vuetify.lang.t('$vuetify.profileJob')"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pa-0 ma-0"></v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                    v-model="password"
                                    outlined
                                    :label="$vuetify.lang.t('$vuetify.passwordLabel')"
                                    :rules="passwordRules"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                    v-model="confirmPassword"
                                    outlined
                                    :label="$vuetify.lang.t('$vuetify.passwordConfirmationLabel')"
                                    :rules="passwordConfirmRules"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-card-text class="pa-0 font-weight-bold">
                                {{$vuetify.lang.t('$vuetify.profileLocationTitle')}}
                            </v-card-text>
                        </v-col>
                    </v-row>

                    <v-row class="px-md-10">
                        <v-col cols="12" sm="6" md="4">
                            <v-autocomplete
                                    v-model="userInfo.country"
                                    :items="countries"
                                    item-text="name"
                                    item-value="id"
                                    menu-props="auto"
                                    :label="$vuetify.lang.t('$vuetify.profileCountry')"
                                    hide-details
                                    prepend-icon="fas fa-flag"
                                    single-line
                                    outlined

                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                    prepend-icon="fas fa-building"
                                    v-model="userInfo.city"
                                    single-line
                                    outlined
                                    :label="$vuetify.lang.t('$vuetify.profileCity')"

                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-card-text class="pa-0 font-weight-bold">
                                {{$vuetify.lang.t('$vuetify.profileTimeTitle')}}
                            </v-card-text>
                        </v-col>
                    </v-row>
                    <v-row class="px-md-10">
                        <v-col cols="12" sm="6" md="4">
                            <v-autocomplete
                                    menu-props="auto"
                                    :label="$vuetify.lang.t('$vuetify.profileTZ')"
                                    v-model="userInfo.tz"
                                    :items="timezones"
                                    item-value="value"
                                    hide-details
                                    prepend-icon="fas fa-clock"
                                    single-line
                                    outlined
                                    :rules="requiredRules"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>

                    <v-divider class="my-10"></v-divider>
                    <v-row class="px-md-10">
                        <v-col cols="12" sm="6" md="4">
                            <v-select
                                    :items="knway"
                                    item-text="title"
                                    item-value="value"
                                    v-model="userInfo.knway"
                                    outlined
                                    :label="$vuetify.lang.t('$vuetify.profileKnway')"
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="8" v-if="userInfo.knway == 'other'">
                            <v-text-field
                                    v-model="userInfo.knway_meta"
                                    outlined
                                    :label="$vuetify.lang.t('$vuetify.profileKnwayOther')"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-btn class="success px-10" @click="addClient">افزودن</v-btn>


            </v-card-actions>
            <AlertsComponent></AlertsComponent>
        </v-card>

    </v-container>
</template>

<script>
    /*eslint-disable*/
    import {EventBus} from './../../event-bus.js';
    // import countries from "../../../data/countries";
    import timezones from "./../../data/timezones";
    // import moment from 'moment-jalaali';
    import moment from 'moment-timezone';
    import AlertsComponent from "@/components/AlertsComponent";


    export default {
        components: {
            AlertsComponent
        },

        created() {
            let x = JSON.parse(JSON.stringify(timezones));
            this.timezones = x.map((item) => {
                item.text = item.text + ' ' + moment.tz(item.text).format('Z');
                return item;
            });

            this.$store.dispatch('getCountries').then((resp) => {
                this.countries = resp.data.countries;
            });

            // this.getUserInfo();

            setTimeout(() => {
                console.log('BD', new Date(this.userInfo.birthDate).toLocaleDateString('fa-IR'));
            }, 1000)
        },
        data() {
            return {

                emailRules: [
                    v => !!v || this.$vuetify.lang.t('$vuetify.emailErrRequired'),
                    v => /.+@.+\..{2}/.test(v) || this.$vuetify.lang.t('$vuetify.emailErrValid'),
                ],
                password: '',
                confirmPassword: '',
                countries: [],

                timezones: timezones,
                married: [
                    {value: "single", title: 'مجرد'},
                    {value: "married", title: 'متاهل'},
                ],
                education: [
                    {value: "nothing", title: 'فاقد مدرک'},
                    {value: "diploma", title: 'دیپلم'},
                    {value: "associate", title: 'فوق دیپلم'},
                    {value: "bachelor", title: 'لیسانس'},
                    {value: "master", title: 'فوق لیسانس'},
                    {value: "doctorate", title: 'دکتری'}
                ],
                knway: [
                    {value: "google", title: "جست و جو در  گوگل"},
                    {value: "twitter", title: "توییتر"},
                    {value: "instagram", title: "اینستاگرام"},
                    {value: "podcast", title: "پادکست"},
                    {value: "bannerads", title: "تبلیغات بنری"},
                    {value: "friends", title: "معرفی دوستان و آشنایان"},
                    {value: "site", title: "سایت"},
                    {value: "other", title: "موارد دیگر"},
                ],
                userInfo: {},
                locale: this.$store.getters.getLocal,
                modal: false,
            };
        },
        methods: {
            addClient() {
                if (this.$refs.profileForm.validate()) {
                    let data = {
                        // birth_date: moment(this.userInfo.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                        birth_date: this.userInfo.birthDate,
                        name: this.userInfo.firstname,
                        family: this.userInfo.lastname,
                        mobile: this.userInfo.cel,
                        timezone: this.userInfo.tz,
                        "familiar_by": this.userInfo.knway,
                        "familiar_by_meta": this.userInfo.knway_meta,
                        "city": this.userInfo.city,
                        "country_id": "10",
                        password: this.password,
                        email: this.userInfo.email,
                        password_confirmation: this.confirmPassword,
                        "marital_state": this.userInfo.married,
                        "education_level": this.userInfo.edu,
                        "job": this.userInfo.job,
                    };


                    this.$store.dispatch('addNewUser', data).then((resp) => {
                        EventBus.$emit('notify', 'green', undefined, 'با موفقیت ثبت شد');
                        this.snackbar = true;
                    }).catch((err) => {

                    });
                }
            },
            // validate() {
            //     ;
            // },
        },

        computed: {
            passwordRules() {
                return [
                    v => !!v || this.$vuetify.lang.t('$vuetify.passwordErrRequired'),
                    v => v.length >= 8 || 'حداقل طول کلمه عبور 8 کاراکتر می باشد'
                ];
            },
            passwordConfirmRules() {
                return [
                    v => !!v || 'پر کردن فیلد تکرار پسورد الزامیست',
                    v => (v === this.password) || 'کلمه عبور و تکرار آن یکسان نمی باشند'
                ];
            },
            requiredRules() {
                return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
                // return [true];
            },

            formatedBD() {
                if (this.$store.getters.getLocal === 'fa') {
                    return new Date(this.userInfo.birthDate).toLocaleDateString('fa-IR');
                } else {
                    return this.userInfo.birthDate;
                }
            }
        }
    }
</script>

<style scoped>

</style>
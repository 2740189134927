/*eslint-disable*/
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes.js';
import store from "../store";
import {i18n} from '../plugins/vuetify.js';
import {EventBus} from "../event-bus";

Vue.use(VueRouter)


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    if (from.name == 'messenger') {
        store.commit('SET_ACTIVE_CHAT', {id: null, title: null});
        store.commit('SET_MESSAGES', {messages: [], conversationId: null});
    }
    if (!store.getters.checkPermission(to.meta.requiredPermission)){
        next('./dashboard')
    }

    window.scrollTo(0, 0);
    const lang = to.params.lang;

    // if (to.query.hasOwnProperty('successful_payment')) {
    //
    //
    //     if (store.getters.userIsLoggedIn) {
    //         //
    //         //     next({name: 'assignconsult', params: {lang: 'fa'}});
    //         //     return;
    //         next({name: 'assignconsult', params: {lang: 'fa'}});
    //
    //         return;
    //     } else {
    //         //
    //         //     next('/login');
    //
    //         return;
    //     }
    // }


    if (to.name === 'preassignconsult') {

        if (!to.path.includes('/fa/panel/assignconsult')) {
            if (store.getters.userIsLoggedIn) {

                next();
                return;
            } else {

                next('/fa/panel/login');
                return;
            }
        }
    }

    if (!store.state.localization.languageCodes.includes(lang)) {


        let allowedLangfree = ['emailverify', 'preorder', 'reset', 'express', 'temp', 'join'];

        let goToRoot = true;

        allowedLangfree.forEach((item) => {

            if (to.path.includes(item)) {
                goToRoot = false;
            }
        });

        if (goToRoot) {
            next(store.getters.getLocal);
            return;
        } else {
            next();
            return;
        }


        // if (!to.path.includes('emailverify')) {
        //     if (!to.path.includes('preorder') && !from.path.includes('preorder')) {
        //         if (!to.path.includes('reset')) {
        //
        //             next(store.getters.getLocal);
        //         }
        //     }
        // } else {
        //     next();
        // }

    } else if (store.state.localization.languageCodes.includes(lang)) {
        if (store.getters.getLocal != lang) {
            store.dispatch('changeLocal', lang);
            i18n.locale = lang;
        }
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {



        //AUTHENTICATION IS REQUIRED
        if (!store.getters.userIsLoggedIn) {

            next({name: 'login'});
            return;
        } else {
            // next();

        }

        if (store.getters.userHasRolesArray(to.meta.requiredRoles)) {


            next();
            return;

        } else {

            console.log("you don't have permission to be here");
            return;
            // next({name: 'dashboard'});
        }

        // (profileState.requireSign ||  (profileState.requireFillTimebooks && profileState.requireCycle))


        let profileState = store.getters.getUserInfo.state

        if (store.getters.userHasRole('customer') && (profileState.requireSign || (profileState.requireFillTimebooks && !profileState.requireCycleType))) {

            next({name: 'dashboard'})
        } else {

            next();
        }


    } else if (to.matched.some(record => record.meta.requiresGuest)) {

        // REQUIRES GUEST


        if (store.getters.userIsLoggedIn) {


            next({
                name: 'dashboard',
                params: {
                    lang,
                }
            });
        } else {

            next();
        }

    } else {

        //REQUIREMENT IS NOT STATED, THUS, WE WILL HAVE NO INTERFERENCE
        next();
    }

});

export default router

<template>
    <v-card>
        <v-card-text>

            <v-container fluid>

                <!--<AdminCalendar></AdminCalendar>-->
                <AdminCalendar v-if="loadCalendar"
                               :cycles="cycles"
                               :customer="$route.params.clientId" :profile="profile"
                               :activeCounselor="activeCounselorId"></AdminCalendar>

            </v-container>

        </v-card-text>
    </v-card>

</template>

<script>
    /*eslint-disable*/
    import AdminCalendar from '@/components/orders/AdminCalendar'

    export default {
        created() {

            this.$store.dispatch('getRequestUserProfile', {userid: this.$route.params.userId}).then((resp) => {

                this.profile = resp.data.client;
                this.cycles = this.profile.cycles.filter((cycle) => cycle.id == this.$route.params.cycleId);
                this.activeCounselorId = null;
                this.loadCalendar = true;
            });
        },
        components: {
            AdminCalendar
        },
        data() {
            return {
                loadCalendar: false,
                cycles: [],
            }
        }
    }
</script>

<style scoped>

</style>
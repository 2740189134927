<template>
  <div>
    <v-btn @click="submitTask" color="green" class="white--text font-weight-bold" small left >ثبت تسک</v-btn>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";

export default {
  methods:{
    submitTask(){
      let payload = {
        id:this.$route.query.taskId,
        status:'done'
      }
      this.$store.dispatch('doneTask',payload).then((resp) => {
        if (resp.data.task){
          EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
        }else{
          EventBus.$emit('notify', 'red', undefined, 'عملیات با مشکل مواجه شد ');
        }
      }).finally(() => {
        EventBus.$emit('returnWindow', 1)
      })
    }
  }
}
</script>

<style scoped>

</style>
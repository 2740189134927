<template>
    <div style="min-height: 100vh" class="d-flex justify-center align-center">
        <img :src='"../assets/mail-"+ `${status}` +".svg"'>
        <v-snackbar

                v-model="snackbar.visibility"
                :color="snackbar.color"
                :bottom="snackbar.position === 'bottom'"
                :timeout="snackbar.timeout"
                :top="snackbar.position === 'top'"
        >
            {{snackbar.message}}
            <v-btn
                    color="primary--text"
                    @click="snackbar.visibility = false"
                    fab
            >
                <v-icon small color="red">fas fa-times</v-icon>
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>

    export default {
        data() {
            return {

                snackbar: {
                    visibility: false,
                    color: 'info',
                    position: 'bottom',
                    timeout: 3000,
                    mode: 'vertical',
                    message: 'this is snackbar message',
                },
                status: 'success'
            }
        },
        created() {
            this.verifyEmail();

        },
        methods: {
            notify(color = 'success', timeout = 3000, message = 'عملیات با موفقیت انجام شد.') {
                this.snackbar.visibility = true;
                this.snackbar.color = color;
                this.snackbar.position = 'bottom';
                this.snackbar.timeout = timeout;
                this.snackbar.mode = 'vertical';
                this.snackbar.message = message;

            },
            verifyEmail() {

                let token = this.$route.params.token;
                this.$store.dispatch('emailVerify', token).then(() => {
                    this.notify();

                    setTimeout(() => {
                        this.$router.push({path: `/fa/panel/login`});
                    }, 1000)


                    // if (data.statusCode == 500) {
                    //     this.status = 'fail';
                    // }
                    //
                    // this.$store.dispatch('checkLogin').then(() => {
                    //
                    //     this.$store.dispatch('getUserInfo').then(() => {
                    //         if (localStorage.getItem('preorder')) {
                    //             console.log('preorder')
                    //             this.$router.push({
                    //                 name: 'preorder',
                    //                 params: {'orderId': localStorage.getItem('preorder')}
                    //             });
                    //         } else {
                    //             console.log('signup')
                    //             this.$router.push({path: `${this.$store.getters.getLocal}/panel/profile`});
                    //         }
                    //
                    //     });
                    //
                    // });

                }).catch(err => {
                    console.log('has err');
                    console.log(err);
                });
            }
        }
    }
</script>

<style>

</style>
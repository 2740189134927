<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title>
          ویرایش گزینه‌ها
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="6">
              <v-form ref="editOption">
                <v-text-field v-model="option.text" dense outlined label="تیتر فارسی "></v-text-field>
                <v-text-field dir="ltr" v-model="option.en_text" dense outlined label="تیتر انگلیسی "></v-text-field>
                <v-checkbox v-model="option.has_question" :value="1"
                            label="بعد از این گزینه سوالی نمایش داده شود؟"></v-checkbox>
                <v-btn color="primary" elevation="0" @click="editOption">
                  ویرایش گزینه
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
          <v-data-table
              v-if="questions"
              hide-default-footer
              :headers="headersQuestions"
              :items="questions"
              disable-pagination
              disable-initial-sort
              class="elevation-0"
          >
            <template v-slot:item.checkbox="{item}">
              <v-checkbox v-model="selectedQuestion" :value="item.id"></v-checkbox>
            </template>

          </v-data-table>
          <v-btn color="primary" elevation="0" @click="attachQuestionToOptions">
            ویرایش سوالات
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "@/event-bus";

export default {
  mounted() {
    this.getOptionData()
  },
  methods: {
    getOptionData() {
      let payload = {
        option_id: this.$route.params.optionId,
        test_id: this.$route.params.testId
      }
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('getMatchMakingTestOption', payload).then((resp) => {
        this.questions = resp.data.questions
        this.option = resp.data.option
        console.log(this.option)
        this.prepareSelectedQuestion()
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    prepareSelectedQuestion() {
      this.questions.forEach((item) => {
        if (item.hasOwnProperty('matchmaking_question_option_question') && item.matchmaking_question_option_question.length != 0) {
          this.selectedQuestion.push(item.id)
        }
      })
    },
    editOption() {
      let payload = {
        option_id: this.$route.params.optionId,
        options: {
          text: this.option.text,
          type: this.option.type,
          has_question: this.option.has_question == 1,
          en_text: this.option.en_text,
        },
      }
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('editMatchMakingOption', payload).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    attachQuestionToOptions() {
      let payload = {
        question_ids: this.selectedQuestion,
        option_id: this.$route.params.optionId,
      }
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('attachQuestionToOptions', payload).then((resp) => {
        EventBus.$emit('setLoading', false)

      })
    }
  },
  data() {
    return {
      headersQuestions: [
        {
          text: '',
          align: 'start',
          value: 'checkbox'
        },
        {
          text: 'id',
          align: 'start',
          value: 'id',
        },
        {text: 'تیتر سوال', value: 'q_text'},
        {text: 'تیتر انگلیسی', value: 'q_en_text', align: 'left'},
        {text: '', value: 'actions'},
      ],
      option: null,
      questions: null,
      selectedQuestion: [],
    }
  }
}
</script>

<style scoped>

</style>
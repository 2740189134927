import firebase from "firebase";
import {firebaseCredential} from "@/firebase-credantional";
// import {EventBus} from "@/event-bus";
const EventEmitter = require('events');

class PushNotification extends EventEmitter {
    initialFirebaseSw() {
        firebase.initializeApp(firebaseCredential);
        navigator.serviceWorker
            .register("./firebase-messaging-sw.js")
            .then((registration) => {
                console.log(registration)
                const messaging = firebase.messaging();
                console.log('initial is done !')
                messaging.useServiceWorker(registration);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getFcmMethod(NotificationStatus) {
        if (NotificationStatus === 'granted') {
            try {
                firebase
                    .messaging()
                    .requestPermission()
                    .then(() => {
                        // console.log("Notification permission granted");
                        firebase
                            .messaging()
                            .getToken()
                            .then((token) => {
                                console.log(token)
                                // alert(token)
                                localStorage.setItem('FCMtoken', token)
                                this.emit('receiveNotification')
                            });
                    })
                    .catch((err) => {
                        console.log("Unable to get token ", err);
                    });
            } catch (e) {
                // console.log(e);
            }
        }

    }

    requestPermissionForNotification() {
        if ('Notification' in window) {
            Notification.requestPermission().then((resp) => {
                this.getFcmMethod(resp)
            })
        }
    }

    bindListenerForRequest() {
        document.addEventListener('click', this.requestPermissionForNotification.bind(this), {once: true})
    }

    getFcmForUser() {
        this.requestPermissionForNotification()
        this.bindListenerForRequest()
    }


}


export default PushNotification
<template>
    <div>
        <template v-if="showTopMenu">

            <v-row align="center" justify="center">
                <v-col>
                    <v-toolbar-items>
                        <v-btn class="py-5" text :to="{name:'dashboard'}">{{$vuetify.lang.t('$vuetify.navDashboard')}}
                        </v-btn>
                        <v-btn class="py-5" text :to="{name:'sessions'}">
                            {{$vuetify.lang.t('$vuetify.navSessionsLabel')}}
                        </v-btn>
                        <!--<v-btn class="py-5" text :to="{name:'calendar'}">{{$vuetify.lang.t('$vuetify.navCalendar')}}-->
                        <!--</v-btn>-->
                        <!--<v-btn class="py-5" text :to="{name:'cycles'}">دوره های درمان</v-btn>-->
                        <v-btn class="py-5" text :to="{name:'products'}">{{$vuetify.lang.t('$vuetify.navProducts')}}
                        </v-btn>

                        <v-btn class="py-5" text :to="{name:'clientFactors'}">فاکتورها
                        </v-btn>

                        <!--<v-btn class="py-5" text :to="{name:'assignconsult'}">{{$vuetify.lang.t('$vuetify.navََََََََََََََAssignConsult')}}</v-btn>-->
                    </v-toolbar-items>
                </v-col>
                <v-col>
                    <v-btn text class="info" @click="openInvite" small>دعوت از دوستان</v-btn>
                </v-col>
            </v-row>
        </template>


        <v-list v-else-if="showNavIcon" id="navIconItems">
            <v-list-item>
                <v-list-item-avatar>
                    <img
                            style="object-fit: cover"
                            :src="require('./../../assets/avatar-default.png')"/>
                </v-list-item-avatar>

                <v-list-item-title>
                    <v-btn text class="font-weight-bold" :to="{name:'profile'}">{{ $store.getters.getUserInfo.firstname
                        + ' ' + $store.getters.getUserInfo.lastname }}
                    </v-btn>
                    <p class="secondary--text px-4" style="font-size: .6rem">مشاهده پروفایل
                        <v-icon color="secondary" small>mdi-chevron-left</v-icon>
                    </p>
                </v-list-item-title>
            </v-list-item>
            <template
                    v-if="getUserInfo != null && isLoggedin && $store.getters.userHasRole('customer')">
                <v-list-item v-for="(item,currency) in getUserInfo.monetary_wallet"
                             :key="currency" dense

                >

                    <v-list-item-action>
                        <!--<v-icon>fas fa-wallet</v-icon>-->
                    </v-list-item-action>
                    <v-list-item-title :class="{'blue--text':item.balance>0,'red--text':item.balance<0}">
                        کیف پول شما
                        <div style="direction:ltr;display: inline-block;">
                            {{item.balance | resolvePrice(currency)}}
                        </div>
                        {{currency | resolveCurrency}}
                    </v-list-item-title>
                    <!--<v-list-item-content>-->
                    <!---->
                    <!--</v-list-item-content>-->

                </v-list-item>


            </template>
            <v-divider></v-divider>
            <v-list-item :to="{name:'wallet'}">
                <v-list-item-action>
                    <v-icon>mdi-format-list-bulleted</v-icon>
                </v-list-item-action>
                <v-list-item-title
                >
                    جلسات باقی مانده
                </v-list-item-title>

            </v-list-item>
            <template v-for="item in items">
                <v-row
                        v-if="item.heading"
                        :key="item.heading"
                        align="center"
                >
                    <v-col cols="6">
                        <v-subheader v-if="item.heading">
                            {{ item.heading }}
                        </v-subheader>
                    </v-col>
                    <v-col
                            cols="6"
                            class="text-center"
                    >
                        <a
                                href="#!"
                                class="body-2 black--text"
                        >EDIT</a>
                    </v-col>
                </v-row>
                <v-list-group
                        v-else-if="item.children"
                        :key="item.text"
                        v-model="item.model"
                        :prepend-icon="item.model ? item.icon : item['icon-alt']"
                        append-icon=""
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item
                            v-for="(child, i) in item.children"
                            :key="i"
                            link
                            class="mx-3"
                            :to="{name:child.routeName}"
                    >
                        <v-list-item-action v-if="child.icon">
                            <v-icon>{{ child.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ child.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <template v-else>

                    <v-list-item
                            v-if="!item.isBtn"
                            :key="item.text"
                            link
                            :to="{name:item.routeName}"
                    >
                        <v-list-item-action>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                            v-else
                            :key="item.text"
                            @click="item.method"
                    >
                        <v-list-item-action>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>

            </template>

            <!--<v-list-item>-->
            <!--<v-list-item-content class="py-0">-->
            <!--<v-btn text :to="{name:'dashboard'}">{{$vuetify.lang.t('$vuetify.navDashboard')}}</v-btn>-->
            <!--</v-list-item-content>-->
            <!--</v-list-item>-->
            <!--<v-list-item>-->
            <!--<v-list-item-content class="py-0">-->
            <!--<v-btn text :to="{name:'sessions'}">{{$vuetify.lang.t('$vuetify.navSessionsLabel')}}</v-btn>-->
            <!--</v-list-item-content>-->
            <!--</v-list-item>-->
            <!--<v-list-item>-->
            <!--<v-list-item-content class="py-0">-->
            <!--<v-btn text :to="{name:'calendar'}">{{$vuetify.lang.t('$vuetify.navCalendar')}}</v-btn>-->
            <!--</v-list-item-content>-->
            <!--</v-list-item>-->
            <!--<v-list-item>-->
            <!--<v-list-item-content class="py-0">-->
            <!--<v-btn text :to="{name:'cycles'}">دوره های درمان</v-btn>-->
            <!--</v-list-item-content>-->
            <!--</v-list-item>-->
            <!--<v-list-item>-->
            <!--<v-list-item-content class="py-0">-->
            <!--<v-btn text :to="{name:'products'}">{{$vuetify.lang.t('$vuetify.navProducts')}}</v-btn>-->
            <!--</v-list-item-content>-->
            <!--</v-list-item>-->
            <!--<v-list-item>-->
            <!--<v-list-item-content class="py-0">-->
            <!--<v-btn class="info "-->
            <!--v-if="$store.getters.userHasRole('customer')"-->
            <!--text-->
            <!--small-->
            <!--@click="openSessionRequestDialog">-->
            <!--درخواست جلسه-->
            <!--</v-btn>-->
            <!--</v-list-item-content>-->
            <!--</v-list-item>-->
            <!--<v-list-item>-->
            <!--<v-list-item-content class="py-0">-->
            <!--<v-btn text class="info" @click="openInvite" small>دعوت از دوستان</v-btn>-->
            <!--</v-list-item-content>-->
            <!--</v-list-item>-->


            <!--<v-list-item class="d-flex align-content-end">-->
            <!--<v-list-item-content class="py-0">-->
            <!--<v-btn text :to="{name:'assignconsult'}">{{$vuetify.lang.t('$vuetify.navََََََََََََََAssignConsult')}}</v-btn>-->
            <!--</v-list-item-content>-->
            <!--</v-list-item>-->
        </v-list>

    </div>
</template>

<script>
    import {EventBus} from "../../event-bus";
    import {mapGetters} from 'vuex';
    import {resolveCurrency} from "../../utilities";

    export default {
        data() {
            return {
                items: [
                    {method: null, routeName: 'dashboard', icon: 'mdi-view-dashboard', text: 'داشبورد'},
                    {method: null, routeName: 'sessions', icon: 'mdi-calendar', text: 'جلسات'},
                    {method: null, routeName: 'products', icon: 'fas fa-heartbeat', text: 'خرید بسته'},
                    {method: null, routeName: 'clientFactors', icon: 'fas fa-receipt', text: 'فاکتورها'},

                    {
                        method: this.openSessionRequestDialog,
                        routeName: '',
                        isBtn: true,
                        icon: 'mdi-monitor',
                        text: 'درخواست جلسه',
                        condition: this.$store.getters.userHasRole('customer')
                    },
                    {method: this.openInvite, isBtn: true, routeName: '', icon: 'mdi-face', text: 'دعوت از دوستان'},
                ]
            }
        },
        methods: {

            openSessionRequestDialog() {
                EventBus.$emit('showAddSessionDialog');
            },
            openInvite() {

                EventBus.$emit('openInvite');
            }
        },
        computed: {
            ...mapGetters({
                    getUserInfo: 'getUserInfo',
                    isLoggedin: 'userIsLoggedIn'
                }
            ),
            showNavIcon() {
                // return true;
                return this.$vuetify.breakpoint.mdAndDown;
            },
            showTopMenu() {
                return this.$vuetify.breakpoint.mdAndUp;
            }
        },
        filters: {
            resolveCurrency(input) {
                return resolveCurrency(input);

            },
            resolvePrice(amount, currency) {
                return currency == 'dollar' ? amount.toFixed(2) : amount.toLocaleString();
            }
        },
    }
</script>

<style>
    #navIconItems .v-btn__content {
        justify-content: right;
    }
</style>
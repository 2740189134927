<template>
  <div>
    <v-container>
      <div>
        <AdminDatePicker
            @close="startAtDatePicker=false"
            :dialog="startAtDatePicker"
            v-model="start_at"
        ></AdminDatePicker>
        <AdminDatePicker
            @close="endAtDatePicker=false"
            :dialog="endAtDatePicker"
            v-model="end_at"
        ></AdminDatePicker>
        <v-row>
          <v-col cols="12">
            <h3>آمار مارکتینگ </h3>
          </v-col>
          <v-col>
            <div>
              <div class="">
                <div class="d-flex " :class="$vuetify.breakpoint.mdAndDown && 'flex-column'">
                  <div class="dataBox filter-item" @click="openModalDatePicker('start_at')"
                       :class="$vuetify.breakpoint.mdAndDown && 'mb-2'">
                    {{ start_at | dateFilter }}
                  </div>
                  <div class="mx-3 d-flex justify-center flex-column" :class="$vuetify.breakpoint.mdAndDown && 'mb-2'">
                    تا
                  </div>
                  <div class="dataBox filter-item" @click="openModalDatePicker('end_at')"
                       :class="$vuetify.breakpoint.mdAndDown && 'mb-2'">
                    {{ end_at | dateFilter }}
                  </div>
                  <div :class="$vuetify.breakpoint.mdAndDown && 'md-2'">
                    <v-btn color="primary" class="mx-2" small @click="filterDates" :loading="loading"
                           :disabled="loading">فیلتر
                    </v-btn>
                  </div>
                </div>
                <div class=" " :class="$vuetify.breakpoint.mdAndUp && 'mr-5'">
                  <div class="d-flex" :class="$vuetify.breakpoint.mdAndDown && 'flex-column'">
                    <div class="d-flex timePeriodsBox py-4">
                      <v-btn class="ma-1 mt-3" @click="setDates(0)" small elevation="0"
                             :loading="loading && activeType ==0"
                             :disabled="loading"
                             :class="{'blue white--text':activeType ==0 }">
                        امروز
                      </v-btn>
                      <v-btn class="ma-1 mt-3" @click="setDates(7)" small elevation="0"
                             :loading="loading && activeType ==7"
                             :disabled="loading"
                             :class="{'blue white--text':activeType ==7 }">
                        این هفته
                      </v-btn>
                      <v-btn class="ma-1 mt-3" @click="setDates(30)" small elevation="0"
                             :loading="loading && activeType ==30"
                             :disabled="loading"
                             :class="{'blue white--text':activeType ==30 }">
                        30 روز اخیر
                      </v-btn>
                      <v-btn class="ma-1 mt-3" @click="setDates(60)" small elevation="0"
                             :loading="loading && activeType ==60"
                             :disabled="loading"
                             :class="{'blue white--text':activeType ==60 }">
                        60 روز اخیر
                      </v-btn>
                      <v-btn class="ma-1 mt-3" @click="setDates(90)" small elevation="0"
                             :loading="loading && activeType ==90"
                             :disabled="loading"
                             :class="{'blue white--text':activeType ==90 }">
                        90 روز اخیر
                      </v-btn>
                    </div>
                    <div class="" :class="$vuetify.breakpoint.mdAndUp && 'd-flex mt-4'">
                      <v-checkbox label="حذف مشتریان جدید" v-model="noFirstPurchase" class="ma-3" dense/>
                      <v-checkbox label="حذفشماره های ایران" v-model="noIR" class="ma-3" dense/>
                    </div>
                  </div>
                </div>
                <div class="timePeriodsBox py-4">
                  <h3 class="mb-2 font-weight-bold">
                    اهداف ماهیانه
                  </h3>
                  <div class="d-flex " :class="$vuetify.breakpoint.mdAndUp && 'justify-right'" style="width: 100%">
                    <div v-for="(month , i) in months" :key="i">
                      <v-btn class="ma-1 mt-3" small elevation="0" :disabled="loading"
                             @click="getMonthStaticAndTargets(month.start,month.end,month.id,month.addYear)"
                             :loading="loading && selectedMonth ==month.id"
                             :class="{'blue white--text': selectedMonth == month.id }">
                        {{ month.label }}
                      </v-btn>
                    </div>
                  </div>
                </div>
                <v-fade-transition mode="out-in">
                  <v-row v-if="dash==null" key="first">

                    <v-col cols="6" md="4" lg="3" v-for="i in 8" :key="i">
                      <v-skeleton-loader
                          style="margin-top: 10px;"
                          max-height="150"
                          type="image"
                      >
                      </v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" md="6" v-for="i in 2" :key="`outer${i}`">
                      <v-skeleton-loader
                          style="margin-top: 10px;"
                          max-height="150"
                          type="image"
                      ></v-skeleton-loader>
                    </v-col>
                  </v-row>
                  <v-row v-else key="second">


                    <v-col cols="6" md="4" lg="3" v-for="(item,index) in dash.statistics" :key="index">
                      <v-card dark style="" class="pa-2 ma-1 statisticsBox">
                        <div class="d-flex justify-center align-center"
                             :class="$vuetify.breakpoint.mdAndDown && 'flex-column flex-column-reverse '">
                          <div class=" dashboard-card-title flex-grow-1 " style=""
                               :class="$vuetify.breakpoint.mdAndDown && 'text-center'">
                            {{ item.title }}
                          </div>
                          <div>
                            <div class="dashboard-card-value">{{ item.value }}</div>
                            <div v-if="item.hasOwnProperty('target_key') && selectedMonth"
                                 :style="$vuetify.breakpoint.mdAndUp && 'margin-top: -20px'">
                              {{ targets[selectedMonth - 1][item.target_key] }}
                            </div>
                          </div>
                        </div>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-card dark style="width: 100%;" class="pa-2 ma-1 statisticsBox">
                        <div class="d-flex ">
                          <div class="flex-grow-1" style="text-align:right;font-size: 1.3em;padding: 20px;margin-top: 10px;">
                            فروش دلاری
                          </div>
                          <div class="" style="padding: 10px;font-weight: bolder;font-size: 3em;">
                            {{ Number(dash.sale.dollar).toLocaleString() }}
                          </div>
                        </div>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-card dark style="width: 100%;" class="pa-2 ma-1 statisticsBox">
                        <div class="d-flex ">
                          <div class="flex-grow-1" style="text-align:right;font-size: 1.3em;padding: 20px;margin-top: 10px;">
                            فروش تومانی

                          </div>
                          <div class="" style="padding: 10px;font-weight: bolder;font-size: 3em;">
                            {{ Number(dash.sale.rial).toLocaleString() }}
                          </div>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-fade-transition>

              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import moment from "moment-jalaali";
import AdminDatePicker from "@/components/AdminDatePicker";

export default {
  components: {AdminDatePicker},
  data() {
    return {
      activeType: 0,
      startAtDatePicker: false,
      endAtDatePicker: false,
      loading: false,
      noIR: true,
      noFirstPurchase: true,
      dash: null,
      dataPicker: false,
      picker: false,
      calenderState: null,
      start_at: null,
      end_at: null,
      months: [
        {
          id: 1,
          label: 'خرداد',
          start: '03-01',
          end: '03-31',
        },
        {
          id: 2,
          label: 'تیر',
          start: '04-01',
          end: '04-31',
        },
        {
          id: 3,
          label: 'مرداد',
          start: '05-01',
          end: '05-31',
        },
        {
          id: 4,
          label: 'شهریور',
          start: '06-01',
          end: '06-31',
        },
        {
          id: 5,
          label: 'مهر',
          start: '07-01',
          end: '07-30',
        },
        {
          id: 6,
          label: 'آبان',
          start: '08-01',
          end: '08-30',
        },
        {
          id: 7,
          label: 'آذر',
          start: '09-01',
          end: '09-30',
        },
        {
          id: 8,
          label: 'دی',
          start: '10-01',
          end: '10-30',
        },
        {
          id: 9,
          label: 'بهمن',
          start: '11-01',
          end: '11-30',
        },
        {
          id: 10,
          label: 'اسفند',
          start: '12-01',
          end: '12-29',
        },
        {
          id: 11,
          label: 'فروردین',
          start: '01-01',
          end: '01-31',
          addYear: true,
        },
        {
          id: 12,
          label: 'اردبیهشت',
          start: '02-01',
          end: '02-31',
          addYear: true,
        },
      ],
      selectedMonth: null,
      targets: null,
    }
  },
  filters: {
    dateFilter(val) {
      if (val) {
        return moment(val).format('YYYY/MM/DD') + " - " + moment(val).format('jYYYY/jMM/jDD')
      }
    }
  },
  computed: {
    // ModelDatePicker: {
    //   get() {
    //     if (this.calenderState == 'start_at') {
    //       return this.start_at
    //     } else {
    //       return this.end_at
    //     }
    //   },
    //   set(value) {
    //     if (this.calenderState == 'start_at') {
    //       this.start_at = value;
    //     } else {
    //       this.end_at = value;
    //     }
    //   }
    // }
  },
  created() {


    this.start_at = moment(new Date()).format('YYYY-MM-DD')
    this.end_at = moment(new Date()).format('YYYY-MM-DD')

    this.getMarketingInfo();
  },
  methods: {
    jalaliToGregorian(date, format = 'Y-MM-DD') {
      return moment(date, 'jYYYY-jM-jDD').format(format)
    },
    filterDates() {
      this.activeType = -1;
      this.getMarketingInfo()
    },
    getMarketingInfo() {
      this.dash = null;
      console.log(this.dash)
      this.loading = true;
      this.$store.dispatch('getMarketingDashboard', {
        from: this.start_at,
        to: this.end_at,
        noFirstPurchase: this.noFirstPurchase,
        noIR: this.noIR

      }).then((resp) => {
        this.dash = resp.data
        this.targets = resp.data.targets
      }).finally(() => {
        this.loading = false;
      })
    },
    openModalDatePicker(state) {
      // this.calenderState = state
      console.log(state)
      if (state === 'start_at')
        this.startAtDatePicker = true
      else this.endAtDatePicker = true;
    },
    setDates(subtract) {
      this.activeType = subtract;
      this.selectedMonth = null
      this.start_at = moment().subtract(subtract, 'days').format('YYYY-MM-DD');
      this.end_at = moment(new Date()).format('YYYY-MM-DD')

      this.getMarketingInfo();
    },
    getMonthStaticAndTargets(start, end, id, addYear = false) {
      this.activeType = null
      this.selectedMonth = id
      let year = moment()
      if (addYear) {
        year.add('year', 1)
      }
      year = year.format('jYYYY-')

      start = year + start
      end = year + end
      // console.log(end,'1212')
      this.start_at = this.jalaliToGregorian(start, 'YYYY-MM-DD')
      this.end_at = this.jalaliToGregorian(end, 'YYYY-MM-DD')

      // this.end_at = moment(new Date()).format('YYYY-MM-DD')
      this.getMarketingInfo();
    }
  },
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .dataBox {
    width: 250px !important;
  }

  .timePeriodsBox {
    width: 300px !important;
    overflow-x: scroll;
  }
}

.statisticsText {
  font-size: 15px;
  color: gray;
}

.statisticsBox {
  /*background-color:rgba(0,50,50,1);width: 100%;*/
  background: linear-gradient(45deg, rgba(0, 100, 100, 1), rgba(0, 50, 50, 1));;
  height: 150px;
  display: flex;
  border-radius: 7px;
  box-shadow: 0 10px 20px -15px rgba(0, 0, 0, .1),
  inset 0 -10px 25px rgba(0, 0, 0, .05);
  font-weight: bolder;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  /*padding: 10px;*/
  /*box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;*/
  /*border: 1px solid #ededed;*/
  /*border-radius: 10px;*/
}

.statisticsCount {
  color: black;
  font-size: 4em;
  text-align: left;
}

.dataBox {
  border: 1px solid #ededed;
  border-radius: 3px;
}

.filter-item {
  padding: 3px 5px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashboard-card-title {
  text-align: right;
  font-size: 1.3em;
  padding: 20px;
  margin-top: 10px;
}

.dashboard-card-value {
  padding: 10px;
  font-weight: bolder;
  font-size: 3em;
}

@media screen and (max-width: 500px) {
  .dashboard-card-title {
    text-align: right;
    font-size: 1em;
    padding: 0px;
    margin-top: 0px;
  }

  .dashboard-card-value {
    padding: 5px;
    font-weight: bolder;
    font-size: 1.5em;

  }

}

</style>
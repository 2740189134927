<template>
  <div class="fill-height" style="overflow: hidden">
    <v-container fluid>
      <v-card class=" ">
        <v-card-title>
          مدریت‌ تسک‌ها
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="3" v-for="(list , i) in lists" :key="i">
              <v-card>
                <v-card-title>
                  {{ list.title }}
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <div class="tasksBox" v-for="(task , i ) in tasks.filter(item => item.type == list.key)" :key="i" v-ripple>
                    <h3>
                      {{task.title}}
                    </h3>
                    <p class="mt-2"> تاریخ ارجاع :  {{task.date}}</p>
                    <div class="wrapper primary"></div>
                    <div class="d-flex mt-2">
                      <div>
                        <v-btn elevation="0" fab x-small>
                          <v-icon>mdi-account</v-icon>
                        </v-btn>
                      </div>
                      <div>
                        <p class="mr-1">
                          {{task.user.name}}
                        </p>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lists: [
        {
          title: 'برای انجام',
          key: 'doing'
        },
        {
          title: 'در حال انجام',
          key: 'todo'
        },
        {
          title: 'انجام شده',
          key: 'done'
        },
        {
          title: 'نگه‌داشته‌شده',
          key: 'paused'
        },
      ],
      tasks: [
        {
          type: 'doing',
          title: 'لغو جلسه کاربر',
          user: {
            name: 'علی امیری'
          },
          date:'1400/04/02'
        },
        {
          type: 'done',
          title: 'تنظیم جلسه برای کاربر',
          user: {
            name: 'علی امیری'
          },
          date:'1400/04/02'
        },
        {
          type: 'done',
          title: 'تنظیم جلسه برای کاربر',
          user: {
            name: 'علی امیری'
          },
          date:'1400/04/02'
        },
        {
          type: 'done',
          title: 'تنظیم جلسه برای کاربر',
          user: {
            name: 'علی امیری'
          },
          date:'1400/04/02'
        },
        {
          type: 'done',
          title: 'تنظیم جلسه برای کاربر',
          user: {
            name: 'علی امیری'
          },
          date:'1400/04/02'
        },
        {
          type: 'done',
          title: 'تنظیم جلسه برای کاربر',
          user: {
            name: 'علی امیری'
          },
          date:'1400/04/02'
        },
        {
          type: 'done',
          title: 'تنظیم جلسه برای کاربر',
          user: {
            name: 'علی امیری'
          },
          date:'1400/04/02'
        },
        {
          type: 'doing',
          title: 'تنظیم جلسه برای کاربر',
          user: {
            name: 'علی امیری'
          },
          date:'1400/04/02'
        },
        {
          type: 'todo',
          title: 'تنظیم جلسه برای کاربر',
          user: {
            name: 'علی امیری'
          },
          date:'1400/04/02'
        },
        {
          type: 'paused',
          title: 'تنظیم جلسه برای کاربر',
          user: {
            name: 'علی امیری'
          },
          date:'1400/04/02'
        },
        {
          type: 'done',
          title: 'تنظیم جلسه برای کاربر',
          user: {
            name: 'علی امیری'
          },
          date:'1400/04/02'
        },
        {
          type: 'todo',
          title: 'تنظیم جلسه برای کاربر',
          user: {
            name: 'علی امیری'
          },
          date:'1400/04/02'
        },
        {
          type: 'paused',
          title: 'تنظیم جلسه برای کاربر',
          user: {
            name: 'علی امیری'
          },
          date:'1400/04/02'
        },
      ]
    }
  }
}
</script>

<style scoped>
.tasksBox{
  margin: 10px;
  padding: 20px;
  border: 1px solid #ededed;
  border-radius: 10px;
}
.wrapper{
  width: 100px;
  height: 1px;
}
</style>
<template>

  <v-container>
    <v-dialog v-model="showWarningDialog" width="500">
      <v-card>
        <v-card-title>
          توجه
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-2">
          شما هیچ بسته مشاوره ای اضافه نکرده اید، آیا نسبت به عملیات مورد نظر اطمینان دارید؟
        </v-card-text>
        <v-card-actions>
          <v-btn class="error" @click="showWarningDialog=false">لغو</v-btn>
          <v-spacer></v-spacer>
          <v-btn class="success" @click="addPayment">
            تایید
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>

      <v-card-title>
        افزودن پرداخت
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="addPayment">
          <v-row>
            <v-col cols="12" md="4" lg="3" sm="6">
              <v-select
                  v-model="payment.payment_method_id"
                  :items="paymentMethods"
                  label="متد"
                  :loading="paymentMethodsLoading"
                  item-text="title"
                  item-value="id"
                  outlined
                  @change="setCurrency"
                  :rules="requiredRules"
              ></v-select>
            </v-col>
            <v-col cols="12" md="4" lg="3" sm="6">
              <v-select
                  v-model="payment.currency"
                  :items="currencies"
                  label="ارز"
                  item-text="title"
                  item-value="value"
                  outlined
                  :rules="requiredRules"
              ></v-select>
            </v-col>


            <v-col cols="12" md="4" lg="3" sm="6">
              <v-text-field
                  v-if="isFocused"
                  outlined
                  label="مبلغ پرداختی"
                  :rules="requiredRules"
                  @blur="isFocused=false"
                  v-model="payment.final_amount"

              >
              </v-text-field>
              <v-text-field
                  v-else-if="!isFocused"
                  outlined
                  label="مبلغ پرداختی"
                  :rules="requiredRules"
                  @focus="isFocused=true"
                  :value="payment.final_amount | toLocaleOrFixed(payment)"
                  style="font-weight: bold"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="4" lg="3" sm="6">
              <div style="position: relative">
                <v-text-field
                    v-model="User"
                    :items="clients"
                    :loading="clientsLoading"
                    hide-no-data
                    hide-selected
                    item-text="full_name"
                    item-value="id"
                    :filter="filterUsers"
                    outlined
                    label="کاربر"
                    :hint="isUserName ? 'جست‌و‌جو با آیدی کاربر' :  'جست‌و‌جو با نام کاربر'"
                    persistent-hint
                    value=""
                    @input="userSelectedID = null"
                    :rules="requiredRules"
                >
                  <template v-slot:prepend>
                    <v-checkbox
                        dense class="pa-0 ma-0" v-model="isUserName">

                    </v-checkbox>
                  </template>
                  <!--                  payment.user_id-->
                </v-text-field>
                <div class="listTimeSelected" v-if="UsersList">
                  <div v-for="(user , i ) in UsersList" :key="i" class="UserList" @click="selectingUser(user)">
                    <div class="d-flex justify-space-between">
                      <div>
                        <b>
                          {{ user.full_name }}
                        </b>
                        <br>
                        <span>
                      {{ user.email }}
                        </span>
                      </div>
                      <div>
                        <v-chip color="primary" class="mt-2" small v-if="user.notes.length != 0">
                          {{ user.notes[0].description }}
                        </v-chip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>


            <v-col cols="12" md="4" lg="3" sm="6">

              <v-text-field
                  outlined
                  :value="payment.date | toJalali"
                  label="تاریخ پرداخت"
                  :rules="requiredRules"
                  prepend-icon="event"
                  readonly
                  @click="modal=true"
              ></v-text-field>
              <AdminDatePicker @close="modal=false" :dialog="modal"
                               v-model="payment.date"></AdminDatePicker>
            </v-col>
            <v-col cols="12" md="4" lg="3" sm="6" v-if="checkRoleAndPermissions('admin.coupons.all')">

              <v-autocomplete
                  v-model="payment.coupon_id"
                  :items="coupons"
                  :loading="couponsLoading"
                  hide-no-data
                  hide-selected
                  item-text="code"
                  item-value="id"
                  outlined
                  label="کد تخفیف"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" lg="3" sm="6">
              <v-text-field
                  outlined
                  label="کد انتقال"
                  v-model="payment.transaction_code"
              >

              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" lg="3" sm="6">
              <v-text-field
                  outlined
                  label="کد پیگیری"
                  v-model="payment.ref_id"
              >

              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="3" xl="3">
              <v-text-field
                  outlined
                  label="توضیحات"
                  v-model="payment.information"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4" lg="3" sm="6">

              <v-autocomplete
                  v-model="selectedPackage"
                  :items="packages"
                  :loading="packagesLoading"
                  hide-no-data
                  hide-selected
                  item-text="title"
                  item-value="id"
                  outlined
                  label="بسته"
                  :search-input.sync="packageId"
                  :filter="packageNamesAndCodes"
                  return-object
              >
                <template v-slot:selection="{item}">
                  {{ item.title }}

                </template>
                <template v-slot:item="{item}">
                  {{ item.title }}
                </template>
              </v-autocomplete>

            </v-col>

            <v-col cols="6" sm="3" md="2" lg="2" xl="1">
              <v-text-field outlined v-model="packageToAddCount" label="تعداد"
                            type="number"></v-text-field>
            </v-col>
            <v-col cols="auto">
              <v-btn small class="primary mt-4" :disabled="selectedPackage== null" @click="addPackage">
                افزودن بسته
                <v-icon class="mx-1" small>fas fa-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">

              <v-fade-transition>
                <v-data-table
                    :headers="headers"
                    :items="payment.paymentPackages"
                    hide-default-footer
                    v-if="payment.paymentPackages.length"

                >
                  <template v-slot:item="{item}">
                    <tr>
                      <td>{{ item.package.title }}</td>
                      <td>
                        {{ item.quantity }}
                      </td>
                      <td>
                        {{ item.package.price | toLocaleOrFixed }}
                      </td>
                      <td class="text-center">
                        <v-btn fab x-small text class="ma-1 primary"
                               @click="increaseQuantity(item)">
                          <v-icon small>
                            fas fa-plus
                          </v-icon>
                        </v-btn>
                        <v-btn fab x-small text class="ma-1 red white--text"
                               @click="decreaseQuantity(item)">
                          <v-icon small>
                            fas fa-minus
                          </v-icon>
                        </v-btn>
                        <v-btn fab x-small text class="ma-1 red white--text"
                               @click="removeItem(item)">
                          <v-icon small>
                            fas fa-trash
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>

                  </template>
                </v-data-table>
              </v-fade-transition>

            </v-col>
          </v-row>

        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="!isNormal" class="grey white--text" @click="skip">رد شدن</v-btn>
        <v-btn class="primary" @click="checkAddPayment">
          افزودن
        </v-btn>
      </v-card-actions>
      <v-row>
        <v-col>
          <AlertsComponent></AlertsComponent>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
/*eslint-disable*/
import AlertsComponent from "@/components/AlertsComponent";
import {EventBus} from "../../event-bus";
import AdminDatePicker from '@/components/AdminDatePicker';
import moment from 'moment-jalaali';
import {mapGetters} from 'vuex';

export default {
  props: {
    mode: {
      default: 'normal'
    },
    userId: {
      default: null
    },
    clients: {
      default: () => {
        return [];
      }
    }
  },
  components: {
    AlertsComponent, AdminDatePicker
  },
  created() {
    // this.clientsCopy = this.clients;
    // if (!this.clients.length) {
    //     this.getClients();
    // }


    this.getCoupons();
    this.getAllPaymentMethods();
  },
  data() {
    return {
      isUserName: false,
      showUserList: false,
      isFocused: false,
      showWarningDialog: false,
      paymentMethodsLoading: false,
      headers: [
        {
          text: 'نام بسته',
          value: 'title', sortable: false
        },
        // {text: 'ارز', value: 'currency', sortable: false},
        {text: 'تعداد', value: 'quantity', sortable: false},
        {text: 'قیمت', value: 'price', sortable: false},
        {text: 'عملیات', value: 'actions', align: 'center', sortable: false},
      ],
      selectedPackage: null,
      packageToAddCount: 1,
      packageId: '',
      // currencies: [
      //     {title: 'دلار', value: 'dollar'},
      //     {title: 'تومان', value: 'rial'},
      //     {title: 'دلار استرالیا', value: 'aud'},
      //     {title: 'دلار کانادا', value: 'cad'},
      //     {title: 'پوند', value: 'gbp'},
      //     {title: 'یورو', value: 'euro'},
      // ],
      paymentMethods: [],

      modal: false,
      packagesLoading: false,
      couponsLoading: false,
      packages: [],
      coupons: [],
      clientsLoading: false,
      clientsCopy: [],
      payment: {
        date: null,
        user_id: null,
        paymentPackages: [],
        coupon_id: null,
        transaction_code: '',
        ref_id: '',
        currency: '',
        method: '',
        discount_amount: '',
        final_amount: null,
        information: '',
      },
      UserNameTimer: null,
      User: null,
      UsersList: null,
      userSelectedID: null,
    }
  },
  methods: {
    selectingUser(user) {
      this.userSelectedID = user.id
      this.User = user.full_name
      this.UsersList = null
      clearTimeout(this.UserNameTimer)
    },
    skip() {
      this.$emit('done')
    },
    emptyOrSpace(str) {
      return str === null || str.match(/^ *$/) !== null;
    },
    filterUsers(item, queryText, itemText) {

      if (item == null) {
        return false;
      }
      if (item.header) {
        return false;
      }

      const hasValue = val => val != null ? val : '';

      const text = hasValue(itemText);
      const email = hasValue(item.email);
      const query = hasValue(queryText);

      return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1 || email.indexOf(query.toString().toLowerCase()) > -1;
    },
    setCurrency(id) {
      let method = this.paymentMethods.find(item => item.id == id);
      if (method != undefined) {
        this.payment.currency = method.code;
      }
    },
    increaseQuantity(item) {
      item.quantity++;
    },
    decreaseQuantity(item) {
      item.quantity--;
      if (item.quantity <= 0) {
        this.payment.paymentPackages.splice(this.payment.paymentPackages.indexOf(item), 1);
      }
    },
    removeItem(item) {
      this.payment.paymentPackages.splice(this.payment.paymentPackages.indexOf(item), 1);
    },
    addPackage() {
      let pack = this.payment.paymentPackages.find((pack) => {
        if (pack.package.id == this.selectedPackage.id) {
          return pack;
        }
      });
      if (pack == undefined || pack == null) {
        this.payment.paymentPackages.push({
          quantity: this.packageToAddCount,
          package: this.selectedPackage
        });
      } else {
        pack.quantity++;
      }


    },
    packageNamesAndCodes(item, queryText, itemText) {
      if (item.header) {
        return false;
      }
      const hasValue = val => val != null ? val : '';
      const text = hasValue(itemText);
      const code = hasValue(item.id);
      const query = hasValue(queryText);
      return text.toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1 || `${code}`.indexOf(query.toString().toLowerCase()) > -1;
    },
    getCoupons() {
      if (this.checkRoleAndPermissions('admin.coupons.all')) {
        this.couponsLoading = true;
        this.$store.dispatch('getAllCoupons').then((resp) => {
          this.coupons = resp.data.coupons;

        }).finally(() => {
          this.couponsLoading = false;
        })
      }
    },
    getAllPaymentMethods() {
      this.paymentMethodsLoading = true;
      this.$store.dispatch('getAllPaymentMethods', {}).then((resp) => {
        this.paymentMethods = resp.data;
        console.log(resp.data);

      }).finally(() => {
        this.paymentMethodsLoading = false;
      })
    },
    checkAddPayment() {
      if (this.$refs.addPayment.validate()) {
        if (!this.payment.paymentPackages.length) {
          this.showWarningDialog = true;
          return;
        }
        this.addPayment();
      }

    },
    addPayment() {
      if (this.$refs.addPayment.validate() && this.userSelectedID) {
        let data = {...this.payment};
        data.user_id = this.userSelectedID
        if (this.payment.paymentPackages.length) {
          data.packages = [];
          this.payment.paymentPackages.forEach((item) => {
            data.packages.push({
              quantity: item.quantity,
              id: item.package.id
            });

          })
        }
        delete data.paymentPackages;
        EventBus.$emit('setLoading', true);
        this.$store.dispatch('adminAddPayment', data).then(() => {
          EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
          EventBus.$emit('setLoading', false);
          if (this.isNormal) {
            setTimeout(() => {
              this.$router.push({name: 'adminTransactions'});
            }, 200)
          } else {
            this.$emit('done');
          }
        }).finally(() => {
          EventBus.$emit('setLoading', false);
          this.showWarningDialog = false;

        })
      }

    },

    getClients() {
      this.clientsLoading = true;
      this.$store.dispatch('getAllPatients').then((resp) => {
        this.clientsCopy = resp.data.clients;
      }).finally(() => {
        this.clientsLoading = false;
      })
    },
    getPackages() {
      this.packagesLoading = true;
      // this.$store.dispatch('getAllPackages').then((resp) => {
      //     this.packages = resp.data;
      // }).finally(() => {
      //     this.packagesLoading = false;
      // })
      this.$store.dispatch('getAllPackagesInCurrency', {currency: this.payment.currency}).then((resp) => {
        this.packages = resp.data.packages;
      }).finally(() => {
        this.packagesLoading = false;
      })
    },
  },
  filters: {
    toJalali(date) {
      if (date) {
        return moment(date, "YYYY-M-DD").format('jYYYY/jMM/jDD');
      }
      return date;
    },
    currencyType(currency) {
      return currency == 'dollar' ? 'دلار' : 'تومان';
    },
    toLocaleOrFixed(value) {
      let output = Number(value).toLocaleString();
      return output != 0 ? output : null;
    }
  },
  computed: {
    ...mapGetters({
      currencies: 'getCurrencies'
    }),
    isNormal() {
      return this.mode == 'normal';
    },
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
  },
  watch: {
    isUserName() {
      this.User = null
    },
    'User'(val, old) {
      clearTimeout(this.UserNameTimer)
      if (this.userSelectedID != null) {
        return;
      }
      this.UserNameTimer = setTimeout(() => {
        if (val) {
          EventBus.$emit('setLoading', true)
          let payload = {
            page: 1,
            options: {
              type: 'patient',
              loadNotes: true,
            }
          }
          if (this.isUserName) {
            payload.options.user_id = this.User
          } else {
            payload.options.patient_name = this.User
          }

          this.$store.dispatch('getClientsListAddPayment', payload).then((resp) => {
            this.UsersList = resp.data.users
          }).finally(() => {
            EventBus.$emit('setLoading', false)
          })
        } else {
          this.UsersList = null
        }
      }, 1000)
    },
    'payment.currency'() {
      this.selectedPackage = null;
      this.payment.paymentPackages = [];
      this.getPackages();
    },
    userId() {
      if (this.userId != null) {
        this.payment.user_id = this.userId;
      }
    }
  }

}
</script>

<style scoped>
.listTimeSelected {
  position: absolute;
  top: 60px;
  left: 0px;
  right: 0px;
  background: white;
  width: 100%;
  min-height: 300px;
  overflow-y: scroll;
  z-index: 10;
  border: 1px solid #ddd;
  border-radius: 0px 0px 10px 10px;
}

.UserList {
  margin-bottom: 5px;
  padding-right: 5px;
}
</style>
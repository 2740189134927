<template>
    <v-container>
        <v-card class="elevation-0">
            <v-card-title >
                افزودن کد هدیه
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-form ref="editGiftCode">
                    <v-row>
                        <v-col cols="12" md="4" lg="3" sm="6">
                            <v-text-field
                                    v-model="editGiftCode.max_usage_count"
                                    outlined label="دفعات قابل استفاده"
                            >

                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" lg="3" sm="6">
                            <v-select
                                    :items="states"
                                    item-text="title"
                                    item-value="value"
                                    v-model="editGiftCode.state"
                                    outlined
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="4" lg="3" sm="6">
                            <v-text-field
                                    v-model="editGiftCode.max_usage_count_per_user"
                                    outlined label="دفعات قابل استفاده هر کاربر"

                            >

                            </v-text-field>
                        </v-col>

                        <v-col cols="12" md="4" lg="3" sm="6">
                            <v-text-field
                                    :rules="requiredRules"
                                    v-model="editGiftCode.code"
                                    outlined label="کد"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="4" lg="3" sm="6">
                            <v-autocomplete
                                    v-model="editGiftCode.user_ids"
                                    :items="clients"
                                    :loading="clientsLoading"
                                    hide-no-data
                                    multiple
                                    hide-selected
                                    item-text="full_name"
                                    item-value="id"
                                    outlined
                                    label="کاربر"
                                    :filter="filterUsers"
                            >
                                <template v-slot:item="{item}">
                                    <div>
                                        <v-list-item-title>{{item.name}} {{item.family}}</v-list-item-title>
                                        <v-list-item-subtitle>{{item.email}}</v-list-item-subtitle>
                                    </div>
                                </template>
                                <template v-slot:selection="{attr, on, item, selected}">
                                    <!--<v-list-item-title>{{item.name}} {{item.family}}</v-list-item-title>-->
                                    <!--<v-list-item-subtitle>{{item.email}}</v-list-item-subtitle>-->
                                    <v-chip
                                            v-bind="attr"
                                            :input-value="selected"
                                            color="blue-grey"
                                            class="white--text"
                                            v-on="on"
                                            small
                                    >
                                            <span v-if='!emptyOrSpace(item.full_name)'>
                                                {{item.full_name}}
                                            </span>
                                        <span v-else>
                                            {{item.email}}
                                        </span>
                                    </v-chip>
                                </template>

                            </v-autocomplete>
                        </v-col>

                        <v-col cols="12" md="4" lg="3" sm="6">

                            <v-text-field
                                    outlined
                                    v-model="editGiftCode.exp_date"
                                    label="تاریخ انقضا"
                                    @click="modal=true"
                                    prepend-icon="event"
                            ></v-text-field>
                            <AdminDatePicker @close="modal=false" :dialog="modal"
                                             v-model="editGiftCode.exp_date"></AdminDatePicker>
                        </v-col>
                    </v-row>
                </v-form>
                <AddGiftCodePriceComponent @updatePrices="pricesChanged"
                                           :allPrices="editGiftCode.currencies"></AddGiftCodePriceComponent>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn class="primary" @click="updateGiftCode">
                    افزودن
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-row>
            <v-col>
                <AlertsComponent></AlertsComponent>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    /*eslint-disable*/
    import AlertsComponent from "@/components/AlertsComponent";
    import AdminDatePicker from '@/components/AdminDatePicker';
    import AddGiftCodePriceComponent from '@/components/Admin/giftCodes/AddGiftCodePriceComponent'
    import {EventBus} from "../../event-bus";

    export default {
        components: {
            AlertsComponent,
            AdminDatePicker,
            AddGiftCodePriceComponent

        },
        data() {
            return {
                states: [
                    {title: 'فعال', value: 'available'},
                    {title: 'غیر فعال', value: 'unavailable'}
                ],
                newCurrencies: [],
                clientsLoading: false,
                clients: [],
                modal: false,
                editGiftCode: {
                    user_ids: [],
                    max_usage_count: null,
                    max_usage_count_per_user: null,
                    exp_date: null,
                    code: null,
                    currencies: [],
                    state: 'available',
                }
            }
        },
        created() {

            this.getClients();
        },
        computed: {
            requiredRules() {
                return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
            },
            requiredArray() {
                return [v => !!v.length || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
            },
        },
        methods: {
            emptyOrSpace(str) {
                return str === null || str.match(/^ *$/) !== null;
            },
            filterUsers(item, queryText, itemText) {

                if (item == null) {
                    return false;
                }
                if (item.header) {
                    return false;
                }

                const hasValue = val => val != null ? val : '';

                const text = hasValue(itemText);
                const email = hasValue(item.email);
                const query = hasValue(queryText);

                return text.toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1 || email.indexOf(query.toString().toLowerCase()) > -1;
            },
            pricesChanged(input) {
                this.newCurrencies = input;
            },
            initialize() {
                let clone = JSON.parse(JSON.stringify(this.giftCode));

                this.editGiftCode.user_ids = clone.user_ids;
                this.editGiftCode.max_usage_count = clone.max_usage_count;
                this.editGiftCode.max_usage_count_per_user = clone.max_usage_count_per_user;
                this.editGiftCode.exp_date = clone.exp_date;
                this.editGiftCode.code = clone.code;
                this.editGiftCode.currencies = clone.currencies;
            },

            getClients() {
                this.clientsLoading = true;
                this.$store.dispatch('getAllPatients').then((resp) => {
                    this.clients = resp.data.clients;
                }).finally(() => {
                    this.clientsLoading = false;
                })
            },
            updateGiftCode() {
                this.editGiftCode.prices = this.newCurrencies;
                let data = {
                    payload: this.editGiftCode,
                };

                EventBus.$emit('setLoading', true);
                if (this.$refs.editGiftCode.validate()) {
                    this.$store.dispatch('adminAddGiftCode', data).then(() => {
                        EventBus.$emit('notify', 'green', undefined, 'با موفقیت انجام شد');
                        this.$router.push({name: 'giftCodes'})
                    }).finally(() => {
                        EventBus.$emit('setLoading', false);

                    })
                }

            }
        },
    }
</script>

<style>

    .smooth-transition {
        -webkit-transition: all .3s ease-in-out;
        -moz-transition: all .3s ease-in-out;
        -ms-transition: all .3s ease-in-out;
        -o-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
    }
</style>
<template>
    <div>
        <calendar></calendar>
    </div>
</template>

<script>
    import calendar from "../../components/calendar/calendar";
    export default {
        components: {
            calendar
        }
    }
</script>

<style>

</style>
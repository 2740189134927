<template>
  <div class="pa-5">
    <v-card color="">
      <v-row>
        <v-col cols="12" lg="3">
          <div class="conversationList fill-height" style="border-left: 1px solid grey">
            <div class="conversationItem" v-for="(conversation , i) in conversations" :key="i"
                 @click="loadConversationMessage(conversation.id,1)"
                 :class="conversation_id == conversation.id && 'activeConversation'"
            >
              <div class="d-flex">
                <div>
                  <v-btn color="grey" fab small elevation="0">
                    <v-icon color="white">mdi-account</v-icon>
                  </v-btn>
                </div>
                <div class="mt-3 mr-2">
                  گفت‌وگو {{ conversation.id }}
                </div>
                <v-spacer></v-spacer>
                <div class="mt-3">
                  {{ conversation.created_at | dateFilter }}
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" lg="9">
          <div class="messagesArea px-2" id="messagesContext">
            <v-fade-transition mode="out-in">
              <div :key="1" v-if="messages.length != 0">
                <div class="d-flex " v-for="(message , i) in messages" :key="i"
                     :class="getSenderId(message) == $route.params.clientId? 'justify-end' : 'justify-start'">
                  <div class="">
                    <div class="messageItem"
                         :class=" getSenderId(message) != $route.params.clientId && 'primary white--text'">

                      <template v-if="message.type == 'text'">
                        {{ message.text }}
                        <div class="d-flex">
                          <div class="editBadge" v-if="message.versions.length != 0">
                            ویرایش شده
                          </div>
                          <div class="deleteBadge" v-if="message.deleted_at != null">
                            حذف شده
                          </div>
                          <v-spacer></v-spacer>

                        </div>
                      </template>
                      <template v-else>
                        <LoadTelegramFile :message_id="message.id" :file_type="message.file_extension"
                                          :file_name="message.file_uri"/>
                      </template>
                      <div class="unkownSender" v-if="!message.sender">
                        ارسال کننده نامشص
                      </div>
                    </div>
                    <div v-if="message.versions.length != 0" class="">
                      <div class="pl-5">
                        <div class="editMessage" v-for="(version , i) in message.versions" :key="i"
                             :class="(i+1) == message.versions.length && 'primary_version'">
                          {{ version.text }}
                          <div class="d-flex">
                            <div class="editBadge" v-if="(i+1) == message.versions.length">
                              ورژن اصلی
                            </div>
                            <div class="deleteBadge" v-if="version.deleted_at != null">
                              حذف شده
                            </div>
                            <v-spacer></v-spacer>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="dateBadge">
                      <div>
                        {{ message.created_at | dateFilterWithTime }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else :key="2">
                <div class="text-center font-weight-bold mt-2">
                  پیامی وجود ندارد
                </div>
              </div>
            </v-fade-transition>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import moment from "moment-jalaali";
import LoadTelegramFile from "@/components/Admin/telegram/LoadTelegramFile";
import {EventBus} from "@/event-bus";

export default {
  components: {LoadTelegramFile},
  data() {
    return {
      conversations: [],
      conversation_id: null,
      messages: [],
      page: 1,
      total: 1,
    }
  },
  mounted() {
    EventBus.$emit('setLoading', true)
    this.$store.dispatch('getUserTelegramConversations', this.$route.params.clientId).then((resp) => {
      this.conversations = resp.data.conversations
    }).finally(() => {
      EventBus.$emit('setLoading', false)
    })
  },
  filters: {
    dateFilter(val) {
      if (!val)
        return ""
      return moment(val).format('jYYYY/jMM/jDD')
    },
    dateFilterWithTime(val) {
      if (!val)
        return ""
      return moment(val).format('jYYYY/jMM/jDD HH:mm')
    }
  },
  methods: {
    getSenderId(message) {
      if (message.sender) {
        return message.sender.id
      }
      return null
    },
    loadConversationMessage(id, page) {
      EventBus.$emit('setLoading', true)
      if (page == 1) {
        this.messages = []
      }
      this.conversation_id = id
      this.$store.dispatch('getUserTelegramConversationsMessages', {
        userId: this.$route.params.clientId,
        conversationId: id,
        page
      }).then((resp) => {
        this.page = resp.data.conversation.pagination.current_page
        this.total = resp.data.conversation.pagination.total_pages
        this.scrollDown(page == 1 ? null : 600)
        if (page == 1) {
          this.bindListener()
        }
        this.messages.unshift(...resp.data.conversation.messages.reverse())
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    scrollDown(val = null) {
      setTimeout(() => {
        let messagesContext = document.getElementById("messagesContext");
        messagesContext.scrollTop = val ? val : messagesContext.scrollHeight;
      }, 1000)
    },
    bindListener() {
      let messagesContext = document.getElementById("messagesContext");

      messagesContext.onscroll = null;

      messagesContext.onscroll = function () {
        if (messagesContext.scrollTop === 0) {
          if (this.page != this.total) {
            this.loadConversationMessage(this.conversation_id, (this.page + 1));
          }
        }
      }.bind(this);
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 800px) {
  .messageItem {
    width: 100% !important;
  }
}

.conversationList {
  max-height: 600px;
  overflow-y: auto;
  padding: 10px;
}

.messagesArea {
  height: 600px;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.conversationItem {
  border: 1px solid transparent;
  padding: 10px;
  transition: all 0.3s;
  width: 100%;
}

.conversationItem:hover {
  background: #dddddd;
  transition: all 0.3s;
  cursor: pointer;
}

.activeConversation {
  background: #dddddd;
  transition: all 0.3s;
  cursor: pointer;
}

.messageItem {
  background: #dddddd;
  padding: 10px;
  margin-bottom: 10px;
  width: 500px;
  border-radius: 10px;
}

.editMessage {
  background: #dddddd;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.primary_version {
  border: 2px solid #43378C;
}

.editBadge {
  background: #0abb87;
  font-size: 8px;
  padding: 2px;
  font-weight: bolder;
  border-radius: 20px;
  margin-top: 10px;
  color: white;
  width: 50px;
  text-align: center;
  margin-right: 5px;
}
.unkownSender {
  background: #ff7735;
  font-size: 8px;
  padding: 2px;
  font-weight: bolder;
  border-radius: 20px;
  color: white;
  width: 80px;
  text-align: center;
  margin-right: 5px;
  margin-bottom: 10px;
}

.deleteBadge {
  background: red;
  font-size: 8px;
  padding: 2px;
  font-weight: bolder;
  border-radius: 20px;
  margin-top: 10px;
  color: white;
  width: 50px;
  text-align: center;
  margin-right: 5px;
}

.dateBadge {
  font-size: 10px;
  padding: 2px;
  border-radius: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
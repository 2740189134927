<template>
  <div>
    <div class="d-flex">
      <h2>
         دوره درمان
      </h2>
      <v-spacer></v-spacer>
      <template v-if="profile.cycles.length != 0">
        <v-btn class="primary" small elevation="0" @click="addCycle = true">
          افزودن دوره درمان
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </div>
    <br>
   <v-fade-transition>
     <template v-if="addCycle">
       <AddNewCycle
           :clientId="profile.id"
           :isAdmin="true"
           :addCycleAction="'adminAddClientNewCycle'"
           :dialog="true"
           :task-id="taskId"
           @close="callbackAddCycle()"
       ></AddNewCycle>
     </template>
   </v-fade-transition>
    <v-row>
      <v-col v-for="cycle in profile.cycles" :key="cycle.id" cols="4">
        <v-card>
          <v-card-title>
            موضوع دوره :
            {{cycle.heading}}
          </v-card-title>
          <v-card-subtitle>
            تعداد کل جلسات {{cycle.sessionCount}}
          </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                text
                color="primary"
                @click="showDetails(cycle)"
            >
              جزئیات
              <v-icon>{{ detailsToShow.includes(cycle.id) ? 'mdi-chevron-up' :
                  'mdi-chevron-down' }}
              </v-icon>
            </v-btn>
          </v-card-actions>

          <v-expand-transition>
            <div v-show="detailsToShow.includes(cycle.id)">
              <v-divider></v-divider>

              <v-card-text>
                {{cycle.problem_description}}
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
    <template v-if="profile.cycles.length == 0">
      <v-btn class="primary mt-2" small elevation="0"    @click="addCycle = true">
        افزودن دوره درمان
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <submit-task />
  </div>
</template>

<script>
/*eslint-disable*/
import AddNewCycle from "@/components/clients/AddNewCycle";
import {EventBus} from "@/event-bus";
import SubmitTask from "@/components/crm/submitTask";
export default {
  components: {SubmitTask, AddNewCycle},
  props:{
    profile:Object,
    taskId:Number,
  },
  data(){
    return{
      detailsToShow: [],
      addCycle:false,
    }
  },
  methods:{
    showDetails(cycle) {
      if (this.detailsToShow.includes(cycle.id)) {
        this.detailsToShow.splice(this.detailsToShow.indexOf(cycle.id), 1);
      } else {
        this.detailsToShow.push(cycle.id);
      }
    },
    callbackAddCycle(){
      this.addCycle = false
      EventBus.$emit('updateClientInfo')
    },
  }
}
</script>

<style scoped>

</style>
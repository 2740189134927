<template>
  <div>
    <div>
      <AdminDatePicker
          @close="fromDateDatePicker=false"
          :dialog="fromDateDatePicker"
          v-model="from_date"
      ></AdminDatePicker>
      <AdminDatePicker
          @close="toDateDatePicker=false"
          :dialog="toDateDatePicker"
          v-model="to_date"
      ></AdminDatePicker>
    </div>

      <div class="mt-4">
      <v-card :loading="loading">
        <v-card-title class="d-block">
          <div>
            <h3>آمار تست ها</h3>
          </div>

          <div class="d-flex">
            <div class="mx-1" @click="fromDateDatePicker=true" style="font-size: .85em;">
              <b>
                {{ from_date | dateFilter }}
              </b>
            </div>
            <div class="mx-3 d-flex justify-center flex-column">
              تا
            </div>
            <div class="" @click="toDateDatePicker=true" style="font-size: .85em;">
              <b>
                {{ to_date | dateFilter }}
              </b>
            </div>

          </div>
          <v-btn small depressed color="primary" class="mx-2" @click="getTestStatistics" :disabled="loading" :loading="loading">فیلتر</v-btn>

        </v-card-title>
        <v-card-text>
          <v-fade-transition mode="out-in">
            <v-data-table
                v-if="statistics"
                :headers="headers"
                :items="statistics"
                :items-per-page="5"
                class="elevation-0"
                disable-pagination
                hide-default-footer
                key="3"
            >
              <template v-slot:body.append>
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <div style="font-size: 1.2em">
                      <b>مجموع : {{ totalTests }}</b>
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <div v-else :key="4">
              <v-skeleton-loader
                  style="margin-top: 10px;"
                  max-height="150"
                  type="image"
              >
              </v-skeleton-loader>
              <v-skeleton-loader
                  style=""
                  max-height="150"
                  type="image"
              >
              </v-skeleton-loader>
            </div>
          </v-fade-transition>
        </v-card-text>
      </v-card>

    </div>
  </div>
</template>

<script>
import AdminDatePicker from "@/components/AdminDatePicker";
import moment from "moment-jalaali";

export default {
  components: {AdminDatePicker},
  methods: {
    getTestStatistics() {
      this.loading=true
      this.$store.dispatch('getTestStatistics', {
        from_date: this.from_date,
        to_date: this.to_date,
      }).then((resp) => {
        this.statistics = resp.data[0]
      }).finally(() => {
        this.loading=false;
      })
    }
  },
  created() {
    this.from_date = moment(new Date()).format('YYYY-MM-DD')
    this.to_date = moment(new Date()).format('YYYY-MM-DD')
    // this.getTestStatistics()
  },
  mounted() {
    // this.getTestStatistics()
  },
  computed:{
    totalTests(){
      let total = 0;
      this.statistics.forEach(item=> total+=item.test_count)
      return total.toLocaleString();
    }
  },
  data() {
    return {
      loading:false,
      fromDateDatePicker: false,
      toDateDatePicker: false,
      statistics: [],
      from_date: null,
      to_date: null,
      headers: [
        {
          text: 'کد',
          align: 'start',
          sortable: true,
          value: 'test_id',
        },
        {
          text: 'نام تست',
          align: 'start',
          sortable: false,
          value: 'test.title',
        },
        {
          text: 'تعداد تست‌های انجام‌شده',
          align: 'start',
          sortable: true,
          value: 'test_count',
        },
      ]
    }
  },
  watch: {
    // from_date: 'getTestStatistics',
    // to_date: 'getTestStatistics'
  },
  filters: {
    dateFilter(val) {
      if (val) {
        return moment(val).format('YYYY/MM/DD') + " - " + moment(val).format('jYYYY/jMM/jDD')
      }
    },
  },
}
</script>

<style scoped>
.statisticsBox {
  /*background-color:rgba(0,50,50,1);width: 100%;*/
  background: linear-gradient(45deg, rgba(0, 100, 100, 1), rgba(0, 50, 50, 1));;
  height: 150px;
  display: flex;
  border-radius: 7px;
  box-shadow: 0 10px 20px -15px rgba(0, 0, 0, .1),
  inset 0 -10px 25px rgba(0, 0, 0, .05);
  font-weight: bolder;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  /*padding: 10px;*/
  /*box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;*/
  /*border: 1px solid #ededed;*/
  /*border-radius: 10px;*/
}
</style>
<template>
    <div>
        <calendar v-if="$vuetify.breakpoint.lgAndUp"
                  :profile="profile"
                  removeTimeAction="adminClientRemoveTimes"
                  addTimeAction="adminClientsaveTimebooks"
                  type="admin-client"
                  @getProfile="updateProfile"
        ></calendar>
        <v-container v-else>
            <DateTimeComponent
                    @getProfile="updateProfile"
                    @done="redirect"
                    :profile="profile"
                    removeTimeAction="adminClientRemoveTimes"
                    addTimeAction="adminClientsaveTimebooks"
            ></DateTimeComponent>
        </v-container>


    </div>
</template>

<script>
    import calendar from "@/components/NewCalendar";
    import DateTimeComponent from "@/components/DateTimeComponent";
    import {EventBus} from "../../../event-bus";

    export default {
        components: {
            calendar,
            DateTimeComponent
        },
        data() {
            return {
                profile: this.$store.getters.getClientProfile
            }
        },
        methods: {
            updateProfile() {
                this.$store.dispatch('getUserProfile', {
                    userid: this.$router.currentRoute.params.clientId
                }).then(() => {
                    this.profile = this.$store.getters.getClientProfile;
                    EventBus.$emit('clientProfileUpdated');
                })
            }
        }
    }
</script>

<style scoped>

</style>
<template>
  <div>
    <v-row>
      <v-col cols="6" lg="4">
        <b>{{ taskDetail.activity_list.title_fa }}</b>
      </v-col>
      <v-col cols="6" lg="4">
        تاریخ ایجاد:
        <span dir="">
          <b>{{ taskDetail.created_at | toJalali }}</b>
        </span>
      </v-col>
      <v-col cols="6" lg="4">
        کاربر :
        <b>{{taskDetail.user.name}}</b>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import momentJalali from "moment-jalaali";

export default {
  props:{
    taskDetail:Object,
  },
  filters:{
    toJalali(input) {
      return momentJalali(input, 'YYYY-MM-DD').format('jYYYY/jMM/jDD')
    },
  }
}
</script>

<style scoped>

</style>
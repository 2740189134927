<template>
  <div>
    <v-row justify="center" align="center">
      <!--<DashboardCard title="تعداد جلسات" :info="dashboard.allSessionsCount"-->
      <!--:img="require('../../assets/dashboard/session.svg')"></DashboardCard>-->
      <!--<DashboardCard title="مراجعان" :info="dashboard.allPatientsCount"-->
      <!--:img="require('../../assets/dashboard/userCount.svg')"></DashboardCard>-->

      <template v-if="dashboard.combinedSales.length==0">
        <v-col
            v-for="i in 8"
            :key="i"
            cols="6"
            sm="4"
            md="3"
            lg="2"
            class="pa-1"
        >
          <v-skeleton-loader
              class="mx-auto"
              max-height="70"
              type="card"
          ></v-skeleton-loader>

        </v-col>
      </template>
      <template v-else>
        <v-col
            v-for="(item, currency) in dashboard.combinedSales"
            :key="currency"
            cols="6"
            sm="4"
            md="3"
            lg="2"
            class="pa-1"
        >

          <v-card
              class="px-0 py-0 elevation-1 gradient-monetary"
              dark
          >
            <v-list-item>
              <v-list-item-icon>
                                <span style="text-transform: uppercase;" class="caption font-weight-bold">
                                    <!--<v-icon>mdi-currency-{{currency | resolveIcon}}</v-icon>-->
                                    <span>{{ currency|resolveCurrency }}</span>
                                </span>
              </v-list-item-icon>
              <v-list-item-content>

                <v-row class="pa-0 ma-0 caption font-weight-bold">
                  <v-col cols="12" class="pa-0 ma-0">
                    جمع:{{ Number(Number(item.total).toFixed(2)).toLocaleString() }}
                  </v-col>
                  <v-col class="pa-0 ma-0">
                    امروز:{{ Number(Number(item.today).toFixed(2)).toLocaleString() }}
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col
            cols="6"
            sm="4"
            md="3"
            lg="2"
            class="pa-1"
        >
          <v-card
              class="px-0 py-2 elevation-1 gradient"
              dark
          >
            <v-list-item>
              <v-list-item-icon>
                                <span style="text-transform: uppercase;" class="caption font-weight-bold">
                                    <span>تعداد جلسات</span>
                                </span>
              </v-list-item-icon>
              <v-list-item-content>

                <v-row class="pa-0 ma-0 subtitle-2">
                  <v-col class="pa-0 ma-0">
                    {{ dashboard.allSessionsCount }}
                  </v-col>
                </v-row>
              </v-list-item-content>

            </v-list-item>
          </v-card>
        </v-col>
        <v-col
            cols="6"
            sm="4"
            md="3"
            lg="2"
            class="pa-1"
        >
          <v-card
              class="px-0 py-2 elevation-1 gradient"
              dark
          >
            <v-list-item>
              <v-list-item-icon>
                                <span style="text-transform: uppercase;" class="caption font-weight-bold">
                                    <!--<v-icon>mdi-currency-{{currency | resolveIcon}}</v-icon>-->
                                    <span>تعداد کاربران</span>
                                </span>

              </v-list-item-icon>
              <v-list-item-content>

                <v-row class="pa-0 ma-0 subtitle-2">
                  <v-col class="pa-0 ma-0">
                    {{ dashboard.allPatientsCount }}
                  </v-col>

                </v-row>
              </v-list-item-content>

            </v-list-item>
          </v-card>
        </v-col>
      </template>

    </v-row>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";
import {resolveCurrency} from "@/utilities";

export default {
  data() {
    return {
      dashboard: {
        usersCount: '',
        totalSession: '',
        allDoctorsCount: '',
        todaySales: [],
        totalSales: [],
        combinedSales: [],
        // doctors:null
      },
    }
  },
  created() {
    this.getMyDoctor()
    EventBus.$emit('setLoading', true);
    this.$store.dispatch('getAdminDashboard').then((resp) => {

      this.$store.commit('setAdminDashboard', resp.data)
      this.dashboard = resp.data;
      this.dashboard.todaySales = resp.data.today_sales;
      this.dashboard.combinedSales = resp.data.combined;

    }).finally(() => {
      EventBus.$emit('setLoading', false);
    })
  },
  filters:{
    resolveCurrency(input) {
      return resolveCurrency(input);
      // let result = '';
      // switch (currency) {
      //     case 'rial':
      //         result = 'تومان';
      //         break;
      //     case 'dollar':
      //         result = 'دلار';
      //         break;
      //     case 'euro':
      //         result = 'یورو';
      //         break;
      //     case 'aud':
      //         result = 'دلار استرالیا';
      //         break;
      //     case 'cad':
      //         result = 'دلار کانادا';
      //         break;
      //     case 'gbp':
      //         result = 'پوند';
      //         break;
      // }
      // return result;
    }

  },
  methods:{
    getMyDoctor() {
      this.$store.dispatch('getAccountManagersDashboard').then((resp) => {
        this.doctors = resp.data.doctors
        console.log(this.doctors)
      })
    }
  }
}
</script>

<style scoped>

.gradient-monetary {
  /*background: #00416A;  !* fallback for old browsers *!*/
  /*background: -webkit-linear-gradient(to bottom, #00416A, #00126A);  !* Chrome 10-25, Safari 5.1-6 *!*/
  background: linear-gradient(45deg, #0081aa, #00619A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.gradient {
  /*background: #00416A;  !* fallback for old browsers *!*/
  /*background: -webkit-linear-gradient(to bottom, #00416A, #00126A);  !* Chrome 10-25, Safari 5.1-6 *!*/
  background: linear-gradient(35deg, #00ccca, #00619A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

</style>
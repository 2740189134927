<template>
    <v-col sm="6" md="3">
        <v-card class="pa-1 pa-md-2" dark color="accent" min-height="180">
            <v-card-text>
                <v-row>
                    <v-col class="d-flex flex-column px-2 py-0">
                        <div>{{ title }}</div>

                    </v-col>
                    <v-col class="py-0 img">
                        <img :src="img">
                    </v-col>
                </v-row>
                <v-row justify="center" >

                    <template v-if="typeof info == 'object'">
                        <v-col v-for="(item,index) in info" class="pa-0 ma-0 flex text-center" :key="index">
                            <span class="font-weight-bold white--text ">
                             <span>{{item.value}} {{item.title}}</span>
                            </span>
                        </v-col>
                    </template>


                    <template v-else>
                        <v-col class="pa-0 ma-0 text-center">
                            <span class="white--text font-weight-bold title text-center">
                            {{info}}
                            </span>
                        </v-col>
                        <!--<div class="font-weight-bold headline white&#45;&#45;text">{{ info }}</div>-->
                    </template>
                </v-row>
            </v-card-text>
        </v-card>
    </v-col>
</template>

<script>
    export default {
        props: ['title', 'info', 'img']
    }
</script>

<style>
    @media screen and (max-width: 360px) {
        .img {
            display: none;
        }
    }
</style>
<template>
  <div>
    <v-card class="my-8">
      <v-card-title>
        لیست پرداخت‌های شاپیفای
        <v-spacer></v-spacer>
        <TableOptions :hasExport="true" :searchFilters="searchFilters"
                      @applyFilters="applyFilters"></TableOptions>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
            hide-default-footer
            :headers="headers"
            :items="webHooks"
            :items-per-page="-1"
        >
          <template v-slot:item.name="{item}">
            {{ item.first_name }} {{ item.last_name }}
          </template>
          <template v-slot:item.items="{item}">
            <div v-for="(section , i) in item.items" :key="i">
              <v-chip small class="mt-2 mb-1">
                <v-icon small class="ml-2">mdi-package</v-icon>
                {{ section }}
              </v-chip>
            </div>
          </template>
          <template v-slot:item.updated_at="{item}">
            {{ item.updated_at | dateFilter }}
          </template>
          <template v-slot:item.reviewed_at="{item}">
            {{ item.reviewed_at | dateFilter }}
          </template>
          <template v-slot:item.platform="{item}">
            <template v-if="item.platform == '3'">
              سیمیاروم
            </template>
            <template v-else-if="item.platform == '4'">
              مشاوره لایو
            </template>
          </template>
          <template v-slot:item.action="{item}">
            <v-btn color="primary" fab x-small elevation="0" @click="reviewShopifyWebhook(item.id)">
              <v-icon>
                mdi-check
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-pagination v-model="page" :length="total_page"></v-pagination>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import momentJalali from "moment-jalaali";
import {EventBus} from "@/event-bus";
import TableOptions from "@/components/TableOptions";
import {shopify_webhook} from "@/searchFilters";
/*eslint-disable*/
export default {
  components: {TableOptions},
  data() {
    return {
      searchFilters: shopify_webhook,
      page: 1,
      total_page: null,
      webHooks: [],
      headers: [
        {text: 'کد', value: 'id'},
        {text: 'نام و نام خانوادگی', value: 'full_name'},
        {text: 'ایمیل', value: 'email'},
        {text: 'ارز', value: 'currency'},
        {text: 'پلتفرم', value: 'platform'},
        {text: 'مبلغ پرداختی', value: 'amount'},
        {text: 'وضعیت', value: 'status'},
        {text: 'محصولات', value: 'items'},
        {text: 'آخرین ویرایش', value: 'updated_at'},
        {text: 'تاریخ بازبینی ', value: 'reviewed_at'},
        {text: '', value: 'action'},
      ],
      options: null,
    }
  },
  methods: {
    reviewShopifyWebhook(id) {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('reviewShopifyWebhook', {transaction_id: id}).then(() => {
        this.getWebHooks()
        EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    applyFilters(options) {
      this.options = options;
      this.page = 1
      this.getWebHooks(this.options)
    },
    getWebHooks(filter) {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('getShopifyWebhook', {page: this.page, filter}).then((resp) => {
        this.webHooks = resp.data.webhooks.data
        console.log(this.webHooks,'1212112')
        this.total_page = resp.data.webhooks.pagination.total_pages
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    }
  },
  mounted() {

  },
  filters: {
    dateFilter(date) {
      if (!date){
        return  null
      }
      return momentJalali(date).format('jYYYY/jMM/jDD - YYYY/MM/DD')
    }
  },
  watch: {
    page() {
      this.getWebHooks(this.options)
    }
  }

}
</script>

<style scoped>

</style>
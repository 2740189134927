export default [
    {"text":"Africa/Abidjan","value":"Africa/Abidjan"},
    {"text":"Africa/Accra","value":"Africa/Accra"},
    {"text":"Africa/Addis_Ababa","value":"Africa/Addis_Ababa"},
    {"text":"Africa/Algiers","value":"Africa/Algiers"},
    {"text":"Africa/Asmara","value":"Africa/Asmara"},
    {"text":"Africa/Bamako","value":"Africa/Bamako"},
    {"text":"Africa/Bangui","value":"Africa/Bangui"},
    {"text":"Africa/Banjul","value":"Africa/Banjul"},
    {"text":"Africa/Bissau","value":"Africa/Bissau"},
    {"text":"Africa/Blantyre","value":"Africa/Blantyre"},
    {"text":"Africa/Brazzaville","value":"Africa/Brazzaville"},
    {"text":"Africa/Bujumbura","value":"Africa/Bujumbura"},
    {"text":"Africa/Cairo","value":"Africa/Cairo"},
    {"text":"Africa/Casablanca","value":"Africa/Casablanca"},
    {"text":"Africa/Ceuta","value":"Africa/Ceuta"},
    {"text":"Africa/Conakry","value":"Africa/Conakry"},
    {"text":"Africa/Dakar","value":"Africa/Dakar"},
    {"text":"Africa/Dar_es_Salaam","value":"Africa/Dar_es_Salaam"},
    {"text":"Africa/Djibouti","value":"Africa/Djibouti"},
    {"text":"Africa/Douala","value":"Africa/Douala"},
    {"text":"Africa/El_Aaiun","value":"Africa/El_Aaiun"},
    {"text":"Africa/Freetown","value":"Africa/Freetown"},
    {"text":"Africa/Gaborone","value":"Africa/Gaborone"},
    {"text":"Africa/Harare","value":"Africa/Harare"},
    {"text":"Africa/Johannesburg","value":"Africa/Johannesburg"},
    {"text":"Africa/Juba","value":"Africa/Juba"},
    {"text":"Africa/Kampala","value":"Africa/Kampala"},
    {"text":"Africa/Khartoum","value":"Africa/Khartoum"},
    {"text":"Africa/Kigali","value":"Africa/Kigali"},
    {"text":"Africa/Kinshasa","value":"Africa/Kinshasa"},
    {"text":"Africa/Lagos","value":"Africa/Lagos"},
    {"text":"Africa/Libreville","value":"Africa/Libreville"},
    {"text":"Africa/Lome","value":"Africa/Lome"},
    {"text":"Africa/Luanda","value":"Africa/Luanda"},
    {"text":"Africa/Lubumbashi","value":"Africa/Lubumbashi"},
    {"text":"Africa/Lusaka","value":"Africa/Lusaka"},
    {"text":"Africa/Malabo","value":"Africa/Malabo"},
    {"text":"Africa/Maputo","value":"Africa/Maputo"},
    {"text":"Africa/Maseru","value":"Africa/Maseru"},
    {"text":"Africa/Mbabane","value":"Africa/Mbabane"},
    {"text":"Africa/Mogadishu","value":"Africa/Mogadishu"},
    {"text":"Africa/Monrovia","value":"Africa/Monrovia"},
    {"text":"Africa/Nairobi","value":"Africa/Nairobi"},
    {"text":"Africa/Ndjamena","value":"Africa/Ndjamena"},
    {"text":"Africa/Niamey","value":"Africa/Niamey"},
    {"text":"Africa/Nouakchott","value":"Africa/Nouakchott"},
    {"text":"Africa/Ouagadougou","value":"Africa/Ouagadougou"},
    {"text":"Africa/Porto-Novo","value":"Africa/Porto-Novo"},
    {"text":"Africa/Sao_Tome","value":"Africa/Sao_Tome"},
    {"text":"Africa/Tripoli","value":"Africa/Tripoli"},
    {"text":"Africa/Tunis","value":"Africa/Tunis"},
    {"text":"Africa/Windhoek","value":"Africa/Windhoek"},
    {"text":"America/Adak","value":"America/Adak"},
    {"text":"America/Anchorage","value":"America/Anchorage"},
    {"text":"America/Anguilla","value":"America/Anguilla"},
    {"text":"America/Antigua","value":"America/Antigua"},
    {"text":"America/Araguaina","value":"America/Araguaina"},
    {"text":"America/Argentina/Buenos_Aires","value":"America/Argentina/Buenos_Aires"},
    {"text":"America/Argentina/Catamarca","value":"America/Argentina/Catamarca"},
    {"text":"America/Argentina/Cordoba","value":"America/Argentina/Cordoba"},
    {"text":"America/Argentina/Jujuy","value":"America/Argentina/Jujuy"},
    {"text":"America/Argentina/La_Rioja","value":"America/Argentina/La_Rioja"},
    {"text":"America/Argentina/Mendoza","value":"America/Argentina/Mendoza"},
    {"text":"America/Argentina/Rio_Gallegos","value":"America/Argentina/Rio_Gallegos"},
    {"text":"America/Argentina/Salta","value":"America/Argentina/Salta"},
    {"text":"America/Argentina/San_Juan","value":"America/Argentina/San_Juan"},
    {"text":"America/Argentina/San_Luis","value":"America/Argentina/San_Luis"},
    {"text":"America/Argentina/Tucuman","value":"America/Argentina/Tucuman"},
    {"text":"America/Argentina/Ushuaia","value":"America/Argentina/Ushuaia"},
    {"text":"America/Aruba","value":"America/Aruba"},
    {"text":"America/Asuncion","value":"America/Asuncion"},
    {"text":"America/Atikokan","value":"America/Atikokan"},
    {"text":"America/Bahia","value":"America/Bahia"},
    {"text":"America/Bahia_Banderas","value":"America/Bahia_Banderas"},
    {"text":"America/Barbados","value":"America/Barbados"},
    {"text":"America/Belem","value":"America/Belem"},
    {"text":"America/Belize","value":"America/Belize"},
    {"text":"America/Blanc-Sablon","value":"America/Blanc-Sablon"},
    {"text":"America/Boa_Vista","value":"America/Boa_Vista"},
    {"text":"America/Bogota","value":"America/Bogota"},
    {"text":"America/Boise","value":"America/Boise"},
    {"text":"America/Cambridge_Bay","value":"America/Cambridge_Bay"},
    {"text":"America/Campo_Grande","value":"America/Campo_Grande"},
    {"text":"America/Cancun","value":"America/Cancun"},
    {"text":"America/Caracas","value":"America/Caracas"},
    {"text":"America/Cayenne","value":"America/Cayenne"},
    {"text":"America/Cayman","value":"America/Cayman"},
    {"text":"America/Chicago","value":"America/Chicago"},
    {"text":"America/Chihuahua","value":"America/Chihuahua"},
    {"text":"America/Costa_Rica","value":"America/Costa_Rica"},
    {"text":"America/Creston","value":"America/Creston"},
    {"text":"America/Cuiaba","value":"America/Cuiaba"},
    {"text":"America/Curacao","value":"America/Curacao"},
    {"text":"America/Danmarkshavn","value":"America/Danmarkshavn"},
    {"text":"America/Dawson","value":"America/Dawson"},
    {"text":"America/Dawson_Creek","value":"America/Dawson_Creek"},
    {"text":"America/Denver","value":"America/Denver"},
    {"text":"America/Detroit","value":"America/Detroit"},
    {"text":"America/Dominica","value":"America/Dominica"},
    {"text":"America/Edmonton","value":"America/Edmonton"},
    {"text":"America/Eirunepe","value":"America/Eirunepe"},
    {"text":"America/El_Salvador","value":"America/El_Salvador"},
    {"text":"America/Fort_Nelson","value":"America/Fort_Nelson"},
    {"text":"America/Fortaleza","value":"America/Fortaleza"},
    {"text":"America/Glace_Bay","value":"America/Glace_Bay"},
    {"text":"America/Godthab","value":"America/Godthab"},
    {"text":"America/Goose_Bay","value":"America/Goose_Bay"},
    {"text":"America/Grand_Turk","value":"America/Grand_Turk"},
    {"text":"America/Grenada","value":"America/Grenada"},
    {"text":"America/Guadeloupe","value":"America/Guadeloupe"},
    {"text":"America/Guatemala","value":"America/Guatemala"},
    {"text":"America/Guayaquil","value":"America/Guayaquil"},
    {"text":"America/Guyana","value":"America/Guyana"},
    {"text":"America/Halifax","value":"America/Halifax"},
    {"text":"America/Havana","value":"America/Havana"},
    {"text":"America/Hermosillo","value":"America/Hermosillo"},
    {"text":"America/Indiana/Indianapolis","value":"America/Indiana/Indianapolis"},
    {"text":"America/Indiana/Knox","value":"America/Indiana/Knox"},
    {"text":"America/Indiana/Marengo","value":"America/Indiana/Marengo"},
    {"text":"America/Indiana/Petersburg","value":"America/Indiana/Petersburg"},
    {"text":"America/Indiana/Tell_City","value":"America/Indiana/Tell_City"},
    {"text":"America/Indiana/Vevay","value":"America/Indiana/Vevay"},
    {"text":"America/Indiana/Vincennes","value":"America/Indiana/Vincennes"},
    {"text":"America/Indiana/Winamac","value":"America/Indiana/Winamac"},
    {"text":"America/Inuvik","value":"America/Inuvik"},
    {"text":"America/Iqaluit","value":"America/Iqaluit"},
    {"text":"America/Jamaica","value":"America/Jamaica"},
    {"text":"America/Juneau","value":"America/Juneau"},
    {"text":"America/Kentucky/Louisville","value":"America/Kentucky/Louisville"},
    {"text":"America/Kentucky/Monticello","value":"America/Kentucky/Monticello"},
    {"text":"America/Kralendijk","value":"America/Kralendijk"},
    {"text":"America/La_Paz","value":"America/La_Paz"},
    {"text":"America/Lima","value":"America/Lima"},
    {"text":"America/Los_Angeles","value":"America/Los_Angeles"},
    {"text":"America/Lower_Princes","value":"America/Lower_Princes"},
    {"text":"America/Maceio","value":"America/Maceio"},
    {"text":"America/Managua","value":"America/Managua"},
    {"text":"America/Manaus","value":"America/Manaus"},
    {"text":"America/Marigot","value":"America/Marigot"},
    {"text":"America/Martinique","value":"America/Martinique"},
    {"text":"America/Matamoros","value":"America/Matamoros"},
    {"text":"America/Mazatlan","value":"America/Mazatlan"},
    {"text":"America/Menominee","value":"America/Menominee"},
    {"text":"America/Merida","value":"America/Merida"},
    {"text":"America/Metlakatla","value":"America/Metlakatla"},
    {"text":"America/Mexico_City","value":"America/Mexico_City"},
    {"text":"America/Miquelon","value":"America/Miquelon"},
    {"text":"America/Moncton","value":"America/Moncton"},
    {"text":"America/Monterrey","value":"America/Monterrey"},
    {"text":"America/Montevideo","value":"America/Montevideo"},
    {"text":"America/Montserrat","value":"America/Montserrat"},
    {"text":"America/Nassau","value":"America/Nassau"},
    {"text":"America/New_York","value":"America/New_York"},
    {"text":"America/Nipigon","value":"America/Nipigon"},
    {"text":"America/Nome","value":"America/Nome"},
    {"text":"America/Noronha","value":"America/Noronha"},
    {"text":"America/North_Dakota/Beulah","value":"America/North_Dakota/Beulah"},
    {"text":"America/North_Dakota/Center","value":"America/North_Dakota/Center"},
    {"text":"America/North_Dakota/New_Salem","value":"America/North_Dakota/New_Salem"},
    {"text":"America/Ojinaga","value":"America/Ojinaga"},
    {"text":"America/Panama","value":"America/Panama"},
    {"text":"America/Pangnirtung","value":"America/Pangnirtung"},
    {"text":"America/Paramaribo","value":"America/Paramaribo"},
    {"text":"America/Phoenix","value":"America/Phoenix"},
    {"text":"America/Port-au-Prince","value":"America/Port-au-Prince"},
    {"text":"America/Port_of_Spain","value":"America/Port_of_Spain"},
    {"text":"America/Porto_Velho","value":"America/Porto_Velho"},
    {"text":"America/Puerto_Rico","value":"America/Puerto_Rico"},
    {"text":"America/Punta_Arenas","value":"America/Punta_Arenas"},
    {"text":"America/Rainy_River","value":"America/Rainy_River"},
    {"text":"America/Rankin_Inlet","value":"America/Rankin_Inlet"},
    {"text":"America/Recife","value":"America/Recife"},
    {"text":"America/Regina","value":"America/Regina"},
    {"text":"America/Resolute","value":"America/Resolute"},
    {"text":"America/Rio_Branco","value":"America/Rio_Branco"},
    {"text":"America/Santarem","value":"America/Santarem"},
    {"text":"America/Santiago","value":"America/Santiago"},
    {"text":"America/Santo_Domingo","value":"America/Santo_Domingo"},
    {"text":"America/Sao_Paulo","value":"America/Sao_Paulo"},
    {"text":"America/Scoresbysund","value":"America/Scoresbysund"},
    {"text":"America/Sitka","value":"America/Sitka"},
    {"text":"America/St_Barthelemy","value":"America/St_Barthelemy"},
    {"text":"America/St_Johns","value":"America/St_Johns"},
    {"text":"America/St_Kitts","value":"America/St_Kitts"},
    {"text":"America/St_Lucia","value":"America/St_Lucia"},
    {"text":"America/St_Thomas","value":"America/St_Thomas"},
    {"text":"America/St_Vincent","value":"America/St_Vincent"},
    {"text":"America/Swift_Current","value":"America/Swift_Current"},
    {"text":"America/Tegucigalpa","value":"America/Tegucigalpa"},
    {"text":"America/Thule","value":"America/Thule"},
    {"text":"America/Thunder_Bay","value":"America/Thunder_Bay"},
    {"text":"America/Tijuana","value":"America/Tijuana"},
    {"text":"America/Toronto","value":"America/Toronto"},
    {"text":"America/Tortola","value":"America/Tortola"},
    {"text":"America/Vancouver","value":"America/Vancouver"},
    {"text":"America/Whitehorse","value":"America/Whitehorse"},
    {"text":"America/Winnipeg","value":"America/Winnipeg"},
    {"text":"America/Yakutat","value":"America/Yakutat"},
    {"text":"America/Yellowknife","value":"America/Yellowknife"},
    {"text":"Antarctica/Casey","value":"Antarctica/Casey"},
    {"text":"Antarctica/Davis","value":"Antarctica/Davis"},
    {"text":"Antarctica/DumontDUrville","value":"Antarctica/DumontDUrville"},
    {"text":"Antarctica/Macquarie","value":"Antarctica/Macquarie"},
    {"text":"Antarctica/Mawson","value":"Antarctica/Mawson"},
    {"text":"Antarctica/McMurdo","value":"Antarctica/McMurdo"},
    {"text":"Antarctica/Palmer","value":"Antarctica/Palmer"},
    {"text":"Antarctica/Rothera","value":"Antarctica/Rothera"},
    {"text":"Antarctica/Syowa","value":"Antarctica/Syowa"},
    {"text":"Antarctica/Troll","value":"Antarctica/Troll"},
    {"text":"Antarctica/Vostok","value":"Antarctica/Vostok"},
    {"text":"Arctic/Longyearbyen","value":"Arctic/Longyearbyen"},
    {"text":"Asia/Aden","value":"Asia/Aden"},
    {"text":"Asia/Almaty","value":"Asia/Almaty"},
    {"text":"Asia/Amman","value":"Asia/Amman"},
    {"text":"Asia/Anadyr","value":"Asia/Anadyr"},
    {"text":"Asia/Aqtau","value":"Asia/Aqtau"},
    {"text":"Asia/Aqtobe","value":"Asia/Aqtobe"},
    {"text":"Asia/Ashgabat","value":"Asia/Ashgabat"},
    {"text":"Asia/Atyrau","value":"Asia/Atyrau"},
    {"text":"Asia/Baghdad","value":"Asia/Baghdad"},
    {"text":"Asia/Bahrain","value":"Asia/Bahrain"},
    {"text":"Asia/Baku","value":"Asia/Baku"},
    {"text":"Asia/Bangkok","value":"Asia/Bangkok"},
    {"text":"Asia/Barnaul","value":"Asia/Barnaul"},
    {"text":"Asia/Beirut","value":"Asia/Beirut"},
    {"text":"Asia/Bishkek","value":"Asia/Bishkek"},
    {"text":"Asia/Brunei","value":"Asia/Brunei"},
    {"text":"Asia/Chita","value":"Asia/Chita"},
    {"text":"Asia/Choibalsan","value":"Asia/Choibalsan"},
    {"text":"Asia/Colombo","value":"Asia/Colombo"},
    {"text":"Asia/Damascus","value":"Asia/Damascus"},
    {"text":"Asia/Dhaka","value":"Asia/Dhaka"},
    {"text":"Asia/Dili","value":"Asia/Dili"},
    {"text":"Asia/Dubai","value":"Asia/Dubai"},
    {"text":"Asia/Dushanbe","value":"Asia/Dushanbe"},
    {"text":"Asia/Famagusta","value":"Asia/Famagusta"},
    {"text":"Asia/Gaza","value":"Asia/Gaza"},
    {"text":"Asia/Hebron","value":"Asia/Hebron"},
    {"text":"Asia/Ho_Chi_Minh","value":"Asia/Ho_Chi_Minh"},
    {"text":"Asia/Hong_Kong","value":"Asia/Hong_Kong"},
    {"text":"Asia/Hovd","value":"Asia/Hovd"},
    {"text":"Asia/Irkutsk","value":"Asia/Irkutsk"},
    {"text":"Asia/Jakarta","value":"Asia/Jakarta"},
    {"text":"Asia/Jayapura","value":"Asia/Jayapura"},
    {"text":"Asia/Jerusalem","value":"Asia/Jerusalem"},
    {"text":"Asia/Kabul","value":"Asia/Kabul"},
    {"text":"Asia/Kamchatka","value":"Asia/Kamchatka"},
    {"text":"Asia/Karachi","value":"Asia/Karachi"},
    {"text":"Asia/Kathmandu","value":"Asia/Kathmandu"},
    {"text":"Asia/Khandyga","value":"Asia/Khandyga"},
    {"text":"Asia/Kolkata","value":"Asia/Kolkata"},
    {"text":"Asia/Krasnoyarsk","value":"Asia/Krasnoyarsk"},
    {"text":"Asia/Kuala_Lumpur","value":"Asia/Kuala_Lumpur"},
    {"text":"Asia/Kuching","value":"Asia/Kuching"},
    {"text":"Asia/Kuwait","value":"Asia/Kuwait"},
    {"text":"Asia/Macau","value":"Asia/Macau"},
    {"text":"Asia/Magadan","value":"Asia/Magadan"},
    {"text":"Asia/Makassar","value":"Asia/Makassar"},
    {"text":"Asia/Manila","value":"Asia/Manila"},
    {"text":"Asia/Muscat","value":"Asia/Muscat"},
    {"text":"Asia/Nicosia","value":"Asia/Nicosia"},
    {"text":"Asia/Novokuznetsk","value":"Asia/Novokuznetsk"},
    {"text":"Asia/Novosibirsk","value":"Asia/Novosibirsk"},
    {"text":"Asia/Omsk","value":"Asia/Omsk"},
    {"text":"Asia/Oral","value":"Asia/Oral"},
    {"text":"Asia/Phnom_Penh","value":"Asia/Phnom_Penh"},
    {"text":"Asia/Pontianak","value":"Asia/Pontianak"},
    {"text":"Asia/Pyongyang","value":"Asia/Pyongyang"},
    {"text":"Asia/Qatar","value":"Asia/Qatar"},
    {"text":"Asia/Qyzylorda","value":"Asia/Qyzylorda"},
    {"text":"Asia/Riyadh","value":"Asia/Riyadh"},
    {"text":"Asia/Sakhalin","value":"Asia/Sakhalin"},
    {"text":"Asia/Samarkand","value":"Asia/Samarkand"},
    {"text":"Asia/Seoul","value":"Asia/Seoul"},
    {"text":"Asia/Shanghai","value":"Asia/Shanghai"},
    {"text":"Asia/Singapore","value":"Asia/Singapore"},
    {"text":"Asia/Srednekolymsk","value":"Asia/Srednekolymsk"},
    {"text":"Asia/Taipei","value":"Asia/Taipei"},
    {"text":"Asia/Tashkent","value":"Asia/Tashkent"},
    {"text":"Asia/Tbilisi","value":"Asia/Tbilisi"},
    {"text":"Asia/Tehran","value":"Asia/Tehran"},
    {"text":"Asia/Thimphu","value":"Asia/Thimphu"},
    {"text":"Asia/Tokyo","value":"Asia/Tokyo"},
    {"text":"Asia/Tomsk","value":"Asia/Tomsk"},
    {"text":"Asia/Ulaanbaatar","value":"Asia/Ulaanbaatar"},
    {"text":"Asia/Urumqi","value":"Asia/Urumqi"},
    {"text":"Asia/Ust-Nera","value":"Asia/Ust-Nera"},
    {"text":"Asia/Vientiane","value":"Asia/Vientiane"},
    {"text":"Asia/Vladivostok","value":"Asia/Vladivostok"},
    {"text":"Asia/Yakutsk","value":"Asia/Yakutsk"},
    {"text":"Asia/Yangon","value":"Asia/Yangon"},
    {"text":"Asia/Yekaterinburg","value":"Asia/Yekaterinburg"},
    {"text":"Asia/Yerevan","value":"Asia/Yerevan"},
    {"text":"Atlantic/Azores","value":"Atlantic/Azores"},
    {"text":"Atlantic/Bermuda","value":"Atlantic/Bermuda"},
    {"text":"Atlantic/Canary","value":"Atlantic/Canary"},
    {"text":"Atlantic/Cape_Verde","value":"Atlantic/Cape_Verde"},
    {"text":"Atlantic/Faroe","value":"Atlantic/Faroe"},
    {"text":"Atlantic/Madeira","value":"Atlantic/Madeira"},
    {"text":"Atlantic/Reykjavik","value":"Atlantic/Reykjavik"},
    {"text":"Atlantic/South_Georgia","value":"Atlantic/South_Georgia"},
    {"text":"Atlantic/St_Helena","value":"Atlantic/St_Helena"},
    {"text":"Atlantic/Stanley","value":"Atlantic/Stanley"},
    {"text":"Australia/Adelaide","value":"Australia/Adelaide"},
    {"text":"Australia/Brisbane","value":"Australia/Brisbane"},
    {"text":"Australia/Broken_Hill","value":"Australia/Broken_Hill"},
    {"text":"Australia/Currie","value":"Australia/Currie"},
    {"text":"Australia/Darwin","value":"Australia/Darwin"},
    {"text":"Australia/Eucla","value":"Australia/Eucla"},
    {"text":"Australia/Hobart","value":"Australia/Hobart"},
    {"text":"Australia/Lindeman","value":"Australia/Lindeman"},
    {"text":"Australia/Lord_Howe","value":"Australia/Lord_Howe"},
    {"text":"Australia/Melbourne","value":"Australia/Melbourne"},
    {"text":"Australia/Perth","value":"Australia/Perth"},
    {"text":"Australia/Sydney","value":"Australia/Sydney"},
    {"text":"Europe/Amsterdam","value":"Europe/Amsterdam"},
    {"text":"Europe/Andorra","value":"Europe/Andorra"},
    {"text":"Europe/Astrakhan","value":"Europe/Astrakhan"},
    {"text":"Europe/Athens","value":"Europe/Athens"},
    {"text":"Europe/Belgrade","value":"Europe/Belgrade"},
    {"text":"Europe/Berlin","value":"Europe/Berlin"},
    {"text":"Europe/Bratislava","value":"Europe/Bratislava"},
    {"text":"Europe/Brussels","value":"Europe/Brussels"},
    {"text":"Europe/Bucharest","value":"Europe/Bucharest"},
    {"text":"Europe/Budapest","value":"Europe/Budapest"},
    {"text":"Europe/Busingen","value":"Europe/Busingen"},
    {"text":"Europe/Chisinau","value":"Europe/Chisinau"},
    {"text":"Europe/Copenhagen","value":"Europe/Copenhagen"},
    {"text":"Europe/Dublin","value":"Europe/Dublin"},
    {"text":"Europe/Gibraltar","value":"Europe/Gibraltar"},
    {"text":"Europe/Guernsey","value":"Europe/Guernsey"},
    {"text":"Europe/Helsinki","value":"Europe/Helsinki"},
    {"text":"Europe/Isle_of_Man","value":"Europe/Isle_of_Man"},
    {"text":"Europe/Istanbul","value":"Europe/Istanbul"},
    {"text":"Europe/Jersey","value":"Europe/Jersey"},
    {"text":"Europe/Kaliningrad","value":"Europe/Kaliningrad"},
    {"text":"Europe/Kiev","value":"Europe/Kiev"},
    {"text":"Europe/Kirov","value":"Europe/Kirov"},
    {"text":"Europe/Lisbon","value":"Europe/Lisbon"},
    {"text":"Europe/Ljubljana","value":"Europe/Ljubljana"},
    {"text":"Europe/London","value":"Europe/London"},
    {"text":"Europe/Luxembourg","value":"Europe/Luxembourg"},
    {"text":"Europe/Madrid","value":"Europe/Madrid"},
    {"text":"Europe/Malta","value":"Europe/Malta"},
    {"text":"Europe/Mariehamn","value":"Europe/Mariehamn"},
    {"text":"Europe/Minsk","value":"Europe/Minsk"},
    {"text":"Europe/Monaco","value":"Europe/Monaco"},
    {"text":"Europe/Moscow","value":"Europe/Moscow"},
    {"text":"Europe/Oslo","value":"Europe/Oslo"},
    {"text":"Europe/Paris","value":"Europe/Paris"},
    {"text":"Europe/Podgorica","value":"Europe/Podgorica"},
    {"text":"Europe/Prague","value":"Europe/Prague"},
    {"text":"Europe/Riga","value":"Europe/Riga"},
    {"text":"Europe/Rome","value":"Europe/Rome"},
    {"text":"Europe/Samara","value":"Europe/Samara"},
    {"text":"Europe/San_Marino","value":"Europe/San_Marino"},
    {"text":"Europe/Sarajevo","value":"Europe/Sarajevo"},
    {"text":"Europe/Saratov","value":"Europe/Saratov"},
    {"text":"Europe/Simferopol","value":"Europe/Simferopol"},
    {"text":"Europe/Skopje","value":"Europe/Skopje"},
    {"text":"Europe/Sofia","value":"Europe/Sofia"},
    {"text":"Europe/Stockholm","value":"Europe/Stockholm"},
    {"text":"Europe/Tallinn","value":"Europe/Tallinn"},
    {"text":"Europe/Tirane","value":"Europe/Tirane"},
    {"text":"Europe/Ulyanovsk","value":"Europe/Ulyanovsk"},
    {"text":"Europe/Uzhgorod","value":"Europe/Uzhgorod"},
    {"text":"Europe/Vaduz","value":"Europe/Vaduz"},
    {"text":"Europe/Vatican","value":"Europe/Vatican"},
    {"text":"Europe/Vienna","value":"Europe/Vienna"},
    {"text":"Europe/Vilnius","value":"Europe/Vilnius"},
    {"text":"Europe/Volgograd","value":"Europe/Volgograd"},
    {"text":"Europe/Warsaw","value":"Europe/Warsaw"},
    {"text":"Europe/Zagreb","value":"Europe/Zagreb"},
    {"text":"Europe/Zaporozhye","value":"Europe/Zaporozhye"},
    {"text":"Europe/Zurich","value":"Europe/Zurich"},
    {"text":"Indian/Antananarivo","value":"Indian/Antananarivo"},
    {"text":"Indian/Chagos","value":"Indian/Chagos"},
    {"text":"Indian/Christmas","value":"Indian/Christmas"},
    {"text":"Indian/Cocos","value":"Indian/Cocos"},
    {"text":"Indian/Comoro","value":"Indian/Comoro"},
    {"text":"Indian/Kerguelen","value":"Indian/Kerguelen"},
    {"text":"Indian/Mahe","value":"Indian/Mahe"},
    {"text":"Indian/Maldives","value":"Indian/Maldives"},
    {"text":"Indian/Mauritius","value":"Indian/Mauritius"},
    {"text":"Indian/Mayotte","value":"Indian/Mayotte"},
    {"text":"Indian/Reunion","value":"Indian/Reunion"},
    {"text":"Pacific/Apia","value":"Pacific/Apia"},
    {"text":"Pacific/Auckland","value":"Pacific/Auckland"},
    {"text":"Pacific/Bougainville","value":"Pacific/Bougainville"},
    {"text":"Pacific/Chatham","value":"Pacific/Chatham"},
    {"text":"Pacific/Chuuk","value":"Pacific/Chuuk"},
    {"text":"Pacific/Easter","value":"Pacific/Easter"},
    {"text":"Pacific/Efate","value":"Pacific/Efate"},
    {"text":"Pacific/Enderbury","value":"Pacific/Enderbury"},
    {"text":"Pacific/Fakaofo","value":"Pacific/Fakaofo"},
    {"text":"Pacific/Fiji","value":"Pacific/Fiji"},
    {"text":"Pacific/Funafuti","value":"Pacific/Funafuti"},
    {"text":"Pacific/Galapagos","value":"Pacific/Galapagos"},
    {"text":"Pacific/Gambier","value":"Pacific/Gambier"},
    {"text":"Pacific/Guadalcanal","value":"Pacific/Guadalcanal"},
    {"text":"Pacific/Guam","value":"Pacific/Guam"},
    {"text":"Pacific/Honolulu","value":"Pacific/Honolulu"},
    {"text":"Pacific/Kiritimati","value":"Pacific/Kiritimati"},
    {"text":"Pacific/Kosrae","value":"Pacific/Kosrae"},
    {"text":"Pacific/Kwajalein","value":"Pacific/Kwajalein"},
    {"text":"Pacific/Majuro","value":"Pacific/Majuro"},
    {"text":"Pacific/Marquesas","value":"Pacific/Marquesas"},
    {"text":"Pacific/Midway","value":"Pacific/Midway"},
    {"text":"Pacific/Nauru","value":"Pacific/Nauru"},
    {"text":"Pacific/Niue","value":"Pacific/Niue"},
    {"text":"Pacific/Norfolk","value":"Pacific/Norfolk"},
    {"text":"Pacific/Noumea","value":"Pacific/Noumea"},
    {"text":"Pacific/Pago_Pago","value":"Pacific/Pago_Pago"},
    {"text":"Pacific/Palau","value":"Pacific/Palau"},
    {"text":"Pacific/Pitcairn","value":"Pacific/Pitcairn"},
    {"text":"Pacific/Pohnpei","value":"Pacific/Pohnpei"},
    {"text":"Pacific/Port_Moresby","value":"Pacific/Port_Moresby"},
    {"text":"Pacific/Rarotonga","value":"Pacific/Rarotonga"},
    {"text":"Pacific/Saipan","value":"Pacific/Saipan"},
    {"text":"Pacific/Tahiti","value":"Pacific/Tahiti"},
    {"text":"Pacific/Tarawa","value":"Pacific/Tarawa"},
    {"text":"Pacific/Tongatapu","value":"Pacific/Tongatapu"},
    {"text":"Pacific/Wake","value":"Pacific/Wake"},
    {"text":"Pacific/Wallis","value":"Pacific/Wallis"},
    {"text":"UTC","value":"UTC"}
];




// export default [
//     {"text": "GMT -12:00 - International Date Line West", "value": "EtcGMT +12"},
//     {"text": "GMT -11:00 - Midway Island, Samoa", "value": "Pacific/Midway"},
//     {"text": "GMT -10:00 - Hawaii", "value": "Pacific/Honolulu"},
//     {"text": "GMT -09:00 - Alaska", "value": "US/Alaska"},
//     {"text": "GMT -08:00 - Pacific Time (US & Canada)", "value": "America/Los_Angeles"},
//     {"text": "GMT -08:00 - Tijuana, Baja California", "value": "America/Tijuana"},
//     {"text": "GMT -07:00 - Arizona", "value": "US/Arizona"},
//     {"text": "GMT -07:00 - Chihuahua, La Paz, Mazatlan", "value": "America/Chihuahua"},
//     {"text": "GMT -07:00 - Mountain Time (US & Canada)", "value": "US/Mountain"},
//     {"text": "GMT -06:00 - Central America", "value": "America/Managua"},
//     {"text": "GMT -06:00 - Central Time (US & Canada)", "value": "US/Central"},
//     {"text": "GMT -06:00 - Guadalajara, Mexico City, Monterrey", "value": "America/Mexico_City"},
//     {"text": "GMT -06:00 - Saskatchewan", "value": "Canada/Saskatchewan"},
//     {"text": "GMT -05:00 - Bogota, Lima, Quito, Rio Branco", "value": "America/Bogota"},
//     {"text": "GMT -05:00 - Eastern Time (US & Canada)", "value": "US/Eastern"},
//     {"text": "GMT -05:00 - Indiana (East)", "value": "US/East-Indiana"},
//     {"text": "GMT -04:00 - Atlantic Time (Canada)", "value": "Canada/Atlantic"},
//     {"text": "GMT -04:00 - Caracas, La Paz", "value": "America/Caracas"},
//     {"text": "GMT -04:00 - Manaus", "value": "America/Manaus"},
//     {"text": "GMT -04:00 - Santiago", "value": "America/Santiago"},
//     {"text": "GMT -03:30 - Newfoundland", "value": "Canada/Newfoundland"},
//     {"text": "GMT -03:00 - Brasilia", "value": "America/Sao_Paulo"},
//     {"text": "GMT -03:00 - Buenos Aires, Georgetown", "value": "America/Argentina/Buenos_Aires"},
//     {"text": "GMT -03:00 - Greenland", "value": "America/Godthab"},
//     {"text": "GMT -03:00 - Montevideo", "value": "America/Montevideo"},
//     {"text": "GMT -02:00 - Mid-Atlantic", "value": "America/Noronha"},
//     {"text": "GMT -01:00 - Cape Verde Is.", "value": "Atlantic/Cape_Verde"},
//     {"text": "GMT -01:00 - Azores", "value": "Atlantic/Azores"},
//     {"text": "GMT +00:00 - Casablanca, Monrovia, Reykjavik", "value": "Africa/Casablanca"},
//     {"text": "GMT +00:00 - Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London", "value": "Etc/Greenwich"},
//     {"text": "GMT +01:00 - Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna", "value": "Europe/Amsterdam"},
//     {"text": "GMT +01:00 - Belgrade, Bratislava, Budapest, Ljubljana, Prague", "value": "Europe/Belgrade"},
//     {"text": "GMT +01:00 - Brussels, Copenhagen, Madrid, Paris", "value": "Europe/Brussels"},
//     {"text": "GMT +01:00 - Sarajevo, Skopje, Warsaw, Zagreb", "value": "Europe/Sarajevo"},
//     {"text": "GMT +01:00 - West Central Africa", "value": "Africa/Lagos"},
//     {"text": "GMT +02:00 - Amman", "value": "Asia/Amman"},
//     {"text": "GMT +02:00 - Athens, Bucharest, Istanbul", "value": "Europe/Athens"},
//     {"text": "GMT +02:00 - Beirut", "value": "Asia/Beirut"},
//     {"text": "GMT +02:00 - Cairo", "value": "Africa/Cairo"},
//     {"text": "GMT +02:00 - Harare, Pretoria", "value": "Africa/Harare"},
//     {"text": "GMT +02:00 - Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius", "value": "Europe/Helsinki"},
//     {"text": "GMT +02:00 - Jerusalem", "value": "Asia/Jerusalem"},
//     {"text": "GMT +02:00 - Minsk", "value": "Europe/Minsk"},
//     {"text": "GMT +02:00 - Windhoek", "value": "Africa/Windhoek"},
//     {"text": "GMT +03:00 - Kuwait, Riyadh, Baghdad", "value": "Asia/Kuwait"},
//     {"text": "GMT +03:00 - Moscow, St. Petersburg, Volgograd", "value": "Europe/Moscow"},
//     {"text": "GMT +03:00 - Nairobi", "value": "Africa/Nairobi"},
//     {"text": "GMT +03:00 - Tbilisi", "value": "Asia/Tbilisi"},
//     {"text": "GMT +03:30 - Tehran", "value": "Asia/Tehran"},
//     {"text": "GMT +04:00 - Abu Dhabi, Muscat", "value": "Asia/Muscat"},
//     {"text": "GMT +04:00 - Baku", "value": "Asia/Baku"},
//     {"text": "GMT +04:00 - Yerevan", "value": "Asia/Yerevan"},
//     {"text": "GMT +04:30 - Kabul", "value": "Asia/Kabul"},
//     {"text": "GMT +05:00 - Yekaterinburg", "value": "Asia/Yekaterinburg"},
//     {"text": "GMT +05:00 - Islamabad, Karachi, Tashkent", "value": "Asia/Karachi"},
//     {"text": "GMT +05:30 - Chennai, Kolkata, Mumbai, New Delhi", "value": "Asia/Calcutta"},
//     {"text": "GMT +05:30 - Sri Jayawardenapura", "value": "Asia/Calcutta"},
//     {"text": "GMT +05:45 - Kathmandu", "value": "Asia/Katmandu"},
//     {"text": "GMT +06:00 - Almaty, Novosibirsk", "value": "Asia/Almaty"},
//     {"text": "GMT +06:00 - Astana, Dhaka", "value": "Asia/Dhaka"},
//     {"text": "GMT +06:30 - Yangon (Rangoon)", "value": "Asia/Rangoon"},
//     {"text": "GMT +07:00 - Bangkok, Hanoi, Jakarta", "value": "Asia/Bangkok"},
//     {"text": "GMT +07:00 - Krasnoyarsk", "value": "Asia/Krasnoyarsk"},
//     {"text": "GMT +08:00 - Beijing, Chongqing, Hong Kong, Urumqi", "value": "Asia/Hong_Kong"},
//     {"text": "GMT +08:00 - Kuala Lumpur, Singapore", "value": "Asia/Kuala_Lumpur"},
//     {"text": "GMT +08:00 - Irkutsk, Ulaan Bataar", "value": "Asia/Irkutsk"},
//     {"text": "GMT +08:00 - Perth", "value": "Australia/Perth"},
//     {"text": "GMT +08:00 - Taipei", "value": "Asia/Taipei"},
//     {"text": "GMT +09:00 - Osaka, Sapporo, Tokyo", "value": "Asia/Tokyo"},
//     {"text": "GMT +09:00 - Seoul", "value": "Asia/Seoul"},
//     {"text": "GMT +09:00 - Yakutsk", "value": "Asia/Yakutsk"},
//     {"text": "GMT +09:30 - Adelaide", "value": "Australia/Adelaide"},
//     {"text": "GMT +09:30 - Darwin", "value": "Australia/Darwin"},
//     {"text": "GMT +10:00 - Brisbane", "value": "Australia/Brisbane"},
//     {"text": "GMT +10:00 - Canberra, Melbourne, Sydney", "value": "Australia/Canberra"},
//     {"text": "GMT +10:00 - Hobart", "value": "Australia/Hobart"},
//     {"text": "GMT +10:00 - Guam, Port Moresby", "value": "Pacific/Guam"},
//     {"text": "GMT +10:00 - Vladivostok", "value": "Asia/Vladivostok"},
//     {"text": "GMT +11:00 - Magadan, Solomon Is., New Caledonia", "value": "Asia/Magadan"},
//     {"text": "GMT +12:00 - Auckland, Wellington", "value": "Pacific/Auckland"},
//     {"text": "GMT +12:00 - Fiji, Kamchatka, Marshall Is.", "value": "Pacific/Fiji"},
//     {"text": "GMT +13:00 - Nuku'alofa", "value": "Pacific/Tongatapu"}
// ];
<template>
    <v-container>
        <v-snackbar

                v-model="snackbar.visibility"
                :color="snackbar.color"
                :bottom="snackbar.position === 'bottom'"
                :timeout="snackbar.timeout"
                :top="snackbar.position === 'top'"
        >
            {{snackbar.message}}
            <v-btn
                    color="primary--text"
                    @click="snackbar.visibility = false"
                    fab
            >
                <v-icon small color="red">fas fa-times</v-icon>
            </v-btn>
        </v-snackbar>
        <v-row justify="center" class="mt-10">
            <v-col cols="12" sm="10" md="6" lg="4" class="mt-10">
                <v-card :loading="sending">
                    <v-form :ref="'resetform'">
                        <v-card-title>
                            تغییر کلمه عبور
                        </v-card-title>
                        <v-card-text>

                            <v-text-field
                                    v-model="password"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="showPassword ? 'text' : 'password'"
                                    @click:append="showPassword = !showPassword"
                                    required
                                    :rules="passwordRules"
                                    label="کلمه عبور"
                                    outlined
                            >

                            </v-text-field>
                            <v-text-field
                                    v-model="verifyPassword"
                                    :type="'password'"
                                    required
                                    :rules="passwordConfirmRules"
                                    label="تکرار کلمه عبور"
                                    outlined
                            >
                            </v-text-field>
                        </v-card-text>

                        <v-card-actions>
                            <v-btn class="primary" :disabled="sending" @click="sendRequest">
                                تایید
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        created() {
            //
            // let token = this.$route.query.token;
            // this.$store.dispatch("checkTokenPassword", token)
            //     .then((res) => {
            //         this.email = res.email;
            //     })
            //     .catch(() => {
            //         this.validToken = "false";
            //     });

        },
        data() {
            return {

                snackbar: {
                    visibility: false,
                    color: 'info',
                    position: 'bottom',
                    timeout: 3000,
                    mode: 'vertical',
                    message: 'this is snackbar message',
                },
                sending: false,
                verifyPassword: '',
                password: '',
                showPassword: false,
                email: null
            }
        },
        computed: {
            passwordRules() {
                return [
                    v => !!v || this.$vuetify.lang.t('$vuetify.passwordErrRequired'),
                    v => v.length >= 8 || 'حداقل طول کلمه عبور 8 کاراکتر می باشد'
                ];
            },
            passwordConfirmRules() {
                return [
                    v => !!v || 'پر کردن فیلد تکرار پسورد الزامیست',
                    v => (v === this.password) || 'کلمه عبور و تکرار آن یکسان نمی باشند'
                ];
            },
        },

        methods: {
            showSnackbar(color = 'success', timeout = 3000, message = 'عملیات با موفقیت انجام شد.') {
                this.snackbar.visibility = true;
                this.snackbar.color = color;
                this.snackbar.position = 'bottom';
                this.snackbar.timeout = timeout;
                this.snackbar.mode = 'vertical';
                this.snackbar.message = message;

            },
            sendRequest() {
                if (this.$refs.resetform.validate()) {

                    this.sending = true;
                    this.$store
                        .dispatch("setPassword", {
                            token: this.$route.params.token,
                            password: this.password,
                            password_confirmation: this.verifyPassword
                        })
                        .then(() => {

                            this.showSnackbar();

                        })
                        .catch((err) => {

                            this.showSnackbar('red', 3000, err.response.data.message);

                        }).finally(() => {
                        setTimeout(() => {
                            this.$router.push({name: 'login', params: {lang: 'fa'}});
                        }, 1500);
                        this.sending = false;
                    });


                }

            }
        }
    }
</script>

<style scoped>

</style>
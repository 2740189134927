<template>
  <div>
    <v-container>
      <v-btn color="primary" class="mt-5" :to="{name: 'addCounselors'}">افزودن مشاور جدید</v-btn>

      <v-card class="my-8">
        <v-card-title ref="adminCounselors">
          {{ title }}
          <!--<v-spacer></v-spacer>-->
          <!--<v-text-field-->
          <!--solo-->
          <!--v-model="search"-->
          <!--append-icon="mdi-magnify"-->
          <!--label="جستجو"-->
          <!--single-line-->
          <!--hide-details-->
          <!--&gt;</v-text-field>-->
        </v-card-title>
        <v-card-text>
          <TableOptions :custom-query-parameter="{page:1}" :searchFilters="searchFilters"
                        @applyFilters="applyFilters"></TableOptions>
        </v-card-text>
        <v-data-table
            hide-default-footer
            :headers="headers"
            :items="counselors"
            :search="search"
        >
          <template v-slot:item.fullname="{ item }">

            <div @click="getCounselorProfile(item.id)" style="cursor: pointer;">
              {{ item.full_name }}
            </div>
          </template>
          <template v-slot:item.action="{ item }">
            <template v-if="checkRoleAndPermissions('doctor.modify.status')">
              <template v-if="item.status =='active'">
                <v-btn small fab elevation="0" class="ml-5" text @click="editDoctorStatus(item.id,'inactive')">
                  <v-icon class=" blue--text"> fas fa-eye</v-icon>
                </v-btn>
              </template>
              <template v-else>
                <v-btn class="ml-5" small elevation="0" color="transparent" fab
                       @click="editDoctorStatus(item.id,  'active')"
                >
                  <v-icon class=" red--text"> fas fa-eye-slash</v-icon>
                </v-btn>
              </template>
            </template>

            <v-btn color="primary" @click="getCounselorProfile(item.id)" fab x-small text>
              <v-icon small>fas fa-user-alt</v-icon>
            </v-btn>

            <v-btn x-small class="primary mx-1" text fab
                   @click="$router.push({name:'counselorCalendar',params:{counselorId:item.id}})"
            >
              <v-icon>fas fa-calendar</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
      <div class="text-center" v-if="!fiveSession">
        <v-pagination
            @input="handlerPagination(getAdminUsers)"
            v-model="page"
            :length="total"
        ></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
// import {mapGetters} from 'vuex'
import TableOptions from '@/components/TableOptions.vue';
import {adminCounselors} from "../../searchFilters";
import {EventBus} from "../../event-bus";
import UrlQueryMixin from "@/Mixin/UrlQueryMixin";

export default {
  mixins: [UrlQueryMixin],
  components: {
    TableOptions
  },
  props: {
    title: {
      default: 'مشاوران',
      type: String
    },
    fiveSession: Boolean
  },
  data() {
    return {
      searchFilters: [],
      options: {},
      requesting: false,
      // loading: true,
      search: '',
      headers: [
        {
          text: 'نام و نام خانوادگی',
          align: 'start',
          sortable: false,
          value: 'fullname',
        },
        {text: 'اطلاعات مشاور', value: 'action', sortable: false},
      ],
      counselors: [],
      page: 1,
      total: 1
    }
  },
  computed: {
    // ...mapGetters({
    // counselors: 'getAdminUsers',
    // page: 'page',
    // total: 'total',
    // }),
  },
  watch: {
    page() {
      // this.$vuetify.goTo(this.$refs.adminCounselors.offsetTop);
    }
  },
  created() {
    this.searchFilters = adminCounselors;
    this.fillPageState()
    // this.getAdminUsers();
  },
  methods: {
    editDoctorStatus(doctorId, status) {
      EventBus.$emit('setLoading', true)
      let data = {
        status: status
      }
      this.$store.dispatch('AdminSaveCounselorProfile', {
        userid: doctorId,
        data
      }).then(() => {
        EventBus.$emit('notify', 'green', undefined, 'ویرایش انجام شد');

        this.getAdminUsers();
      })
    },
    applyFilters(options) {
      this.resetPaginationInApplyFilterIfNeeded(options)
      this.options = options;
      this.getAdminUsers();
    },
    getAdminUsers() {
      EventBus.$emit('setLoading', true);
      this.$store.dispatch('getDoctorsList', {
        page: this.page,
        options: {
          ...this.options
        }
      }).then((data) => {
        this.counselors = data.data.doctors;
        // data.data.forEach(client => {
        //     this.counselors.push(client);
        //     client['fullname'] = client.firstname + ' ' + client.lastname;
        // });

        this.$vuetify.goTo(this.$refs.adminCounselors.offsetTop);


        this.total = data.data.pagination.total_pages;
        // this.loading = false;
        // console.log("--------------------data--------------------");
        // console.log(data.data.users);
        // console.log("--------------------data--------------------");
      }).finally(() => {
        EventBus.$emit('setLoading', false);
      })
    },
    // nextPage() {
    //     this.$store.dispatch('pageChangeHandle', {value: 'next', page: this.page, url: '/admin/users/list', commitName: 'setAdminUsers', usertype: '&usertype=counselor'});
    //     console.log('page', this.page)
    // },
    // previousPage() {
    //     this.$store.dispatch('pageChangeHandle', {value: 'previous', page: this.page, url: '/admin/users/list', commitName: 'setAdminUsers', usertype: '&usertype=counselor'});
    //     console.log('page', this.page)
    // },
    // specificPage(page) {
    //     this.$store.commit('SET_SPECIFIC_PAGE', page);
    //     this.$store.dispatch('pageChangeHandle', {value: '', page: page, url: '/admin/users/list', commitName: 'setAdminUsers', usertype: '&usertype=counselor'});
    //     console.log('page', page)
    // },
    getCounselorProfile(id) {
      if (this.checkRoleAndPermissions('admin.doctors.show')) {
        this.$router.push('counselors/' + `${id}` + '/profile');
      }
    }
  }
}
</script>

<style scoped>

</style>
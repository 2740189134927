<template>
  <div>
    <v-window v-model="windowHandler">
      <v-window-item :value="0">
        <template v-if="windowHandler == 0">
          <AdminSessions mode="requestedSessions" location="crm" @updateWindow="updateWindow($event)"></AdminSessions>
        </template>
      </v-window-item>
      <v-window-item :value="1">
        <template v-if="windowHandler == 1">
          <AdminClientCycles
              :customer="$route.params.clientId" :profile="profile"
              :getProfile="false"
              @updateWindow="updateWindow($event)"
          ></AdminClientCycles>
        </template>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import AdminClientCycles from "@/views/admin/AdminClientCycles";
import AdminSessions from "@/components/sessions/AdminSessions";

export default {
  components: {AdminSessions, AdminClientCycles},

  data() {
    return {
      windowHandler: 0
    }
  },
  methods:{
    updateWindow(val){
      this.windowHandler = val
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
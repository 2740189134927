<template>
  <div>
    <p class="font-weight-bold">ثبت پیگیری خرید </p>
    <v-form ref="addHistory" @submit.prevent="changeTaskData" @keydown.enter="changeTaskData">
      <v-row>
        <v-col cols="12" lg="4">
          <v-select
              :items="status"
              item-text="title"
              item-value="value"
              v-model="statusSelected"
              outlined
              dense
              :rules="requiredRules"
          ></v-select>
        </v-col>
      </v-row>
    </v-form>
    <v-form ref="addDesc">
      <v-divider></v-divider>
      <v-textarea outlined dense class="px-5 mt-10" label="توضیحات" v-model="task.description" :value="task.description" :rules="requiredRules"></v-textarea>
    </v-form>


    <v-btn color="primary"  elevation="0" class="mr-5 mt-10" @click="changeTaskData()">ثبت</v-btn>

  </div>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "@/event-bus";
import AdminDatePicker from "@/components/AdminDatePicker";
import moment from "moment-jalaali";

export default {
  components: {AdminDatePicker},
  props: {
    task: Object,
  },
  data() {
    return {
      date: null,
      showDate: false,
      statusSelected: null,
      desc: null,
      status: [
        {
          title: 'تمایل به خرید',
          value: "will_buy"
        },
        {
          title: "عدم تمایل به خرید",
          value: "will_not_buy"
        }
      ]
    }
  },
  mounted() {
  },
  computed: {
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
  },
  methods: {
    saveHistory() {
      if (this.$refs.addHistory.validate()) {
        EventBus.$emit('setLoading', false);
        this.$store.dispatch('doneTask', {
          id: this.$route.query.taskId,
          status: "done",
          buying_decision: this.statusSelected,
          needReloadUsersList: true,
        }).then(() => {
          EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
          EventBus.$emit('returnWindow')
          this.$router.push({name: 'mainPageUserBasedCrm'})
        }).finally(() => {
          EventBus.$emit('setLoading', false);
        })
      }
    },
    addDesc(){
      if (this.$refs.addDesc.validate()){
        let payload = {
          id: this.$route.query.taskId,
          status: "paused",
          description: this.task.description
        }
        this.$store.dispatch('changeTaskStatus', payload).then(() => {
          EventBus.$emit('getTaskList')
          EventBus.$emit('notify', 'green', undefined,  'توضیحات موفقیت به ثبت رسید');
        })
      }
    },
    changeTaskData(type) {
      if (this.task.description != null){
        this.addDesc()
      }
      if (this.statusSelected != null){
        this.saveHistory();
      }
    },
  },
  filters: {

  }
}
</script>

<style scoped>

</style>
// src/plugins/vuetify.js
/*eslint-disable*/

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VueI18n from 'vue-i18n'
import messages from './languageLabels.js'
import {ajaxPost, ajaxGet} from '../utilities.js';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)
Vue.use(VueI18n)

let rtl;
let localeLang = localStorage.getItem('local');
// let direction = localStorage.getItem('direction');

if (localeLang != null) {
    if (localeLang == 'fa') {
        rtl = true;
    } else {
        rtl = false;
    }
} else {
    localeLang = 'fa';
    rtl = true;
}

// if (direction != null) {
//     console.log('NOT NULL');
//     rtl = (direction == 'rtl') ? true : false;
//
// } else {
//     rtl = true;
// }

// console.log('default language');
// console.log(localizationSettings.defaultLanguage);
// Create VueI18n instance with options
export const i18n = new VueI18n({
    // locale: localizationSettings.defaultLanguage, // set locale
    locale: localeLang || 'fa', // set locale
    messages, // set locale messages
});

export default new Vuetify({
    lang: {
        t: (key, ...params) => i18n.t(key, params),
    },
    rtl: rtl,
    theme: {
        themes: {
            light: {
                primary: '#43378C',
                secondary: '#86C23B',
                accent: '#888'
            },
        },
    }
})
<template>
  <div>
    <v-dialog v-model="hourTimePicker" width="400px" persistent class="white--text">
      <v-time-picker
          v-if="typeTimePicker === 'start'"
          style="margin: 0px auto"
          class="elevation-0"
          ampm-in-title
          format="ampm"
          v-model="startTime"
      ></v-time-picker>
      <v-time-picker
          v-if="typeTimePicker === 'end'"
          style="margin: 0px auto"
          class="elevation-0"
          ampm-in-title
          format="ampm"
          v-model="endTime"
      ></v-time-picker>
      <v-btn color="primary" @click="hourTimePicker = false">
        تایید
      </v-btn>
    </v-dialog>
    <v-dialog v-model="recurringCustomModal" width="500px" persistent>
      <v-card>
        <v-card-title>
          افزودن تکرار
        </v-card-title>
        <v-card-text class="pt-2">
          <div class="d-flex mx-2">
            <div>
              <div class="mt-2">
                تکرار برای هر
              </div>
            </div>
            <div class="mx-2">
              <v-text-field v-model="customRecurring.interval" type="number" style="width: 100px" outlined
                            dense></v-text-field>
            </div>
            <div class="mx-2">
              <v-select style="width: 100px" v-model="customRecurring.type" :items="recurrenceRoleType"
                        item-text="title" item-value="key" outlined dense></v-select>
            </div>
          </div>
          <div class="days" v-if="customRecurring.type == 'WEEKLY'">
            <div class="titleDays">
              تکرار در
            </div>
            <div class="daysArea mt-3 d-flex justify-space-between mx-5 flex-row-reverse">
              <div v-for="(day , i ) in days" :key="i">
                <v-btn fab small elevation="0" @click="selectDayCustomRecurring(day)"
                       :color="isDaySelected(day) &&  'primary'">{{ day }}
                </v-btn>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" elevation="0" @click="prepareCustomRecurring">
            افزودن
          </v-btn>
          <v-btn color="red" text @click="cancelCustomRecurring">
            لغو
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="Modal.addFreeTime" v-model="Modal.addFreeTime" width="500px">
      <v-card>
        <v-card-title class="font-weight-bold">
          افزودن زمان خالی
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-5 black--text">
          <v-form ref="addFreeTimeForm">
            <div class="eventItems ">
              <div class="timeDate d-flex">
                <div class="flex-grow-1 ml-5 text-left">
                  {{ selectedDate.date | filterDate }} - {{ selectedDate.date | jalaliFilter }}
                  <br>
                  <div class="recommendedTime mb-2">
                    <v-chip x-small class="mr-2" v-for="(time , i) in timeRecommended" :key="i"
                            @click="prepareEndTime(time.interval)">
                      {{ time.title }}
                    </v-chip>
                  </div>
                  <div class="selectHour d-flex justify-end"
                       @mouseleave="selectStartTime = false;selectEndTime = false">
                    <div style="width: 150px" class="ml-2 inputWithSelect" @click="selectStartTime = false">
                      <v-text-field dense outlined label="تا" :rules="requiredRules" v-model="endTime"
                                    @click="selectEndTime = true"
                                    @focusin="selectEndTime = false">
                      </v-text-field>
                      <div class="listTimeSelected" v-if="selectEndTime">
                        <div class="timeSelected pl-2" v-for="(hour ,i ) in hourPeriod" :key="i"
                             @click="endTime = hour;selectEndTime = false">
                          {{ hour }}
                        </div>
                      </div>
                    </div>
                    <div style="width: 150px" class="inputWithSelect" @click="selectEndTime = false">
                      <v-text-field dense outlined label="از" :rules="requiredRules" v-model="startTime"
                                    @click="selectStartTime = true"
                                    @focusin="selectStartTime = true"></v-text-field>
                      <div class="listTimeSelected" v-if="selectStartTime">
                        <div class="timeSelected pl-2" v-for="(hour ,i ) in hourPeriod" :key="i"
                             @click="startTime = hour;selectStartTime = false">
                          {{ hour }}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="icon">
                  <v-icon>mdi-clock-outline</v-icon>
                </div>
              </div>

              <div class="d-flex description justify-end" :key="2">
                <v-btn elevation="0" class="ml-10 mb-2" x-small color="primary" @click="addRecurring=true"
                       v-if="!addRecurring">افزودن تکرار
                </v-btn>
              </div>
              <div v-if="addRecurring" :key="1">
                <div class="d-flex mt-4 description justify-end">
                  <div class="ml-5">
                    <v-select :items="recurringItems" item-text="title" item-value="value" v-model="recurringType"
                              dense
                              append-icon="mdi-close"
                              @click:append="addRecurring=false"
                              label="نحوه تکرار"></v-select>
                  </div>
                  <div>
                    <v-icon class="mt-2">mdi-timer</v-icon>
                  </div>
                </div>
              </div>
              <!--              <v-fade-transition>-->
              <!--                <div v-if="recurringType === 'MONTHLY'">-->
              <!--                  <div class="d-flex  description justify-end">-->
              <!--                    <div class="ml-5">-->
              <!--                      <v-text-field dense outlined label="تعداد ماه‌ها برای تکرار" v-model="countRecurring"/>-->
              <!--                    </div>-->
              <!--                    <div>-->
              <!--                      <v-icon class="mt-2">mdi-timer</v-icon>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </v-fade-transition>-->
              <div class="d-flex  description justify-end">
                <div class="ml-5">
                  <v-text-field outlined rows="1" v-model="description" dense
                                label="توضیحات"></v-text-field>
                </div>
                <div>
                  <v-icon class="mt-2">mdi-note-outline</v-icon>
                </div>
              </div>
              <div class="d-flex description justify-end">
                <div class="ml-5">
                  <v-select v-model="activeCalendar" dense style="width: 250px" :items="calenders" item-text="summary"
                            item-value="google_id" outlined label="تقویم"></v-select>
                </div>
                <div>
                  <v-icon class="mt-2">mdi-calendar</v-icon>
                </div>
              </div>
            </div>

          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" elevation="0" @click="submitFreeTime" :disabled="disables.addFreeTime">ذخیره</v-btn>
          <v-btn color="primary" elevation="0" text @click="Modal.addFreeTime = false;recurringType = null">بستن</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="Modal.attachUserToFreeTime" v-if="Modal.attachUserToFreeTime" width="400px">
      <v-card>
        <v-card-title>
          افزودن کاربر به زمان خالی
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          <v-form ref="attachUserToTimeFrom" @submit.prevent>
            <div style="position: relative">
              <v-text-field @keypress="searchUser" :rules="requiredRules" v-model="attachTimeToUserModel.username"
                            label="کاربر"
                            item-text="full_name" item-value="id"
                            dense
                            outlined></v-text-field>
              <div class="dropDownUsers" v-if="users">
                <div class="userName" v-for="(user , i) in users" :key="i" @click="selectUser(user)">
                  {{ user.full_name }}
                </div>
              </div>
            </div>
            <v-checkbox v-model="attachTimeToUserModel.makeSession" :value="true" label="ساخت جلسه"></v-checkbox>
            <v-checkbox v-model="attachTimeToUserModel.indebt" :value="true" label="بدهکار کردن"></v-checkbox>
            <v-checkbox v-if="selectedDate.recurringEventId" v-model="attachTimeToUserModel.singleEvent" :value="true"
                        label="همین‌جلسه"></v-checkbox>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="attachTimeToUser" :disabled="disables.attachUser">ثبت</v-btn>
          <v-btn color="primary" text @click="Modal.attachUserToFreeTime = !Modal.attachUserToFreeTime">بستن</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="Modal.deleteUserFromEvent" v-if="Modal.deleteUserFromEvent" width="400px">
      <v-card v-if="Modal.deleteUserFromEvent">
        {{ selectedDate.event.recurrence }}
        <v-card-title>
          حذف کاربر از زمان
        </v-card-title>
        <v-card-text>
          <v-form ref="detachForm">
            <v-select
                v-if="selectedDate.event.recurringEventId"
                :rules="requiredRules"
                label="نوع حذف" :items="deleteTypes" item-text="title"
                item-value="type"
                v-model="detachUserFromTimeModal.type"></v-select>
            <template v-else>
              از حذف کاربر در این زمان مطمئن هستید ?
            </template>
          </v-form>

          <!--          <div-->
          <!--              v-if="selectedDate.event.extendedProperties.private.session_id && selectedDate.event.extendedProperties.private.session_id != '0'">-->
          <!--            <v-checkbox v-model="detachUserFromTimeModal.deleteSessionRadio" :value="true"-->
          <!--                        label="حذف جلسه"></v-checkbox>-->
          <!--          </div>-->
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" elevation="0" class="white--text" @click="detachUserFromTime"
                 :disabled="disables.detachUser">
            حذف
          </v-btn>
          <v-btn color="primary" text @click="Modal.deleteUserFromEvent = false">
            لغو
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="Modal.deleteEvent" v-if="Modal.deleteEvent" width="400px">
      <v-card>
        <v-card-title>
          حذف زمان
        </v-card-title>
        <v-card-text>
          <v-form ref="deleteTime">
            <v-select
                v-if="selectedDate.event.recurringEventId" :rules="requiredRules" label="نوع حذف" :items="deleteTypes"
                item-text="title"
                item-value="type"
                v-model="deleteTime.type"></v-select>
            <template v-else>
              از حذف این زمان مطمئن هستید؟
            </template>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" elevation="0" :disabled="disables.deleteTime" class="white--text" @click="deleteEvent">
            حذف
          </v-btn>
          <v-btn color="primary" text @click="Modal.deleteEvent = false">
            لغو
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/*eslint-disable*/
import moment from "moment-jalaali";
import {EventBus} from "@/event-bus";

export default {
  props: {
    Modal: Object,
    timePickerModal: Boolean,
    addEmptyTimeModal: Boolean,
    selectedDate: Object,
    calenders: Array,
    selectedEvent: Object,
  },
  data() {
    return {
      recurringCustomModal: false,
      debounce: null,
      hourPeriod: [
        '00:00',
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00',
      ],
      timeRecommended: [
        {
          title: "۹۰ دقیقه",
          interval: 90,
        },
        {
          title: "۶۰ دقیقه",
          interval: 60,
        },
        {
          title: "۳۰ دقیقه",
          interval: 30,
        },
        {
          title: "۴۵ دقیقه",
          interval: 45,
        },
        {
          title: "۱۵ دقیقه",
          interval: 15,
        },

      ],
      startTime: moment(new Date()).format("HH:mm"),
      endTime: moment(new Date().getTime() + 1 * 60 * 60 * 1000).format("HH:mm"),
      addRecurring: false,
      selectEndTime: false,
      selectStartTime: false,
      countRecurring: null,
      typeTimePicker: null,
      hourTimePicker: false,
      activeCalendar: null,
      description: null,
      recurringType: null,
      users: null,
      attachTimeToUserModel: {
        username: null,
        userId: null,
        makeSession: false,
        singleEvent: false,
        indebt: false,
      },
      deleteTypes: [
        {
          type: 'this',
          title: 'این جلسه'
        },
        {
          type: 'thisAndContinue',
          title: 'بعدی‌ها'
        },
        {
          type: 'all',
          title: 'همه'
        }
      ],
      detachUserFromTimeModal: {
        type: null,
        deleteSessionRadio: false,
      },
      deleteTime: {
        type: null
      },
      days: [
        'Sa', 'Su', 'Mo', 'Tu', 'We', 'Th', 'Fr'
      ],
      disables: {
        addFreeTime: false,
        deleteTime: false,
        detachUser: false,
        attachUser: false,
      },
      customRecurring: {
        type: "WEEKLY",
        days: [],
        interval: 1,
      },
      recurrenceRoleType: [
        {
          title: 'روز',
          key: 'DAILY'
        },
        {
          title: 'ماه',
          key: 'MONTHLY'
        },
        {
          title: 'هفته',
          key: 'WEEKLY'
        },
      ]
    }
  },

  methods: {
    prepareEndTime(interval) {
      let tempStartDate = moment(new Date()).format('Y-MM-DD') + " " + this.startTime
      let endHour = moment(new Date(tempStartDate).getTime() + 1 * interval * 60 * 1000).format("HH:mm")
      this.endTime = endHour
      // this.endTime = l
    },
    cancelCustomRecurring() {
      this.recurringType = 'DAILY'
      this.recurringCustomModal = false
      this.customRecurring = {
        type: "WEEKLY",
        days: [this.filterDateMethod(this.selectedEvent.date).substr('0', '2')],
        interval: null,
      }
    },
    prepareCustomRecurring() {
      let RRule;
      switch (this.customRecurring.type) {
        case "MONTHLY":
          RRule = `RRULE:FREQ=MONTHLY;WKST=SA`
          break;
        case 'WEEKLY':
          RRule = `RRULE:FREQ=WEEKLY;WKST=SA;BYDAY=${this.customRecurring.days.toString().toUpperCase()}`
          break;
        case 'DAILY':
          RRule = "RRULE:FREQ=DAILY;WKST=SA";
          break;
      }
      if (this.customRecurring.interval) {
        RRule += `;INTERVAL=${this.customRecurring.interval}`
      }
      this.recurringCustomModal = false
      return RRule
    },
    selectDayCustomRecurring(day) {
      let today = this.filterDateMethod(this.selectedEvent.date).substr('0', '2')
      if (today != day) {
        let index = this.customRecurring.days.findIndex(item => item == day)
        if (index == -1) {
          this.customRecurring.days.push(day)
        } else {
          this.customRecurring.days.splice(index, 1)
          console.log(this.customRecurring.days, index)
        }
      }
    },
    isDaySelected(day) {
      if (this.customRecurring.days.findIndex(item => item == day) != -1) {
        return true
      }
    },
    selectUser(user) {
      this.attachTimeToUserModel.username = user.full_name
      this.attachTimeToUserModel.userId = user.id
      this.users = null
    },
    searchUser() {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.users = null
        EventBus.$emit('setLoading', true)
        this.$store.dispatch('getClientsList', {
          page: 1, options: {
            type: 'patient',
            patient_name: this.attachTimeToUserModel.username
          }
        }).then((resp) => {
          if (resp.data.users.length != 0) {
            this.users = resp.data.users
          }
        }).finally(() => {
          EventBus.$emit('setLoading', false)
        })
      }, 1000)
    },
    getClientList() {
      this.$store.dispatch('getClientsList', {
        page: 1, options: {
          type: 'patient',
        }
      }).then((resp) => {
        this.users = resp.data.users
      })
    },
    test() {
      let inputEl = this.$refs.input.$el.querySelector('input')
      setTimeout(() => {
        inputEl.select()
      }, 200)
    },
    getTimezoneString(timezone) {
      let tzValue = moment().tz(timezone).utcOffset();
      let strVal = '';
      let hour = "";
      if (tzValue > 0) {
        hour = +parseInt(tzValue / 60) < 10 ? '+0' + parseInt(tzValue / 60) : "+" + parseInt(tzValue / 60);
      } else {
        // console.log(parseInt(tzValue / 60), 'bbbbbb')
        hour = Math.abs(parseInt(tzValue / 60)) < 10 ? '-0' + Math.abs(parseInt(tzValue / 60)) : parseInt(tzValue / 60);
      }
      let remainder = (tzValue % 60) < 10 ? tzValue % 60 + '0' : tzValue % 60;
      // let hour = parseInt(tzValue / 60) < 10 ? '0' + parseInt(tzValue / 60) : parseInt(tzValue / 60);
      strVal += (hour).toString() + ':' + (remainder).toString();
      return strVal;
    },
    prepareRecurring(recurring) {
      switch (recurring) {
        case 'WEEKLYON':
          let dayLabel = this.filterDateMethod(this.selectedDate.date)
          dayLabel = dayLabel.substr(0, 2).toUpperCase()
          return `RRULE:FREQ=WEEKLY;WKST=SU;BYDAY=${dayLabel}`
        case 'DAILY':
          return "RRULE:FREQ=DAILY"
        case "MONTHLY":
          return "RRULE:FREQ=MONTHLY"
        case "custom":
          return this.prepareCustomRecurring()
        default :
          return null
      }
      // DAILY
    },
    submitFreeTime() {
      if (this.$refs.addFreeTimeForm.validate()) {
        EventBus.$emit('setLoading', true)
        let start = moment(this.selectedDate.date + " " + this.startTime).format(`YYYY-MM-DD\THH:mm:ss`) + this.getTimezoneString('Asia/Tehran')
        let end = moment(this.selectedDate.date + " " + this.endTime).format(`YYYY-MM-DD\THH:mm:ss`) + this.getTimezoneString('Asia/Tehran')
        let calendarId = this.activeCalendar.hasOwnProperty('google_id') ? this.activeCalendar.google_id : this.activeCalendar
        let activeCalendar = this.calenders.find(item => item.google_id == calendarId)
        // let recurrence = `RRULE:FREQ=${this.recurringType}`

        let payload = {
          "calendar_id": calendarId,
          "summary": "free",
          "description": this.description,
          "start_dateTime": start,
          "end_dateTime": end,
          "recurrence": this.prepareRecurring(this.recurringType),
          // "recurrence": "RRULE:FREQ=WEEKLY;WKST=SA;INTERVAL=2;BYDAY=WE,SU",
          "doctor_id": activeCalendar.doctor_id
        }
        if (!this.addRecurring) {
          delete payload.recurrence
        }
        this.disables.addFreeTime = true
        this.$store.dispatch('storeFreeTime', payload).then((resp) => {
          this.$emit('reloadCalendar')
          this.Modal.addFreeTime = false
          this.addRecurring = false
          this.recurringType = null
          this.customRecurring = {
            type: "WEEKLY",
            days: [this.filterDateMethod(this.selectedEvent.date).substr('0', '2')],
            interval: 1,
          }
          // this.$refs.addFreeTimeForm.reset()
        }).catch(() => {
          this.disables.addFreeTime = false
        }).finally(() => {
          this.disables.addFreeTime = false
          EventBus.$emit('setLoading', false)
        })
      }
    },
    attachTimeToUser() {
      // alert(this.attachTimeToUserModel.makeSession)
      // return;
      if (this.$refs.attachUserToTimeFrom.validate()) {
        if (this.attachTimeToUserModel.userId) {
          let activeCalendar = this.calenders.find(item => item.google_id == this.$route.query.calender_id)
          let payload = {
            "calendar_id": this.$route.query.calender_id,
            "recurring_event_id": this.selectedEvent.recurringEventId,
            "user_id": this.attachTimeToUserModel.userId,
            "with_create_session": !this.attachTimeToUserModel.makeSession ? false : true,
            "event_id": this.selectedEvent.id,
            "doctor_id": activeCalendar.doctor_id,
            // "doctor_id": 76,
            "datetime_start": this.selectedEvent.start.dateTime,
            "datetime_end": this.selectedEvent.end.dateTime,
            "indebt": this.attachTimeToUserModel.indebt
          }
          this.disables.attachUser = true
          if (!this.attachTimeToUserModel.singleEvent && this.selectedDate.recurringEventId) {
            EventBus.$emit('setLoading', true)
            this.$store.dispatch('attachTimeToUserRecurrence', payload).then((resp) => {
              this.Modal.attachUserToFreeTime = false
              this.$emit('reloadCalendar')
              this.$refs.attachUserToTimeFrom.reset()
            }).finally(() => {
              EventBus.$emit('setLoading', false)
              this.disables.attachUser = false
            })
          } else {
            EventBus.$emit('setLoading', true)
            this.$store.dispatch('attachTimeToUserSingle', payload).then((resp) => {
              this.Modal.attachUserToFreeTime = false
              this.$emit('reloadCalendar')
              this.$refs.attachUserToTimeFrom.reset()
            }).finally(() => {
              EventBus.$emit('setLoading', false)
              this.disables.attachUser = false
            })
          }
        } else {
          EventBus.$emit('notify', 'red', undefined, 'لطفا کاربر را انتخاب کند');
        }
      }
    },
    detachUserFromTime() {
      if (this.$refs.detachForm.validate()) {
        let payload;
        if (this.selectedDate.event.recurringEventId) {
          if (this.detachUserFromTimeModal.type) {
            switch (this.detachUserFromTimeModal.type) {
              case "this":
                payload = {
                  url: '/admin/googlecalendar/events/reserved/delete',
                  data: {
                    "calendar_id": this.$route.query.calender_id,
                    "event_id": this.selectedDate.event.id,
                    'summary': 'free',
                    'description': 'free'
                  }
                }
                break;
              case "thisAndContinue":
                payload = {
                  url: '/admin/googlecalendar/events/recurring/endandnew',
                  data: {
                    "calendar_id": this.$route.query.calender_id,
                    "recurring_event_id": this.selectedDate.event.recurringEventId,
                    "event_id": this.selectedDate.event.id,
                    "datetime_start": this.selectedDate.event.start.dateTime,
                    "datetime_end": this.selectedDate.event.end.dateTime,
                    'summary': 'free',
                    'description': 'free'
                  }
                }
                break;
              case 'all':
                payload = {
                  url: '/admin/googlecalendar/events/recurring/reserved/delete',
                  data: {
                    "calendar_id": this.$route.query.calender_id,
                    "recurring_event_id": this.selectedDate.event.recurringEventId,
                    'summary': 'free',
                    'description': 'free'
                  }
                }
                break;
            }
          }
        } else {
          payload = {
            url: '/admin/googlecalendar/events/reserved/delete',
            data: {
              "calendar_id": this.$route.query.calender_id,
              "event_id": this.selectedDate.event.id,
              'summary': 'free',
              'description': 'free'
            }
          }
        }
        EventBus.$emit('setLoading', true)
        this.disables.detachUser = true
        let detachUserFunction = () => {
          return this.$store.dispatch('detachUserFromTime', payload).then(() => {
          }).finally(() => {
            this.$emit('reloadCalendar')
            this.disables.detachUser = false
            this.Modal.deleteUserFromEvent = false
            EventBus.$emit('setLoading', false)
          })
        }
        let deleteSession = () => {
          return this.$store.dispatch('deleteSession', {
            "calendar_id": this.$route.query.calender_id,
            "event_id": this.selectedDate.event.id,
            "user_id": this.selectedDate.event.extendedProperties.private.user_id,
            "session_id": this.selectedDate.event.extendedProperties.private.session_id
          }).then(() => {
            this.Modal.deleteUserFromEvent = false
          })
        };
        if (this.selectedDate.event.extendedProperties.private.session_id && this.selectedDate.event.extendedProperties.private.session_id !== '0') {
          deleteSession().then(detachUserFunction).finally(() => {
            this.disables.detachUser = false
          });
        } else {
          detachUserFunction().finally(() => {
            this.disables.detachUser = false
          })
        }
        // if (this.detachUserFromTimeModal.deleteSessionRadio) {

        // } else {

        // }
      }
    },
    deleteEvent() {
      if (this.$refs.deleteTime.validate()) {
        let payload;
        if (this.selectedDate.event.recurringEventId) {
          if (this.deleteTime.type) {
            switch (this.deleteTime.type) {
              case "this":
                payload = {
                  url: '/googlecalendar/event/delete',
                  data: {
                    "calendar_id": this.$route.query.calender_id,
                    "event_id": this.selectedDate.event.id,
                  }
                }
                break;
              case "thisAndContinue":
                payload = {
                  url: '/googlecalendar/events/recurring/end',
                  data: {
                    "calendar_id": this.$route.query.calender_id,
                    "recurring_event_id": this.selectedDate.event.recurringEventId,
                    // "datetime_end": moment(this.selectedDate.event.start.dateTime).subtract(10, 'minute')
                    "datetime_end": '2022-11-19T13:50:00+03:30'
                  }
                }
                break;
              case 'all':
                payload = {
                  url: '/googlecalendar/event/recurring/delete',
                  data: {
                    "calendar_id": this.$route.query.calender_id,
                    "recurring_event_id": this.selectedDate.event.recurringEventId,
                  }
                }
                break;
            }

          }
        } else {
          payload = {
            url: '/googlecalendar/event/delete',
            data: {
              "calendar_id": this.$route.query.calender_id,
              "event_id": this.selectedDate.event.id,
            }
          }
        }
        EventBus.$emit('setLoading', true)
        this.disables.deleteTime = true
        this.$store.dispatch('deleteEvent', payload).then(() => {
          this.Modal.deleteEvent = false
          this.$emit('reloadCalendar')
        }).finally(() => {
          this.disables.deleteTime = false
          EventBus.$emit('setLoading', false)
        })
      }
    },
    jalaliFilterMethod(date) {
      return new Date(date).toLocaleDateString('fa-ir', {weekday: "long"})
    },
    filterDateMethod(date) {
      return new Date(date).toLocaleDateString('en-us', {weekday: "long"})
      // return moment(date).format('dddd,MMMM d')
    },
  },
  filters: {
    filterDate(date) {
      return new Date(date).toLocaleDateString('en-us', {weekday: "long", month: "long", day: "numeric"})
      // return moment(date).format('dddd,MMMM d')
    },
    jalaliFilter(date) {
      return new Date(date).toLocaleDateString('fa-ir', {weekday: "long", month: "long", day: "numeric"})
    }
  },
  mounted() {
    let hour = moment(new Date()).format("HH")
    this.startTime = this.hourPeriod.find(item => item.includes(hour))
    let endHour = moment(new Date().getTime() + 1 * 60 * 60 * 1000).format("H")
    this.endTime = this.hourPeriod.find(item => item.includes(endHour))

    this.activeCalendar = {
      google_id: this.$route.query.calender_id
    }
    // this.getClientList()
  },
  computed: {

    recurringItems() {
      if (this.selectedDate) {
        return [
          // {
          //   title: 'بدون تکرار',
          //   value: 'DAILY'
          // },
          {
            title: 'هرروز',
            value: 'DAILY'
          },
          {
            title: "ماهانه",
            value: "MONTHLY"
          },
          {
            title: 'هفتگی در ' + this.jalaliFilterMethod(this.selectedDate.date) + " ها",
            value: 'WEEKLYON',
          },
          {
            title: 'سفارشی',
            value: "custom",
          }
        ]
      }
      return []
    },
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
  },
  watch: {
    'selectedEvent.date'() {
      this.customRecurring.days.push(this.filterDateMethod(this.selectedEvent.date).substr('0', '2'))
    },
    recurringCustomModal() {

    },
    recurringType(val) {
      if (val == 'custom') {
        this.recurringCustomModal = true
      }
    },
    '$route.query.calender_id'() {
      this.activeCalendar = {
        google_id: this.$route.query.calender_id
      }
    },
    'Modal.addFreeTime'() {
      this.addRecurring = false
      if (this.selectedDate.time != '') {
        this.startTime = this.selectedDate.time.substr('0', '2') + ":00"
        this.endTime = new Number(this.selectedDate.time.substr('0', '2')) + 1 + ":00"
      } else {
        this.startTime = moment(new Date()).format("HH") + ":00"
        this.endTime = moment(new Date().getTime() + 1 * 60 * 60 * 1000).format("HH") + ":00"
      }
      this.activeCalendar = {
        google_id: this.$route.query.calender_id
      }
      this.customRecurring = {
        type: "WEEKLY",
        days: [this.filterDateMethod(this.selectedEvent.date).substr('0', '2')],
        interval: 1,
      }
      this.recurringType = null
    },
    'Modal.deleteUserFromEvent'() {
      this.detachUserFromTimeModal.deleteSessionRadio = false
    }
  }
}
</script>

<style scoped>
.dropDownUsers {
  position: absolute;
  top: 42px;
  width: 100%;
  max-height: 100px;
  overflow-y: auto;
  z-index: 999;
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #dddddd;
  background: white;
  padding: 10px;
  /*box-shadow: 0px 0px 5px 0px #000;*/
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.userName {
  cursor: pointer;
}

.inputWithSelect {
  position: relative;
}

.listTimeSelected {
  position: absolute;
  top: 40px;
  background: white;
  width: 100%;
  height: 100px;
  overflow-y: scroll;
  z-index: 10;
  border: 1px solid #ddd;
  border-radius: 0px 0px 10px 10px;
}

.timeSelected {
  /*cursor: pointer;*/
}
</style>
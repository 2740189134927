<template>
    <div>
        <v-toolbar-items v-if="showTopMenu">
            <v-btn class="py-5" text :to="{name:'dashboard'}">{{$vuetify.lang.t('$vuetify.navDashboard')}}</v-btn>
            <v-btn class="py-5" text :to="{name:'sessions'}">{{$vuetify.lang.t('$vuetify.navSessionsLabel')}}</v-btn>
            <v-btn class="py-5" text :to="{name:'calendar'}">{{$vuetify.lang.t('$vuetify.navCalendar')}}</v-btn>
            <v-btn class="py-5" text :to="{name:'clients'}">{{$vuetify.lang.t('$vuetify.navClientsLabel')}}</v-btn>
        </v-toolbar-items>

        <v-list v-else-if="showNavIcon" id="navIconItems" dense>
            <v-list-item>
                <v-list-item-avatar>
                    <v-img
                            style="object-fit: cover"
                            :src="$store.getters.getUserInfo.avatar ? $store.getters.getUserInfo.avatar : require('./../../assets/avatar-default.png')"></v-img>
                </v-list-item-avatar>

                <v-list-item-title>
                    <v-btn text :to="{name:'profile'}">{{ $store.state.userInfo.firstname + ' ' +
                        $store.state.userInfo.lastname }}
                    </v-btn>
                    <p class="secondary--text px-4" style="font-size: .6rem">مشاهده پروفایل
                        <v-icon color="secondary" small>mdi-chevron-left</v-icon>
                    </p>
                </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>

            <template v-for="item in items">
                <v-row
                        v-if="item.heading"
                        :key="item.heading"
                        align="center"
                >
                    <v-col cols="6">
                        <v-subheader v-if="item.heading">
                            {{ item.heading }}
                        </v-subheader>
                    </v-col>
                    <v-col
                            cols="6"
                            class="text-center"
                    >
                        <a
                                href="#!"
                                class="body-2 black--text"
                        >EDIT</a>
                    </v-col>
                </v-row>
                <v-list-group
                        v-else-if="item.children"
                        :key="item.text"
                        v-model="item.model"
                        :prepend-icon="item.model ? item.icon : item['icon-alt']"
                        append-icon=""
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item
                            v-for="(child, i) in item.children"
                            :key="i"
                            link
                            class="mx-3"
                            :to="{name:child.routeName}"
                    >
                        <v-list-item-action v-if="child.icon">
                            <v-icon>{{ child.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ child.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <v-list-item
                        v-else
                        :key="item.text"
                        link
                        :to="{name:item.routeName}"
                >
                    <v-list-item-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <!--<template v-for="item in items">-->
                <!--<v-row-->
                        <!--v-if="item.heading"-->
                        <!--:key="item.heading"-->
                        <!--align="center"-->
                <!--&gt;-->
                    <!--<v-col cols="6">-->
                        <!--<v-subheader v-if="item.heading">-->
                            <!--{{ item.heading }}-->
                        <!--</v-subheader>-->
                    <!--</v-col>-->
                    <!--<v-col-->
                            <!--cols="6"-->
                            <!--class="text-center"-->
                    <!--&gt;-->
                        <!--<a-->
                                <!--href="#!"-->
                                <!--class="body-2 black&#45;&#45;text"-->
                        <!--&gt;EDIT</a>-->
                    <!--</v-col>-->
                <!--</v-row>-->
                <!--<v-list-group-->
                        <!--v-else-if="item.children"-->
                        <!--:key="item.text"-->
                        <!--v-model="item.model"-->
                        <!--:prepend-icon="item.model ? item.icon : item['icon-alt']"-->
                        <!--append-icon=""-->
                <!--&gt;-->
                    <!--<template v-slot:activator>-->
                        <!--<v-list-item-content>-->
                            <!--<v-list-item-title>-->
                                <!--{{ item.text }}-->
                            <!--</v-list-item-title>-->
                        <!--</v-list-item-content>-->
                    <!--</template>-->
                    <!--<v-list-item-->
                            <!--v-for="(child, i) in item.children"-->
                            <!--:key="i"-->
                            <!--link-->
                            <!--class="mx-3"-->
                            <!--:to="{name:child.routeName}"-->
                    <!--&gt;-->
                        <!--<v-list-item-action v-if="child.icon">-->
                            <!--<v-icon>{{ child.icon }}</v-icon>-->
                        <!--</v-list-item-action>-->
                        <!--<v-list-item-content>-->
                            <!--<v-list-item-title>-->
                                <!--{{ child.text }}-->
                            <!--</v-list-item-title>-->
                        <!--</v-list-item-content>-->
                    <!--</v-list-item>-->
                <!--</v-list-group>-->
                <!--<template v-else>-->

                    <!--<v-list-item-->
                            <!--v-if="!item.isBtn"-->
                            <!--:key="item.text"-->
                            <!--link-->
                            <!--:to="{name:item.routeName}"-->
                    <!--&gt;-->
                        <!--<v-list-item-action>-->
                            <!--<v-icon>{{ item.icon }}</v-icon>-->
                        <!--</v-list-item-action>-->
                        <!--<v-list-item-content>-->
                            <!--<v-list-item-title>-->
                                <!--{{ item.text }}-->
                            <!--</v-list-item-title>-->
                        <!--</v-list-item-content>-->
                    <!--</v-list-item>-->
                    <!--<v-list-item-->
                            <!--v-else-->
                            <!--:key="item.text"-->
                            <!--@click="item.method"-->
                    <!--&gt;-->
                        <!--<v-list-item-action>-->
                            <!--<v-icon>{{ item.icon }}</v-icon>-->
                        <!--</v-list-item-action>-->
                        <!--<v-list-item-content>-->
                            <!--<v-list-item-title>-->
                                <!--{{ item.text }}-->
                            <!--</v-list-item-title>-->
                        <!--</v-list-item-content>-->
                    <!--</v-list-item>-->
                <!--</template>-->

            <!--</template>-->


        </v-list>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                items: [
                    {method: null, routeName: 'dashboard', icon: 'mdi-view-dashboard', text: 'داشبورد'},
                    {method: null, routeName: 'sessions', icon: 'mdi-monitor-dashboard', text: 'جلسات'},
                    {method: null, routeName: 'calendar', icon: 'mdi-calendar', text: 'تقویم'},
                    {method: null, routeName: 'clients', icon: 'mdi-account-group', text: 'مراجعان'},

                ]
            }
        },
        computed: {
            showNavIcon() {
                // return true;
                return this.$vuetify.breakpoint.mdAndDown;
            },
            showTopMenu() {
                return this.$vuetify.breakpoint.mdAndUp;
            }
        }
    }
</script>

<style>
    #navIconItems .v-btn__content {
        justify-content: right;
    }
</style>
<template>
    <div class="d-flex"
         :class="{ 'flex-row-reverse': !msg.is_mine,'flex-row':msg.is_mine }"
         style="max-width:100%">
        <v-card
                dense
                style="height:auto;white-space: normal;"
                class="pa-1 ma-1 elevation-0"
                max-width="80%"
        >
            <v-card-text class="pa-0 ma-0">
                <template v-if="msg.body.type.startsWith('image')">
                    <img :src="imgFile" style="width: 100%;"/>
                </template>
                <template v-else>
                    <v-chip
                            :color="msg.is_mine ? 'primary' : ''"
                            style="height:auto;white-space: normal;"
                            class="pa-4 mb-0"
                    >
                        <div class="mx-1"> {{msg.contents}}</div>
                    </v-chip>
                </template>
            </v-card-text>
            <v-divider class="my-1"></v-divider>
            <v-card-actions class="pa-0 ma-0">
                <v-progress-circular
                        v-if="result=='inProgress'"
                        :value="uploadPercentage"
                        :size="20"
                        :width="2"
                        color="blue"
                >
                    <div style="font-size: .6em;margin-top: 3px;">{{uploadPercentage}}</div>
                </v-progress-circular>

                <template v-if="result=='failed'">
                    <v-icon small class="error--text">fas fa-times</v-icon>
                </template>
                <template v-else-if="result=='done'">
                    <v-icon small class="green--text">mdi-check</v-icon>
                </template>

            </v-card-actions>

        </v-card>

    </div>
</template>
<script>

    // import BaseMessage from './BaseMessage';
    import axios from 'axios';

    export default {
        props: ['msg','mode'],
        name: 'text-message',
        data() {
            return {
                result: null,
                uploadPercentage: 0,
                imgFile: null
            }
        }
        ,
        methods: {
            createImage(file) {
                return new Promise((resolve) => {
                    let reader = new FileReader();
                    reader.onload = e => {
                        resolve(e.target.result);
                    };
                    reader.readAsDataURL(file);
                });
            },
            submitFile() {
                if (this.msg.body == '') {
                    return;
                }

                let formData = new FormData();
                formData.append('file', this.msg.body);
                formData.append('type', 'file');
                this.result = 'inProgress';

                let url = '';

                if (this.mode == 'customer') {
                    url = this.$store.getters.getBaseUrl + `/user/conversations/${this.msg.conversation.id}/messages`;
                } else if (this.mode == 'counselor') {
                    url = this.$store.getters.getBaseUrl + `/doctor/conversations/${this.msg.conversation.id}/messages`;
                } else if (this.mode == 'admin') {
                    url = this.$store.getters.getBaseUrl + `/admin/conversations/${this.msg.conversation.id}/messages`;
                }

                axios.post(url,
                    formData,
                    {
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: this.$store.getters.getAccessToken
                        },
                        onUploadProgress: (progressEvent) => {
                            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                        }
                    }
                ).then(() => {
                    this.result = 'done';
                    console.log('SUCCESS!!');
                }).catch(() => {
                    this.result = 'failed';
                    console.log('FAILURE!!');
                });
            },
        },


        mounted() {
            if (this.msg.body.type.startsWith('image')) {
                this.createImage(this.msg.body).then(resp => {
                    this.imgFile = resp;
                });
            }
            this.submitFile();
        }
    }
</script>

<style scoped>

</style>
import { render, staticRenderFns } from "./AdminInnvoice.vue?vue&type=template&id=dbbe3a22&scoped=true&"
import script from "./AdminInnvoice.vue?vue&type=script&lang=js&"
export * from "./AdminInnvoice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbbe3a22",
  null
  
)

export default component.exports
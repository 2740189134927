<template>
  <div>
    <div class="mt-5">
      <div class="d-flex">
        <v-btn class="primary" @click="changeWindow">
          <v-icon>mdi-arrow-right</v-icon>
          بازگشت
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn elevation="0" color="primary" class="ml-10" @click="doDateDialog">
          <v-icon>mdi-clock</v-icon>
        </v-btn>
      </div>
      <br>
      <br>
      <div class="task">
        <div class="taskInfo">
          <TaskDetialCRM :task-detail="taskInfo"/>
        </div>
        <template v-if="Object.keys(clientProfile).length != 0 ">
          <div class="taskAction">
            <v-card>
              <v-card-text>
                <template v-if="taskInfo.activity_list.title_en == 'check patient profile'">
                  <UserEditProfileSectionCRM :task-id="taskInfo.id" :client-profile="clientProfile"/>
                </template>
                <template v-else-if="taskInfo.activity_list.title_en == 'set session'">
                  <SetSessionCrm/>
                </template>
                <template v-else-if="taskInfo.activity_list.title_en == 'set doctor'">
                  <AssignDoctorCRM :profile="clientProfile" :task-id="taskInfo.id"/>
                </template>
                <template v-else-if="taskInfo.activity_list.title_en == 'sell progress'">
                  <OrdertrackingCRM :task="taskInfo"/>
                </template>
                <template v-else-if="taskInfo.activity_list.title_en == 'purchase follow up'">
                  <OrdertrackingCRM :task="taskInfo"/>
                </template>
              </v-card-text>
            </v-card>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "@/event-bus";
import TaskDetialCRM from "@/views/admin/crm/task/taskDetialCRM";
import AddCycleCRM from "@/views/admin/crm/task/addCycleCRM";
import UserEditProfileSectionCRM from "@/views/admin/crm/sectionManager/UserEditProfileSectionCRM";
import momentJalali from "moment-jalaali";
import AdminClientCycles from "@/views/admin/AdminClientCycles";
import OrdertrackingCRM from "@/views/admin/crm/task/OrdertrackingCRM";
import SetSessionCrm from "@/views/admin/crm/task/setSessionCrm";
import AssignDoctorCRM from "@/views/admin/crm/task/assignDoctorCRM";

export default {
  components: {
    AssignDoctorCRM,
    SetSessionCrm,
    OrdertrackingCRM,
    UserEditProfileSectionCRM, AddCycleCRM, TaskDetialCRM, AdminClientCycles
  },
  props: {
    taskInfo: Object,
    clientProfile: Object,
  },
  data() {
    return {
      tasks: [],
      profile: []
    }
  },
  methods: {
    changeWindow() {
      EventBus.$emit('returnWindow', 1)
    },
    doDateDialog(){
      EventBus.$emit('doDateDialog',true)
    }
    // getUserTask(){
    //   this.$store.dispatch('getTaskUser').then((resp)=>{
    //     this.tasks = resp
    //   })
    // }
  },
  mounted() {

  },
  filters: {
    toJalali(input) {
      return momentJalali(input, 'YYYY-MM-DD').format('jYYYY/jMM/jDD')
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <v-dialog width="500" v-model="show" persistent>
      <v-card>
        <v-card-title>
          کسر/اضافه
        </v-card-title>
        <v-card-text>
          <v-form ref="extra">
            <v-row>
              <v-col cols="8">
                <v-text-field :rules="requiredRules" v-model="extraTime" type="number" dense outlined
                              label="زمان"></v-text-field>
                <div>
                  <v-btn class="ml-2" x-small elevation="0" color="primary" v-for="(time ,i ) in extraPeriods" :key="i"
                         @click="extraTime += Number(time)">
                    {{ time }}
                    دقیقه
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="4">
                <v-select :rules="requiredRules" v-model="extraTimeType" :items="extraItems" item-value="key"
                          item-text="title" dense outlined
                          label="کسر/اضافه">

                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" elevation="0" @click="setExtraSessionTimeOnSession">
            ثبت
          </v-btn>
          <v-btn color="red" class="white--text" elevation="0" @click="closeModal">
            لغو
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {EventBus} from "@/event-bus";

export default {
  props: {
    session: Object,
    config: Object,
    show: Boolean,
  },
  data() {
    return {
      extraTime: 0,
      extraTimeType: null,
      extraPeriods: [
        1,
        5,
        10,
        15
      ],
      extraItems: [
        {
          key: '-',
          title: 'بدهکاری'
        },
        {
          key: ' ',
          title: 'بستانکاری'
        },
      ],
      modalExtraSession: false,
    }
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    usedSessionAfterAddExtraTime() {
      let options = {
        session: this.session.id,
        state: 'used',
        doctorId: this.session.doctor.id,
        datetime: null,
      };
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('adminSessionChangeState', options).then(() => {
        this.$emit('afterSet');
        this.$emit('close')
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    setExtraSessionTimeOnSession() {
      if (this.$refs.extra.validate()) {
        let payload = {
          session_id: this.session.id,
          extra_time: this.extraTimeType + this.extraTime
        }
        EventBus.$emit('setLoading', true)
        this.$store.dispatch('setExtraTimeOnSession', payload).then(() => {
          this.$emit('afterSet');
          if (this.session.state != 'used') {
            this.usedSessionAfterAddExtraTime()
          }
          this.$emit('close')
          this.extraTime = 0
          this.extraTimeType = null
          EventBus.$emit('notify', 'green', undefined, 'زمان روی جلسه ست شد');
        }).finally(() => {
          EventBus.$emit('setLoading', false)
        })
      }

    },
  },
  computed: {
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="fill-height">
    <v-card class="fill-height">
      <v-card-title>
        افزودن یادداشت
      </v-card-title>
      <v-card-text>
        <v-form ref="addForm">
          <v-textarea
              outlined
              height="300"
              label="متن یادداشت"
              v-model="note"
              :rules="requiredRules"
          >
          </v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" @click="addNote">افزودن</v-btn>
        <v-btn color="red" class="white--text" @click="close">بستن</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      note: null,
    }
  },
  methods: {
    close() {
      this.$emit('addDialogHandler', false)
    },
    addNote() {
      if (this.$refs.addForm.validate()) {
        this.$store.dispatch('addNoteClient',
            {
              "user_id": this.$route.params.clientId,
              "description": this.note
            }).then(() => {
          this.$emit('afterAddNote')
          this.close()
        })
      }

    }
  },
  computed: {
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <v-container class="py-12 mt-5">

      <AdminDatePicker
          @close="startAtDatePicker=false"
          :dialog="startAtDatePicker"
          v-model="start_at"
      ></AdminDatePicker>
      <AdminDatePicker
          @close="endAtDatePicker=false"
          :dialog="endAtDatePicker"
          v-model="end_at"
      ></AdminDatePicker>
      <!--      <v-row>-->
      <!--        <v-col cols="12">-->
      <!--          <h3>آمار مارکتینگ </h3>-->
      <!--        </v-col>-->
      <!--        <v-col>-->
      <!--          <div>-->
      <!--            <div class="">-->
      <!--              <div class="d-flex " :class="$vuetify.breakpoint.mdAndDown && 'flex-column'">-->
      <!--                <div class="dataBox filter-item" @click="openModalDatePicker('start_at')"-->
      <!--                     :class="$vuetify.breakpoint.mdAndDown && 'mb-2'">-->
      <!--                  {{ start_at | dateFilter }}-->
      <!--                </div>-->
      <!--                <div class="mx-3 d-flex justify-center flex-column" :class="$vuetify.breakpoint.mdAndDown && 'mb-2'">-->
      <!--                  تا-->
      <!--                </div>-->
      <!--                <div class="dataBox filter-item" @click="openModalDatePicker('end_at')"-->
      <!--                     :class="$vuetify.breakpoint.mdAndDown && 'mb-2'">-->
      <!--                  {{ end_at | dateFilter }}-->
      <!--                </div>-->
      <!--                <div :class="$vuetify.breakpoint.mdAndDown && 'md-2'">-->
      <!--                  <v-btn color="primary" class="mx-2" small @click="filterDates" :loading="loading"-->
      <!--                         :disabled="loading">فیلتر-->
      <!--                  </v-btn>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              <div class=" " :class="$vuetify.breakpoint.mdAndUp && 'mr-5'">-->
      <!--                <div class="d-flex" :class="$vuetify.breakpoint.mdAndDown && 'flex-column'">-->
      <!--                  <div class="d-flex timePeriodsBox py-4">-->
      <!--                    <v-btn class="ma-1 mt-3" @click="setDates(0)" small elevation="0"-->
      <!--                           :loading="loading && activeType ==0"-->
      <!--                           :disabled="loading"-->
      <!--                           :class="{'blue white&#45;&#45;text':activeType ==0 }">-->
      <!--                      امروز-->
      <!--                    </v-btn>-->
      <!--                    <v-btn class="ma-1 mt-3" @click="setDates(7)" small elevation="0"-->
      <!--                           :loading="loading && activeType ==7"-->
      <!--                           :disabled="loading"-->
      <!--                           :class="{'blue white&#45;&#45;text':activeType ==7 }">-->
      <!--                      این هفته-->
      <!--                    </v-btn>-->
      <!--                    <v-btn class="ma-1 mt-3" @click="setDates(30)" small elevation="0"-->
      <!--                           :loading="loading && activeType ==30"-->
      <!--                           :disabled="loading"-->
      <!--                           :class="{'blue white&#45;&#45;text':activeType ==30 }">-->
      <!--                      30 روز اخیر-->
      <!--                    </v-btn>-->
      <!--                    <v-btn class="ma-1 mt-3" @click="setDates(60)" small elevation="0"-->
      <!--                           :loading="loading && activeType ==60"-->
      <!--                           :disabled="loading"-->
      <!--                           :class="{'blue white&#45;&#45;text':activeType ==60 }">-->
      <!--                      60 روز اخیر-->
      <!--                    </v-btn>-->
      <!--                    <v-btn class="ma-1 mt-3" @click="setDates(90)" small elevation="0"-->
      <!--                           :loading="loading && activeType ==90"-->
      <!--                           :disabled="loading"-->
      <!--                           :class="{'blue white&#45;&#45;text':activeType ==90 }">-->
      <!--                      90 روز اخیر-->
      <!--                    </v-btn>-->
      <!--                  </div>-->
      <!--                  <div class="" :class="$vuetify.breakpoint.mdAndUp && 'd-flex mt-4'">-->
      <!--                    <v-checkbox label="حذف مشتریان جدید" v-model="noFirstPurchase" class="ma-3" dense/>-->
      <!--                    <v-checkbox label="حذفشماره های ایران" v-model="noIR" class="ma-3" dense/>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              <div class="timePeriodsBox py-4">-->
      <!--                <h3 class="mb-2 font-weight-bold">-->
      <!--                  اهداف ماهیانه-->
      <!--                </h3>-->
      <!--                <div class="d-flex " :class="$vuetify.breakpoint.mdAndUp && 'justify-right'" style="width: 100%">-->
      <!--                  <div v-for="(month , i) in months" :key="i">-->
      <!--                    <v-btn class="ma-1 mt-3" small elevation="0" :disabled="loading"-->
      <!--                           @click="getMonthStaticAndTargets(month.start,month.end,month.id,month.addYear)"-->
      <!--                           :loading="loading && selectedMonth ==month.id"-->
      <!--                           :class="{'blue white&#45;&#45;text': selectedMonth == month.id }">-->
      <!--                      {{ month.label }}-->
      <!--                    </v-btn>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </v-col>-->
      <!--      </v-row>-->
      <MarketingStatistics/>
      <div class="mt-12 pt-10">
        <v-row justify="center">
          <v-col cols="12" lg="6">
            <TestStatisticsDashboard/>
          </v-col>
        </v-row>
      </div>
      <div class="mt-10">
        <DoctorsListDashboard v-if="checkRoleAndPermissions('admin.accountmanagers.dashboard')"/>
      </div>
      <div>
<!--        <CombinedSales/>-->
      </div>
    </v-container>
  </div>
</template>

<script>
/*eslint-disable*/
import moment from "moment-jalaali";
import AdminDatePicker from "@/components/AdminDatePicker";
import TestStatisticsDashboard from "@/components/dashboards/TestStatisticsDashboard";
import DoctorsListDashboard from "@/components/Admin/DoctorsListDashboard";
import CombinedSales from "@/components/dashboards/combinedSales";
import MarketingStatistics from "@/components/dashboards/marketingStatistics";

export default {
  components: {MarketingStatistics, CombinedSales, DoctorsListDashboard, TestStatisticsDashboard, AdminDatePicker},

}
</script>

<style scoped>
/*.statisticsBoxArea {*/
/*  width: 30%;*/
/*  margin: 0px auto;*/
/*}*/

</style>
<template>
    <v-card>
        <template v-if="mode=='patient'">
            <template v-if="session.doctor != null">
                <v-card-title>
                    <div>
                        {{session.doctor.full_name}}
                        <div>

                            <template>
                                <v-chip small class="info">
                                    {{session.diff_time}}
                                </v-chip>
                            </template>
                            <!--<template v-else-if="session.state == 'used'">-->
                            <!--<v-chip small class="success">-->
                            <!--برگزار شده-->
                            <!--</v-chip>-->
                            <!--</template>-->
                        </div>
                    </div>

                    <v-spacer></v-spacer>
                    <v-avatar size="70" class="elevation-1">
                        <img :src="session.doctor.avatar" alt="">
                    </v-avatar>
                </v-card-title>
                <v-card-title class="ma-0 pt-0">
                    <v-btn @click="showQNA" small class="blue white--text" text>پرسشنامه</v-btn>
                </v-card-title>

            </template>
            <v-card-title v-else>
                <v-chip class="error" label>مشاور ثبت نشده</v-chip>
                <v-spacer></v-spacer>
                <v-avatar size="70" class="elevation-1">
                    <img :src="require('./../../assets/avatar-default.png')" alt="">
                </v-avatar>
            </v-card-title>
        </template>
        <template v-else>
            <template>
                <v-card-title>
                    <div>
                        {{session.user.full_name}}
                        <div>

                            <template>
                                <v-chip small class="info">
                                    {{session.diff_time}}
                                </v-chip>
                            </template>
                            <!--<template v-else-if="session.state == 'used'">-->
                            <!--<v-chip small class="success">-->
                            <!--برگزار شده-->
                            <!--</v-chip>-->
                            <!--</template>-->


                        </div>
                    </div>
                    <v-spacer></v-spacer>
                    <v-avatar size="70" class="elevation-1">
                        <img :src="require('./../../assets/avatar-default.png')" alt="">
                    </v-avatar>
                </v-card-title>
                <div class="mx-2">

                </div>
            </template>

        </template>


        <v-card-text>
            <div v-if="mode=='patient'">
                دوره درمان:{{session.cycle.heading}}
            </div>
            <template v-else>
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            دوره درمان:{{session.cycle.heading}}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            {{session.cycle.problem_description}}
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </template>
        </v-card-text>
        <v-card-text v-if="session.state=='doctor_assigned' && selectTime">
            <SessionSetTime @updateSessions="updateSessions" :session="session"></SessionSetTime>
        </v-card-text>
        <v-card-actions>
            <v-row dense>
                <v-col cols="6" class="text-center">
                    <v-chip class="info">
                        <span v-if="session.fixed_date != null">
                        {{session.fixed_date}}
                        {{session.fixed_time}}

                        </span>
                        <span v-else>زمان نامشخص</span>


                    </v-chip>
                    <template v-if="session.timezone!=null">
                        <div class="mt-1 subtitle-2">
                            <span>
                                به وقت {{session.timezone}}
                            </span>
                        </div>
                    </template>
                </v-col>
                <v-col cols="6" class="text-center">
                    <v-chip block text :class="sessionStateClass"> {{session.state | resolveSessionState}}
                    </v-chip>
                </v-col>
            </v-row>
        </v-card-actions>
        <v-card-actions>

            <v-row dense justify="center" align="center">
                <v-col cols="6">
                    <v-btn block class="warning" @click="joinSession" :disabled="!session.can_enter" :loading="loading">
                        ورود
                    </v-btn>
                </v-col>
                <v-col cols="6" v-if="factor">
                    <v-btn block class="warning" @click="getFactor(session)" :disabled="session.state != 'used'">
                        فاکتور
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
    import {EventBus} from "../../event-bus";
    import SessionSetTime from "@/components/sessions/SessionSetTime";

    export default {
        props: {
            session: {},
            factor: {
                default: true
            },
            timezone: {
                default: null
            },
            mode: {
                default: 'patient'
            },
            selectTime: {
                default: true
            }

        },
        components: {SessionSetTime},
        data() {
            return {
                sessionAppLink: null,
                loading: false
            }
        },
        computed: {
            sessionStateClass() {
                let val = '';
                if (this.session.state == 'session_requested') {
                    val = 'disabled'
                } else if (this.session.state == 'doctor_assigned') {
                    val = ''
                } else if (this.session.state == 'used') {
                    val = 'success'
                } else if (this.session.state == 'date_fixed') {
                    val = 'info'
                }
                return val;

            }
        },
        methods: {
            showQNA() {
                window.cl3CQobtr('porsline-popup-iframe');
                window.showPopUp();
            },
            updateSessions() {
                this.$emit('updateSessions');
            },
            getTimes() {
                this.requesting = true;
                this.$store.dispatch('getSessionRequestTimes', {id: this.currentRequestSession.id}).then((resp) => {
                    this.doctorTimes = resp.data.doctorTimes;
                    this.requesting = false;
                }).finally(() => {
                    this.requesting = false;
                })
            },
            joinSession() {
                if (this.session.link == null) {
                    this.loading = true;
                    if (this.mode == 'patient') {
                        this.$store.dispatch('clientJoinSession', this.session.id).then((resp) => {
                            this.sessionAppLink = resp.data.URL;
                            window.location = resp.data.URL;
                        }).finally(() => {
                            this.loading = false;
                        })
                    } else {
                        this.$store.dispatch('doctorJoinSession', this.session.id).then((resp) => {
                            this.sessionAppLink = resp.data.URL;
                            window.location = resp.data.URL;
                        }).finally(() => {
                            this.loading = false;
                        })
                    }

                } else {
                    this.sessionAppLink = this.session.link;
                    window.location = this.session.link;
                }
            },
            getFactor(item) {
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('getFactor', item.id).finally(() => {
                    EventBus.$emit('setLoading', false);
                })
            },
            startSession(session) {
                this.$router.push({name: 'startsession', params: {sessionId: session.id}})
            }
        },
        filters: {
            resolveSessionState(input) {
                switch (input) {
                    case 'session_requested':
                        return 'در انتظار بررسی';
                    case 'doctor_assigned':
                    case 'date_fixed':
                        return 'در انتظار برگزاری';
                    case 'used':
                        return 'برگزار شده';
                }
            }
        }
    }
</script>

<style scoped>

</style>
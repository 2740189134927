<template>
    <v-card>
        <v-tabs
                v-model="tab"
                centered
        >
            <v-tab
                    :href="`#tab-1`"
            >
                جستجوی کاربر
            </v-tab>
            <v-tab
                    :href="`#tab-2`"
            >
                ساختن کاربر جدید
            </v-tab>

        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item
                    :value="`tab-1`"
            >
                <v-card flat>
                    <v-card-text>
                        <v-autocomplete
                                v-model="user_id"
                                :items="clients"
                                :loading="loadingClients"
                                hide-no-data
                                hide-selected
                                item-text="full_name"
                                item-value="id"
                                outlined
                                label="کاربر"
                                :rules="requiredRules"
                                :filter="filterClients"
                        >
                            <template v-slot:item="{item}">
                                <v-row dense>
                                    <v-col>
                                        {{item.full_name}}
                                    </v-col>
                                    <v-col>
                                        {{item.email}}
                                    </v-col>

                                </v-row>
                            </template>
                        </v-autocomplete>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="primary"
                                @click="selectUser"
                        >
                            ادامه
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-tab-item>

            <v-tab-item
                    :value="`tab-2`"
            >
                <v-card flat>
                    <v-card-text>
                        <v-alert type="info">
                            لطفا کاربراطلاعات زیر را برای تشکیل حساب کاربری وارد نمایید.
                        </v-alert>
                        <v-form ref="profileForm">
                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="profileInfo.name" outlined
                                                  label="نام"
                                                  :rules="requiredRules"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="profileInfo.family" outlined
                                                  :rules="requiredRules"
                                                  label="نام خانوادگی"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="profileInfo.mobile" outlined
                                                  :rules="requiredRules"
                                                  label="موبایل"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="profileInfo.email" outlined
                                                  :rules="requiredRules"
                                                  label="ایمیل"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="6" md="4">
                                    <v-autocomplete
                                            menu-props="auto"
                                            :label="$vuetify.lang.t('$vuetify.profileTZ')"
                                            v-model="profileInfo.timezone"
                                            :items="timezones"
                                            item-value="value"
                                            hide-details
                                            prepend-icon="fas fa-clock"
                                            single-line
                                            outlined
                                            :rules="requiredRules"

                                    >
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="profileInfo.password" outlined
                                                  :rules="passwordRules"
                                                  label="کلمه عبور"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="4">
                                    <v-text-field v-model="profileInfo.password_confirmation" outlined
                                                  :rules="passwordConfirmRules"
                                                  label="تکرار کلمه عبور"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="primary"
                                @click="saveProfile"
                        >
                            ثبت و ادامه
                        </v-btn>
                    </v-card-actions>
                    <v-card-text>
                        <AlertsComponent></AlertsComponent>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </v-card>

</template>

<script>
    import timezones from "@/data/timezones";
    import moment from 'moment-timezone';
    import {EventBus} from "../../../../event-bus";
    import AlertsComponent from "@/components/AlertsComponent";


    export default {

        props: {
            clients: {
                default: function () {
                    return [];
                }
            },
            loadingClients: {
                default: false
            }
        },
        components: {AlertsComponent},
        created() {
            this.sortAndGenerateTimezoneOffset();
            // this.getClients();

        },
        computed: {
            requiredRules() {
                return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
            },
            passwordRules() {
                return [
                    v => !!v || this.$vuetify.lang.t('$vuetify.passwordErrRequired'),
                    v => v.length >= 8 || 'حداقل طول کلمه عبور 8 کاراکتر می باشد'
                ];
            },
            passwordConfirmRules() {
                return [
                    v => !!v || 'پر کردن فیلد تکرار پسورد الزامیست',
                    v => (v === this.profileInfo.password) || 'کلمه عبور و تکرار آن یکسان نمی باشند'
                ];
            },
        },
        methods: {
            fillDoctors() {
                if (!this.allDoctors.length) {
                    this.$store.dispatch("getAllDoctors");
                }
            },
            filterClients(item, queryText, itemText) {

                if (item == null) {
                    return false;
                }

                if (item.header) {
                    return false;
                }

                const hasValue = val => val != null ? val : '';

                const text = hasValue(itemText);
                const code = hasValue(item.email);
                const query = hasValue(queryText);

                return text.toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1 || code.indexOf(query.toString().toLowerCase()) > -1;
            },
            selectUser() {
                if (this.user_id == null) {
                    EventBus.$emit('notify', 'red', undefined, 'ابتدا کاربر را انتخاب نمایید');
                    return;
                }

                this.$emit('userSelected', this.user_id)

            },
            // getClients() {
            //     this.clientsLoading = true;
            //     this.$store.dispatch('getAllPatients').then((resp) => {
            //         this.clients = resp.data.clients;
            //     }).finally(() => {
            //         this.clientsLoading = false;
            //     })
            // },
            saveProfile() {
                if (this.$refs.profileForm.validate()) {
                    this.$store.dispatch('addNewUser', this.profileInfo).then((resp) => {
                        EventBus.$emit('notify', 'green', undefined, 'با موفقیت ثبت شد');
                        this.user_id = resp.data.profile.id;
                        this.$emit('userSelected', this.user_id);
                        this.$emit('newUser');
                    })
                }
            },
            sortAndGenerateTimezoneOffset() {
                let x = JSON.parse(JSON.stringify(timezones));
                let tempTime = x.map((item) => {
                    let tznum = moment.tz(item.text).format('Z');

                    item.offset = moment.tz(item.text).utcOffset();
                    item.text = item.text + ' ' + tznum;
                    return item;
                });
                tempTime = tempTime.sort((a, b) => {
                    return a.offset - b.offset;
                });
                this.timezones = tempTime;
            },
        },
        data() {
            return {
                tab: 'tab-1',
                user_id: null,
                // clients: [],
                // clientsLoading: false,
                timezones: timezones,
                profileInfo: {
                    name: '',
                    family: '',
                    mobile: '',
                    email: '',
                    timezone: '',
                    password: '',
                    password_confirmation: '',
                }
            }
        },
    }
</script>

<style scoped>

</style>
<template>


  <v-container>
    <v-form ref="profileForm">
      <v-row>
        <v-col cols="12">
          <v-card-text class="pa-0 font-weight-bold">
            {{ $vuetify.lang.t('$vuetify.profileInformationTitle') }}
          </v-card-text>
        </v-col>
      </v-row>
      <v-row class="px-md-10">
        <v-col cols="12" sm="6" md="4">
          <v-text-field
              v-model="userInfo.firstname"
              outlined
              :label="$vuetify.lang.t('$vuetify.profileFirstname')"
              :rules="requiredRules"

          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
              v-model="userInfo.lastname"
              outlined
              :label="$vuetify.lang.t('$vuetify.profileLastname')"
              :rules="requiredRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
              v-model="userInfo.en_name"
              outlined
              label="نام انگلیسی"

          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
              v-model="userInfo.en_family"
              outlined
              label="نام خانوادگی انگلیسی"

          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-select
              :items="currencies"
              item-text="title"
              item-value="id"
              v-model="userInfo.insurance_currency_id"
              outlined
              label="ارز کاربر"
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-select
              v-model="userInfo.married"
              :items="married"
              item-text="title"
              item-value="value"
              :label="$vuetify.lang.t('$vuetify.profileCivilStatus')"
              outlined
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="userInfo.birthDate"
              persistent
              width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  outlined
                  v-model="formatedBD"
                  :value="userInfo.birthDate"
                  :label="$vuetify.lang.t('$vuetify.profileBirthDate')"
                  prepend-icon="event"
                  readonly
                  v-on="on"

              ></v-text-field>
            </template>
            <v-date-picker
                v-model="userInfo.birthDate"
                :max="new Date().toISOString().substr(0, 10)"
                scrollable locale="fa"
                first-day-of-week="6">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(userInfo.birthDate)">OK</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
              :readonly="!checkRoleAndPermissions('admin.modify.user.email')"
              v-model="userInfo.email"
              outlined
              :label="$vuetify.lang.t('$vuetify.profileEmail')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
              v-model="userInfo.cel"
              outlined
              :label="$vuetify.lang.t('$vuetify.profileMobile')"
              :rules="requiredRules"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-select
              :items="education"
              item-text="title"
              item-value="value"
              v-model="userInfo.edu"
              outlined
              :label="$vuetify.lang.t('$vuetify.profileEdu')"

          ></v-select>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
              v-model="userInfo.job"
              outlined
              :label="$vuetify.lang.t('$vuetify.profileJob')"
          ></v-text-field>
        </v-col>
        <!--<template v-if="showPasswordChange">-->


        <!--<v-col cols="12" class="pa-0 ma-0"></v-col>-->
        <!--<v-col cols="12" sm="6" md="4">-->
        <!--<v-text-field-->
        <!--v-model="userInfo.password"-->
        <!--outlined-->
        <!--:label="$vuetify.lang.t('$vuetify.passwordLabel')"-->
        <!--&gt;</v-text-field>-->
        <!--</v-col>-->
        <!--<v-col cols="12" sm="6" md="4">-->
        <!--<v-text-field-->
        <!--v-model="confirmPassword"-->
        <!--outlined-->
        <!--:label="$vuetify.lang.t('$vuetify.passwordConfirmationLabel')"-->
        <!--&gt;</v-text-field>-->
        <!--</v-col>-->
        <!--</template>-->
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card-text class="pa-0 font-weight-bold">
            {{ $vuetify.lang.t('$vuetify.profileLocationTitle') }}
          </v-card-text>
        </v-col>
      </v-row>

      <v-row class="px-md-10">
        <v-col cols="12" sm="6" md="4">
          <v-autocomplete
              v-model="userInfo.country"
              :items="countries"
              item-text="name"
              item-value="id"
              menu-props="auto"
              :label="$vuetify.lang.t('$vuetify.profileCountry')"
              hide-details
              prepend-icon="fas fa-flag"
              single-line
              outlined

          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
              prepend-icon="fas fa-building"
              v-model="userInfo.city"
              single-line
              outlined
              :label="$vuetify.lang.t('$vuetify.profileCity')"

          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card-text class="pa-0 font-weight-bold">
            {{ $vuetify.lang.t('$vuetify.profileTimeTitle') }}
          </v-card-text>
        </v-col>
      </v-row>
      <v-row class="px-md-10">
        <v-col cols="12" sm="6" md="4">
          <v-autocomplete
              menu-props="auto"
              :label="$vuetify.lang.t('$vuetify.profileTZ')"
              v-model="userInfo.tz"
              :items="timezones"
              item-value="value"
              hide-details
              prepend-icon="fas fa-clock"
              single-line
              outlined
              :rules="requiredRules"
              @focus="alertChangetz"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-divider class="my-10"></v-divider>
      <v-row class="px-md-10">
        <v-col cols="12" sm="6" md="4">
          <v-select
              :items="knway"
              item-text="title"
              item-value="value"
              v-model="userInfo.knway"
              outlined
              :label="$vuetify.lang.t('$vuetify.profileKnway')"

          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="6" md="8" v-if="userInfo.knway == 'other'">
          <v-text-field
              v-model="userInfo.knway_meta"
              outlined
              :label="$vuetify.lang.t('$vuetify.profileKnwayOther')"

          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
/*eslint-disable*/
import {EventBus} from '../../../event-bus.js';
// import countries from "../../../data/countries";
import timezones from "../../../data/timezones";
// import moment from 'moment-jalaali';
import moment from 'moment-timezone';

export default {
  beforeDestroy() {
    EventBus.$off('saveProfileEvent', this.saveProfile);
    // EventBus.$off('resetProfileEvent', this.resetProfile);
    EventBus.$off('validateEvent', this.validate);
  },
  created() {

    let x = JSON.parse(JSON.stringify(timezones));
    this.timezones = x.map((item) => {
      item.text = item.text + ' ' + moment.tz(item.text).format('Z');
      return item;
    });

    this.$store.dispatch('getCountries').then((resp) => {
      this.countries = resp.data.countries;
    });

    this.getUserInfo();
    // console.log('IT IS CREATED', this.$router.currentRoute.name);
    EventBus.$on('saveProfileEvent', this.saveProfile);
    // EventBus.$on('resetProfileEvent', this.resetProfile);
    EventBus.$on('validateEvent', this.validate);

    setTimeout(() => {
      console.log('BD', new Date(this.userInfo.birthDate).toLocaleDateString('fa-IR'));
    }, 1000)
  },
  data() {
    return {
      currencies: [
        {'id': 1, 'title': 'دلار', code: 'dollar'},
        {'id': 3, 'title': 'یورو', code: 'euro'},
        {'id': 4, 'title': 'دلار کانادا', code: 'cad'},
        {'id': 5, 'title': 'پوند', code: 'gbp'},
        {'id': 6, 'title': 'دلار استرالیا', code: 'aud'},
        {'id': 7, 'title': 'لیر ترکیه', code: 'try'},
      ],
      confirmPassword: null,
      formData: null,
      avatar: '',
      avatarFile: null,
      genders: ['Male', 'Female'],
      gender: '',
      // countries,
      countries: [],
      states: ['Tehran', 'Qazvin', 'Ahvaz'],
      timezones: timezones,
      city: '',
      married: [
        {value: "single", title: 'مجرد'},
        {value: "married", title: 'متاهل'},
        {value: "in_relationship", title: 'رابطه عاطفی'},
        {value: "divorced", title: 'طلاق'},
        {value: "widowed", title: 'بیوه'},
        {value: "complicated", title: 'سایر'},
      ],
      education: [
        {value: "nothing", title: 'فاقد مدرک'},
        {value: "diploma", title: 'دیپلم'},
        {value: "associate", title: 'فوق دیپلم'},
        {value: "bachelor", title: 'لیسانس'},
        {value: "master", title: 'فوق لیسانس'},
        {value: "doctorate", title: 'دکتری'}
      ],
      knway: [
        {value: "google", title: "جست و جو در  گوگل"},
        {value: "twitter", title: "توییتر"},
        {value: "instagram", title: "اینستاگرام"},
        {value: "podcast", title: "پادکست"},
        {value: "bannerads", title: "تبلیغات بنری"},
        {value: "friends", title: "معرفی دوستان و آشنایان"},
        {value: "site", title: "سایت"},
        {value: "other", title: "موارد دیگر"},
      ],
      userInfo: {},
      locale: this.$store.getters.getLocal,
      modal: false,
    };
  },
  methods: {
    getUserInfo() {
      if (this.$router.currentRoute.name == 'profile') {
        this.$store.dispatch('getUserInfo').then(() => {
          this.userInfo = this.$store.getters.getUserInfo;
          if (this.userInfo.requiredFields.includes('timezone')) {
            this.userInfo.tz = null;
          }
          // this.userInfo.birthDate = moment(this.userInfo.birthDate, 'jYYYY-jMM-jDD').format('YYYY-MM-DD');
          if (this.userInfo.birthDate != null) {
            this.userInfo.birthDate = moment(this.userInfo.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
          } else {
            this.userInfo.birthDate = moment().format('YYYY-MM-DD');
          }

        })
      } else if (this.$router.currentRoute.name == 'dashboard') {
        this.$store.dispatch('getUserInfo').then(() => {
          this.userInfo = this.$store.getters.getUserInfo;
          if (this.userInfo.requiredFields.includes('timezone')) {
            this.userInfo.tz = null;
          }
          if (this.userInfo.birthDate != null) {
            this.userInfo.birthDate = moment(this.userInfo.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
          } else {
            this.userInfo.birthDate = moment().format('YYYY-MM-DD');
          }
        })
      } else if (this.$router.currentRoute.name == 'clientDetail') {

        this.$store.dispatch('getUserProfile', {
          userid: this.$router.currentRoute.params.clientId
        }).then((data) => {
          EventBus.$emit('clientProfileUpdated');
          this.userInfo = data.data.client;
          if (this.userInfo.birthDate == null) {
            this.userInfo.birthDate = null;
          } else {
            this.userInfo.birthDate = moment(this.userInfo.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
          }
        })
      } else if (this.$router.currentRoute.name == 'CrmUserInfo') {
        EventBus.$emit('setLoading', true);
        this.$store.dispatch('getUserProfile', {
          userid: this.$router.currentRoute.params.clientId
        }).then((data) => {
          EventBus.$emit('clientProfileUpdated');
          this.userInfo = data.data.client;
          if (this.userInfo.birthDate == null) {
            this.userInfo.birthDate = null;
          } else {
            this.userInfo.birthDate = moment(this.userInfo.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
          }
        }).finally(() => {
          EventBus.$emit('setLoading', false);
        })
      }
      if (this.$router.currentRoute.name == 'addCounselors') {
        this.userInfo = {}
      }

    },
    saveProfile() {
      if (this.$refs.profileForm.validate()) {
        if (this.$router.currentRoute.name == 'profile' || this.$router.currentRoute.name == 'dashboard') {
          let data = {
            // birth_date: moment(this.userInfo.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            birth_date: this.userInfo.birthDate,
            name: this.userInfo.firstname,
            family: this.userInfo.lastname,
            en_name: this.userInfo.en_name,
            en_family: this.userInfo.en_family,
            mobile: this.userInfo.cel,
            timezone: this.userInfo.tz,
            "familiar_by": this.userInfo.knway,
            "familiar_by_meta": this.userInfo.knway_meta,
            "city": this.userInfo.city,
            "country_id": this.userInfo.country,
            "marital_state": this.userInfo.married,
            "education_level": this.userInfo.edu,
            "job": this.userInfo.job,
          };


          if ((typeof this.userInfo.country) == "object") {
            console.log('it is an object');
            if (this.userInfo.country.hasOwnProperty('id')) {
              console.log('has id');
              data.country_id = this.userInfo.country.id;
            } else {
              data.country_id = this.userInfo.country;
            }
          }

          // this.userInfo.birthDate = moment(this.userInfo.birthDate, 'YYYY-MM-DD').format('jYYYY-jMM-jDD');

          this.$store.dispatch('saveUserInfo', data).then(() => {
            this.snackbar = true;

            this.$store.dispatch('getUserInfo').then(() => {
              EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
              EventBus.$emit('clientProfileSaved');
            });

          }).catch(() => {
            EventBus.$emit('clientProfileSaveError');
          }).finally(() => {
            EventBus.$emit('clientProfileSaveError');

          });
          // this.snackbar = true;
        } else if (this.$router.currentRoute.name == 'clientDetail') {
          console.log('admin is saving', this.userInfo);
          let data = {
            // birth_date: moment(this.userInfo.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            birth_date: this.userInfo.birthDate,
            name: this.userInfo.firstname,
            en_name: this.userInfo.en_name,
            en_family: this.userInfo.en_family,
            family: this.userInfo.lastname,
            mobile: this.userInfo.cel,
            timezone: this.userInfo.tz,
            "familiar_by": this.userInfo.knway,
            "familiar_by_meta": this.userInfo.knway_meta,
            "city": this.userInfo.city,
            "email": this.userInfo.email,
            // "country_id": this.userInfo.country,
            "marital_state": this.userInfo.married,
            "education_level": this.userInfo.edu,
            "job": this.userInfo.job,
            insurance_currency_id: this.userInfo.insurance_currency_id
          };

          // alert(this.userInfo.country.hasOwnProperty('id'));
          if (this.userInfo.country != null) {
            if (this.userInfo.country.hasOwnProperty('id')) {
              // console.log('has id');

              data.country_id = this.userInfo.country.id;
            } else {

              data.country_id = this.userInfo.country;
            }
          }

          // if (this.userInfo.password != "" && this.showPasswordChange) {
          //     data.password = this.userInfo.password;
          //     data.password_confirmation = this.confirmPassword;
          // }


            this.$store.dispatch('saveUserProfile', {
              userId: this.$router.currentRoute.params.clientId,
              data
            }).then(() => {
            // this.snackbar = true;
            EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
            EventBus.$emit('clientProfileSaved');
          }).catch((err) => {
            console.log('err', err);
            console.log('an error is here');
            EventBus.$emit('clientProfileSaveError');
          }).finally(() => {
            EventBus.$emit('clientProfileSaveError');
          })
        }
      } else {
        EventBus.$emit('clientProfileSaveError');
      }
    },
    validate() {
      this.$refs.profileForm.validate();
    },
    resetProfile() {
      console.log('Reseting');
      this.userInfo = {};
    },
    alertChangetz() {
      if (this.userInfo.tz) this.$store.commit('setSnackbar', {show: true, type: 'alertChangeTZ'});
    }
  },
  watch: {
    '$route': function () {
      this.getUserInfo();
    }
  },
  computed: {
    showPasswordChange() {
      return this.$router.currentRoute.name == 'clientDetail' ? true : false;
    },
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
    isAdmin() {
      return this.$router.currentRoute.name == 'clientDetail' ? true : false;
    },
    formatedBD() {
      if (this.userInfo.birthDate != null) {
        if (this.$store.getters.getLocal === 'fa') {
          return new Date(this.userInfo.birthDate).toLocaleDateString('fa-IR');
        } else {
          return this.userInfo.birthDate;
        }
      } else {
        return null;
      }

    }
  }
}
</script>

<style scoped>

</style>
import constants from './constants/constants'

let adminTicketFilters = {
    stateFilters: {
        title: 'وضعیت',
        key: 'state',
        type: 'singleselect',
        options: [
            {title: 'رسیدگی نشده', value: 'pending'},
            {title: 'دیده شده', value: 'seen'},
            {title: 'رسیدگی شده', value: 'resolved'},
        ]
    },
    typeFilters: {
        title: 'نوع',
        key: 'type',
        type: 'singleselect',
        options: [
            {title: 'درخواست', value: ''},
        ]
    },
}

let couponFilters = {
    expiredFilter: {
        title: 'انقضا',
        key: 'expired',
        type: 'singleselect',
        default: true,
        options: [
            {title: 'منقضی نشده', value: "notExpired", default: true},
            {title: 'منقضی شده', value: "expired"}
        ]
    },
    stateFilter: {
        title: 'وضعیت',
        key: 'state',
        type: 'singleselect',
        default: true,
        options: [
            {title: 'فعال', value: 'available', default: true},
            {title: 'غیر فعال', value: 'used'}
        ]
    },
    amountFilter: {
        title: 'مبلغ',
        key: 'amount',
        type: 'text',
        options: ['']
    }
};


let packageFilters = {
    quantityFilter: {
        title: 'تعداد جلسه',
        key: 'session_count',
        type: 'text',
        options: ['']
    },
    titleFilter: {
        title: 'عنوان',
        key: 'title',
        type: 'text',
        options: ['']
    },
    currencyFilter: {
        title: 'ارز',
        key: 'payment_unit',
        type: 'singleselect',
        options: [
            {title: 'تومان', value: 'rial'},
            {title: 'دلار', value: 'dollar'},
        ]
    },
    stateFilter: {
        title: 'وضعیت',
        key: 'state',
        type: 'singleselect',
        default: true,
        options: [
            {title: 'فعال', value: 'active', default: true},
            {title: 'غیر فعال', value: 'inactive'}
        ]
    },
    amountFilter: {
        title: 'مبلغ',
        key: 'amount',
        type: 'text',
        options: ['']
    }
};


let financialFilters = {

    stateFilterClient: {
        title: 'وضعیت',
        key: 'state',
        type: 'singleselect',
        default: true,
        options: [
            {title: 'نامشخص', value: 'unknown'},
            {title: 'پرداخت شده', value: 'paid'},
            {title: 'شکست خورده', value: 'failed'},
            {title: 'ریفاند', value: 'refund'},
        ]
    },
    stateFiltersRecord: {
        title: 'وضعیت',
        key: 'state',
        type: 'singleselect',
        default: true,
        options: [
            {title: 'نامشخص', value: 'unknown'},
            {title: 'پرداخت شده', value: 'paid', default: true},
            {title: 'شکست خورده', value: 'failed'},
            {title: 'ریفاند', value: 'refund'},
        ]
    },
    cartStateFilters: {
        title: 'وضعیت',
        key: 'state',
        type: 'multiselect',
        default: true,
        options: [
            {title: 'پرداخت شده', value: 'paid', default: true},
            {title: 'پیش پرداخت', value: 'partially_paid', default: true},
            // {title: 'پیش فاکتور', value: 'submit'},
        ]
    },
    currencyFilter: {
        title: 'ارز',
        key: 'currency',
        type: 'singleselect',
        options: [
            {title: 'تومان', value: 'rial'},
            {title: 'دلار', value: 'dollar'},
            {title: 'یورو', value: 'euro'},
            {title: 'دلار استرالیا', value: 'aud'},
            {title: 'دلار کانادا', value: 'cad'},
            {title: 'پوند', value: 'gbp'},
        ]
    },

    referenceFilters: {
        title: 'شماره پیگیری',
        key: 'ref_id',
        type: 'text',
        options: ['']
    },
    cartPaymentSortFilters: {
        title: 'مرتب سازی بر اساس',
        key: 'orderBy',
        type: 'singleselect',
        default: true,
        options: [
            {title: 'تاریخ پرداخت صعودی', value: 'asc'},
            {title: 'تاریخ پرداخت نزولی', value: 'desc', default: true}
        ]
    },
    paymentSortFilters: {
        title: 'مرتب سازی بر اساس',
        key: 'orderBy',
        type: 'singleselect',
        default: true,
        options: [
            {title: 'مبلغ صعودی', value: 'amount:asc'},
            {title: 'مبلغ نزولی', value: 'amount:desc',},
            {title: 'تاریخ پرداخت صعودی', value: 'payment_date:asc'},
            {title: 'تاریخ پرداخت نزولی', value: 'payment_date:desc', default: true}
        ]
    },

    cartSortFilters: {
        title: 'مرتب سازی بر اساس',
        key: 'orderBy',
        type: 'singleselect',
        default: true,
        options: [
            {title: 'مبلغ صعودی', value: 'amount:asc'},
            {title: 'مبلغ نزولی', value: 'amount:desc',},
            {title: 'تاریخ پرداخت صعودی', value: 'date:asc'},
            {title: 'تاریخ پرداخت نزولی', value: 'date:desc', default: true}
        ]
    },
    firstBuy: {
        title: 'نوبت خرید',
        key: 'is_first_purchase',
        type: 'singleselect',
        default: true,
        options: [
            {title: 'فقط خرید‌اول', value: true},
            {title: 'بجز خرید اول', value: false,},
        ]
    },
};

// 'order' ,
// 'from_date' ,
// 'to_date' ,
// 'from_amount' ,
// 'to_amount' ,
// 'currency' ,


let filters = {
    general: {
        whatsapp: {
            title: 'پیام‌های واتساپ',
            key: 'has_whatsapp',
            type: 'singleselect',
            expandable: false,
            options: [
                {value: "no_whatsapp", title: "بدون ارسال یادآوری"},
                {value: "with_whatsapp", title: "با ارسال یادآوری ",},
            ]
        },
        reviewed_at: {
            title: 'بازبینی شده',
            key: 'reviewed_at',
            type: 'singleselect',
            expandable: false,
            default: true,
            options: [
                {value: "all", title: "همه"},
                {value: "reviewed", title: "بازبینی شده ",},
                {value: "not_reviewed", title: "بازبینی نشده ", default: true},
            ]
        },
        clientId: {
            title: 'کد کاربری',
            key: 'user_id',
            type: 'text',
            expandable: false,
            options: ['']
        },
        indebtUser: {
            title: 'کاربر بدهکار',
            key: 'in_debt',
            type: 'singleselect',
            expandable: false,
            default: true,
            options: [
                {value: "1", title: "بدهکار "},
                {value: "0", title: "همه", default: true},

            ]
        },
        patientNameFilter: {
            title: 'نام مراجع',
            key: 'patient_name',
            type: 'text',
            expandable: false,
            options: ['']
        },
        patientMobileFilter: {
            title: 'تلفن همراه',
            key: 'mobile',
            type: 'text',
            ltr: true,
            expandable: false,
            options: ['']
        },
        patientEmailFilter: {
            title: 'ایمیل',
            key: 'email',
            type: 'text',
            ltr: true,
            expandable: false,
            options: ['']
        },
        patientFamiliarityFilter: {
            title: 'آشنایی',
            key: 'familiar_by',
            type: 'singleselect',
            default: false,
            options: [
                {value: "google", title: "جست و جو در  گوگل"},
                {value: "twitter", title: "توییتر"},
                {value: "instagram", title: "اینستاگرام"},
                {value: "podcast", title: "پادکست"},
                {value: "bannerads", title: "تبلیغات بنری"},
                {value: "friends", title: "معرفی دوستان و آشنایان"},
                {value: "site", title: "سایت"},
                {value: "other", title: "موارد دیگر"},
            ]
        },
        // patientPaymentExistence: {
        //     title: 'وضعیت خرید',
        //     key: 'patient_payment_existence',
        //     type: 'singleselect',
        //     default: false,
        //     options: [
        //         {title: 'بدون خرید', value: 'no_payments'},
        //         {title: 'با خرید', value: 'has_payments'},
        //     ]
        // },
        patientOriginType: {
            title: 'نوع مشتری',
            key: 'user_origin_type',
            type: 'singleselect',
            default: false,
            options: [
                {title: 'غیر ریالی', value: 'dollar'},
                {title: 'ریالی', value: 'rial'},
            ]
        },
        patientPaymentType: {
            title: 'نوع پرداخت',
            key: 'patient_payment_type',
            type: 'singleselect',
            default: false,
            options: [
                // {title: 'دلاری', value: 'dollar'},
                // {title: 'ریالی', value: 'rial'},
                {title: 'تومان', value: 'rial'},
                {title: 'دلار', value: 'dollar',},
                {title: 'یورو', value: 'euro',},
                {title: 'پوند', value: 'gbp',},
                {title: 'دلار کانادا', value: 'cad',},
                {title: 'دلار استرالیا', value: 'aud',},
                {title: 'دلاری و ریالی', value: 'both_currencies'},
                {title: 'با پرداخت', value: 'has_payment'},
                {title: 'بدون پرداخت', value: 'no_payment'},
                {title: 'بدون پرداخت ریالی', value: 'no_rial'},
            ]
        },
        patientDoctorType: {
            title: 'وضعیت مشاور',
            key: 'doctor_existence',
            type: 'singleselect',
            default: false,
            options: [
                {title: 'با مشاور', value: 'has_doctors'},
                {title: 'بدون مشاور', value: 'no_doctors',},
            ]
        },

        counselorFilters: {
            title: 'نام مشاور',
            key: 'doctor_name',
            type: 'text',
            options: ['']
        },

        counselorIdFilters: {
            title: 'مشاور',
            key: 'doctor_ids',
            type: 'multiselect',
            options: []
        },
        marketingCounselorIdFilters: {
            title: 'حذف مشاور',
            key: 'doctor_ids',
            type: 'multiselect',
            default: true,
            options: []
        },

        marketingAddCounselorIdFilters: {
            title: 'مشاور',
            key: 'filter_doctor_ids',
            type: 'multiselect',
            default: true,
            options: []
        },
        marketingChurnNoFurtherPayment: {
            title: 'وضعیت خرید',
            key: 'no_further_payments',
            type: 'singleselect',
            default: true,
            options: [
                {title: 'بدون خرید تا 1 ماه بعد', value: false, default: true},
                {title: 'بدون خرید تا امروز', value: true},
            ]
        },
        marketingPeriodFilter: {
            title: 'بازه',
            key: 'period',
            type: 'singleselect',
            default: true,
            options: [
                {title: 'هفتگی', value: 'weekly' },
                {title: 'ماهیانه', value: 'monthly',default: true},
            ]
        },
        sessionSortFilters: {
            title: 'مرتب سازی بر اساس',
            key: 'orderBy',
            type: 'singleselect',
            default: true,
            options: [
                {title: 'تاریخ جلسه صعودی', value: 'session_date:asc'},
                {title: 'تاریخ جلسه نزولی', value: 'session_date:desc'},
                {title: 'تاریخ درخواست صعودی', value: 'request_date:asc'},
                {title: 'تاریخ درخواست نزولی', value: 'request_date:desc', default: true}
            ]
        },
        adminClientSessionSortFilters: {
            title: 'مرتب سازی بر اساس',
            key: 'orderBy',
            type: 'singleselect',
            default: true,
            options: [
                {title: 'تاریخ جلسه صعودی', value: 'session_date:asc'},
                {title: 'تاریخ جلسه نزولی', value: 'session_date:desc', default: true},
                {title: 'تاریخ درخواست صعودی', value: 'request_date:asc'},
                {title: 'تاریخ درخواست نزولی', value: 'request_date:desc'}
            ]
        },
        stateFilters: {
            title: 'وضعیت',
            key: 'state',
            type: 'multiselect',
            default: true,
            options: [
                {title: 'درخواست', value: 'session_requested'},
                {title: 'تخصیص یافته', value: 'doctor_assigned'},
                {title: 'تاریخ ست شده', value: 'date_fixed'},
                {title: 'برگزار شده', value: 'used'},
                {title: 'لغو شده', value: 'canceled'},
            ]
        },
        MethodsStateFilters: {
            title: 'وضعیت',
            key: 'state',
            type: 'singleselect',
            default: true,
            options: [
                {title: 'فعال', value: 'active'},
                {title: 'غیر‌فعال', value: 'inactive'},
            ]
        },
        counselorStatus: {
            title: 'وضعیت',
            key: 'doctor_status',
            type: 'singleselect',
            default: true,
            options: [
                {title: 'فعال', value: 'active', default: true},
                {title: 'غیر‌فعال', value: 'inactive'},
            ]
        },
        MethodsUserVisibilityFilters: {
            title: 'نمایش به کاربر',
            key: 'user_visibility',
            type: 'singleselect',
            default: true,
            options: [
                {title: 'فعال', value: 'visible'},
                {title: 'غیر‌فعال', value: 'invisible'},
            ]
        },

        MethodsTypeFilters: {
            title: 'نوع',
            key: 'type',
            type: 'singleselect',
            default: true,
            options: [
                {title: 'آنلاین', value: 'online'},
                {title: 'آفلاین', value: 'offline'},
            ]
        },

        typeFilters: {
            title: 'نوع',
            key: 'type',
            type: 'multiselect',
            default: true,
            options: constants.withDefault(constants.packages.types, 'session')
        },

        clientSessionStateFilters: {
            title: 'وضعیت',
            key: 'state',
            type: 'multiselect',
            default: true,
            options: [
                {title: 'درخواست', value: 'session_requested', default: true},
                {title: 'تخصیص یافته', value: 'doctor_assigned', default: true},
                {title: 'تاریخ ست شده', value: 'date_fixed', default: true},
                {title: 'برگزار شده', value: 'used', default: true}
            ]
        },
        clientSessionRequestStateFilters: {
            title: 'وضعیت',
            key: 'state',
            type: 'multiselect',
            default: true,
            options: [
                {title: 'درخواست', value: 'session_requested', default: true},
                {title: 'تخصیص یافته', value: 'doctor_assigned', default: true},
                {title: 'تاریخ ست شده', value: 'date_fixed'},
                {title: 'برگزار شده', value: 'used'}
            ]
        },
        couponCodeFilter: {
            title: 'کد تخفیف',
            key: 'code',
            type: 'text',
            expandable: false,
            options: ['']
        }
    },
    sessionDateFilters: [
        {
            title: 'از تاریخ',
            key: 'from_date',
            type: 'datepicker',
            options: ['']
        },
        {
            title: 'تا تاریخ',
            key: 'to_date',
            type: 'datepicker',
            options: ['']
        }
    ],
    cartPaymentDateFilters: [
        {
            title: 'از تاریخ پرداخت',
            key: 'payment_from_date',
            type: 'datepicker',
            options: ['']
        },
        {
            title: 'تا تاریخ پرداخت',
            key: 'payment_to_date',
            type: 'datepicker',
            options: ['']
        }
    ],
    userJoinDateFilters: [
        {
            title: 'از تاریخ',
            key: 'from_date',
            type: 'datepicker',
            options: ['']
        },
        {
            title: 'تا تاریخ',
            key: 'to_date',
            type: 'datepicker',
            options: ['']
        }
    ],
    paymentAmountFilters: [
        {
            title: 'از مبلغ',
            key: 'from_amount',
            type: 'text',
            options: ['']
        },
        {
            title: 'تا مبلغ',
            key: 'to_amount',
            type: 'text',
            options: ['']
        }
    ],
    requestDateFilters: [
        {
            title: 'درخواست از',
            key: 'from_request_date',
            type: 'datepicker',
            options: ['']
        },
        {
            title: 'درخواست تا',
            key: 'to_request_date',
            type: 'datepicker',
            options: ['']
        }
    ],
    marketing: {
        dateFilter: [
            {
                title: 'از تاریخ',
                key: 'from_date',
                type: 'datepicker',
                options: ['']
            },
            {
                title: 'تا تاریخ',
                key: 'to_date',
                type: 'datepicker',
                options: ['']
            }
        ],
        PresessionDateFilter: [
            {
                title: 'از تاریخ پیش مشاوره',
                key: 'presession_from_date',
                type: 'datepicker',
                options: ['']
            },
            {
                title: 'تا تاریخ پیش مشاوره',
                key: 'presession_to_date',
                type: 'datepicker',
                options: ['']
            }
        ],

    },
    pre_session: [
        {
            title: 'وضعیت برگذاری',
            key: 'pre_session_status',
            type: 'singleselect',
            options: [
                {
                    title: 'در انتظار برگذاری',
                    value: 'date_fixed'
                },
                {
                    title: 'لغو‌شده',
                    value: 'canceled'
                },
                {
                    title: 'برگذارشده',
                    value: 'used'
                },
            ]
        },
        {
            title: 'وضعیت خرید',
            key: 'payment_status',
            type: 'singleselect',
            options: [
                {
                    title: 'با پرداخت',
                    value: 'with_payment'
                },
                {
                    title: 'بدون پرداخت',
                    value: 'without_payment'
                },
            ]
        },
        {
            title: 'نحوه ثبت‌نام',
            key: 'register_on',
            type: 'singleselect',
            options: [
                {
                    title: 'پنل',
                    value: 'panel'
                },
                {
                    title: 'لندینگ پیش‌مشاوره',
                    value: 'landing'
                },
                {
                    title: 'تست‌‌ها',
                    value: 'tests'
                },
            ]
        }
    ],
    monitoring: {
        session_count: {
            title: 'تعداد جلسات',
            key: 'session_count',
            type: 'text',
            expandable: false,
            default: 5,
            options: ['']
        },
        currency: {
            title: 'ارز ترجیحی',
            key: 'currency_filter',
            type: 'singleselect',
            expandable: false,
            default: 5,
            options: [
                {
                    title: 'ریال',
                    value: 'rial'
                },
                {
                    title: 'دلار',
                    value: 'usd'
                },
                {
                    title: 'همه',
                    value: 'both'
                }
            ]
        },
        firstBuy: {
            title: 'نوبت خرید',
            key: 'is_first_purchase',
            type: 'singleselect',
            default: true,
            options: [
                {title: 'فقط خرید‌اول', value: true},
                {title: 'بجز خرید اول', value: false,},
            ]
        },
        patientNameFilter: {
            title: 'نام مراجع',
            key: 'firstname',
            type: 'text',
            expandable: false,
            options: ['']
        },
        patientFamilyFilter: {
            title: 'نام خانوادگی مراجع',
            key: 'lastname',
            type: 'text',
            expandable: false,
            options: ['']
        },
        patientEmailFilter: {
            title: 'ایمیل',
            key: 'email',
            type: 'text',
            ltr: true,
            expandable: false,
            options: ['']
        },
    }
}

let crmfilter = {
    status: {
        title: 'وضعیت',
        key: 'situations',
        type: 'multiselect',
        default: true,
        options: [

            {
                title: 'تکمیل اطلاعات',
                value: 'check_user_profile'
            },
            {
                title: 'تنظیم جلسه',
                value: 'set_session',
            },
            {
                title: 'در انتظار برگزاری',
                value: "wait_for_session"
            },
            {
                title: 'تایید پشتیبان فروش ',
                value: "seller_determine"
            },
            {
                title: "در انتظار پیگیری خرید",
                value: "sell_progress"
            },
            {
                title: 'عدم تمایل به خرید',
                value: "will_not_buy"
            },
            {
                title: 'تمایل به خرید',
                value: "will_buy"
            },
            {
                title: 'در, انتظار خرید',
                value: "buy_package"
            },
            {
                title: 'تخصیص دکتر',
                value: "set_doctor",
            },
            {
                title: "در انتظار پیگیری خرید ",
                value: "purchase_follow_up"
            },
            {
                title: 'عدم ادامه',
                value: "discontinue"
            }
        ]
    },
    hasActiveTasks: {
        title: 'کاربران',
        key: 'hasActiveTasks',
        type: 'singleselect',
        default: true,
        options: [
            {title: 'کاربران با تسک ', value: true, default: true},
            {title: 'کاربران بدون تسک ', value: false},
        ]
    }
}


let userFilters = {
    registerFrom: {
        title: 'ثبت نام',
        key: 'psytest_register',
        type: 'singleselect',
        default: true,
        options: [
            {title: 'ثبت نام اصلی', value: 'main_register', default: true},
            {title: 'ثبت نام تست', value: 'test_register'},
        ]
    }
};
const adminSessionFilters = [
    filters.general.stateFilters,
    filters.general.patientNameFilter,
    filters.general.typeFilters,
    filters.general.clientId,
    // filters.general.counselorFilters,
    filters.general.counselorIdFilters,
    filters.general.sessionSortFilters,
    ...filters['sessionDateFilters'],
    ...filters['requestDateFilters'],
    filters.general.whatsapp
];

const adminFinancialFilters = [
    financialFilters.stateFilterClient,
    financialFilters.paymentSortFilters,
    financialFilters.currencyFilter,
    filters.general.patientNameFilter,
    financialFilters.referenceFilters,
    ...filters['sessionDateFilters'],
    ...filters['paymentAmountFilters']
]

const adminCartFactorsFilters = [
    financialFilters.cartStateFilters,
    financialFilters.cartPaymentSortFilters,
    financialFilters.currencyFilter,
    filters.general.patientNameFilter,
    filters.general.clientId,
    filters.general.patientEmailFilter,
    financialFilters.referenceFilters,
    ...filters['sessionDateFilters'],
    ...filters['paymentAmountFilters'],
    ...filters['cartPaymentDateFilters'],
    financialFilters.firstBuy

]

const adminMatchMakingResultsFilters = [
    {
        title: 'وضعیت جلسه',
        key: 'user_has_session',
        type: 'singleselect',
        default: true,
        options: [
            {title: 'جلسه برگزار شده', value: 'HAS_SESSION', default: true},
            {title: 'بدون جلسه', value: 'NO_SESSION'}
        ]
    }
]


const adminFinancialTransactionsFilters = [
    financialFilters.paymentSortFilters,
    financialFilters.currencyFilter,
    filters.general.patientNameFilter,
    financialFilters.referenceFilters,
    ...filters['sessionDateFilters'],
    ...filters['paymentAmountFilters']

]


const adminFinancialRefundsFilters = [
    financialFilters.paymentSortFilters,
    financialFilters.currencyFilter,
    filters.general.patientNameFilter,
    financialFilters.referenceFilters,
    ...filters['sessionDateFilters'],
    ...filters['paymentAmountFilters']
]


const adminFinancialRecordsFilters = [
    financialFilters.paymentSortFilters,
    financialFilters.stateFiltersRecord,
    financialFilters.currencyFilter,
    filters.general.patientNameFilter,
    financialFilters.referenceFilters,
    ...filters['sessionDateFilters'],
    ...filters['paymentAmountFilters']

]


let dateSessionFilters = [
    filters.general.stateFilters,
    filters.general.patientNameFilter,
    filters.general.counselorFilters,
    filters.general.sessionSortFilters,
    ...filters['requestDateFilters']
];

let clientDetailSessionsFilters = [
    filters.general.stateFilters,
    filters.general.counselorFilters,
    filters.general.sessionSortFilters,
    ...filters['sessionDateFilters'],
    ...filters['requestDateFilters']
];


let adminSessionRequests = [
    filters.general.sessionSortFilters,
    filters.general.counselorIdFilters,
    filters.general.patientNameFilter,
    filters.general.typeFilters,
    // filters.general.counselorFilters,

    ...filters['requestDateFilters']
];

let adminClients = [
    filters.general.clientId,
    filters.general.indebtUser,
    filters.general.patientNameFilter,
    filters.general.patientMobileFilter,
    filters.general.patientEmailFilter,
    filters.general.patientFamiliarityFilter,
    filters.general.patientOriginType,
    filters.general.patientPaymentType,
    filters.general.patientDoctorType,
    filters.general.counselorIdFilters,
    userFilters.registerFrom,
    // filters.general.patientPaymentExistence,
    ...filters['userJoinDateFilters'],


];

let adminCounselors = [
    filters.general.patientNameFilter,
    filters.general.counselorStatus
];


let adminTickets = [
    adminTicketFilters.stateFilters,
    adminTicketFilters.typeFilters,
    filters.general.patientNameFilter
]
let adminCouponFilters = [
    filters.general.patientNameFilter,
    filters.general.couponCodeFilter,
    filters.general.counselorFilters,
    couponFilters.stateFilter,
    couponFilters.amountFilter,
    couponFilters.expiredFilter,
];

let adminGiftCodeFilters = [];

let adminPackageFilters = [
    filters.general.counselorFilters,
    filters.general.typeFilters,
    // packageFilters.amountFilter,
    packageFilters.stateFilter,
    // packageFilters.currencyFilter,
    packageFilters.quantityFilter,
    packageFilters.titleFilter,
];


let paymentMethodFilters = [
    filters.general.MethodsStateFilters,
    filters.general.MethodsUserVisibilityFilters,
    filters.general.MethodsTypeFilters
];


let adminClientUsedSessionFilters = [
    filters.general.clientSessionStateFilters,
    filters.general.typeFilters,
    filters.general.counselorFilters,
    filters.general.adminClientSessionSortFilters,
    ...filters['sessionDateFilters'],
    ...filters['requestDateFilters']
]


let adminClientRequestedSessionFilters = [
    filters.general.clientSessionRequestStateFilters,
    filters.general.typeFilters,
    filters.general.counselorFilters,
    filters.general.sessionSortFilters,
    ...filters['sessionDateFilters'],
    ...filters['requestDateFilters'],

]


let marketingChurnFilters = [
    ...filters['sessionDateFilters'],
    filters.general.marketingPeriodFilter,
    filters.general.marketingCounselorIdFilters,
    filters.general.marketingAddCounselorIdFilters,
    // filters.general.marketingChurnNoFurtherPayment,
];

let sessionAverageReport = [
    ...filters['sessionDateFilters'],
    // filters.general.marketingPeriodFilter,
    filters.general.marketingCounselorIdFilters,
    filters.general.marketingAddCounselorIdFilters,
    // filters.general.marketingChurnNoFurtherPayment,
];

const crmFilter = [
    crmfilter.status,
    crmfilter.hasActiveTasks
]
const whatsappLog = [
    filters.general.clientId
]

const marketing_survey = [
    ...filters.marketing.dateFilter
]
const user_monitoring_report = [
    ...filters.marketing.dateFilter,
    filters.monitoring.patientNameFilter,
    filters.monitoring.patientFamilyFilter,
    filters.monitoring.patientEmailFilter,
    filters.general.clientId,
    filters.monitoring.session_count,
    filters.monitoring.currency,
    filters.general.counselorIdFilters,
    filters.monitoring.firstBuy,
]
const pre_session_report = [
    ...filters.marketing.dateFilter,
    ...filters.marketing.PresessionDateFilter,
    ...filters.pre_session
]

const sessions_chart = [
    ...filters['sessionDateFilters'],
]

const shopify_webhook = [
    filters.general.reviewed_at
]

export {
    shopify_webhook,
    whatsappLog,
    adminSessionFilters,
    dateSessionFilters,
    clientDetailSessionsFilters,
    adminFinancialFilters,
    adminSessionRequests,
    adminClients,
    adminTickets,
    adminCounselors,
    adminCouponFilters,
    adminPackageFilters,
    adminFinancialTransactionsFilters,
    adminFinancialRecordsFilters,
    adminFinancialRefundsFilters,
    adminClientUsedSessionFilters,
    adminClientRequestedSessionFilters,
    adminCartFactorsFilters,
    adminMatchMakingResultsFilters,
    adminGiftCodeFilters,
    marketingChurnFilters,
    crmFilter,
    paymentMethodFilters,
    marketing_survey,
    user_monitoring_report,
    pre_session_report,
    sessions_chart,
    sessionAverageReport
};
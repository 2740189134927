<template>

    <v-container fluid>
        <v-tour name="firstStep" :steps="tourSteps.first"
                :options="myOptions"
        ></v-tour>
        <v-tour name="secondStep" :steps="tourSteps.second"
                :options="myOptions"
        ></v-tour>
        <v-tour name="thirdStep" :steps="tourSteps.third"
                :options="myOptions"
        ></v-tour>
        <v-dialog v-if="loaded" max-width="1100"
                  v-model="wizard" scrollable>
            <v-card class="elevation-0">
                <v-card-text class="pa-0 ma-0">
                    <v-stepper v-model="step">
                        <v-stepper-header class="warning">
                            <v-stepper-step :editable="step > 1" :complete="step > 1" step="1">اطلاعات
                                پایه
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :editable="step > 2 || !profile.state.requireSign" :complete="step > 2"
                                            step="2">خرید بسته
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step :complete="step > 3" :editable="step > 3 || !profile.state.requireBuy "
                                            step="3">موضوع مشاوره
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <!--<v-stepper-step :complete="step > 4" step="4">انتخاب مشاور</v-stepper-step>-->
                            <!--<v-divider></v-divider>-->

                            <v-stepper-step :complete="step > 4" :editable="step > 4" step="4">ثبت زمان پیشنهادی
                            </v-stepper-step>

                        </v-stepper-header>

                        <v-stepper-items>
                            <v-stepper-content step="1">

                                <v-card class="elevation-0"

                                >
                                    <v-alert type="info">
                                        لطفا اطلاعات زیر را برای تشکیل حساب کاربری خود وارد نمایید.
                                    </v-alert>
                                    <v-card-text>
                                        <v-form ref="profileForm" data-v-step="0">
                                            <v-row>


                                                <v-col cols="12" sm="6" md="4">
                                                    <v-text-field v-model="profileInfo.name" outlined
                                                                  label="نام"
                                                                  :rules="requiredRules"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-text-field v-model="profileInfo.family" outlined
                                                                  :rules="requiredRules"
                                                                  label="نام خانوادگی"></v-text-field>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-autocomplete
                                                            v-model="profileInfo.country"
                                                            :items="countries"
                                                            item-text="name"
                                                            item-value="id"
                                                            menu-props="auto"
                                                            label="کشور"
                                                            hide-details
                                                            prepend-icon="fas fa-flag"
                                                            single-line
                                                            outlined
                                                            :rules="requiredRules"
                                                    ></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" sm="6" md="4">
                                                    <v-autocomplete
                                                            menu-props="auto"
                                                            :label="$vuetify.lang.t('$vuetify.profileTZ')"
                                                            v-model="profileInfo.timezone"
                                                            :items="timezones"
                                                            item-value="value"
                                                            hide-details
                                                            prepend-icon="fas fa-clock"
                                                            single-line
                                                            outlined
                                                            :rules="requiredRules"

                                                    >

                                                    </v-autocomplete>
                                                </v-col>

                                            </v-row>
                                        </v-form>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <div data-v-step="1">

                                            <v-btn

                                                    color="primary"
                                                    @click="saveProfile"
                                            >
                                                ادامه
                                            </v-btn>

                                        </div>
                                    </v-card-actions>
                                </v-card>


                            </v-stepper-content>

                            <v-stepper-content step="2">
                                <v-card
                                        data-v-step="2"
                                        class="elevation-0"
                                >
                                    <v-card-text>
                                        <ClientPricingComponent @hasPackages="checkSecondStepTour"
                                                                @closeWizard="closeWizard(false)"></ClientPricingComponent>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn class="elevation-0" @click="step=1">
                                            <v-icon class="mx-1 grey--text text--darken-2" small>fas
                                                fa-long-arrow-alt-right
                                            </v-icon>
                                            بازگشت
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                                color="primary"
                                                @click="setStep"
                                                :disabled="profile.state.requireBuy"
                                        >
                                            ادامه
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>

                            </v-stepper-content>
                            <v-stepper-content step="3">
                                <v-card
                                        class="elevation-0"

                                >
                                    <v-card-text v-if="!hideCycles">

                                        <AddNewCycleDashboard @cycleAdded="postCycleAdd"
                                                              data-v-step="3"
                                                              :dialog="false"></AddNewCycleDashboard>

                                    </v-card-text>
                                    <v-card-text v-else>
                                        <v-progress-linear
                                                active
                                                indeterminate
                                                query
                                                rounded
                                                stream
                                        ></v-progress-linear>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn class="elevation-0" @click="step=2">
                                            <v-icon class="mx-1 grey--text text--darken-2" small>fas
                                                fa-long-arrow-alt-right
                                            </v-icon>
                                            بازگشت
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                                v-if="profile.cycles.length==0"
                                                color="primary"
                                                @click="addCycle"
                                        >
                                            ادامه
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>

                            </v-stepper-content>

                            <!--<v-stepper-content step="4">-->
                                <!--<v-card>-->
                                    <!--&lt;!&ndash;<v-card-title>انتخاب مشاور</v-card-title>&ndash;&gt;-->
                                    <!--&lt;!&ndash;<v-divider></v-divider>&ndash;&gt;-->
                                    <!--<v-card-text>-->
                                        <!--<v-alert type="info">-->
                                            <!--لطفا مشاور خود را انتخاب نمایید-->
                                        <!--</v-alert>-->
                                        <!--<v-row class="text-center" justify="center">-->



                                            <!--<v-col v-for="doctor in doctors" :key="doctor.id" cols="12" sm="6" md="3"-->
                                                   <!--class="text-center">-->
                                                <!--<v-card-->
                                                        <!--class="text-center "-->
                                                        <!--@click="selectDoctor(doctor)"-->
                                                <!--&gt;-->
                                                    <!--<v-card-text>-->
                                                        <!--<v-overlay-->
                                                                <!--v-if="selectedDoctor != null"-->
                                                                <!--absolute-->
                                                                <!--z-index="0"-->
                                                                <!--opacity=".6"-->
                                                                <!--:value="selectedDoctor.id == doctor.id"-->
                                                                <!--color="rgba(0,250,150,.4)"-->
                                                        <!--&gt;-->

                                                        <!--</v-overlay>-->

                                                        <!--<template-->
                                                                <!--v-if="selectedDoctor != null"-->
                                                        <!--&gt;-->
                                                            <!--<v-btn-->
                                                                    <!--fab-->
                                                                    <!--small-->
                                                                    <!--v-if="selectedDoctor.id == doctor.id"-->
                                                                    <!--class="green cursor-pointer green-check elevation-0"-->
                                                            <!--&gt;-->
                                                                <!--<v-icon class="white&#45;&#45;text" small>-->
                                                                    <!--fas fa-check-->
                                                                <!--</v-icon>-->
                                                            <!--</v-btn>-->
                                                        <!--</template>-->


                                                        <!--<v-avatar-->
                                                                <!--:size="100"-->
                                                                <!--class="elevation-2"-->
                                                        <!--&gt;-->

                                                            <!--<v-img-->
                                                                    <!--v-if="doctor.avatar != null"-->
                                                                    <!--:src="doctor.avatar"-->
                                                            <!--&gt;</v-img>-->
                                                        <!--</v-avatar>-->
                                                    <!--</v-card-text>-->

                                                    <!--<v-card-text class="text-center title pa-0 ma-0">-->
                                                        <!--<span>{{doctor.full_name}}</span>-->
                                                    <!--</v-card-text>-->
                                                    <!--<v-card-text>-->

                                                        <!--{{doctor.profession}}-->

                                                    <!--</v-card-text>-->
                                                    <!--<v-card-subtitle v-if="doctor.hasOwnProperty('total')">-->
                                                        <!--تعداد بسته های شما با مشاور: {{doctor.total}}-->
                                                    <!--</v-card-subtitle>-->
                                                <!--</v-card>-->

                                            <!--</v-col>-->


                                        <!--</v-row>-->
                                        <!--<v-row justify="center" align="center">-->
                                            <!--<v-col class="text-center">-->
                                                <!--<v-btn v-if="canRequestNewDoctors" class="elevation-1"-->
                                                       <!--@click="selectNewDoctorPass">انتخاب-->
                                                    <!--مشاور جدید-->
                                                <!--</v-btn>-->
                                            <!--</v-col>-->
                                        <!--</v-row>-->
                                    <!--</v-card-text>-->
                                    <!--<v-card-actions>-->
                                        <!--<v-btn @click="step&#45;&#45;" text>قبلی</v-btn>-->

                                        <!--<v-spacer></v-spacer>-->

                                        <!--<v-btn-->
                                                <!--color="primary"-->
                                                <!--@click="sendSessionRequest"-->
                                        <!--&gt;-->
                                            <!--ثبت-->
                                        <!--</v-btn>-->

                                    <!--</v-card-actions>-->
                                <!--</v-card>-->
                            <!--</v-stepper-content>-->

                            <v-stepper-content step="4">

                                <DateTimeComponent
                                        :tour="showCalendarTour"
                                        @done="redirect"
                                        :profile="profile"
                                        @getProfile="updateProfile"
                                        :message="message"
                                ></DateTimeComponent>

                            </v-stepper-content>

                        </v-stepper-items>
                    </v-stepper>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-container>

</template>

<script>
    /*eslint-disable*/


    import AddNewCycleDashboard from '@/components/clients/AddNewCycleDashboard';
    import ClientPricingComponent from "../products/ClientPricingComponent";
    import {mapGetters} from 'vuex';
    import timezones from "../../data/timezones";
    import moment from 'moment-timezone';
    import {EventBus} from "../../event-bus";
    import DoctorTimes from '@/components/clients/DoctorTimes'
    import DateTimeComponent from '@/components/DateTimeComponent';
    import ChangeTzForm from '@/components/ChangeTzFormComponent'
    import LS from "../../LocalStorateAPI";


    export default {
        props: {
            sessionRequest: {
                default: false
            }
        },
        created() {
            this.sortAndGenerateTimezoneOffset();
            // EventBus.$emit('setLoading', true);

            this.$store.dispatch('getCountries').then((resp) => {
                this.countries = resp.data.countries;
            });

        },
        mounted() {
            this.$store.dispatch('getUserInfo').then(() => {

                let localVal = LS.get('show-tour');

                if (this.profile.state.requireSign || localVal == 'true') {
                    LS.set('show-tour', true);
                    this.showTour = true;
                }


                this.setStep();
                this.fillFirstStep();
                this.fillThirdStep();
                this.loaded = true;
            }).finally(() => {
                // EventBus.$emit('setLoading', false);
            });

        },
        methods: {
            closeWizard(clearStorage = true) {
                this.wizard = false;
                this.$emit('closed');
                if (clearStorage) {
                    if (this.showTour) {
                        LS.remove('show-tour');
                        this.showTour = false;
                    }
                }

            },
            checkSecondStepTour() {
                setTimeout(() => {
                    if (this.step == 2) {
                        this.checkTour('secondStep');
                    }
                }, 100)

            },
            checkTour(step) {
                if (this.showTour) {
                    setTimeout(() => {
                        this.$nextTick().then(() => {
                            // this.$tours[step].start()
                        })
                    }, 300)

                }
            },
            myCustomPreviousStepCallback(currentStep) {
                console.log(currentStep);
            },
            myCustomNextStepCallback(currentStep) {
                console.log(currentStep);
            },
            updateProfile() {
                // this.$store.dispatch('getUserInfo').then(() => {
                //     this.profile = this.$store.getters.profile;
                // })
            },
            redirect() {
                // if (!this.sessionRequest) {
                //     // this.$router.push({name: 'sessions'});
                // } else {
                //     this.$emit('closed');
                //
                // }
                this.closeWizard();
                // this.$router.push({name: 'sessions', params: {lang: 'fa'}});
            },
            selectNewDoctorPass() {
                this.selectedDoctor = null;
                this.newDoctor = true;
                this.sendSessionRequest();
            },
            selectTime(hours) {
                this.selectedSessionTimes = hours;
            },
            saveTimeForRequest() {

                if (this.selectedDoctor != null) {

                    if (!this.selectedSessionTimes.length && Object.keys(this.doctorTimes).length) {

                        EventBus.$emit('notify', 'red', undefined, 'لطفا یکی از زمانهای مشاور را انتخاب نمایید');

                    }
                    else if (!this.selectedSessionTimes.length && Object.keys(this.doctorTimes).length == 0) {

                        this.step = 6;

                    } else {

                        let payload = {
                            times: this.selectedSessionTimes,
                            sessions: this.sessions
                        };

                        this.$store.dispatch('selectMultipleSessionRequestTime', payload).then((resp) => {
                            EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
                            this.step = 6;
                        });
                    }

                } else {
                    this.$router.push('sessions');
                }
            },
            fillThirdStep() {
                this.cycles = this.profile.cycles;

                //SESSION COUNT
                // if (this.profile.wallet.global_packages > this.profile.wallet.dr_packages) {
                //     this.sessionCount = this.profile.wallet.global_packages;
                // } else {
                //     this.sessionCount = this.profile.wallet.dr_packages;
                // }

                if (this.cycles.length >= 1) {
                    this.selectedCycle = this.cycles[0];
                }
            },
            fillFirstStep() {
                this.profileInfo.name = this.profile.firstname;
                this.profileInfo.family = this.profile.lastname;
                if (!this.profile.requiredFields.includes('timezone')) {
                    this.profileInfo.timezone = this.profile.tz;
                }

                this.profileInfo.country = this.profile.country_id;
            },
            sortAndGenerateTimezoneOffset() {
                let x = JSON.parse(JSON.stringify(timezones));
                let tempTime = x.map((item) => {
                    let tznum = moment.tz(item.text).format('Z');

                    item.offset = moment.tz(item.text).utcOffset();
                    item.text = item.text + ' ' + tznum;
                    return item;
                });
                tempTime = tempTime.sort((a, b) => {
                    return a.offset - b.offset;
                });
                this.timezones = tempTime;
            },
            postCycleAdd(data) {

                this.sessionCount = data.sessionCount;
                if (data.cycle == null || data.cycle == undefined) {
                    this.cycles = this.profile.cycles;
                    this.selectedCycle = this.profile.cycles[0];
                } else {
                    this.selectedCycle = data.cycle;
                }

                EventBus.$emit('setLoading', true);
                this.getDoctors(this.selectedCycle).then(() => {
                    if (this.canRequestNewDoctors) {

                        this.selectedDoctor = null;
                        this.newDoctor = true;

                        let options = {
                            "cycle_id": this.selectedCycle.id,
                            "session_count": this.sessionCount
                        };

                        this.$store.dispatch('sendSessionRequest', options).then((resp) => {
                            // EventBus.$emit('notify', 'green', undefined, 'درخواست شما با موفقیت به ثبت رسید');
                            // if (this.showTour) {
                            //     this.showCalendarTour = true;
                            // }
                            if (this.hideCycles) {
                                this.hideCycles = false;
                            }
                            this.sessionRequested = true;
                            if (!this.profile.state.hadSessions) {
                                this.message = 'اطلاعات شما با موفقیت ثبت شد، تا 24 ساعت آینده با شما تماس خواهیم گرفت';
                            }
                            this.step = 4;
                        });

                    }
                }).finally(() => {
                    EventBus.$emit('setLoading', false);
                });

                // this.getDoctors(this.selectedCycle).then(() => {
                //     if (this.doctors.length == 0 && this.canRequestNewDoctors) {
                //
                //         this.selectedDoctor = null;
                //         this.newDoctor = true;
                //
                //         let options = {
                //             "cycle_id": this.selectedCycle.id,
                //             "session_count": this.sessionCount
                //         };
                //
                //         if (this.selectedDoctor != null) {
                //             options.doctor_id = this.selectedDoctor.id;
                //
                //         }
                //
                //         this.$store.dispatch('sendSessionRequest', options).then((resp) => {
                //             EventBus.$emit('notify', 'green', undefined, 'درخواست شما با موفقیت به ثبت رسید');
                //             if (this.showTour) {
                //                 this.showCalendarTour = true;
                //             }
                //             this.step = 5;
                //         });
                //
                //     } else {
                //         this.step++;
                //     }
                // }).finally(() => {
                //     EventBus.$emit('setLoading', false);
                // });

            },
            addCycle() {
                EventBus.$emit('postConsultationInfoEvent');
                // this.setStep();
            },
            sendSessionRequest() {
                if (!this.newDoctor && this.selectedDoctor == null) {
                    EventBus.$emit('notify', 'red', undefined, 'لطفا یک گزینه را انتخاب نمایید');
                    return;
                }

                let options = {
                    "cycle_id": this.selectedCycle.id,
                    "session_count": this.sessionCount
                };
                if (this.selectedDoctor != null) {
                    options.doctor_id = this.selectedDoctor.id;

                } else {
                    this.completed = true;
                }
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('sendSessionRequest', options).then((resp) => {
                    if (resp.data.hasOwnProperty('doctorTimes')) {
                        this.doctorTimes = resp.data.doctorTimes;
                    }

                    if (resp.data.hasOwnProperty('sessions')) {
                        this.maxSelectSessionTime = resp.data.sessions.length;
                        this.sessions = resp.data.sessions;
                    }
                    this.sessionRequested = true;
                    EventBus.$emit('notify', 'green', undefined, 'درخواست شما با موفقیت به ثبت رسید');
                    this.step = 4;
                    //
                    // if (Object.keys(this.doctorTimes).length) {
                    //     this.step = 5;
                    // } else {
                    //     this.step = 6;
                    // }


                    // this.step++;
                    // this.$store.dispatch('getUserInfo');
                    // if (this.selectedDoctor == null) {
                    //     this.completed = true;
                    // }
                    //
                    // if (resp.data.hasOwnProperty('doctorTimes')) {
                    //     this.doctorTimes = resp.data.doctorTimes;
                    // }
                    //
                    // if (resp.data.hasOwnProperty('sessions')) {
                    //     this.maxSelectSessionTime = resp.data.sessions.length;
                    //     this.sessions = resp.data.sessions;
                    // }
                    //
                    // this.$nextTick().then(() => {
                    //     this.e1 = 3;
                    // });
                }).finally(() => {
                    EventBus.$emit('setLoading', false);
                });

            },
            selectDoctor(doctor) {
                this.newDoctor = false;
                if (this.selectedDoctor == doctor) {
                    this.selectedDoctor = null;
                } else {
                    this.selectedDoctor = doctor;
                }
            },
            selectNewDoctor() {
                this.selectedDoctor = null;

                this.newDoctor = !this.newDoctor;
            },
            getDoctors(cycle) {
                return new Promise((resolve, reject) => {
                    this.$store.dispatch('getSessionRequestDoctors', {cycleId: cycle.id}).then((resp) => {
                        this.doctors = resp.data.results.doctors;

                        this.selectedDoctor = null;
                        this.newDoctor = false;

                        if (this.doctors.length == 1) {
                            this.selectedDoctor = this.doctors[0];
                        }

                        if (resp.data.selected_doctor != null) {
                            this.selectedDoctor = this.doctors.find(doctor => doctor.id == resp.data.selected_doctor.id);
                        }


                        this.canRequestNewDoctors = resp.data.can_request_new_doctor;
                        resolve();
                    }).catch((resp) => {
                        reject();
                    })
                })

            },


            sendTimes() {

                let data = {timebooks: []};

                this.selectedTimes.forEach(day => {
                    let start = "";
                    let end = "";

                    data.timebooks.push({'date': day.date.replace(/-/g, '/'), 'periods': []});

                    day.periods.forEach(time => {
                        start = time.from;
                        end = time.to;
                        data.timebooks.forEach(item => {
                            item.periods.push({'start': start + ':00', 'end': end + ':00'})
                        })
                    })
                });

                EventBus.$emit('setLoading', true);
                this.$store.dispatch('clientPostConsultInfo', data).then(() => {

                    EventBus.$emit('notify', 'green', undefined, 'زمان های شما با موفقیت به ثبت رسیدند');
                    if (!this.sessionRequest) {
                        this.$router.push({name: 'sessions'});
                    } else {
                        this.$emit('closed');
                    }

                }).finally(() => {
                    EventBus.$emit('setLoading', false);
                })
            },

            unique(arr) {
                let a = arr.concat();
                for (let i = 0; i < a.length; ++i) {
                    for (let j = i + 1; j < a.length; ++j) {
                        if (a[i] === a[j])
                            a.splice(j--, 1);
                    }
                }

                return a;
            },
            removeTime(time, timePeriod) {
                time.periods.splice(time.periods.indexOf(timePeriod), 1);
                if (!time.periods.length) {
                    this.removeTimeRecord(time);
                }
            },
            removeTimeRecord(time) {
                this.selectedTimes.splice(this.selectedTimes.indexOf(time), 1);
            },

            addToTimes() {
                if (this.$refs.addToTimesForm.validate()) {

                    if (!this.currentTime.periods.length) {
                        EventBus.$emit('notify', 'red', undefined, 'لطفا حداقل یک بازه را انتخاب نمایید');
                    } else {
                        let x = {
                            date: this.currentTime.date,
                            periods: this.currentTime.periods
                        };
                        let exists = this.selectedTimes.find((time) => {
                            return time.date == x.date;
                        });

                        if (exists != undefined) {
                            // this.removeTime(exists);
                            // this.exists.periods

                            exists.periods = this.unique(exists.periods.concat(x.periods));
                            // console.log(exists.periods.filter(value => x.periods.includes(value)));
                            // this.removeTimeRecord(exists);
                            // this.selectedTimes.push(toAdd);
                        } else {

                            this.selectedTimes.push(x);
                        }
                        this.currentTime.date = '';
                        this.currentTime.periods = [];
                        this.showAddDialog = false;

                    }
                }
            },
            selectPeriod(period) {

                if (!this.currentTime.periods.includes(period)) {
                    this.currentTime.periods.push(period);
                } else {
                    this.currentTime.periods.splice(this.currentTime.periods.indexOf(period), 1);
                }
            },
            saveProfile() {
                let data = {
                    name: this.profileInfo.name,
                    family: this.profileInfo.family,
                    timezone: this.profileInfo.timezone,
                    country_id: this.profileInfo.country,
                };

                if (this.$refs.profileForm.validate()) {
                    EventBus.$emit('setLoading', true);
                    this.$store.dispatch('saveUserInfo', data).then(() => {
                        this.$store.dispatch('getUserInfo').then(() => {
                            // EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
                            EventBus.$emit('clientProfileSaved');
                            this.setStep();
                        });
                    }).catch(() => {
                        EventBus.$emit('clientProfileSaveError');
                    }).finally(() => {
                        EventBus.$emit('clientProfileSaveError');
                        EventBus.$emit('setLoading', false);
                    });
                }
                // this.setStep();
            },
            setStep() {
                if (this.profile.state.requireSign) {
                    this.step = 1;
                    // this.checkTour('firstStep');
                } else if (this.profile.state.requireBuy && (!this.profile.state.hasPendingSessions || this.sessionRequest)) {
                    // } else if (this.profile.state.requireBuy && !this.profile.state.hasPendingSessions) {

                    this.step = 2;

                } else if (this.profile.state.requireCycle || !this.profile.state.hasPendingSessions || this.sessionRequest) {
                    // this.$store.commit('setCycleById', this.profile.state.requireCycleId);
                    this.step = 3;

                    if (this.sessionRequest && this.profile.cycles.length == 1) {
                        this.hideCycles = true;
                        this.postCycleAdd({
                            cycle: this.profile.cycles[0],
                            sessionCount: 1
                        });
                        return;
                    }


                    if (this.profile.cycles.length == 0) {
                        this.checkTour('thirdStep');
                    }

                    // }
                    // else if (this.profile.state.requireFillTimebooks) {
                    //     this.step = 4;
                } else if (this.profile.state.requireFillTimebooks) {
                    // if (this.showTour) {
                    //     this.showCalendarTour = true;
                    // }

                    this.step = 4;
                    if (!this.profile.state.hadSessions) {
                        this.message = 'اطلاعات شما با موفقیت ثبت شد، تا 24 ساعت آینده با شما تماس خواهیم گرفت';
                    }
                } else {
                    this.closeWizard();
                }
            }
        },
        components: {
            // AddNewCycle,
            ChangeTzForm,
            DateTimeComponent,
            DoctorTimes,
            AddNewCycleDashboard,
            ClientPricingComponent
        },
        data() {
            return {
                hideCycles: false,
                sessionRequested: false,
                message: 'اطلاعات شما با موفقیت ثبت شد',
                showTour: false,
                showCalendarTour: false,
                myCallbacks: {
                    onPreviousStep: this.myCustomPreviousStepCallback,
                    onNextStep: this.myCustomNextStepCallback
                },
                currentStep: 0,
                myOptions: {
                    labels: {
                        buttonSkip: 'بستن',
                        buttonPrevious: 'قبلی',
                        buttonNext: 'بعدی',
                        buttonStop: 'پایان'
                    }
                },
                tourSteps: {
                    first: [
                        {
                            target: '[data-v-step="0"]',
                            params: {
                                enableScrolling: false,
                                placement: 'top'
                            },
                            header: {
                                title: 'خوش آمدید',
                            },
                            content: `ابتدا اطلاعات خود را کامل کنید`
                        },
                        {
                            target: '[data-v-step="1"]',
                            params: {
                                enableScrolling: true,
                                placement: 'top'
                            },
                            header: {
                                title: 'تکمیل اطلاعات',
                            },
                            content: `پس از تکمیل اطلاعات بر روی دکمه ادامه بزنید.`
                        }
                    ],
                    second: [
                        {
                            target: '[data-v-step="2"]',
                            params: {
                                enableScrolling: false,
                                placement: 'top'
                            },
                            header: {
                                title: 'خرید بسته',
                            },
                            content: `بسته مورد نظر خود را انتخاب نمایید`
                        }
                    ],

                    third: [
                        {
                            target: '[data-v-step="3"]',
                            params: {
                                enableScrolling: false,
                                placement: 'top'
                            },
                            header: {
                                title: 'موضوع مشاوره',
                            },
                            content: `موضوع مشاوره و تعداد جلسات خود را وارد نمایید`
                        }
                    ]
                },
                // profile: this.$store.getters.getUserInfo,
                maxSelectSessionTime: 0,
                selectedSessionTimes: [],
                doctorTimes: {},
                canRequestNewDoctors: false,
                doctors: [],
                selectedDoctor: null,
                newDoctor: false,

                sessionCount: 1,
                cycles: [],
                selectedCycle: null,
                currentTime: {
                    date: '',
                    periods: []
                },
                selectedTimes: [],

                periods: [
                    {title: 'صبح', from: '8', to: '12'},
                    {title: 'ظهر', from: '12', to: '16'},
                    {title: 'عصر', from: '16', to: '20'},
                    {title: 'شب', from: '20', to: '24'},
                ],
                menu: false,
                showAddDialog: false,
                loaded: false,
                timezones: timezones,
                profileInfo: {
                    name: '',
                    family: '',
                    country: '',
                    timezone: '',
                },
                countries: [],
                country: null,
                step: 0,
                wizard: true
            }
        },
        computed: {
            sessionCountRules() {
                return [
                    value => !!value || 'تعداد جلسات را وارد نمایید.',
                    value => (value > 0) || 'تعداد جلسات حداقل باید 1 باشد',
                    value => (value <= this.profile.wallet.total) || 'تعداد جلسات درخواستی باید از جلسات خریداری شده شما کمتر باشد',
                ];
            },
            needsCycle() {
                return this.profile.state.requireCycle
            },
            requiredRules() {
                return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
            },
            ...mapGetters({
                profile: 'getUserInfo',
                platform: 'getPlatform',
                cart: 'getCart'
            }),
        },
        watch: {
            selectedCycle() {

                if (!this.profile.state.requireSign && !this.profile.state.requireBuy && this.profile.wallet.total) {
                    // this.getDoctors(this.selectedCycle);
                }
            },
            wizard() {
                // if (!this.sessionRequest) {
                //     this.$router.push({name: 'sessions'});
                // }
                this.closeWizard();

            }
        }

    }
</script>

<style scoped>
    .green-check {
        position: absolute !important;
        top: -10px !important;
        right: -10px !important;
    }
</style>
<template>
  <div>
    <v-card>
      <v-card-title>
        ثبت زمان برای یادآوری بعدی
      </v-card-title>
      <v-card-text>
        <v-form ref="doDateForm">
          <v-text-field
              :value="date | toJalali"
              outlined
              label="تاریخ جلسه"
              prepend-icon="event"
              readonly
              @click="showDate=true"
              :rules="requiredRules"
              dense
          >
          </v-text-field>
          <AdminDatePicker @close="showDate=false"
                           :dialog="showDate"
                           v-model="date">
          </AdminDatePicker>
        </v-form>

      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="addDoDate">
          ثبت
        </v-btn>
        <v-btn color="red" class="white--text" @click="DoDateDialogHandler(false)">
          بستن
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import AdminDatePicker from "@/components/AdminDatePicker";
import moment from "moment-jalaali";
import {EventBus} from "@/event-bus";

export default {
  components: {AdminDatePicker},
  props:{
    taskinfo:Object
  },
  data() {
    return {
      date : '',
      showDate: false,
    }
  },
  methods: {
    addDoDate() {
      if (this.$refs.doDateForm.validate()) {
        let payload = {
          id: this.$route.query.taskId,
          status: "paused",
          do_date: moment(this.date).format('Y-MM-DD')
        }
        EventBus.$emit('setLoading', true)
        this.$store.dispatch('changeTaskStatus', payload).then(() => {
          EventBus.$emit('returnWindow')
          EventBus.$emit('notify', 'green', undefined, 'تاریخ پیگیری بعدی با موفقیت به ثبت رسید');
        }).finally(() => {
          EventBus.$emit('setLoading', false)
          this.DoDateDialogHandler(false)
        })
      }
    },
    DoDateDialogHandler(val) {
      this.$emit('DoDateDialogHandler', {
        target: 'DoDateDialogHandler',
        status: val,
      })
    }
  },
  computed: {
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
    // date(){
    //   return '';
    // }
  },
  mounted() {
    this.date =   moment(this.taskinfo.do_date).format('Y-MM-DD')
  },
  filters:{
    toJalali(date) {
      if (date) {
        return moment(date, "YYYY-M-DD").format('jYYYY/jMM/jDD');
      }
      return date;
    },
  }
}
</script>

<style scoped>

</style>
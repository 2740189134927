<template>
  <div>
    <v-dialog v-model="roleActionModal" width="500px" v-if="targetRole">
      <v-card>
        <v-card-title>
          <template v-if="roleModalAction == 'add'">
            افزودن نقش
          </template>
          <template v-else>
            ویرایش نقش
          </template>
        </v-card-title>
        <v-card-text>
          <template v-if="roleModalAction == 'add'">
            <v-form ref="roleForm">
              <v-text-field label="نام نقش " outlined dense :rules="requiredRules"></v-text-field>
            </v-form>
          </template>
          <template v-else>
            <div v-if="permissions" class="permissionsArea">
              <v-checkbox v-model="selectedPermission" :value="item.id" v-for="(item , i) in permissions"
                          :label="item.name" :key="i"></v-checkbox>
            </div>
            <div v-else class="text-center mt-2">
              <v-progress-circular indeterminate></v-progress-circular>
            </div>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" elevation="0" @click="attachPermissionsToRole">
            افزودن
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <v-card>
        <v-card-title class="justify-space-between">
          <div>
            نقش‌ها
          </div>
          <div>
            <v-btn color="primary" elevation="0" small class="justify-space-between">
              افزودن نقش
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <div class="rolesArea">
            <v-data-table
                :headers="headers"
                :items="roles"
                v-if="roles"
                disable-pagination
                hide-default-footer
                :items-per-page="5"
                show-expand
                class="elevation-1"
            >
              <template v-slot:expanded-item="{ headers, item }" class="blue">
                <td :colspan="headers.length">
                  <v-row>
                    <v-col col="12" lg="3" v-for="(permission , i) in item.permissions" :key="i">
                      {{ permission.name }}
                    </v-col>
                  </v-row>
                </td>
              </template>
              <template v-slot:item.actions="{item}">
                <v-btn color="primary" fab x-small elevation="0" @click="editRole(item)">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      roleActionModal: false,
      roleModalAction: 'edit',
      targetRole: null,
      headers: [
        {text: 'کد ', value: 'id'},
        {text: 'نام ', value: 'name'},
        {
          text: 'عملیات',
          value: 'actions'
        }
      ],
      roles: null,
      permissions: null,
      selectedPermission: [],
    }
  },
  computed: {
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
  },
  methods: {
    getRoles() {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('getRoles').then((resp) => {
        this.roles = resp.data
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    editRole(target) {
      this.targetRole = target
      this.prepareSelectedPermission()
      this.roleActionModal = true
    },
    prepareSelectedPermission() {
      this.selectedPermission = []
      this.targetRole.permissions.forEach((role) => {
        this.selectedPermission.push(role.id)
      })
    },
    getPermissionList() {
      this.$store.dispatch('getPermissionList').then((resp) => {
        this.permissions = resp.data
      })
    },
    attachPermissionsToRole() {
      let payload = {
        role_id: this.targetRole.id,
        permission_ids: this.selectedPermission
      }
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('attachPermissionToRole', payload).then(() => {
        this.roleActionModal = false
        this.getRoles()
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    }
  },
  mounted() {
    this.getRoles()
    this.getPermissionList()
  }
}
</script>

<style>
.rolesArea .v-data-table tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
}

.permissionsArea {
  height: 250px;
  padding-top: 10px;
  overflow-x: auto;
}

.permissionsArea .v-input--selection-controls {
  margin-top: -10px !important;
  padding: 0px;
}


</style>
<template>
  <div class="mt-10">
    <v-container fluid>
      <v-card>
        <v-dialog v-model="dateFilter" width="500px">
          <v-card>
            <v-card-title>
              فیلتر‌ها
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-3">
              <v-select :items="months" item-text="title" item-value="key" v-model="selectedMonth"
                        label="ماه :"></v-select>
            </v-card-text>
            <v-card-actions class="justify-center pb-4">
              <v-btn color="primary" @click="dateFilter = false">اعمال</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showMoreDialog.visibility" width="400">
          <v-card>
            <v-card-title class="d-flex justify-center">
              {{ showMoreDialog.date }}
            </v-card-title>
            <v-card-text style="height: 300px;overflow: scroll">
              <div class="cardEvent  primary" v-for="(event , i ) in moreEvents" :key="i">
                <v-menu offset-y :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="fill-height d-flex  px-1 py-1  " v-on="on" v-bind="attrs">
                      <div class="eventBox flex-grow-1">
                        <div class="d-flex justify-space-between ">
                          <div>
                            <strong>{{ event.name }}</strong>
                            <template class="time" v-if="event.end && event.start">
                              {{ formatEventTime(event.end) }} - {{ formatEventTime(event.start) }}
                            </template>
                          </div>
                          <div v-if="event.event.extendedProperties">
                            <template
                                v-if="event.event.extendedProperties.private.session_id && event.event.extendedProperties.private.session_id != '0'">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon color="white" v-on="on" v-bind="attrs" x-small>mdi-video</v-icon>
                                </template>
                                <span>
                                برای این زمان، جلسه ساخته شده است
                              </span>
                              </v-tooltip>
                            </template>
                            <template
                                v-if="event.event.extendedProperties.private.user_id && event.event.extendedProperties.private.user_id != '0'">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon color="white" v-on="on" v-bind="attrs" x-small>mdi-account</v-icon>
                                </template>
                                <span>
                                برای این زمان، کاربر مشخص شده است
                                  </span>
                              </v-tooltip>
                            </template>
                          </div>
                        </div>

                      </div>
                    </div>
                  </template>
                  <div>
                    <div class="menusTime white">
                      <v-btn text style="width: 100%" x-small v-for="(item , i ) in renderMenuItemEvent(event)"
                             :style="{color:item.color || 'black' }"
                             :key="i"
                             class="menuItem mb-4 d-flex justify-space-between" @click="item.action()"
                             :disabled="item.disable">
                        <div>
                          {{ item.title }}
                        </div>
                        <div>
                          <v-icon x-small>mdi-arrow-left</v-icon>
                        </div>
                      </v-btn>
                    </div>
                  </div>
                </v-menu>

              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
        <ModalAreaGoolgeCalendar
            v-if="calendars"
            :modal="modals" @reloadCalendar="getEventsCalendar"
            :add-empty-time-modal="addEmptyTimeModal"
            :selected-date="selectedDate"
            :calenders="calendars"
            :selected-event="selectedDate"
        />
        <v-row>
          <v-col cols="12" lg="10">
            <div class=" fill-height" style="height: 90vh">
              <div class="d-flex justify-space-between" v-if="loadCalendar"
                   :class="$vuetify.breakpoint.mdAndDown && 'flex-wrap'">
                <div class="d-flex flex-column justify-center align-center "
                     :style="$vuetify.breakpoint.mdAndDown && 'width: 100%'">
                  <div>
                    <v-icon @click="prev" class="ml-3 mr-2" size="25">mdi-chevron-right</v-icon>
                    <div class="monthTitle text-center">
                      {{ SideBarCalendar | DateTitleFilter }}
                    </div>
                    <v-icon @click="next" class="mr-3" size="25">mdi-chevron-left</v-icon>
                  </div>
                </div>
                <div class="d-flex " :class="$vuetify.breakpoint.mdAndDown && 'mt-2 mx-2'"
                     :style="$vuetify.breakpoint.mdAndDown && 'width: 100%'">
                  <v-select class="ml-2" style="width: 120px" dense outlined label="نوع تقویم" :items="calanderTypes"
                            v-model="type" item-value="key" item-text="title"></v-select>
                  <v-btn style="height: 40px" color="primary" elevation="0" outlined @click="gotoToday">
                    امروز
                  </v-btn>
                </div>

              </div>
              <v-calendar
                  ref="calendar"
                  v-if="loadCalendar"
                  v-model="calendarModel"
                  :weekdays="w"
                  :events="eventsRendered"
                  :event-color="getEventColor"
                  @click:time="openModalAddFree"
                  :type="type"
                  dir="ltr"
                  event-more-text="{0} events hidden"
                  @click:more="openModalDay"
                  :event-height='25'
                  style="height: 90%"
                  :start='SideBarCalendar'
              >
                <template v-slot:day="item">
                  <div @click="openModalAddFree(item)"
                       style="position:absolute;top:0;left:0;z-index:0;width:100%;height: 100%;">
                  </div>
                </template>

                <template v-slot:day-label="item">
                  <div
                      style="border-radius:30px;width: 30px;height:30px; margin:0 auto 5px auto;position: relative;z-index: 2;">
                    <v-btn @click="showDayInCalendar(item.date)" fab x-small elevation="1">{{ item.day }}</v-btn>
                  </div>

                </template>
                <template v-slot:event="{ event }" @click.stop="openModalAddFree">
                  <v-menu offset-y :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="fill-height d-flex  px-1 py-1  " v-on="event.event.extendedProperties && on"
                           v-bind="event.event.extendedProperties && attrs">
                        <div class="eventBox flex-grow-1">
                          <div class="d-flex justify-space-between ">
                            <div>
                              <strong class="mx-1">{{ event.name }}</strong>
                              <template class="time" v-if="event.end && event.start">
                                <strong>{{ formatEventTime(event.end) }} - {{ formatEventTime(event.start) }}</strong>
                              </template>
                            </div>
                            <div v-if="event.event.extendedProperties">
                              <template
                                  v-if="event.event.extendedProperties.private.session_id && event.event.extendedProperties.private.session_id != '0'">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="white" v-on="on" v-bind="attrs" x-small>mdi-video</v-icon>
                                  </template>
                                  <span>
                                برای این زمان، جلسه ساخته شده است
                              </span>
                                </v-tooltip>
                              </template>
                              <template
                                  v-if="event.event.extendedProperties.private.user_id && event.event.extendedProperties.private.user_id != '0'">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="white" v-on="on" v-bind="attrs" x-small>mdi-account</v-icon>
                                  </template>
                                  <span>
                                برای این زمان، کاربر مشخص شده است
                                  </span>
                                </v-tooltip>
                              </template>
                            </div>
                          </div>

                        </div>
                      </div>
                    </template>
                    <div>
                      <div class="menusTime white">
                        <v-btn text style="width: 100%" x-small v-for="(item , i ) in renderMenuItemEvent(event)"
                               :style="{color:item.color || 'black' }"
                               :key="i"
                               class="menuItem mb-4 d-flex justify-space-between" @click="item.action()"
                               :disabled="item.disable">
                          <div>
                            {{ item.title }}
                          </div>
                          <div>
                            <v-icon x-small>mdi-arrow-left</v-icon>
                          </div>
                        </v-btn>
                      </div>
                    </div>
                  </v-menu>
                </template>

              </v-calendar>
              <div class="fill-height d-flex justify-center align-center" v-else-if="this.selectedCalendar">
                <div class="text-center">
                  <v-progress-circular indeterminate></v-progress-circular>
                  <br>
                  <p class="mt-5">
                    درحال‌آماده‌سازی‌تقویم
                  </p>
                </div>
              </div>
              <div class="fill-height d-flex justify-center align-center" v-else>
                لطفا یک تقویم را انتخاب کنید
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="2">
            <v-divider></v-divider>
            <div style="width: 100%" class="mb-3">
              <v-date-picker no-title style="border: none !important;" full-width ref="datepicker" color="primary"
                             v-model="SideBarCalendar"></v-date-picker>
            </div>
            تقویم‌ها
            <v-card class="mt-1 ml-4" elevation="0" style="box-shadow: 0 3px 5px -1px rgba(0,0,0,.2)!important;">
              <v-card-text>
                <div class="calendarsListArea " dir="ltr">
                  <v-radio-group v-model="selectedCalendar">
                    <v-radio class="pa-0 ma-0 mt-2" dense v-for="(calendar , i ) in calendars"
                             :value="calendar.google_id" :label="calendar.summary"
                             :key="i"></v-radio>
                  </v-radio-group>
                </div>
              </v-card-text>
            </v-card>


          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/*eslint-disable*/

import moment from "moment-jalaali";
import {mapGetters} from "vuex";
import {EventBus} from "@/event-bus";
import ModalAreaGoolgeCalendar from "@/components/Admin/googleCalendar/ModalAreaGoolgeCalendar";

export default {
  name: 'calendarView',
  components: {ModalAreaGoolgeCalendar},
  data() {
    return {
      calendarExpanded: 0,
      SideBarCalendar: moment(new Date()).format('YYYY-MM-DD HH:mm'),
      selectedEvent: null,
      calenderTitle: null,
      eventsRendered: [],
      modals: {
        addFreeTime: false,
        attachUserToFreeTime: false,
        deleteUserFromEvent: false,
        deleteEvent: false,
      },
      loadCalendar: false,
      selectedCalendar: null,
      dateFilter: false,
      type: "month",
      months: [
        {
          title: "January",
          key: 0,
        },
        {
          title: "February",
          key: 1,
        },
        {
          title: "March",
          key: 2,
        },
        {
          title: "April",
          key: 3,
        },
        {
          title: "May",
          key: 4,
        },
        {
          title: "June",
          key: 5,
        },
        {
          title: "July",
          key: 6,
        },
        {
          title: "August",
          key: 7,
        },
        {
          title: "September",
          key: 8,
        },
        {
          title: "October",
          key: 9,
        },
        {
          title: "November",
          key: 10,
        },
        {
          title: "December",
          key: 11,
        }
      ],
      selectedMonth: new Date().getMonth() > 9 ? "0" + new Date().getMonth() : new Date().getMonth(),
      w: [
        6,
        0,
        1,
        2,
        3,
        4,
        5,
      ],
      selectedDate: null,
      addEmptyTimeModal: false,
      recurringItems: [
        'تکی',
        'هرروز',
        'همین روز در همه‌ هفته‌ها'
      ],
      selectingTime: false,
      timePickerModal: false,
      showMoreDialog: {
        date: null,
        visibility: false
      },
      moreEvents: [],
      calendarModel: '2022/01/05',
      calanderTypes: [
        {
          title: 'روزانه',
          key: 'day'
        },
        {
          title: 'ماهانه',
          key: 'month'
        },
        {
          title: 'هفتگی',
          key: 'week'
        },
      ],
      disables: {
        deleteSession: false,
        makeSession: false,
        changeStateSession: false,
      }
    }
  },
  methods: {
    showDayInCalendar(date) {
      this.SideBarCalendar = moment(date).format("YYYY-MM-DD")
      this.type = 'day'
    },
    gotoToday() {
      this.SideBarCalendar = moment(new Date()).format("YYYY-MM-DD")
    },
    renderMenuItemEvent(event) {
      if (event.event.hasOwnProperty('extendedProperties')) {
        let menus = []
        let data = event.event.extendedProperties.private
        if (data.status == 'free') {
          menus.push({
            title: "افزودن کاربر",
            disable: false,
            action: () => {
              this.attachUserToFreeTime(event)
            },
          })
        }
        // console.log(data.user_id && !data.session_id )
        if (data.user_id && data.user_id != '0' && data.session_id == 0) {
          menus.push({
            title: "ساخت جلسه برای زمان",
            disable: this.disables.makeSession,
            action: () => {
              this.makeSessionForEvent(event)
            },
          })
          menus.push({
            title: "ساخت جلسه با بدهی",
            disable: this.disables.makeSession,
            action: () => {
              this.makeSessionForEvent(event,true)
            },
          })
        }
        if (data.session_id && data.session_id != 0) {
          if (data.status != 'used') {
            menus.push({
              title: "برگذاری جلسه",
              disable: this.disables.changeStateSession,
              action: () => {
                this.changeStateSession(event, 'used')
              },
              color: 'black'
            })
          }
          menus.push({
            title: "حذف جلسه",
            disable: this.disables.deleteSession,
            action: () => {
              this.deleteSession(event)
            },
            color: 'red'
          })

        }
        if (data.user_id && data.user_id != '0') {
          menus.push({
            title: "حذف کاربر",
            disable: false,
            action: () => {
              this.selectedDate = event
              this.modals.deleteUserFromEvent = true
            },
            color: 'red'
          })
        }
        menus.push({
          title: "حذف زمان",
          disable: false,
          action: () => {
            this.modals.deleteEvent = true
            this.selectedDate = event
          },
          color: 'red'
        })
        return menus
      }
    },
    deleteSession(event) {
      EventBus.$emit('setLoading', true)
      this.disables.deleteSession = true
      this.$store.dispatch('deleteSession', {
        "calendar_id": this.$route.query.calender_id,
        "event_id": event.event.id,
        "user_id": event.event.extendedProperties.private.user_id,
        "session_id": event.event.extendedProperties.private.session_id
      }).then(() => {
        this.getEventsCalendar()
      }).finally(() => {
        this.disables.deleteSession = false
        EventBus.$emit('setLoading', false)
      })
    },
    changeStateSession(event, status) {
      let payload = {
        calendar_id: this.$route.query.calender_id,
        event_id: event.event.id,
        session_id: event.event.extendedProperties.private.session_id,
        user_id: event.event.extendedProperties.private.user_id,
        doctor_id: event.event.extendedProperties.private.doctor_id,
        dateTime: event.event.start.dateTime,
      }
      this.disables.changeStateSession = true
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('doneSessionCalender', payload).then(() => {
        this.getEventsCalendar()
      }).finally(() => {
        EventBus.$emit('setLoading', false)
        this.disables.changeStateSession = false
      })
    },
    attachUserToFreeTime(event) {
      this.selectedDate = event.event
      this.modals.attachUserToFreeTime = true
    },
    openModalAddFree(day) {
      this.selectedDate = day
      this.modals.addFreeTime = true
    },
    next() {
      // console.log(this.$refs.calendar.next())
      // this.$refs.calendar.next()
      this.SideBarCalendar = moment(this.SideBarCalendar).add('1', 'months').format("YYYY-MM-DD")
    },
    prev() {
      // this.$refs.calendar.prev()
      this.SideBarCalendar = moment(this.SideBarCalendar).add('-1', 'months').format("YYYY-MM-DD")
    },
    openModalDay(day) {
      this.modals.addFreeTime = false

      this.moreEvents = []
      this.eventsRendered.forEach((item) => {
        let start = moment(item.start).format('YYYY-MM-DD')
        if (day.date == start) {
          this.moreEvents.push(item)
        }
      })
      this.showMoreDialog = {
        visibility: true,
        date: moment(day.date).format('YYYY/MM/DD')
      }
    },
    getEventColor(item) {
      return item.color
    },
    getCalendars() {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('getCalendars').finally(() => {
        // console.log(this.$store.getters.,)
        EventBus.$emit('setLoading', false)
      })
    },
    getEventsCalendar() {
      if (this.$route.query.calender_id) {
        this.showMoreDialog.visibility = false
        this.eventsRendered = []
        this.loadCalendar = false
        // this.showMoreDialog.visibility = fa
        EventBus.$emit('setLoading', true)
        this.$store.dispatch('getEventsCalendar', {calendar_id: this.$route.query.calender_id}).then((resp) => {
          this.prepareEvents()
        }).finally(() => {
          this.addEmptyTimeModal = false;
          EventBus.$emit('setLoading', false)
        })
      }

    },
    formatEventTime(date) {
      return new Date(date).toLocaleTimeString('en-US', {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false
      })
    },
    clickOnEvent(item) {
      console.log(item)
    },
    startAddEmptyTimeProcess(item) {
      this.selectedDate = item.date
      this.addEmptyTimeModal = true
    },
    prepareEvents() {
      this.events.forEach((item) => {
        // console.log(item.start.dateTime)
        // console.log(moment(item. start.dateTime).format('YYYY-MM-DD hh:mm'))

        if (item.start.dateTime && item.end.dateTime) {
          this.eventsRendered.push(
              {
                name: item.summary,
                start: moment(item.start.dateTime).format('YYYY-MM-DD HH:mm'),
                end: moment(item.end.dateTime).format('YYYY-MM-DD HH:mm'),
                event: item,
                color: this.prepareColorForEvent(item)
              }
          )
        } else if (item.start.date && item.end.date) {
          this.eventsRendered.push(
              {
                name: item.summary,
                start: moment(item.start.date).format('YYYY-MM-DD'),
                end: moment(item.end.date).format('YYYY-MM-DD'),
                event: item,
                color: this.prepareColorForEvent(item)
              }
          )
        }
      })
      this.loadCalendar = true
    },
    prepareColorForEvent(item) {
      let data = item.extendedProperties.private
      if (item.summary == 'free') {
        return 'grey'
      }
      if (data.user_id && data.user_id != 0) {
        if (data.session_id && data.session_id != 0) {
          if (data.status == 'used'){
            return '#54A047'
          }else{
            return '#90CAF9'
          }
        } else {
          return '#F08C2D'
        }
      }

      return 'primary'
    },
    makeSessionForEvent(event,indebt=false) {
      console.log(event)
      let payload = {
        "doctor_id": event.event.extendedProperties.private.doctor_id,
        "user_id": event.event.extendedProperties.private.user_id,
        "calendar_id": this.$route.query.calender_id,
        "event_id": event.event.id,
        "datetime_start": event.event.start.dateTime,
        "datetime_end": event.event.end.dateTime,
        "indebt": indebt,
      }
      EventBus.$emit('setLoading', true)
      this.disables.makeSession = true
      this.$store.dispatch('makeSessionForEvent', payload).then((resp) => {
        this.getEventsCalendar()
      }).finally(() => {
        this.disables.makeSession = false
        EventBus.$emit('setLoading', false)
      })
    }
  },
  mounted() {

    // this.prepareEvents()
    this.getCalendars()
    if (this.$route.query.calender_id) {
      this.selectedCalendar = this.$route.query.calender_id
      this.getEventsCalendar()
    }
    // this.getEventsCalendar()
  },
  watch: {
    SideBarCalendar() {
      // this.eventsRendered = []
      // this.prepareEvents()
      // this.getEventsCalendar()
    },
    selectedCalendar() {
      this.$router.push({name: 'calendarCrm', query: {calender_id: this.selectedCalendar}})
      // this.getEventsCalendar()
    },
    selectedMonth(val) {
      if (val < 10) {
        return "0" + val
      }
    },
    '$route.query.calender_id'() {
      this.getEventsCalendar()
    }
  },
  filters: {
    filterDate(date) {
      return new Date(date).toLocaleDateString('en-us', {weekday: "long", month: "long", day: "numeric"})
      // return moment(date).format('dddd,MMMM d')
    },
    DateTitleFilter(date) {
      return new Date(date).toLocaleDateString('en-us', {year: 'numeric', month: "long"})
    }
  },
  computed: {
    ...mapGetters({
      calendars: 'getCalenders',
      events: 'getEventsCalender'
    })
  }
}
</script>

<style>
.monthTitle {
  font-size: 15pt;
  width: 150px;
  display: inline-block
}

.menusTime {
  padding: 10px;
}

.menuItem {
  font-size: 10px;
  cursor: pointer;
}

.eventBox .time {
  text-align: center;
  font-size: 8px;
}

.theme--light.v-calendar-events .v-event-more {
  text-align: center !important;
}

.v-expansion-panel::before {
  box-shadow: none;
}

.calendarsListArea {
  max-height: 350px;
  overflow: auto;
}

.v-event-timed {
  border: 5px solid brown !important;
}

.cardEvent {
  margin: 5px 0px;
  color: white;
  padding: 2px;
  text-align: center;
  border-radius: 10px;
}

.dayLabel {
  font-size: 13px;
  height: 40px;
}

.listTimeSelected {
  position: absolute;
  top: 40px;
  background: white;
  width: 100%;
  height: 100px;
  overflow-y: scroll;
  z-index: 10;
  border: 1px solid #ddd;
  border-radius: 0px 0px 10px 10px;
}


/*.v-picker__title {*/
/*  display: none;*/
/*}*/
</style>
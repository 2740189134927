<template>
  <div>
    <v-container fluid>
      <v-dialog v-model="questionModal" width="500">
        <v-card>
          <v-card-title>
            <template v-if="questionAction == 'add'">
              افزودن سوال
            </template>
            <template v-else>
              ویرایش سوال
            </template>
          </v-card-title>
          <v-card-text>
            <v-form ref="questionForm" @submit.prevent="questionAction == 'add'  ? storeQuestion() : editQuestion() ">
              <v-text-field :rules="requiredRules" v-model="targetQuestion.q_text" outlined dense
                            label="تیتر فارسی"></v-text-field>
              <v-text-field dir="ltr" :rules="requiredRules" outlined dense v-model="targetQuestion.q_en_text"
                            label="تیتر انگلیسی"></v-text-field>
              <v-select :rules="requiredRules" dense :items="typeQuestions" item-text="title" item-value="key" outlined
                        label="نوع سوال" v-model="targetQuestion.type"></v-select>
              <v-select :rules="requiredRules" :items="viewQuestions" item-value="key" item-text="title" dense outlined
                        label="نوع نمایش سوال" v-model="targetQuestion.vue_type"></v-select>
              <v-checkbox label="این سوال در هر صورت پرسیده شود" v-model="targetQuestion.step"
                          :value="1"></v-checkbox>
            </v-form>
          </v-card-text>
          <v-card-actions>

            <v-btn color="primary" elevation="0" @click="questionAction == 'add'  ? storeQuestion() : editQuestion() ">
              <template v-if="questionAction == 'add'">
                افزودن
              </template>
              <template v-else>
                ویرایش
              </template>
            </v-btn>
            <v-btn color="primary" outlined v-if="questionAction == 'edit'" @click="openModalAddOption(targetQuestion)">
              افزودن گزینه
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="modalAddOption" width="500">
        <v-card>
          <v-card-title>
            افزودن گزینه‌ برای سوال
          </v-card-title>
          <v-card-text>
            <v-form ref="addOptionForm">
              <v-text-field :rules="requiredRules" v-model="option.text" dense outlined
                            label="تیتر فارسی"></v-text-field>
              <v-text-field  dir="ltr" :rules="requiredRules" v-model="option.en_text" dense outlined
                            label="تیتر انگلیسی"></v-text-field>
              <v-checkbox :value="1"
                          v-model="option.has_question"
                          label="بعد از این گزینه سوالی نمایش داده شود؟"></v-checkbox>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" elevation="0" @click="insertOption">
              افزودن
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card>
        <v-card-title class="justify-space-between">
          <div>
            لیست سوالات
          </div>
          <v-btn color="primary" small elevation="0" @click="openModalAddQuestion">
            افزودن سوال
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="questionsArea">
            <v-data-table
                v-if="questions"
                hide-default-footer
                :headers="headers"
                :items="questions"
                disable-pagination
                disable-initial-sort
                show-expand
                class="elevation-0"
            >
              <template v-slot:item.actions="{item}">
                <v-btn color="primary" fab x-small elevation="0" @click="startEditQuestion(item)">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:item.q_en_text="{item}">
                <div dir="ltr">
                  {{ item.q_en_text }}
                </div>
              </template>
              <template v-slot:expanded-item="{ headers, item }" class="blue">
                <td :colspan="headers.length">
                  <div class="pa-5">
                    <v-card>
                      <div class="px-5 py-5  flex-grow-1" style="width: 100%">
                        <div class="d-flex justify-space-between">
                          <div>
                            <p>
                              <b>
                                گزینه‌ها :
                              </b>
                            </p>
                          </div>
                          <div>
                            <v-btn small elevation="0" color="primary" @click="openModalAddOption(item)">
                              افزودن گزینه
                            </v-btn>
                          </div>
                        </div>
                        <v-data-table
                            :headers="optionHeader"
                            :items="item.question_options"
                            disable-pagination
                            hide-default-footer
                        >
                          <template v-slot:item.actions="{item}">
                            <v-btn
                                x-small
                                fab
                                color="primary"
                                style="text-decoration: none"
                                elevation="0"
                                :to="{name:'matchMakingOption',params:{optionId:item.id , testId:$route.params.testId}}">
                              <v-icon small>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <template v-slot:item.AI="{item}">
                            <v-checkbox :value="item.id" v-model="AIoptions"></v-checkbox>
                          </template>
                        </v-data-table>
                        <!--                    <div class="optionItem" v-for="(option ,i) in item.question_options" :key="i">-->
                        <!--                      {{ option.text }}-->
                        <!--                    -->
                        <!--                    </div>-->
                      </div>
                    </v-card>
                  </div>
                </td>
              </template>

            </v-data-table>
          </div>
        </v-card-text>
        <v-card-actions>
            <div class="">
              <v-fade-transition>
                <v-btn color="primary" class="fixedBtnAi" v-if="AIoptions.length != 0">
                  ثبت
                </v-btn>
              </v-fade-transition>
            </div>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "@/event-bus";

export default {
  data() {
    return {
      optionHeader: [
        {
          text: 'کد',
          align: 'start',
          value: 'id',
        },
        {
          text: 'متن فارسی',
          align: 'start',
          value: 'text',
        },
        {
          text: 'متن انگلیسی',
          align: 'left',
          value: 'en_text',
        },
        {
          text: 'ارسال اطلاعات برای هوش‌مصنوعی',
          align: 'start',
          value: 'AI',
        },
        {
          text: '',
          align: 'start',
          value: 'actions',
        },
      ],
      headers: [
        {
          text: 'id',
          align: 'start',
          value: 'id',
        },
        {text: 'تیتر سوال', value: 'q_text'},
        {text: 'تیتر انگلیسی', value: 'q_en_text', align: 'left'},
        {text: '', value: 'actions'},
      ],
      AIoptions: [],
      questions: null,
      questionModal: false,
      questionAction: null,
      typeQuestions: [
        {
          key: 'radio',
          title: "تک جوابی"
        },
        {
          key: 'checkbox',
          title: "چند جوابی"
        },
        {
          key: 'bool',
          title: "درست غلط"
        },
        {
          key: 'number',
          title: "اعداد"
        },
        {
          key: 'text',
          title: "متن"
        },

      ],
      viewQuestions: [
        {
          key: 'checkbox',
          title: 'چند جوابی'
        },
        {
          key: "text",
          title: "فرم متنی"
        },
        {
          key: "trueFalse",
          title: "درست غلط"
        },
        {
          title: "بازه اعداد",
          key: "numericRange",
        },
        {
          title: "انتخاب چندگزینه",
          key: "selectBox",
        },
        {
          title: "انخاب تک گزینه‌ای",
          key: "radio",
        },
      ],
      targetQuestion: {},
      modalAddOption: false,
      option: {},
    }
  },
  methods: {
    openModalAddQuestion() {
      this.questionModal = true
      this.questionAction = 'add'
      this.$refs.questionForm.reset()
    },
    getTestQuestions() {
      let payload = {
        test_id: this.$route.params.testId,
        withOptions: 1,
      }
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('getMatchMakingTestQuestions', payload).then((resp) => {
        this.questions = resp.data
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    storeQuestion() {
      if (this.$refs.questionForm.validate()) {
        this.targetQuestion.test_id = this.$route.params.testId
        let payload = {
          questions: [
            this.targetQuestion
          ]
        }
        EventBus.$emit('setLoading', true)
        this.$store.dispatch('storeMatchMakingTestQuestions', payload).then((resp) => {
          this.questionModal = false
          this.$refs.questionForm.reset()
          this.getTestQuestions()
        }).finally(() => {
          EventBus.$emit('setLoading', false)
        })
      }
    },
    startEditQuestion(question) {
      this.targetQuestion = JSON.parse(JSON.stringify(question))
      this.questionModal = true
      this.questionAction = 'edit'
    },
    editQuestion() {
      this.targetQuestion.test_id = this.$route.params.testId
      // this.targetQuestion.question_id = this.targetQuestion.id
      let payload = {
        question: {
          test_id: this.$route.params.testId,
          id: this.targetQuestion.id,
          q_text: this.targetQuestion.q_text,
          q_en_text: this.targetQuestion.q_en_text,
          type: this.targetQuestion.type,
          vue_type: this.targetQuestion.vue_type,
          step: this.targetQuestion.step,
        },
      }
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('editMatchMakingTestQuestions', payload).then(() => {
        this.questionModal = false
        this.getTestQuestions()
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    openModalAddOption(targetQuestion) {
      this.questionModal = false
      this.targetQuestion = JSON.parse(JSON.stringify(targetQuestion))
      this.modalAddOption = true
    },
    insertOption() {
      if (this.$refs.addOptionForm.validate()) {
        let payload = {
          options: [
            {
              text: this.option.text,
              type: 'text',
              has_question: this.option.has_question == 1,
              en_text: this.option.en_text,
              question_id: this.targetQuestion.id,
              local_id: 1,
            },
          ]
        }
        EventBus.$emit('setLoading', true)
        this.$store.dispatch('insertMatchMakingOption', payload).finally(() => {
          EventBus.$emit('setLoading', false)
          this.getTestQuestions()
          this.$refs.addOptionForm.reset()
          this.modalAddOption = false
        })
      }
    }
  },
  mounted() {
    this.getTestQuestions()
  },
  computed: {
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
  },
}
</script>

<style>
@media (min-width: 200px) and (max-width: 600px) {
 .fixedBtnAi{
   right: 0px !important;
   left: 0px !important;
   width: 100% !important;
 }
}
.fixedBtnAi{
  position: fixed;
  bottom: 0px;
  right:  100px;
  z-index: 10;
}
.optionItem {
  line-height: 25pt;
}

.questionsArea .v-data-table tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
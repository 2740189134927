<template>
  <div>
    <v-menu
        left
        bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            icon
            v-bind="attrs"
            v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
            v-for="(item , i) in items"
            :key="i"
            @click="item.action()"
        >
          <v-list-item-title>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

  </div>
</template>

<script>

export default {
  props: {
    user_id: Number,
  },
  data() {
    return {
      items: [
        {
          title: 'تخصیص پشتیبان',
          action: () => {
            this.$emit('dialogAssignAccount', {
              dialog: true,
              userID: this.user_id,
            })
          }
        },
        {
          title:"لغو جلسه",
          action: () => {
            this.$router.push({name: 'cancelSessionCrm', params: {clientId: this.user_id}});
          }
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
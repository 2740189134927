<template>
  <v-container fluid>

    <v-card class="elevation-0">

      <v-card-title id="v-step-0">
        لیست فاکتور ها
      </v-card-title>
      <v-card-text>
        <TableOptions :custom-query-parameter="{page:1}" :hasExport="true" :searchFilters="searchFilters"
                      :action-required-permission="{
                              download:'admin.export.cart.transactions.default',
                            }"
                      @applyFilters="applyFilters"></TableOptions>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
            :headers="factorHeaders"
            :items="factors"
            show-expand

            hide-default-footer
        >
          <template v-slot:item.methods="{item}">
            <template v-for="(payment,index) in paidPayments(item)">
              <div :key="index" class="pa-0 ma-0 caption" v-if="payment.method!=null">
                <span>{{ payment.method.title }}</span>
                <v-divider v-if="index < paidPayments(item).length-1"></v-divider>
              </div>

            </template>

          </template>

          <template v-slot:item.date="{item}">
            <!--<div>-->
            <!--{{getLastPaymentDate(item)}}-->
            <!--</div>-->
            <div>
              <div>{{ item.last_payment_date }}</div>
            </div>
          </template>

          <template v-slot:item.state="{item}">
            {{ item.state | resolveCartState }}
          </template>
          <template v-slot:item.actions="{item}">
            <a :href="'orders/'+item.id"
               @click.prevent="$router.push({name:'singleOrder',params:{factorId:item.id}})"
               v-if="checkRoleAndPermissions('admin.cart.transactions.show')">
              <v-btn fab x-small>
                <v-icon small>mdi-view-headline</v-icon>
              </v-btn>
            </a>

          </template>

          <template v-slot:item.user.full_name="{ item }">
            <div style="position: relative;">
              <div v-if="item.needsToRequestSession">
                <a target="_blank" :href="'clients/'+item.user.id" class="new-payment-badge"
                   style="text-decoration: none">new</a>
              </div>
              <div v-if="item.is_first_purchase">
                <a target="_blank" :href="'clients/'+item.user.id" class="is_first_badge"
                   style="text-decoration: none">first purchase</a>
              </div>
              <v-chip :href="'clients/'+item.user.id">
                <a @click.prevent="getClientProfile(item.user.id)" :href="'clients/'+item.user.id"
                   style="text-decoration: none">{{ item.user.full_name }}</a>
              </v-chip>
            </div>

          </template>

          <template v-slot:item.user.mobile="{item}">
                        <span style="display: block; text-align: left;direction:ltr">
                            {{ item.user.mobile }}
                        </span>
          </template>
          <template v-slot:item.final_amount="{item}">
            {{ Number(item.final_amount).toLocaleString() }}
          </template>
          <template v-slot:item.remaining_amount="{item}">
            {{ Number(item.remaining_amount).toLocaleString() }}
          </template>
          <template v-slot:item.packages="{item}">
            <v-list-item v-for="(pack,index) in item.packages" :key="index" dense>
              <v-list-item-icon class="pa-1 ma-0">
                {{ pack.pivot.quantity }}
                <v-icon x-small class="mx-2"> fas fa-times</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="caption">
                <span>{{ pack.title }}</span>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-0 ma-0">
              <v-card class="elevation-0 pa-0 ma-0">
                <v-card-text>
                  <v-card class="ma-2 elevation-1">
                    <v-card-text>

                      <v-data-table
                          dense
                          hide-default-footer
                          :headers="packageHeader"
                          :items="item.packages"
                      >
                        <template v-slot:top>
                          بسته ها
                          <v-divider></v-divider>
                        </template>
                        <template v-slot:item.pivot.discount="{item}">

                                                        <span class="ma-3">
                                                            {{ (Number(item.pivot.discount).toLocaleString()) }}
                                                        </span>

                        </template>
                        <template v-slot:item.pivot.final_price="{item}">

                          <template v-if="item.currency=='rial'">
                            {{ Number(item.pivot.final_price).toLocaleString() }}
                          </template>

                          <template v-if="item.currency=='dollar'">
                            {{ Number(item.pivot.final_price) }}
                          </template>


                        </template>
                        <template v-slot:item.pivot.price="{item}">

                          <template v-if="item.currency=='rial'">
                            {{ Number(item.pivot.price).toLocaleString() }}
                          </template>


                          <template v-if="item.currency=='dollar'">
                            {{ Number(item.pivot.price) }}
                          </template>

                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>

                  <v-card class="ma-2" dense>

                    <v-card-text>
                      <v-data-table
                          dense
                          hide-default-footer
                          :headers="paymentsHeaders"
                          :items="item.payments"
                      >
                        <template v-slot:top>
                          پرداخت ها
                          <v-divider></v-divider>
                        </template>
                        <template v-slot:item.type="{item}">
                          {{ item.type | resolvePaymentType }}
                        </template>
                        <template v-slot:item.state="{item}">
                          {{ item.state | resolvePaymentState }}
                        </template>
                        <template v-slot:item.method="{item}">
                                                    <span v-if="item.method!=null">
                                                        {{ item.method.title }}
                                                    </span>
                        </template>
                        <template v-slot:item.date="{item}">
                                                    <span v-if="item.date!=null">
                                                        {{ item.dateTime }}
                                                    </span>
                        </template>

                        <template v-slot:item.final_amount="{item}">
                          <template v-if="item.currency != 'dollar'">
                            {{ (Number(item.final_amount).toLocaleString()) }}
                          </template>
                          <template v-else>
                                                        <span class="ma-3">
                                                            {{ (Number(item.final_amount).toLocaleString()) }}
                                                        </span>
                          </template>
                        </template>


                      </v-data-table>
                    </v-card-text>
                  </v-card>

                </v-card-text>
              </v-card>
              <!--</v-sheet>-->
            </td>
          </template>
        </v-data-table>

        <!--<v-card class="my-8 v-step-1">-->

        <!--<v-expansion-panels>-->
        <!--<v-expansion-panel>-->
        <!--<v-row dense>-->
        <!--<v-col>-->
        <!--<div class="text-center">-->
        <!--مراجعه کننده-->
        <!--</div>-->
        <!--</v-col>-->
        <!--<v-col>-->
        <!--<div class="text-center">-->
        <!--ایمیل-->
        <!--</div>-->
        <!--</v-col>-->
        <!--<v-col>-->
        <!--<div class="text-center">-->
        <!--موبایل-->
        <!--</div>-->
        <!--</v-col>-->
        <!--<v-col>-->
        <!--<div class="text-center">-->
        <!--تاریخ-->
        <!--</div>-->
        <!--</v-col>-->
        <!--<v-col>-->
        <!--<div class="text-center">-->
        <!--ساعت-->
        <!--</div>-->
        <!--</v-col>-->
        <!--<v-col>-->
        <!--<div class="text-center">-->
        <!--مبلغ پرداختی-->
        <!--</div>-->
        <!--</v-col>-->
        <!--<v-col>-->
        <!--<div class="text-center">-->
        <!--تخفیف-->
        <!--</div>-->
        <!--</v-col>-->
        <!--<v-col>-->
        <!--<div class="text-center">-->
        <!--وضعیت-->
        <!--</div>-->
        <!--</v-col>-->
        <!--</v-row>-->
        <!--</v-expansion-panel>-->
        <!--<v-expansion-panel-->
        <!--v-for="(item,i) in factors"-->
        <!--:key="i"-->
        <!--&gt;-->


        <!--<v-expansion-panel-header>-->

        <!--<v-data-table></v-data-table>-->
        <!--<v-row dense>-->
        <!--<v-col class="text-center">-->
        <!--&lt;!&ndash;{{Object.keys(item)}}&ndash;&gt;-->
        <!--{{item.user.full_name}}-->
        <!--</v-col>-->
        <!--<v-col class="text-center">-->
        <!--&lt;!&ndash;{{Object.keys(item)}}&ndash;&gt;-->
        <!--{{item.user.email}}-->
        <!--</v-col>-->
        <!--<v-col class="text-center">-->
        <!--&lt;!&ndash;{{Object.keys(item)}}&ndash;&gt;-->
        <!--{{item.user.mobile}}-->
        <!--</v-col>-->
        <!--<v-col class="text-center">-->
        <!--&lt;!&ndash;{{Object.keys(item)}}&ndash;&gt;-->
        <!--{{item.submitted_at}}-->
        <!--</v-col>-->
        <!--<v-col class="text-center">-->
        <!--&lt;!&ndash;{{Object.keys(item)}}&ndash;&gt;-->
        <!--{{item.submitted_at}}-->
        <!--</v-col>-->

        <!--<v-col class="text-center">-->
        <!--<template v-if="item.currency != 'dollar'">-->
        <!--{{(Number(item.price).toLocaleString())}} {{item.currency}}-->
        <!--</template>-->
        <!--<template v-else>-->
        <!--<span class="ma-3">{{ item.price}}</span>-->
        <!--<span>{{item.currency | resolveCurrency }}</span>-->
        <!--</template>-->
        <!--</v-col>-->
        <!--<v-col class="text-center">-->
        <!--{{item.discount}}-->
        <!--</v-col>-->
        <!--<v-col class="text-center">-->
        <!--{{item.state | resolveCartState}}-->
        <!--</v-col>-->

        <!--</v-row>-->
        <!--</v-expansion-panel-header>-->
        <!--<v-expansion-panel-content>-->


        <!--<v-row dense>-->
        <!--<v-col cols="12">-->
        <!--<v-card>-->
        <!--<v-card-title>-->
        <!--بسته های خریداری شده-->
        <!--</v-card-title>-->
        <!--<v-divider></v-divider>-->
        <!--<v-card-text>-->
        <!--<v-data-table-->
        <!--hide-default-footer-->
        <!--:headers="packageHeader"-->
        <!--:items="item.packages"-->
        <!--&gt;-->
        <!--<template v-slot:item.pivot.final_price="{item}">-->

        <!--<template v-if="item.currency=='rial'">-->
        <!--{{Number(item.pivot.final_price).toLocaleString()}}-->
        <!--</template>-->

        <!--<template v-if="item.currency=='dollar'">-->
        <!--{{Number(item.pivot.final_price)}}-->
        <!--</template>-->


        <!--</template>-->
        <!--<template v-slot:item.pivot.price="{item}">-->

        <!--<template v-if="item.currency=='rial'">-->
        <!--{{Number(item.pivot.price).toLocaleString()}}-->
        <!--</template>-->


        <!--<template v-if="item.currency=='dollar'">-->
        <!--{{Number(item.pivot.price)}}-->
        <!--</template>-->

        <!--</template>-->
        <!--</v-data-table>-->
        <!--</v-card-text>-->
        <!--</v-card>-->
        <!--</v-col>-->
        <!--</v-row>-->
        <!--<v-row dense>-->
        <!--<v-col cols="12">-->
        <!--<v-card>-->

        <!--<v-card-text>-->
        <!--<v-data-table-->
        <!--hide-default-footer-->
        <!--:headers="paymentsHeaders"-->
        <!--:items="item.payments"-->
        <!--&gt;-->
        <!--<template v-slot:item.type="{item}">-->
        <!--{{item.type | resolvePaymentType}}-->
        <!--</template>-->
        <!--<template v-slot:item.state="{item}">-->
        <!--{{item.state | resolvePaymentState}}-->
        <!--</template>-->

        <!--<template v-slot:item.final_amount="{item}">-->
        <!--<template v-if="item.currency != 'dollar'">-->
        <!--{{(Number(item.final_amount).toLocaleString())}}-->
        <!--</template>-->
        <!--<template v-else>-->
        <!--<span class="ma-3">{{ item.final_amount}}</span>-->
        <!--</template>-->
        <!--</template>-->
        <!--</v-data-table>-->
        <!--</v-card-text>-->
        <!--<v-card-actions v-if="item.state=='submit'">-->
        <!--<v-spacer></v-spacer>-->
        <!--<v-btn class="blue white&#45;&#45;text" @click="payFactor(item)">-->
        <!--<span v-if="item.canBePaid==true">-->
        <!--پرداخت-->
        <!--</span>-->
        <!--<span v-else>-->
        <!--الحاق به سبد خرید-->
        <!--</span>-->

        <!--</v-btn>-->
        <!--</v-card-actions>-->
        <!--</v-card>-->
        <!--</v-col>-->
        <!--</v-row>-->


        <!--</v-expansion-panel-content>-->
        <!--</v-expansion-panel>-->
        <!--</v-expansion-panels>-->
        <!--</v-card>-->
        <div class="text-center" data-v-step="2">
          <v-pagination
              @input="handlerPagination(getAdminFactors)"
              v-model="page"
              :length="total"
          ></v-pagination>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
/*eslint-disable*/
import {EventBus} from "../../event-bus";
import {adminCartFactorsFilters} from "../../searchFilters";
import TableOptions from '@/components/TableOptions.vue';
import moment from 'moment-jalaali';
import UrlQueryMixin from "@/Mixin/UrlQueryMixin";

export default {
  mixins: [UrlQueryMixin],
  components: {
    TableOptions
  },
  mounted: function () {
    // this.$tours['myTour'].start()
  },
  data() {
    return {

      factorHeaders: [
        {
          text: 'مراجعه کننده', align: 'right', value: 'user.full_name'
        },
        {
          text: 'موبایل', align: 'left', value: 'user.mobile'
        },
        {
          text: 'تاریخ', align: 'center', value: 'date'
        },
        {
          text: 'ساعت', align: 'center', value: 'time'
        },
        {
          text: 'بسته ها', align: 'right', value: 'packages'
        },
        {
          text: 'مبلغ فاکتور', align: 'left', value: 'final_amount'
        },
        {
          text: 'باقیمانده', align: 'left', value: 'remaining_amount'
        },
        {
          text: 'ارز', align: 'right', value: 'currency'
        },
        {
          text: 'کد تخفیف', align: 'left', value: 'coupon_code'
        },
        {
          text: 'ایمیل', align: 'left', value: 'user.email'
        },
        {
          text: 'متد', align: 'center', value: 'methods'
        },
        {
          text: 'وضعیت', align: 'center', value: 'state'
        },
        {
          text: '', align: 'center', value: 'actions'
        },
      ],
      options: {},
      searchFilters: adminCartFactorsFilters,
      steps: [
        {
          target: '#v-step-0',  // We're using document.querySelector() under the hood
          params: {
            enableScrolling: false,
          },
          header: {
            title: 'Get Started',
          },
          content: `کانتنت 1`
        },
        {
          target: '.v-step-1',
          // params: {
          //     enableScrolling: false,
          // },
          content: 'کانتنت 2'
        },
        {
          target: '[data-v-step="2"]',
          content: 'کانتنت 3',
          params: {
            placement: 'top', // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            // enableScrolling: false,
          }
        }
      ],


      paymentsHeaders: [
        {text: 'مبلغ', value: 'final_amount'},
        {text: 'نوع', value: 'type'},
        {text: 'متد', value: 'method'},
        {text: 'تاریخ', value: 'date'},
        {text: 'وضعیت', value: 'state'},

      ],
      headers: [
        {
          text: 'ارز',
          align: 'center',
          sortable: false,
          value: 'currency',
        },
        {text: 'مبلغ پرداختی', value: 'price', align: 'center'},
        {text: 'تخفیف', value: 'discount', align: 'center'},
        // {text: 'تخفیف محصولات', value: 'items_discount', align: 'center'},
        {text: 'تاریخ ', value: 'submitted_at', align: 'center'},
      ],
      packageHeader: [
        {text: 'عنوان', value: 'title', align: 'center'},
        {text: 'تعداد', value: 'pivot.quantity', align: 'center'},
        {text: 'تعداد جلسات', value: 'session_count', align: 'center'},
        {text: 'مبلغ', value: 'pivot.price', align: 'center'},
        {text: 'تخفیف', value: 'pivot.discount', align: 'center'},
        {text: 'مبلغ نهایی', value: 'pivot.final_price', align: 'center'},
      ],
      page: 1,
      total: 1,
      factors: [],
    }
  },
  computed: {},
  created() {
    this.fillPageState()
    // this.getAdminFactors();
  },
  methods: {
    getLastPaymentDate(item) {
      return new Date(Math.max(...item.payments.map(payment => new Date(payment.date))));
    },
    paidPayments(factor) {
      return factor.payments.filter(item => this.paymentIsPaid(item))
    },
    paymentIsPaid(payment) {

      return payment.state == 'paid';
    },
    getClientProfile(id) {
      this.$router.push({name: 'clientDetail', params: {clientId: id}});
    },
    applyFilters(options) {
      this.resetPaginationInApplyFilterIfNeeded(options)
      this.options = options;
      this.getAdminFactors();
    },
    payFactor(item) {
      EventBus.$emit('setLoading', true);
      let options = {
        factorId: item.id,
        userId: this.$store.getters.getUserInfo.id
      }
      // this.$store.dispatch('payFactor', options).then((resp) => {
      //     let result = resp.data;
      //
      //     if (result.state == 'SUCCESS') {
      //
      //         if (!result.hasOwnProperty('url')) {
      //             if (result.state == 'SUCCESS') {
      //                 this.$store.commit('setCart', result.result);
      //                 this.$router.push({name: 'clientCart', params: {warning: result.warning}})
      //             }
      //         } else {
      //             window.location = result.url;
      //         }
      //
      //     } else {
      //         EventBus.$emit('notify', 'red', undefined, resp.data.message);
      //     }
      //
      // }).catch((resp) => {
      //     console.log(resp);
      // }).finally(() => {
      //     EventBus.$emit('setLoading', false);
      // })
    },

    getAdminFactors() {
      EventBus.$emit('setLoading', true);
      console.log('options', this.options)
      this.$store.dispatch('adminGetFactors', {page: this.page, options: this.options}).then((data) => {

        // console.log(data);
        this.total = data.data.pagination.total_pages;
        console.log(data.data.factors);
        this.factors = data.data.factors;
      }).finally(() => {
        EventBus.$emit('setLoading', false);
      });
    }

  },
  watch: {

    page() {

      // this.$vuetify.goTo(this.$refs.factors.offsetTop);
    }
  },
  filters: {
    toJalali(date) {
      return moment(date, "YYYY-M-DD HH:mm:ss").format('jYYYY-jMM-jDD HH:mm:ss');
    },
    resolveCartState(input) {
      let resolved = '';
      switch (input) {
        case 'paid':
          resolved = 'پرداخت شده';
          break;
        case 'submit' :
          resolved = 'نهایی شده';
          break;
        default:
          resolved = input;
      }
      return resolved;
    },
    resolvePaymentType(input) {
      if (input == 'online') {
        return 'آنلاین';
      } else if (input == 'wallet_debt_settlement') {
        return 'بابت بدهی کیف پول';
      } else if (input == 'offline') {
        return 'پرداخت آفلاین';
      } else if (input == 'wallet_payment') {
        return 'پرداخت از طریق کیف پول';
      }

    },
    resolveCartState(state) {
      let result;
      switch (state) {
        case 'submit' :
          result = 'پیش فاکتور';
          break;
        case 'partially_paid' :
          result = 'پیش پرداخت';
          break;
        case 'paid':
          result = 'پرداخت شده';
          break;
      }
      return result;
    },
    resolvePaymentState(state) {
      let result;
      switch (state) {
        case 'awaiting' :
          result = 'در انتظار بازگشت از بانک'
          break;
        case 'failed':
          result = 'لغو شده';
          break;
        case 'paid':
          result = 'پرداخت شده';
          break;
        case 'rejected':
          result = 'رد شده';
          break;
      }
      return result;
    }
  }
}
</script>

<style>
/*#sessions .v-card:not(.v-sheet--tile):not(.v-card--shaped):not(:first-child) {*/
/*border-radius: 15px;*/
/*border-left: 8px solid #43378C;*/
/*}*/

/*#sessions .v-card:not(.v-sheet--tile):not(.v-card--shaped):first-child {*/
/*border-radius: 15px;*/
/*border-left: 8px solid #86C23B;*/
/*}*/
</style>

<style scoped>
/*.green-border {*/
/*border: 5px solid #86C23B;*/
/*}*/
.new-payment-badge {
  font-size: .7em;
  cursor: pointer;
  font-weight: bolder;
  position: absolute;
  right: -15px;
  top: -3px;
  padding: 2px 3px;
  line-height: 12px;
  background-color: rgba(230, 0, 0, 1);
  color: white;
  border-radius: 5px;
  z-index: 2;
}

.is_first_badge {
  font-size: .7em;
  cursor: pointer;
  font-weight: bolder;
  position: absolute;
  right: -15px;
  bottom: -8px;
  padding: 2px 3px;
  line-height: 12px;
  background-color: green;
  color: white;
  border-radius: 5px;
  z-index: 2;
}
</style>

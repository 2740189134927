<template>
  <div>
    <v-card-text v-if="clientProfile!=null && newProfile!=null">
      <p class="font-weight-bold">اطلاعات کاربر</p>
      <v-form ref="profileForm">
        <v-row>
          <v-col cols="12" md="6" lg="3" v-for="(item,i) in titles" :key="i" @click="selectForEdit(item.key)">
            <v-fade-transition mode="out-in">
              <template>
                <div :key="i + '-edit'">
                  <template v-if="item.type == 'text' || item.type == 'number' || item.type == 'email'">
                    <template v-if="item.required">
                      <v-text-field
                          dense outlined :label="item.title"
                          v-model="newProfile[item.key]"
                          :rules="item.rule"
                      ></v-text-field>
                    </template>
                    <template v-else>
                      <v-text-field
                          dense outlined :label="item.title" :type="item.type"
                          v-model="newProfile[item.key]"
                      ></v-text-field>
                    </template>
                  </template>
                  <template v-else-if="item.type == 'select'">
                    <v-select
                        dense
                        v-model="newProfile[item.key]"
                        :items="item.items"
                        item-text="title"
                        item-value="value"
                        :label="item.title"
                        outlined
                        :rules="item.rule"
                    ></v-select>
                  </template>
                  <template v-else-if="item.type == 'date'">
                    <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="clientProfile[item.key]"
                        persistent
                        width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                            outlined
                            v-model="birth_date"
                            :value="birth_date"
                            :label="$vuetify.lang.t('$vuetify.profileBirthDate')"
                            prepend-icon="event"
                            readonly
                            v-on="on"
                            dense
                            :rules="item.rule"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="birth_date"
                          :max="new Date().toISOString().substr(0, 10)"
                          scrollable locale="fa"
                          first-day-of-week="6">

                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                        <v-btn text color="
                      primary" @click="modal = false">OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </template>
                  <template v-else-if="item.type == 'selectAuto'">
                    <v-autocomplete
                        v-if="countries.length != 0"
                        dense
                        v-model="newProfile[item.key]"
                        :items="item.items"
                        :item-text="item.key != 'tz' ? 'name' : 'text'"
                        :item-value="item.key == 'tz' ? 'id' : 'code'"
                        menu-props="auto"
                        :label="item.title"
                        hide-details
                        prepend-icon="fas fa-flag"
                        single-line
                        outlined
                        :rules="item.rule"
                    ></v-autocomplete>
                  </template>
                </div>
              </template>
            </v-fade-transition>
          </v-col>
        </v-row>
      </v-form>
      <v-fade-transition>
        <template v-if="seleted.length > 0 || taskId != null">
          <v-btn color="primary" key="btn1" small elevation="0" left @click="saveProfile">
            {{ taskId === null ? 'ویرایش کاربر' : 'تایید اطلاعات کاربر' }}
          </v-btn>
        </template>
      </v-fade-transition>
    </v-card-text>
    <br>
  </div>
</template>

<script>

/*eslint-disable*/
import moment from "moment-jalaali";
import timezones from "@/data/timezones";
import {EventBus} from "@/event-bus";


export default {
  props: {
    taskId: {
      required: false,
      default: null,
    },
    clientProfile: Object,
  },
  components: {
    // AdminDemographyComponent,
  },
  data() {
    return {
      panels: 0,
      modal: false,
      tabs: 0,
      panel: [0, 1],
      // clientProfile: null,
      seleted: [],
      countries: [],
      newProfile: null,
      country_id: 0,
      married: [
        {value: "single", title: 'مجرد'},
        {value: "married", title: 'متاهل'},
        {value: "in_relationship", title: 'رابطه عاطفی'},
        {value: "divorced", title: 'طلاق'},
        {value: "widowed", title: 'بیوه'},
        {value: "complicated", title: 'سایر'},
      ],
      birth_date: null,
      rules: {
        required: value => !!value || 'پر کردن این فیلد الزامی است',
      }
    }
  },
  methods: {

    saveProfile() {
      if (!this.$refs.profileForm.validate()) {
        return;
      }
      let data = {
        // birth_date: moment(this.userInfo.birthDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        birth_date: this.birth_date,
        name: this.newProfile.firstname,
        family: this.newProfile.lastname,
        mobile: this.newProfile.cel,
        timezone: this.newProfile.tz,
        "city": this.newProfile.city,
        "country_id": this.country_id == 0 ? this.clientProfile.country_id : this.country_id,
        "marital_state": this.newProfile.married,
        "education_level": this.newProfile.edu,
        "job": this.newProfile.job,
      };
      if (this.taskId != null) {
        Object.assign(data, {
          'task_id': this.taskId
        })
      }
      EventBus.$emit('setLoading', true);
      this.$store.dispatch('saveUserProfile', {
        userId: this.$route.params.clientId,
        data
      }).then(() => {
        this.snackbar = true;
        if (this.taskId != null) {
          this.$store.dispatch('doneTask', {
            id: this.$route.query.taskId,
            status: "done",
          }).then(() => {
            EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
            EventBus.$emit('updateClientInfo')
            this.$router.back()
            EventBus.$emit('returnWindow')
          }).finally(() => {
            EventBus.$emit('setLoading', false);
          })
        } else {
          EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
          EventBus.$emit('updateClientInfo')
        }
      });
    },
    selectForEdit(key) {
      console.log(this.seleted.indexOf(key))
      if (this.seleted.indexOf(key) == -1) {
        this.seleted.push(key)
        console.log(this.seleted)
      }
    },
    toJalali(date) {
      if (date) {
        return moment(date, "YYYY-M-DD").format('jYYYY/jMM/jDD');
      }
      return date;
    },

  },
  mounted() {
    this.birth_date = this.clientProfile.birthDate
    this.newProfile = this.clientProfile

  },
  watch: {
    '$route'() {
      this.getClientInfo()
    },
    'newProfile.country'(val) {
      this.countries.forEach((item) => {
        if (item.code == val) {
          this.country_id = item.id
        }
      })
    }
  },
  updated() {
    this.newProfile = this.clientProfile
  },
  created() {
    this.$store.dispatch('getCountries').then((resp) => {
      this.countries = resp.data.countries;
    });
  },
  computed: {
    titles() {
      return [
        {
          title: "نام",
          key: "firstname",
          type: "text",
          required: true,
          rule:this.requiredRules
        },
        {
          title: "نام خانوادگی",
          key: "lastname",
          type: 'text',
          rule:this.requiredRules,
          required: true,
        },
        {
          title: "وضعیت تاهل",
          key: "married",
          type: 'select',
          items: [
            {value: "single", title: 'مجرد'},
            {value: "married", title: 'متاهل'},
            {value: "in_relationship", title: 'رابطه عاطفی'},
            {value: "divorced", title: 'طلاق'},
            {value: "widowed", title: 'بیوه'},
            {value: "complicated", title: 'سایر'},
          ],
          filter: (val) => {
            switch (val) {
              case "single" :
                return 'مجرد'
              case "married":
                return 'متاهل'
              case "in_relationship" :
                return 'رابطه عاطفی'
              case "divorced" :
                return 'طلاق'
              case "widowed" :
                return 'بیوه'
              case "complicated" :
                return 'سایر'
            }
          }
        },
        {
          title: "تاریخ تولد",
          key: "birthDate",
          type: 'date',
          filter: (val) => {
            return this.toJalali(val)
          },
        },
        {
          title: "مدرک تحصیلی",
          key: 'edu',
          type: 'select',
          items: [
            {value: "nothing", title: 'فاقد مدرک'},
            {value: "diploma", title: 'دیپلم'},
            {value: "associate", title: 'فوق دیپلم'},
            {value: "bachelor", title: 'لیسانس'},
            {value: "master", title: 'فوق لیسانس'},
            {value: "doctorate", title: 'دکتری'}
          ],
          filter: (val) => {
            switch (val) {
              case "nothing":
                return 'فاقد مدرک'
              case "diploma":
                return 'دیپلم'
              case "associate":
                return 'فوق دیپلم'
              case "bachelor":
                return 'لیسانس'
              case "master":
                return 'فوق لیسانس'
              case "doctorate":
                return 'دکتری'
            }
          }
        },
        {
          title: "ایمیل",
          key: "email",
          type: "email",
          required: true
        },
        {
          title: "تلفن همراه",
          key: 'cel',
          type: 'number',
          required: true,
        },
        {
          title: "شغل",
          key: 'job',
          type: 'text'
        },
        {
          title: " اطلاعات مکانی ",
          key: 'country',
          type: 'selectAuto',
          items: this.countries,
          filter: () => {
            // if (this.clientProfile.length > 0) {
            return this.clientProfile.country.name
            // }
          }
        },
        {
          title: 'شهر',
          key: 'city',
          type: 'text',
        },
        {
          title: 'منظقه زمانی',
          key: 'tz',
          type: 'selectAuto',
          items: timezones,
          required: true

        }
      ]
    },
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
    emailRules() {
      return [
        v => !!v || this.$vuetify.lang.t('$vuetify.emailErrRequired'),
        v => /.+@.+\..{2}/.test(v) || this.$vuetify.lang.t('$vuetify.emailErrValid'),
      ];
    }
  }
}
</script>

<style scoped>

</style>
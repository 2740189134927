<template>
    <v-container fluid>
        <admin-orders ></admin-orders>
<!--        <client-orders v-if="$store.getters.userHasRole('customer')"></client-orders>-->
    </v-container>
</template>

<script>
    import AdminOrders from '@/components/orders/AdminOrders.vue'

    export default {
        components: {
            AdminOrders,
            // ClientOrders
        }
    }
</script>

<style scoped>

</style>

<template>
    <v-container>
        <v-alert
                class="error white--text"
                border="left"
                elevation="0"
                v-if="getUserInfo.state.hasPendingPayments"
        >
            درخواست پرداخت آفلاین شما ثبت گردید. نتیجه از طریق ایمیل به اطلاع شما خواهد رسید.
        </v-alert>

        <v-alert class="info px-1" dense>
            <ul class="text-right white--text">
                <li class="ma-2 mr-4">لطفا برای برگزاری جلسه، بسته مورد نظر خود را انتخاب نمایید.</li>
                <v-divider></v-divider>
                <li class="ma-2 mr-4">پیش از ثبت سفارش از خاموش بودن فیلترشکن خود اطمینان داشته باشید.</li>
            </ul>
        </v-alert>

        <!---->
        <!--<v-alert type="info">-->
        <!---->
        <!--</v-alert>-->


        <!--<v-alert-->
        <!--color="error"-->
        <!--border="left"-->
        <!--elevation="1"-->
        <!--colored-border-->
        <!--icon="mdi-exclamation-thick"-->
        <!--&gt;-->

        <!--</v-alert>-->


        <v-container class="lighten-5 px-0">
            <v-row no-gutters justify="center">
                <v-col
                        v-for="(item, index) in products"
                        :key="`product-${index}`"
                        cols="12"
                        sm="4"
                >

                    <v-card
                            hover
                            outlined
                            align="center"
                            class="ma-2"

                            :class="{'disabled':getCartType != null && item.currency != getCartType}"
                    >

                        <v-card-title class="justify-center bg py-2 display-1 font-weight-light">

                            <template v-if="item.currency != 'dollar'">
                                {{(Number(item.price).toLocaleString())}}
                                <span>{{item.currency |  resolveCurrency }}</span>
                            </template>
                            <template v-else>
                                <span class="ma-3">{{ item.price}}</span>
                                <span>{{item.currency | resolveCurrency }}</span>
                            </template>
                            <!--<small v-if="item.currency =='dollar'">$</small>-->
                            <!--<small v-else>تومان</small>-->

                        </v-card-title>
                        <v-card-subtitle style="background-color: #f1f1f1">{{ item.title }}</v-card-subtitle>
                        <!--<v-card-title class="justify-center">-->
                        <!--<img class="m-auto" src="../../assets/origami.svg"/>-->
                        <!--</v-card-title>-->
                        <v-alert class="ma-1 elevation-0 font-weight-bold label info white--text" type="info" dense>
                            <span>قیمت هر جلسه</span>
                            <template v-if="item.currency != 'dollar'">
                                {{(Number(item.single_session_price).toLocaleString())}}
                                <span>{{item.currency |  resolveCurrency }}</span>
                            </template>
                            <template v-else>
                                <span class="ma-3">{{ Number(item.single_session_price)}}</span>
                                {{item.currency | resolveCurrency }}
                            </template>
                        </v-alert>
                        <v-card-text class="pa-2">
                            <p class="mb-0">صرفه جویی در زمان</p>
                        </v-card-text>
                        <v-card-text class="pa-2">
                            <p class="mb-2">از خاموش بودن فیلترشکن خود اطمینان داشته باشید</p>
                        </v-card-text>

                        <v-card-actions style="background-color: #f1f1f1" class="justify-center py-5">
                            <v-btn dark class="px-3 elevation-0"
                                   :color="getCartType != null && item.currency != getCartType ?'grey':'secondary'"
                                   @click="addToCart(item)"
                            >
                                افزودن به سبد خرید
                            </v-btn>
                        </v-card-actions>

                    </v-card>

                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
    /*eslint-disable*/
    import {mapGetters} from 'vuex';
    import {EventBus} from "../../event-bus";
    import {resolveCurrency} from "../../utilities";

    export default {
        name: 'pricing',
        props: {
            pushToCart: {
                default: true
            }
        },
        data() {
            return {
                products: [],
                isHovered: false
            }
        },
        computed: {

            ...mapGetters(['getCartType', 'getUserInfo']),
            productList() {
                return this.$store.getters.getProductList;

            }
        },
        created() {
            this.getProductList();
        },
        mounted() {

        },
        methods: {
            getProductList() {
                EventBus.$emit('setLoading', true);
                this.$store.dispatch('getProductList', {page: 1}).then((data) => {
                    this.products = data.packages;
                }).finally(() => {
                    EventBus.$emit('setLoading', false);
                    this.$emit('hasPackages');
                })
            },
            addToCart(item) {

                if (this.getCartType != null && item.currency != this.getCartType) {

                    this.$store.commit('setSnackbar', {
                        show: true,
                        type: 'error',
                        message: 'ارز آیتم موجود در کارت شما با این محصول مطابقت ندارد'
                    });
                } else {
                    EventBus.$emit('setLoading', true);
                    this.$store.dispatch('addToCart', item).then(() => {

                        this.$gtm.trackEvent({
                            'event': 'eec.addToCart',
                            'ecommerce': {
                                'add': {
                                    'products': [{
                                        'name': item.title,
                                        'id': item.id,
                                        'price': item.price,
                                        'brand': 'Simiaroom',
                                        'category': 'Counseling',
                                        // 'variant': 'Gray',
                                        'quantity': 1
                                    }]
                                }
                            }
                        });

                        // this.$store.dispatch('getCart');
                        this.$emit('closeWizard');
                        this.$router.push({name: 'clientCart', params: {lang: 'fa'}});
                        EventBus.$emit('setLoading', false);
                    }).finally(() => {
                        EventBus.$emit('setLoading', false);
                    });


                }


            }
        },
        filters: {
            resolveCurrency(input) {
                return resolveCurrency(input);
                // return input == 'rial' ? 'تومان' : 'دلار';
            }
        }
    }

</script>

<style>
    #pricing .v-card:not(.v-sheet--tile):not(.v-card--shaped) {
        border-radius: 8px;
    }
</style>

<style scoped>
    #pricing {
        min-height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #pricing .flexcard {
        display: flex;
        flex-direction: column;
    }

    .bg {
        background-color: #f1f1f1;
    }
</style>
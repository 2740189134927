<template>
  <div>
    <v-navigation-drawer fixed v-model="drawer.visibility" right :width="$vuetify.breakpoint.mdAndDown ? '90%' : '20%'">
      <div class="d-flex justify-space-between">
        <div class="titleArea">
          تسک‌های امروز
        </div>
        <div class="mt-2" @click="closeMenu" style="cursor: pointer">
          <v-icon>mdi-close</v-icon>
        </div>
      </div>
      <v-fade-transition mode="out-in">
        <div key="1" v-if="!tasks">
          <div class="mt-5 text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </div>
        <div key="2" v-else-if="tasks.length != 0">
          <div class="taskArea pa-3">
            <div class="taskItem" v-for="(task  , i ) in tasks" :key="i">
              <Task :task="task" @afterModify="getTasks"/>
            </div>
          </div>
          <v-pagination v-if="pagination.total > 1" class="mt-4" v-model="pagination.page"
                        :length="pagination.total"></v-pagination>
        </div>
        <div v-else>
          <div class="text-center">
            <img src="../../assets/success.png" width="100px" alt="">
            <div>
              <b>
                تبریک شما همه‌ی تسک‌های خود را انجام داده‌اید
              </b>
            </div>
          </div>
        </div>
      </v-fade-transition>
    </v-navigation-drawer>

  </div>
</template>

<script>

/*eslint-disable*/
import Task from "@/components/TaskManager/Task";
import {mapGetters} from "vuex";
import gsap from 'gsap';
/*eslint-disable*/
export default {
  components: {Task},
  data() {
    return {
      tasks: null,
      pagination: {
        page: 1,
        total: 1,
      },
      // drawer:true
    }
  },
  props: {
    drawer: Object
  },
  computed: {
    ...mapGetters({
      // 'drawer': 'getDrawerTask',
    }),

  },
  mounted() {
  },
  methods: {
    closeMenu() {
      this.drawer.visibility = false
      // this.$store.dispatch('setDrawerTask', false)
      // gsap.fromTo('.drawerArea', {opacity: 1, x: '0'}, {opacity: 0, x: '250px'}).then(() => {
      //   gsap.fromTo('.drawerArea', {visibility: 'visible'}, {visibility: 'hidden'})
      //   gsap.fromTo('.coverDrawer', {opacity: 0.5, visibility: 'visible'}, {opacity: 0}).then(() => {
      //     gsap.fromTo('.coverDrawer', {visibility: 'visible'}, {visibility: 'hidden'})
      //   })
      // })
    },
    openMenu() {
      // gsap.fromTo('.coverDrawer', {opacity: 0}, {opacity: 0.5, visibility: 'visible'}).then(() => {
      //   gsap.fromTo('.drawerArea', {opacity: 0, x: '250px',}, {opacity: 1, x: 0, visibility: 'visible'})
      // })
    },
    getTasks() {
      this.tasks = null
      this.$store.dispatch('getAssignedTask', this.pagination.page).then((resp) => {
        this.tasks = resp.data.hasOwnProperty('assignedTasks') ? resp.data.assignedTasks : []
        this.pagination.total = resp.data.pagination.total_pages
        // this.tasks = []
      })
    },
  },
  watch: {
    drawer(val) {
      if (val) {
        this.openMenu()
      }
    },
    '$route'() {
      if (this.drawer) {
        this.closeMenu()
      }
    },
    'pagination.page'() {
      this.getTasks()
    },
    'drawer.visibility'(val) {
      if (val) {
        this.getTasks()
        this.pagination = {
          page: 1,
          total: 1,
        }
      }
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 800px) {
  .taskArea {
    height: 80vh !important;
  }
}

.taskArea {
  height: 86vh;
  overflow-y: auto;
}

.titleArea {
  font-size: 15pt;
  font-weight: bolder;
  text-align: center;
  padding: 10px;
}

.drawerArea {
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 380px;
  z-index: 999;
  background: white;
  opacity: 0;
  visibility: hidden;
}

.coverDrawer {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background: black;
  z-index: 998;
  opacity: 0;
  visibility: hidden;
}

.taskItem {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
</style>

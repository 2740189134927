<template>
  <div>
    <AdminDatePicker @close="customDateDialog=false"
                     :dialog="customDateDialog"
                     @input="customDateDialog = false"
                     v-model="newDateTask"
    ></AdminDatePicker>
    <div class="d-flex">
      <div>
        <v-checkbox @change="changeStatusTask" dense color="green" v-model="status"></v-checkbox>
      </div>
      <div class="mt-5 mr-2  flex-grow-1">
        <del v-if="task.status == 'done'">
          {{ message }}
          <template v-if="task.children.length != 0">
            <v-btn color="primary" x-small elevation="0" class="mt-2">
              یاد‌آوری در
              {{ task.children[0].visible_from_date | dataFilter }}
            </v-btn>
          </template>
        </del>
        <div v-else>
          {{ message }}
        </div>
      </div>

    </div>
    <div class="d-flex justify-space-between">
      <div class="dateTask mt-2">
        <img src="../../assets/calendar.png" width="10px" alt="">
        {{ task.created_at }}
      </div>
      <div>
        <div class="dateTask">
          <div>
            <div class="d-flex">
              <v-btn fab x-small color="primary" elevation="0" @click="setAction('reasons')">
                <v-icon>
                  mdi-file
                </v-icon>
              </v-btn>
              <v-btn v-if="task.status != 'done'" fab x-small color="primary" class="mr-1" elevation="0"
                     @click="setAction('delay')">
                <v-icon>
                  mdi-clock
                </v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-fade-transition>
      <div class="delayBlock" key="1" v-if="action == 'delay'">
        <div class="d-flex mt-4">
          <v-btn x-small class="ml-2" color="primary" @click="addToTime('day')">
            یک روز
          </v-btn>
          <v-btn class="ml-2" color="primary" @click="addToTime('week')"
                 x-small>
            یک هفته
          </v-btn>
          <v-btn class="ml-2" color="primary" @click="addToTime('month')"
                 x-small>
            یک ماه
          </v-btn>
          <v-btn class="ml-2" color="primary" x-small
                 @click="openCustomDate">
            تاریخ دلخواه
          </v-btn>
        </div>
        <div class="mt-1">
          <b style="font-size: 10px">
            تاریخ جدید تسک:
            {{ newDateTask | dataFilter }}
          </b>
        </div>
        <div class="text-left">
          <v-btn color="primary" x-small elevation="0" @click="setDelay">
            ثبت
          </v-btn>
        </div>
      </div>
      <div class="delayBlock mt-4" key="1" v-if="action == 'reasons'">
        <v-fade-transition mode="out-in">
          <v-select :items="reasons[task.required_action]" @change="addReasonToTask" v-model="reason" label="دلیل" dense
                    outlined append-outer-icon="mdi-pencil" v-if="!openTextFiled"
                    @click:append-outer="openTextFiled = true"></v-select>
          <div v-else>
            <v-textarea outlined dense label="دلیل" v-model="reason" append-icon="mdi-playlist-check"
                        @click:append="openTextFiled = false"></v-textarea>
            <v-btn color="primary" x-small style="margin-top: -20px" elevation="0" @click="addReasonToTask">
              ثبت
            </v-btn>
          </div>
        </v-fade-transition>
      </div>


    </v-fade-transition>
  </div>
</template>

<script>
import moment from "moment-jalaali";
import AdminDatePicker from '@/components/AdminDatePicker';
import {EventBus} from "@/event-bus";

export default {
  components: {AdminDatePicker},

  props: {
    task: Object,
  },
  data() {
    return {
      status: false,
      delayModal: false,
      newDateTask: moment(),
      customDateDialog: false,
      openTextFiled: false,
      action_type: {
        "follow_up_after_first_purchase": 'پیگیری خرید پکیج دوم',
        "follow_up_before_last_session": 'پیگیری خرید مجدد',
        "follow_up_delay_after_assigning_doctor": 'پیگیری تخصیص دکتر',
        "follow_up_next_session_delay": 'پیگیری جلسه جدید',
        "set_follow_up_session": 'پیگیری جلسه فالو‌آپ',
        "assigned_user_your_doctor":'تخصیص داده شده به دکتر های شما : '
      },
      reasons: {
        "follow_up_after_first_purchase": [
          'مراجع‌کننده پاسخ‌گو نیست',
          'شکایت از تغیر قیمت',
          'خاتمه دوره درمان'
        ],
        "follow_up_before_last_session": [
          'مراجع‌کننده پاسخ‌گو نیست',
          'شکایت از تغیر قیمت',
          'خاتمه دوره درمان'

        ],
        "follow_up_delay_after_assigning_doctor": [
          'مراجع‌کننده پاسخ‌گو نیست',
          'شکایت از تغیر قیمت',

        ],
        "follow_up_next_session_delay": [
          'مراجع‌کننده پاسخ‌گو نیست',
          'شکایت از تغیر قیمت',
          'خاتمه دوره درمان'
        ],
        "set_follow_up_session": [
          'مراجع‌کننده پاسخ‌گو نیست',
          'شکایت از تغیر قیمت',
          'خاتمه دوره درمان'
        ],
      },
      items: [
        'مراجع‌کننده پاسخ‌گو نیست',
        'شکایت از تغیر قیمت',
      ],
      action: null,
      reason: null,
    }
  },
  computed: {
    message() {
      return this.action_type[this.task.required_action] +
          '   '
          +
          this.task.assignedTaskable.full_name
      // return `تنطیم جلسه برای  ${this.task.assignedTaskable.full_name}`
    },

  },
  mounted() {
    this.status = this.task.status == 'done'
  },
  methods: {
    setDelay() {
      if (!this.newDateTask || this.newDateTask == '') {
        EventBus.$emit('notify', 'red', undefined, 'زمان یادآوری بعدی را انتخاب کنید');
        return;
      }
      let payload = {
        parent_id: this.task.id,
        assigned_taskable_type: this.task.assigned_taskable_type,
        assigned_taskable_id: this.task.assigned_taskable_id,
        data: this.task.data,
        required_action: this.task.required_action,
        visible_from_date: moment(this.newDateTask).format('YYYY-MM-DD'),
      }
      EventBus.$emit('setLoading', true)
      this.status = true
      this.changeStatusTask()
      this.$store.dispatch('addTask', payload).then(() => {
        EventBus.$emit('notify', 'green', undefined, 'تسک به زمان دلخواه شما منتقل شد');
        this.$emit('afterModify')
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })

    },
    addReasonToTask() {
      if (!this.reason || this.reason == '') {
        EventBus.$emit('notify', 'red', undefined, 'لطفا یک دلیل را انتخاب کنید');
        return;
      }
      let payload = {
        status: 'done',
        "reason": this.reason,
        task_id: this.task.id,
      }
      this.$store.dispatch('doneTask', payload).then(() => {
        this.$emit('afterModify')
        EventBus.$emit('notify', 'green', undefined, 'وضعیت تسک تغیر کرد');
      }).finally(() => {
        this.reason = null
        EventBus.$emit('setLoading', false)
      })
    },
    setAction(action) {
      if (this.action == action) {
        this.action = null
      } else {
        this.action = action
      }
    },
    submitDelay() {
      this.delayModal = false
      EventBus.$emit('notify', 'green', undefined, 'زمان جدید تسک اضافه شد');
      this.$emit('afterModify')
    },
    openCustomDate() {
      this.customDateDialog = true
    },
    addToTime(type) {
      switch (type) {
        case 'day':
          this.newDateTask = moment().add('1', 'day')
          break;
        case 'week':
          this.newDateTask = moment().add('1', 'week')
          break;
        case 'month':
          this.newDateTask = moment().add('1', 'month')
          break;
      }
      this.action = null
      this.setDelay()
    },
    changeStatusTask() {

      EventBus.$emit('setLoading', true)
      let payload = {
        "status": this.status == true ? 'done' : 'todo',
        // "reason": "She's sick & can't attend her session.",
        // "visible_from_date": "2024-05-01",
        task_id: this.task.id,
      }
      this.$store.dispatch('doneTask', payload).then(() => {
        this.$emit('afterModify')
        EventBus.$emit('notify', 'green', undefined, 'وضعیت تسک تغیر کرد');
      }).finally(() => {
        EventBus.$emit('setLoading', false)
        this.status = false
      })

    },
  },
  filters: {
    dataFilter(val) {
      if (!val)
        return null
      return moment(val).format('jYYYY/jMM/jDD')
    }
  },
  watch: {
    status(val) {
      if (val) {
        // this.changeStatusTask('done')
      } else {
        // this.changeStatusTask('todo')
      }
    }
  }
}
</script>

<style scoped>

. {

}

.dateTask {
  font-size: 12px;
  color: grey;
}

.menuItem {
  font-size: 14px;
  padding: 10px;
  transition: all 0.3s;
}

.menuItem:hover {
  transition: all 0.3s;
  background: #ededed;
}
</style>
<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <AdminExpressEntryComponent></AdminExpressEntryComponent>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import AdminExpressEntryComponent from '@/components/Admin/user/AdminClientExpressEntry.vue';

    export default {
        components: {
            AdminExpressEntryComponent
        }
    }
</script>

<style scoped>

</style>
<template>
    <div>
        <v-content>
            <router-view></router-view>
        </v-content>
        <v-snackbar

                v-model="snackbar.visibility"
                :color="snackbar.color"
                :bottom="snackbar.position === 'bottom'"
                :timeout="snackbar.timeout"
                :top="snackbar.position === 'top'"
        >
            {{snackbar.message}}
            <v-btn
                    color="primary--text"
                    @click="snackbar.visibility = false"
                    fab
                    x-small
            >

                <v-icon small color="red">fas fa-times</v-icon>
            </v-btn>
        </v-snackbar>

    </div>
</template>

<script>
    // @ is an alias to /src

    import {EventBus} from "../../event-bus";
    // import connection from "../../classes/Connection.js";

    export default {
        name: 'mainlayout',
        components: {},
        created() {
            // this.setCookie('inviter', 'BINGO');
            //
            let inviter = this.getCookie('inviter');
            if (this.$route.query.hasOwnProperty('inviter')) {
                this.delete_cookie('inviter','/','simiaroom.com');
                this.$store.dispatch('setInviter', this.$route.query.inviter);
            }
            else if (inviter != "") {
                this.$store.dispatch('setInviter', inviter);
            }


            // alert(this.$route.query.inviter);

            // this.cn = new connection(this.handleConnection);

            EventBus.$on('resetSocket', this.resetSocket);
            EventBus.$on('disconnectSocket', this.disconnectSocket);
            EventBus.$on('setLoading', this.setLoading);
            this.$store.dispatch('checkLogin').then((loggedIn) => {
              if(loggedIn) this.$store.dispatch('getAdminNavbarNotifications');
            });
            this.$store.dispatch('getPlatformInfo');

            window.addEventListener('scroll', this.handleScroll);
        },

        destroyed() {
            EventBus.$off('resetSocket', this.resetSocket);
            EventBus.$off('disconnectSocket', this.disconnectSocket);
            window.removeEventListener('scroll', this.handleScroll);
        },
        mounted() {
            this.$store.commit('setMinHeight');
            EventBus.$on('notify', (color = 'success', timeout = 3000, message = 'عملیات با موفقیت انجام شد.') => {
                this.snackbar.visibility = true;
                this.snackbar.color = color;
                this.snackbar.position = 'bottom';
                this.snackbar.timeout = timeout;
                this.snackbar.mode = 'vertical';
                this.snackbar.message = message;
            });
        },
        beforeDestroy() {
            EventBus.$off('setLoading', this.setLoading);
            EventBus.$off('notify');
        },
        data() {
            return {
                cn: null,
                snackbar: {
                    visibility: false,
                    color: 'info',
                    position: 'bottom',
                    timeout: 3000,
                    mode: 'vertical',
                    message: 'this is snackbar message',
                },
                lang: "",
                path: this.$route.name,
                drawer: true,
                // pageIsScrolled: false,
                scrollFabIsVisible: false,

            };
        },
        computed: {},
        watch: {
            '$socket.connected'(state) {
                if (state) {
                    this.$socket.client.on(this.$store.getters.getBcCode, payload => {
                        this.$store.dispatch('gotMessage', {payload, socket: this.$socket});
                    })
                    // this.$socket.$subscribe(this.$store.getters.getBcCode, payload => {
                    //     this.$store.dispatch('gotMessage', {payload, socket: this.$socket});
                    // });
                }
            }
        },
        methods: {
            delete_cookie(name, path, domain) {
                if (this.getCookie(name) != "") {
                    document.cookie = name + "=" +
                        ((path) ? ";path=" + path : "") +
                        ((domain) ? ";domain=" + domain : "") +
                        ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
                }
            },
            getCookie(cname) {
                let name = cname + "=";
                let ca = document.cookie.split(';');
                for (let i = 0; i < ca.length; i++) {
                    let c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            setCookie(cname, cvalue, exdays) {
                var d = new Date();
                d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
                var expires = "expires=" + d.toUTCString();
                document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
            },

            disconnectSocket(bcCode) {
                this.$socket.client.off(bcCode);
                this.$socket.client.close();
            },
            // handleConnection() {
            //     if (navigator.onLine) {
            //         connection.isReachable('https://app.simiaroom.com').then(function (online) {
            //             if (online) {
            //                 alert('damn')
            //                 // handle online status
            //                 console.log('online');
            //             } else {
            //                 alert('yay')
            //                 console.log('no connectivity');
            //             }
            //         });
            //     } else {
            //         // handle offline status
            //         console.log('offline');
            //     }
            // },
            resetSocket(data) {

                if (this.$store.getters.getBcCode != data) {
                    this.$socket.client.close();
                    this.$socket.client.io.opts.query = 'token=' + data;
                    this.$socket.client.connect();
                }
            },
            setLoading(loading) {
                // console.log('here too');
                this.$store.commit('setLoading', loading);
            },
            handleScroll() {
                if (window.scrollY > 100 && !this.scrollFabIsVisible) {

                    this.scrollFabIsVisible = true;

                } else if (window.scrollY <= 100 && this.scrollFabIsVisible) {

                    this.scrollFabIsVisible = false;

                }

            }
        }
    }
</script>

<style>
    .back-to-top {
        position: fixed;
        right: 20px;
        bottom: 20px;
        z-index: 2;
    }
</style>

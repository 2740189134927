<template>
  <div>
    <v-autocomplete
        label="زمان محلی"
        :items="timezones"
        item-value="value"
        @input="changeTz"
        v-model="selectedTimezone"
    >
    </v-autocomplete>
  </div>
</template>

<script>
import timezones from "../../data/timezones";
import {EventBus} from "@/event-bus";
import momentTz from "moment-timezone";

/*eslint-disable*/
export default {
  props: {
    user_id: Number,
    user_timezone: {
      require: false,
    }
  },
  data() {
    return {
      timezones: timezones,
      selectedTimezone: null,
    }
  },
  created() {


    let x = JSON.parse(JSON.stringify(timezones));
    this.timezones = x.map((item) => {
      item.text = item.text + ' ' + momentTz.tz(item.text).format('Z');
      return item;
    });

    this.selectedTimezone = this.user_timezone
  },
  methods: {
    changeTz() {
      EventBus.$emit('setLoading', true);
      let data = {

        timezone: this.selectedTimezone,

      };

      this.$store.dispatch('saveUserProfile', {
        userId: this.user_id,
        data
      }).then((resp) => {
        this.$emit('callback', this.selectedTimezone)
      }).finally(() => {
        EventBus.$emit('setLoading', false);
      })
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class=" mt-10 `fill-height d-flex flex-column justify-space-around" :style="$vuetify.breakpoint.mdAndUp ?'padding: 10px 30% 10px 25%' : ''">
    <div>
      <template>
        <div class="text-center fill-height " style="">
          <p class="titleSelectTime">
            زمان‌های ترجیحی که کاربر انتخاب کرده است :
          </p>
          <v-row class="mt-12">
            <v-col cols="9" lg="9" class="" style="margin-top: -40px">
              <div>
                <div class="d-flex justify-space-between flex-row-reverse">
                  <div v-for="(item , i) in Object.keys(days)" :key="i" :class="i % 2 != 0 ? 'greyRow ' : ''"
                       style="width: 100%;height: 100%;padding-bottom: 20px">
                    <span class="dayLabel">{{ item }}</span>
                    <div v-for="n in 4" :key="n"  >
                      <div class="d-flex justify-space-around my-6 ">
                        <div>
                          <v-icon v-if="isActiveDay(item , n)">
                            mdi-check
                          </v-icon>
                          <v-icon v-else>

                          </v-icon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="3" lg="3">
              <div class="timesArea">
                <p class="times" v-for="(item , i) in times" :key="i">
                  {{ item.title }}
                  <!--                <img :src='require("@/assets/newOnboading/" + item.pic)' width="20" alt="">-->
                </p>
              </div>
            </v-col>
          </v-row>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    timesSelected:Object,
  },
  data() {
    return {
      days: {
        'Sa': [],
        'Su': [],
        'Mo': [],
        'Tu': [],
        'We': [],
        'Th': [],
        'Fr': [],
      },
      times: [
        {
          title: "8-12",
          pic: 'morning.png'
        },
        {
          title: '12-16',
          pic: 'morning.png'
        },
        {
          title: '16-20',
          pic: 'morning.png'
        },
        {
          title: '20-24',
          pic: 'morning.png'
        }
      ],
    }
  },
  computed:{
    timeSelectedData(){
      return JSON.parse(this.timesSelected.value)
    }
  },
  methods:{
    isActiveDay(key,time){
      let day = this.timeSelectedData[key]
       if(day){
         if (day.includes(time)){
           return true
         }
       }
       return false
    }
  }
}
</script>

<style scoped>
.greyRow {
  background: #FBFCFF;
  height: 50px;
}

.titleSelectTime {
  font-size: 14px;
}

.subTitleSelectTime {
  margin-top: -10px;
  font-size: 12px;
}
.dayLabel {
  color: #1c53c1;
  font-size: 12px;
}
.timesArea {
  /*background: #d1fdff;*/
  /*background: url("../assets/newOnboading/backCom.svg");*/
  background-size: cover;
  border: solid 1px #d1dcff;
  border-radius: 10px;
  width: 77px;
  /*padding: 20px;*/
}
.times {
  font-size: 12px;
  color: #1c53c1;
  margin-top: 20px;
  width: 100%;
}


</style>
<template>
    <v-container>
        <v-row>
            <v-col>

                <v-card
                        class="elevation-2"
                >
                    <AdminProfileComponent></AdminProfileComponent>

                    <v-card-actions class="mt-12">
                        <v-btn class="success px-10" @click="saveProfile">ثبت</v-btn>
<!--                        <v-btn class="grey lighten-2 px-10" @click="resetProfile">Reset</v-btn>-->
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import AdminProfileComponent from '@/components/profiles/components/AdminProfileComponent.vue';
    import {EventBus} from '../../event-bus.js'

    export default {
        components: {
            AdminProfileComponent
        },
        methods: {
            saveProfile() {
                EventBus.$emit('saveProfileEvent');
            },
            resetProfile() {
                EventBus.$emit('resetProfileEvent');
            }
        }


    }
</script>

<style scoped>

</style>
<template>
  <div>
    <v-card>
      <v-card-title>
        دریافت گزارش جلسات
      </v-card-title>
      <v-card-text>
        <v-window v-model="windowHandler">
          <v-window-item value="getFilter">
            <v-form ref="filterForm">
              <div v-for="(option , i ) in formOptions" :key="i">
                <template v-if="option.type == 'autocomplete'">
                  <v-autocomplete
                      :rules="option.required && requiredRules"
                      item-value="doctor_profile.id"
                      :item-text="option.item_name"
                      :items="option.data"
                      v-model="query[option.key]"
                      :label="option.title" outlined
                      dense/>
                </template>
                <template v-if="option.type == 'multiple-select'">
                  <v-select item-value="value"
                            :rules="requiredRules"
                            chips multiple
                            item-text="title"
                            :items="option.data" v-model="query[option.key]"
                            :label="option.title" outlined

                            dense/>
                </template>
                <template v-if="option.type == 'date'">
                  <v-text-field
                      :rules="option.required?  requiredRules :[]"
                      outlined
                      dense
                      v-model="query[option.key].value"
                      :label="option.title"
                      prepend-icon="event"
                      readonly
                      clearable
                      @click="query[option.key].showDialog=true"
                  ></v-text-field>
                  <AdminDatePicker @close="query[option.key].showDialog=false"
                                   :dialog="query[option.key].showDialog"
                                   @input="query[option.key].showDialog = false"
                                   v-model="query[option.key].value"
                  ></AdminDatePicker>
                </template>
              </div>
            </v-form>
          </v-window-item>
          <v-window-item value="getResult">
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-center flex-column flex-grow-1">
                زمان‌ها بر اساس این زمان محلی هستند:
              </div>
              <div>
                {{ timezone }}
                <!--                <v-autocomplete dense :items="timezones" v-model="timezone"></v-autocomplete>-->
              </div>
            </div>
            <v-textarea v-model="message" rows="10">

            </v-textarea>
          </v-window-item>
        </v-window>
      </v-card-text>
      <v-card-actions>
        <template v-if="windowHandler == 'getFilter'">
          <v-btn color="primary" @click="getReport">
            دریافت گزارش
          </v-btn>
        </template>
        <template v-if="windowHandler == 'getResult'">
          <v-btn color="primary" @click="copyText">
            کپی کردن
          </v-btn>
          <v-btn @click="windowHandler = 'getFilter'" text color="primary">
            بازگشت
          </v-btn>
        </template>
      </v-card-actions>

    </v-card>
  </div>
</template>

<script>
/*eslint-disable*/
import {mapGetters} from "vuex";
import AdminDatePicker from "@/components/AdminDatePicker";
import moment from "moment-jalaali";
import timezones from "../../../data/timezones";
import momentTz from "moment-timezone";
import {EventBus} from "@/event-bus";

export default {
  components: {AdminDatePicker},
  data() {
    return {
      doctors: null,
      timezones: timezones,
      query: {
        state: ['date_fixed'],
        from_date: {
          showDialog: false,
          value: null,
        },
        to_date: {
          showDialog: false,
          value: null,
        }
      },
      doctor: null,
      timezone: null,
      sessions: null,
      groupSession: null,
      sessionReport: {},
      windowHandler: "getFilter",
      message: null,
    }
  },
  mounted() {
    this.timezone = this.profile.tz
    this.fillDoctors()
    this.query.from_date.value = moment().format('YYYY-MM-DD');
    this.prepareMessage()
  },
  methods: {
    copyText() {
      const stateAsString = this.message; // Convert state to a string
      navigator.clipboard.writeText(stateAsString)
          .then(() => {
            EventBus.$emit('notify', 'green', undefined, 'متن کپی شد');
          })
          .catch((error) => {
            EventBus.$emit('notify', 'red', undefined, 'در فرایند کپی متن مشکل ایجاد شده است');
          });
    },
    prepareMessage() {
      if (!this.doctor) return;
      this.message =
          "سلام \n" +
          "وقتتون بخیر\n" +
          "خدمت دکتر  " +
          this.doctor.full_name +
          "\n" +
          "برنامه به اين ترتيب فيكس شد: "
      Object.keys(this.groupSession).sort().forEach(item => {
        this.message += "\n" + "\n" + moment(item).locale("fa").format("dddd") + " - "
        this.message += moment(item).format("jYYYY/jMM/jDD") + " - " + moment(item).format("YYYY/MM/DD") + "\n"
        this.groupSession[item].sort((a, b) => {
          let amoment = moment(a.fixed_date_time_g, 'YYYY-MM-DD HH:mm:ss')
          let bmoment = moment(b.fixed_date_time_g, 'YYYY-MM-DD HH:mm:ss')

          if (amoment.isAfter(bmoment)) {
            return 1;
          } else if (amoment.isBefore(bmoment)) {
            return -1;
          } else {
            return 0
          }
        }).forEach(session => {
          // this.message += momentTz.tz(session.fixed_date_time_g, this.profile.tz).tz(this.timezone).format('hh:mm');
          this.message += session.user.full_name + " - " + moment(session.fixed_date_time_g).format('HH:mm') + "\n"
        })
      })
      this.message += "\n" + 'سپاس 🌹'
    },
    getReport() {
      if (this.$refs.filterForm.validate()) {
        let payload = {
          doctor_ids: this.query.doctor_ids.toString(),
          state: this.query.state.join('|'),
          from_date: this.query.from_date.value,
          to_date: this.query.to_date.value
        }
        this.$store.dispatch('getAdminSessions', {perPage: 100, page: 1, options: payload}).then((resp) => {
          this.sessions = resp.data.sessions
          if (this.sessions.length != 0) {
            this.groupingSessions()
            this.prepareDoctor()
            this.prepareMessage()
            this.windowHandler = 'getResult'
          } else {
            EventBus.$emit('notify', 'red', undefined, 'در این بازه زمانی جلسه ای نیست');

          }
        })
      }

    },
    prepareDoctor() {
      this.doctor = this.sessions[0].doctor
    },
    groupingSessions() {
      const groupedData = this.sessions.reduce((result, obj) => {
        const key = moment(obj.fixed_date_time_g).format('YYYY-MM-DD');
        if (!result[key]) {
          result[key] = [];
        }
        result[key].push(obj);
        return result;
      }, {});

      this.groupSession = groupedData
    },
    fillDoctors() {

      let promise = new Promise((resolve, reject) => {
        if (!this.allDoctors.length) {
          this.$store.dispatch("getAllDoctors").then(res => {
            this.doctors = res.data.doctors;
            resolve();
          }).catch(err => {
            console.log(err);
          });
        } else {
          this.doctors = this.allDoctors;
          resolve();
        }
      })

      promise.then(() => {
        let mappedDoctors = this.doctors.map((doctor) => {
          return {
            title: doctor.full_name, value: doctor.doctor_profile.id
          };
        });


        this.rerender = true;
      });
    },
  },
  watch: {
    timezone() {

      this.prepareMessage()
    }
  },
  computed: {
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
    formOptions() {
      return [
        {
          key: 'doctor_ids',
          title: "دکتر",
          type: "autocomplete",
          data: this.doctors,
          item_key: 'id',
          item_name: 'full_name',
          required: true
        },
        // {
        //   key: 'state',
        //   title: "وضعیت",
        //   type: "multiple-select",
        //   data: [
        //     {title: 'درخواست', value: 'session_requested'},
        //     {title: 'تخصیص یافته', value: 'doctor_assigned'},
        //     {title: 'تاریخ ست شده', value: 'date_fixed'},
        //     {title: 'برگزار شده', value: 'used'},
        //     {title: 'لغو شده', value: 'canceled'},
        //   ],
        //   item_key: 'title',
        //   item_name: 'value',
        //   required: true
        // },
        {
          key: 'from_date',
          title: "تاریخ شروع",
          type: "date",
          data: this.doctors,
          item_key: 'id',
          item_name: 'full_name',
          required: true
        },
        {
          key: 'to_date',
          title: "تاریخ پایان",
          type: "date",
          data: this.doctors,
          item_key: 'id',
          item_name: 'full_name',
          required: false
        },


      ]
    },
    ...mapGetters({
      allDoctors: 'getAdminAllDoctors',
      'profile': 'getUserInfo',
      // sessions: 'getAdminSessions',
      // page: 'page',
      // total: 'total',
    })
  }

}
</script>

<style scoped>

</style>






<template>
  <v-container class="mt-10">
    <v-row>
      <div id="chart"

           style="width:100%;border: 1px solid rgba(0,0,0,.3);border-radius: 12px;box-shadow: 0 5px 10px -3px rgba(0,0,0,.3);padding:5px;"
      >
        <v-card elevation="0">
          <v-card-text class="pa-3">
            <v-select
                :items="[{title:'درصد',value:'percentage'},{title:'کاربران',value:'users'}]"
                class=""
                item-text="title"
                item-value="value"
                label="Solo field"
                dense
                v-model="showOption"
                solo
            ></v-select>

            <!--                        <v-expansion-panels-->
            <!--                            v-model="panel"-->
            <!--                        >-->


            <!--                            <v-expansion-panel>-->
            <!--                                <v-expansion-panel-header>-->
            <!--                                    <div>-->
            <!--                                        <v-icon small>-->
            <!--                                            fas fa-cogs-->
            <!--                                        </v-icon>-->
            <!--                                    </div>-->

            <!--                                </v-expansion-panel-header>-->
            <!--                                <v-expansion-panel-content>-->
            <!--                                    <v-row>-->
            <!--                                        <v-col>-->
            <!--                                            <v-text-field-->
            <!--                                                outlined-->
            <!--                                                dense-->
            <!--                                                :value="fromDate |toJalali "-->
            <!--                                                :label="'از تاریخ'"-->
            <!--                                                prepend-icon="event"-->
            <!--                                                readonly-->
            <!--                                                @click="showDialog=true"-->
            <!--                                            ></v-text-field>-->

            <!--                                            <AdminDatePicker @close="showDialog=false"-->
            <!--                                                             :dialog="showDialog"-->
            <!--                                                             @input="showDialog = false"-->
            <!--                                                             v-model="fromDate"-->
            <!--                                            ></AdminDatePicker>-->
            <!--                                        </v-col>-->
            <!--                                        <v-col>-->
            <!--                                            <v-text-field-->
            <!--                                                outlined-->
            <!--                                                dense-->
            <!--                                                :value="toDate |toJalali"-->
            <!--                                                :label="'تا تاریخ'"-->
            <!--                                                prepend-icon="event"-->
            <!--                                                readonly-->
            <!--                                                @click="toDateShowDialog=true"-->
            <!--                                            ></v-text-field>-->

            <!--                                            <AdminDatePicker @close="toDateShowDialog=false"-->
            <!--                                                             :dialog="toDateShowDialog"-->
            <!--                                                             @input="toDateShowDialog = false"-->
            <!--                                                             v-model="toDate"-->
            <!--                                            ></AdminDatePicker>-->
            <!--                                        </v-col>-->
            <!--                                    </v-row>-->
            <!--                                    <v-row>-->
            <!--                                        <v-spacer></v-spacer>-->
            <!--                                        <v-btn class="primary mx-2" @click="render=!render" :loading="!render" :disabled="!render">اعمال</v-btn>-->
            <!--                                    </v-row>-->

            <!--                                </v-expansion-panel-content>-->
            <!--                            </v-expansion-panel>-->

            <!--                        </v-expansion-panels>-->
            <TableOptions
                v-if="renderOptions"
                :hasExport="true"
                :searchFilters="searchFilters"
                @applyFilters="applyFilters"
                :action-required-permission="{
                  download:'admin.expressentry'
                }"

            ></TableOptions>

          </v-card-text>
        </v-card>
        <v-skeleton-loader
            class="mx-auto mt-3"
            type="image"
            v-if="!render"
        ></v-skeleton-loader>
        <v-fade-transition>
          <apexchart v-if="render" type="line" height="350" :options="chartOptions"
                     :series="series"></apexchart>
        </v-fade-transition>
      </div>

    </v-row>
  </v-container>
</template>

<script>
/*eslint-disable*/
import apexchart from 'vue-apexcharts';
import AdminDatePicker from '@/components/AdminDatePicker';
import moment from "moment-jalaali";
import TableOptions from "@/components/TableOptions";
import {mapGetters} from 'vuex';
import {
  marketingChurnFilters
} from "../../../searchFilters";

export default {
  computed: {
    ...mapGetters({
      allDoctors: 'getAdminAllDoctors'
      // sessions: 'getAdminSessions',
      // page: 'page',
      // total: 'total',
    })
  },
  methods: {
    fillDoctors() {
      let promise = new Promise((resolve, reject) => {
        if (!this.allDoctors.length) {
          this.$store.dispatch("getAllDoctors").then(res => {
            this.doctors = res.data.doctors;
            resolve();
          }).catch(err => {
            console.log(err);
          });
        } else {
          this.doctors = this.allDoctors;
          resolve();
        }
      })

      promise.then(() => {


        let mappedDoctors = this.doctors.map((doctor) => {
          let data = {
            title: doctor.full_name, value: doctor.doctor_profile.id
          };
          this.addDoctors.push(JSON.parse(JSON.stringify(data)));
          if (this.defaultDoctorExcludes.includes(doctor.doctor_profile.id)) data.default = true;
          return data;
        });

        marketingChurnFilters.find((item) => {
          if (item.key == 'doctor_ids') {
            item.options = mappedDoctors;
          } else if (item.key == 'filter_doctor_ids') {
            item.options = this.addDoctors;
          }
        });
        this.searchFilters = marketingChurnFilters;

      }).finally(() => {
        this.renderOptions = true;
      });
    },
    getChurnData() {
      if (!this.payload.hasOwnProperty('wantsExcel')) {
        this.render = false;
      }

      this.$store.dispatch('getChurnData', this.payload).then((resp) => {

        if (!this.payload.hasOwnProperty('wantsExcel')) {
          this.series = resp.data.series;
          this.chartOptions.xaxis.categories = resp.data.categories;
          this.chartOptions.yaxis.max = resp.data.max + 5;
          this.$nextTick().then(() => {
            this.render = true;
          });
        } else {
          delete this.payload.wantsExcel;
        }
      })
    },
    applyFilters(data) {
      this.payload = data;
      this.getChurnData()
    }
  },
  created() {

    this.fillDoctors();
    // this.getChurnData();

  },
  components: {
    apexchart, AdminDatePicker, TableOptions

  },
  filters: {
    toJalali(date) {
      if (date) {
        return moment(date, "YYYY-M-DD").format('jYYYY/jMM/jDD');
      }
      return date;
    },
  },
  data() {
    return {
      addDoctors: [],
      defaultDoctorExcludes: [8, 12, 89, 107, 13],
      renderOptions: false,
      doctors: [],
      payload: {},
      searchFilters: [],
      panel: false,
      toDateShowDialog: false,
      showDialog: false,
      fromDate: null,
      toDate: null,
      showOption: 'users',
      render: false,
      series: [
        // {
        //     name: "All users",
        //     data: [28, 29, 33, 36, 32, 32, 33],
        //     users: [
        //         {name: 'mohamad'},
        //         {name: 'nima'},
        //         {name: 'mehri'},
        //     ]
        // },
        // {
        //     name: "Churn Users",
        //     data: [12, 11, 14, 18, 17, 13, 13],
        //     users: {
        //         3: [
        //             {
        //                 name: 'reza'
        //             },
        //             {
        //                 name: 'mohamad'
        //             }
        //         ]
        //     }
        // }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#77B6EA', '#FF0000'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Churn Graph',
          align: 'center'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: [],
          title: {
            text: 'Weeks'
          }
        },
        yaxis: {
          title: {
            text: 'User Count'
          },
          min: 0,
          max: 40
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        },
        tooltip: {
          custom: ({series, seriesIndex, dataPointIndex}) => {
            if (this.showOption != 'users') {
              return this.series[seriesIndex]['churnRate'][dataPointIndex] + '%';
            } else {

              let text = '<div >';
              if (seriesIndex == 0) {
                text = '<div>همه کاربران</div>'
                text += '<hr/>'
              } else {
                text = '<div>کاربران چرن شده</div>'
                text += '<hr/>'
              }
              text += '<div class="arrow_box text-right">';
              if (this.series[seriesIndex]['users'].hasOwnProperty(dataPointIndex)) {
                this.series[seriesIndex]['users'][dataPointIndex].forEach(user => {
                  text += `<div>${user.full_name}</div>`;
                })
              }
              text += '</div></div>'
              return text;

            }
          }
        }
      },
    }
  }
}
</script>

<style scoped>

</style>
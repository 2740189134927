<template>
  <v-container>
    <v-card :loading="loading" elevation="0">
      <v-card-text>
        <v-col>
          <v-card>
            <v-card-text>
              <v-row dense>
                <v-col v-for="(item , i ) in surveys" :key="i" cols="4">
                  <div class="ma-2" v-if="item.key != 'times'">
                    {{ item.key | titleFilter }}
                    <v-chip>
                      {{ item.value }}
                    </v-chip>
                  </div>
                </v-col>
              </v-row>
              <template v-if="surveys.find(item => item.key === 'times')">
                <TimeCalenderTimesSurvey :times-selected="surveys.find(item => item.key === 'times')"/>
              </template>

            </v-card-text>
            <!--        <v-card-text>-->
            <!--          -->
            <!--        </v-card-text>-->
          </v-card>
        </v-col>
      </v-card-text>

    </v-card>
  </v-container>
</template>

<script>
import TimeCalenderTimesSurvey from "@/components/Admin/user/timeCalenderTimesSurvey";

export default {
  components: {TimeCalenderTimesSurvey},
  created() {
    this.getSurveys();
  },
  data() {
    return {
      surveys: [],
      loading: false
    }
  },
  methods: {
    getSurveys() {
      this.loading = true;
      this.$store.dispatch('getAdminClientSurvey', {
        clientId: this.$route.params.clientId
      }).then((resp) => {
        this.surveys = resp.data.data;
        console.log(this.surveys)
      }).finally(() => {
        this.loading = false;
      })
    },
    convertToJson(val) {
      let converted = JSON.parse(val)
      return converted;
    }
  },

  filters: {
    titleFilter(val) {
      switch (val) {
        case "category":
          return "موضوع مشاوره "
        case "gender":
          return "جنسیت"
        case 'age':
          return "سن"
        case "relationship":
          return "وضعیت ناهل"
        case "doctor":
          return "دکتر"
        case "times":
          return "زمان‌های درخواستی"
        default :
          return val
      }
    },
    dayFilter(val) {
      switch (val) {
        case 'Sa':
          return 'شنبه';
        case 'Su' :
          return 'یکشنبه';
        case 'Mo' :
          return 'دوشبنه';
        case 'Tu' :
          return 'سه‌شنبه';
        case 'We' :
          return 'چهارشنبه';
        case 'Th' :
          return 'پنج‌شنبه';
        case 'Fr' :
          return 'جمعه';
      }
    },
    hourFilter(val) {
      switch (val) {
        case 1:
          return ' 8-12'
        case 2:
          return '12-16'
        case 3:
          return '16-20'
        case 4:
          return '20-24'
        default :
          return val
      }
    }
  },
  mounted() {
    this.getSurveys()
  }
}
</script>

<style scoped>

</style>
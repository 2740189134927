<template>
    <div>
        <v-data-table
                class="elevation-0 pa-2 ma-0"
                dense
                :headers="headers"
                :items="prices"
                :items-per-page="5"
                hide-default-footer
        >
            <template v-slot:item.currency="{item}">
                {{item.title}}
            </template>

            <template v-slot:item.price="{item}">
                {{Number(item.pivot.amount).toLocaleString()}}
            </template>

        </v-data-table>
    </div>
</template>

<script>
    import {resolveCurrency} from "../../../utilities";

    export default {
        props: ['allPrices'],
        created() {
            this.prices = this.allPrices;
        },
        computed: {},

        data() {
            return {
                prices: [],
                priceToAdd: {
                    currency: null,
                    price: null
                },
                headers: [
                    {text: 'نوع ارز', value: 'currency'},
                    {text: 'مبلغ', value: 'price'},
                ],
            }
        },
        filters: {
            resolveCurrency(input) {
                return resolveCurrency(input);
            }
        },

    }
</script>

<style scoped>

</style>
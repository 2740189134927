<template>
  <div>
    <v-form ref="assignForm" v-if="accountMangers != null">
      <v-card>
        <v-card-title class="font-weight-bold">
          تخصیص پشتیبان
        </v-card-title>
        <v-card-text>
          <v-select
              :items="accountMangers"
              item-text="full_name"
              item-value="id"
              outlined
              label="پشتیبان"
              :rules="requiredRules"
              v-model="seleted"
          >
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn color="green" class="white--text" @click="setAccountManger()"> ثبت</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <div v-else>
      <v-card>
       <v-card-text class="text-center">
         <v-progress-circular
             indeterminate
             color="primary"
         ></v-progress-circular>
       </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import {EventBus} from "@/event-bus";

export default {
// {
//   "user_id":26,
//     "account_manager_id":1,
//     "status":"active",
//     "manager_access":true
// }
  props: {
    user_id: Number,
  },
  data() {
    return {
      seleted: null,
      accountMangers: null
    }
  },
  methods: {
    getAccountMangerList() {
      this.$store.dispatch('getAccountMangerList').then((resp) => {
        console.log(resp);
        this.accountMangers = resp.data.users
      })
    },
    setAccountManger() {
      if (this.$refs.assignForm.validate()){
        EventBus.$emit('setLoading',true)
        this.$store.dispatch('assignAccountManger', {
          account_manager_id: this.seleted,
          user_id: this.user_id,
          status: "active",
          manager_access: true
        }).then(() => {
          EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
          this.$emit('dialogAssignAccount',false)
          EventBus.$emit('updateListUsersCRM')
          this.$router.push({name:'mainPageUserBasedCrm'})
        }).finally(() => {
          EventBus.$emit('setLoading',false)
        })
      }
    }
  },
  computed: {
    requiredRules() {
      return [v => !!v || this.$vuetify.lang.t('$vuetify.fillIsRequired')];
    },
  },
  mounted() {
    this.getAccountMangerList();
  }
}
</script>

<style scoped>

</style>
<template>
    <CouponTemplate v-if="couponToEdit!=null" :title="'ویرایش کد تخفیف'" buttonText="ویرایش" @proceed="editCoupon"
                    closable="true"
                    :couponToEdit="couponToEdit"
                    @close="$emit('close')"></CouponTemplate>
</template>

<script>
    /*eslint-disable*/
    import CouponTemplate from '@/components/Admin/coupons/CouponTemplate';
    import {EventBus} from "../../../event-bus";

    export default {
        props: ['coupon'],
        created() {
            let limitedCondition = this.coupon.conditions.find(x => x.type == 'limitedpackages');
            let packages = [];
            if (limitedCondition) {
                packages = limitedCondition.payload.data.packages;
            }


            this.couponToEdit = {
                max_usage_count: this.coupon.max_usage,
                max_usage_count_per_user: this.coupon.max_usage_count_per_user,
                exp_date: this.coupon.exp_date,
                discount_type: this.coupon.discount_type,
                usd_discount_amount: this.coupon.amount_usd,
                irr_discount_amount: this.coupon.amount_irr,
                code: this.coupon.code,
                conditions: this.coupon.conditions.map(x => x.type),
                packages,
                is_cart_coupon: this.coupon.is_cart_coupon
            };
            if (this.coupon.doctor != null) {
                this.couponToEdit.doctor_id = this.coupon.doctor.id;
            }
            if (this.coupon.user != null) {
                this.couponToEdit.user_id = this.coupon.user.id;
            }
            if (this.coupon.package != null) {
                this.couponToEdit.package_id = this.coupon.package.id;
            }

            console.log(this.couponToEdit);
        },
        data() {
            return {
                couponToEdit: null
            }
        },
        components: {
            CouponTemplate
        },
        methods: {
            editCoupon(data) {
                this.$store.dispatch('AdminEditCoupon', {id: this.coupon.id, payload: data}).then((resp) => {
                    console.log(resp);
                    EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
                    this.$emit('done');
                })
            }
        }
    }
</script>

<style scoped>

</style>
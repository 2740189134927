<template>
  <div>
    <h4>افزودن دکتر</h4>
    <v-divider class="my-5"></v-divider>
    <v-autocomplete
        v-if="!resetSelectionAll"
        label="همه دکتر‌ها"
        v-model="doctor"
        :items="doctorList"
        item-text="full_name"
        item-value="doctor_profile.id"
    ></v-autocomplete>
    <v-autocomplete
        v-if="!resetSelectionAi"
        label="دکتر‌های پیشنهادی سیستم"
        v-model="doctorMatchMaking"
        :items="listDoctorMatchMaking"
        item-text="user.full_name"
        item-value="user.id"
    ></v-autocomplete>

    <v-fade-transition v-if="doctor || doctorMatchMaking ">
      <v-textarea outlined class="mt-3" label="دلیل انتخاب دکتر "></v-textarea>
    </v-fade-transition>
    <v-btn color="green" class="white--text font-weight-bold" small elevation="0" @click="assignDoctor">ثبت دکتر</v-btn>
  </div>
</template>

<script>
/*eslint-disable*/
import {EventBus} from "@/event-bus";

export default {
  props: {
    profile: Object,
    taskId: Number,
  },
  data() {
    return {
      resetSelectionAll: false,
      resetSelectionAi: false,
      doctor: null,
      doctorList: null,
      doctorMatchMaking: null,
      listDoctorMatchMaking: null,
    }
  },
  mounted() {
    this.getDoctorList()
    this.getDoctorMatchMaking()
  },
  methods: {
    getDoctorList() {
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('getDoctors').then((resp) => {
        this.doctorList = resp.data.doctors
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    assignDoctor() {
      alert(this.doctorMatchMaking != null )
      return;
      let payload = {
        user_id: this.$route.params.clientId,
        doctor_id: this.doctor,
        matchMakingDoctor: this.doctorMatchMaking != null ,
        status: "active"
      }
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('assignDoctor', payload).then(() => {
        this.$store.dispatch('doneTask', {
          id: this.$route.query.taskId,
          status: "done",
        }).then(() => {
          EventBus.$emit('notify', 'green', undefined, 'با موفقیت به ثبت رسید');
          EventBus.$emit('returnWindow')
          EventBus.$emit('updateClientInfo')
        }).finally(() => {
          EventBus.$emit('setLoading', false)
        })
      })
    },
    getDoctorMatchMaking() {
      this.$store.dispatch('getDoctorListMatchMaking', {
        // user_id:this.$route.params.clientId,
        user_id: 475
      }).then((resp) => {
        this.listDoctorMatchMaking = resp.data[0].doctors
      })
    }
  },
  watch: {
    doctor(val) {
      if (val) {
        this.resetSelectionAi = true;
        this.doctorMatchMaking = null
        this.$nextTick().then(() => {
          this.resetSelectionAi = false;
        })
      }
    },
    doctorMatchMaking(val) {
      if (val) {
        this.doctor = null
        this.resetSelectionAll = true;
        this.$nextTick().then(() => {
          this.resetSelectionAll = false;
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <TableOptions :hasExport="true" :searchFilters="searchFilters"
                            @applyFilters="applyFilters"></TableOptions>
              <!--              <v-checkbox label="نمایش فاصله زمانی" v-model="show_diff_time"-->
              <!--                          class="pa-0 ma-0 mt-2"-->
              <!--                          dense></v-checkbox>-->
              <v-btn
                  class="mb-5 mr-3"
                  elevation="0"
                  :outlined="!show_diff_time"
                  small
                  :color="show_diff_time ? 'primary' : ''"
                  @click="show_diff_time = !show_diff_time"
              >
                <v-icon small class="ml-2">
                  <template v-if="show_diff_time">mdi-check</template>
                  <template v-else>mdi-cross</template>
                </v-icon>
                نمایش فاصله زمانی
              </v-btn>
              <v-data-table
                  :headers="preparedHeader"
                  :items="data"
                  hide-default-footer
                  disable-pagination
              >
                <template v-slot:item.brith_data="{item}">
                  <template v-if="item.last_document">
                    {{ item.last_document.birth_date | dateFilter }}
                  </template>
                </template>
                <template v-slot:item.assigned_doctor_status="{item}">
                  {{ item.assigned_doctor_status === 'active' ? 'فعال' : 'غیر فعال' }}
                </template>
                <template v-slot:item.full_name="{item}">
                  <router-link :to="{name:'clientDetail',params:{clientId:item.id}}" style="text-decoration: none">
                    {{ item.full_name }}
                  </router-link>
                </template>
                <template v-slot:item.marital_status="{item}">
                  <template v-if="item.last_document">
                    {{ maritalStatus(item.last_document.marital_status) }}
                  </template>
                </template>
                <template v-slot:item.country="{item}">
                  <template v-if="item.last_document && item.last_document.country">
                    {{ item.last_document.country.name }}
                  </template>
                </template>
                <template v-slot:item.gender="{item}">
                  <template v-if="item.last_document">
                    {{ item.last_document.gender }}
                  </template>
                </template>
                <template v-slot:item.knway="{item}">
                  <template v-if="item.encryptedData && item.encryptedData.client">
                    {{ kenwaySource(item.encryptedData.client.knway) }}
                  </template>
                </template>
                <template v-slot:item.detach_reason="{item}">
                  {{ stopReasons[item.detach_reason] }}
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="text-center">
            <v-pagination
                v-model="pagination.page"
                :length="pagination.total"

            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TableOptions from "@/components/TableOptions";
import {
  user_monitoring_report
} from "@/searchFilters";
import {mapGetters} from "vuex";
import {EventBus} from "@/event-bus";
/*eslint-disable*/
export default {
  components: {TableOptions},
  data() {
    return {
      header: [
        {
          text: 'آیدی',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'نام و نام خانوادگی',
          align: 'start',
          sortable: true,
          value: 'full_name',
        },


        // {
        //   text: 'فاصله اولین و آخرین جلسه',
        //   align: 'start',
        //   sortable: true,
        //   value: 'first_last_diff',
        // },
        // {
        //   text: 'تاریخ آخرین جلسه در بازه',
        //   align: 'start',
        //   sortable: true,
        //   value: 'last_session_date',
        // },
        {
          text: 'تاریخ پیش مشاوره',
          align: 'start',
          width: '100px',
          sortable: true,
          value: 'pre_session_date',
        },
        {
          text: 'تاریخ اولین خرید',
          align: 'start',
          sortable: true,
          width: '100px',
          value: 'first_purchase_date',
        },
        {
          text: 'تاریخ تخصیص',
          align: 'start',
          sortable: true,
          width: '100px',
          value: 'assignment_date',
        },
        {
          text: 'نام دکتر',
          align: 'start',
          sortable: true,
          value: 'doctor_name',
        },
        // {
        //   text: 'جلسه اول',
        //   align: 'center',
        //   width: '100px',
        //   sortable: true,
        //   value: 'session_1',
        // },
        // {
        //   text: 'جلسه دوم',
        //   align: 'center',
        //   width: '100px',
        //   sortable: true,
        //   value: 'session_2',
        // },
        // {
        //   text: 'جلسه سوم',
        //   align: 'center',
        //   width: '100px',
        //   sortable: true,
        //   value: 'session_3',
        // },
        // {
        //   text: 'جلسه جهارم',
        //   align: 'center',
        //   width: '100px',
        //   sortable: true,
        //   value: 'session_4',
        // },
        // {
        //   text: 'جلسه پنجم',
        //   align: 'center',
        //   width: '100px',
        //   sortable: true,
        //   value: 'session_5',
        // },
        {
          text: 'دلیل خاتمه',
          align: 'start',
          sortable: true,
          value: 'detach_reason',
        },
        {
          text: 'میانگین در بازه',
          align: 'start',
          sortable: true,
          value: 'average',
        },
        {
          text: 'تعداد جلسات در بازه',
          align: 'start',
          sortable: true,
          value: 'session_count',
        },
        {
          text: 'تعداد همه جلسات',
          align: 'start',
          sortable: true,
          value: 'all_sessions_count',
        },
        // {
        //   text: 'آیدی دکتر',
        //   align: 'start',
        //   sortable: true,
        //   value: 'doctor_id',
        // },

        // {
        //   text: 'نحوه آشنایی',
        //   align: 'start',
        //   sortable: true,
        //   value: 'knway',
        // },
        {
          text: 'توضیحات ',
          align: 'start',
          sortable: true,
          value: 'description',
        },
        // {
        //   text: 'بازخورد مراجع',
        //   align: 'start',
        //   sortable: true,
        //   value: 'feedback',
        // },

        {
          text: 'وضعیت',
          align: 'start',
          sortable: true,
          value: 'assigned_doctor_status',
        },
        // {
        //   text: 'نام پشتیبان',
        //   align: 'start',
        //   sortable: true,
        //   value: 'manager_name',
        // },
        {
          text: 'باقیمانده جلسات',
          align: 'start',
          sortable: true,
          value: 'remaining_sessions',
        },
        {
          text: 'تعداد خریداری شده',
          align: 'start',
          sortable: true,
          value: 'all_purchases',
        },
        {
          text: 'بدهی‌ها',
          align: 'start',
          sortable: true,
          value: 'debts',
        },
        {
          text: 'نوبت جلسات',
          align: 'start',
          sortable: true,
          value: 'session_order',
        },
        {
          text: 'زمان گذشته از آخرین جلسه',
          align: 'start',
          sortable: true,
          value: 'since_last_session',
        },

        {
          text: 'مسئول پیش مشاوره',
          align: 'start',
          sortable: true,
          value: 'pre_session_agent',
        },
      ],
      searchFilters: user_monitoring_report,
      preparedHeader: [],
      data: [],
      filters: null,
      show_diff_time: true,
      session_count: 5,
      pagination: {
        page: 1,
        total: 1,
      },
      kenway: [
        {value: "google", title: 'گوگل'},
        {value: "twitter", title: 'توییتر'},
        {value: "instagram", title: 'اینستاگرام'},
        {value: "podcast", title: 'پادکست'},
        {value: "bannerads", title: 'تبلیغات بنری'},
        {value: "friends", title: 'دوستان و آشنایان'},
        {value: "youtube", title: 'یوتیوب'},
        {value: "other", title: 'موارد‌ دیگر'},
      ],

      stopReasons: {
        client_not_answering: "عدم پاسخ دهی مشتری",
        client_end_of_therapy: "پایان درمان توسط مشتری",
        client_partner_not_participating: "عدم مشارکت شریک مشتری",
        financial_problem: "مشکلات مالی و عدم بیمه",
        client_dissatisfied_by_therapist: "ناراضی بودن مشتری از روانشناس",
        client_personal_issues: "مسائل شخصی مشتری",
        client_continuing_in_person: "ادامه مشاوره حضوری",
        other: 'موارد دیگر',
      }

    }
  },
  computed: {
    ...mapGetters({
      allDoctors: 'getAdminAllDoctors'
      // sessions: 'getAdminSessions',
      // page: 'page',
      // total: 'total',
    })
  },
  mounted() {
    this.preparedHeader = this.header
    // this.getUserMonitoringReport({})
  },
  created() {
    this.fillDoctors()
  },
  methods: {
    getUserMonitoringReport(filters) {
      if (!filters.hasOwnProperty('session_count')) {
        filters.session_count = 5
      }
      filters.page = this.pagination.page
      EventBus.$emit('setLoading', true)
      this.$store.dispatch('getUserMonitoringReport', filters).then((resp) => {
        this.data = resp.data.users
        this.pagination.total = resp.data.pagination.total_pages;
        // = {
        //  page: resp.data.pagination.current_page,
        // total:
        // }
        this.prepareHeader()
      }).finally(() => {
        EventBus.$emit('setLoading', false)
      })
    },
    kenwaySource(value) {
      const source = this.kenway.find(source => source.value === value);
      return source ? source.title : value;
    },
    applyFilters(filters) {
      this.pagination.page = 1
      this.filters = filters
      console.log(this.filters, '2112121')
      if (filters.hasOwnProperty('session_count')) {
        this.session_count = filters.session_count
      }
      this.getUserMonitoringReport(filters)
    },
    prepareHeader() {
      this.preparedHeader = [...this.header]
      let temp = []
      for (var i = 1; i <= this.session_count; i++) {
        temp.push({
          text: ` جلسه ${i} `,
          align: 'center',
          width: '100px',
          sortable: true,
          value: this.show_diff_time ? `session_${i}_diff` : `session_${i}`,
        },);
      }
      this.preparedHeader.splice(5, 0, ...temp);
      // console.log(temp)
    },
    fillDoctors() {
      let promise = new Promise((resolve, reject) => {
        if (!this.allDoctors.length) {
          this.$store.dispatch("getAllDoctors").then(res => {
            this.doctors = res.data.doctors;
            resolve();
          }).catch(err => {
            console.log(err);
          });
        } else {
          this.doctors = this.allDoctors;
          resolve();
        }
      })

      promise.then(() => {
        let mappedDoctors = this.doctors.map((doctor) => {
          return {
            title: doctor.full_name, value: doctor.doctor_profile.id
          };
        });
        user_monitoring_report.find((item) => {
          console.log(item.key, '12121s')
          if (item.key == 'doctor_ids') {
            item.options = mappedDoctors;
          }
        });
        this.rerender = true;
      });
    },
  },
  watch: {
    show_diff_time() {
      this.prepareHeader()
    },
    'pagination.page'() {
      delete this.filters.wantsExcel
      this.getUserMonitoringReport(this.filters)
    }
  }
}
</script>

<style scoped>

</style>
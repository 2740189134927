<template>
  <div>
    <v-container>
      <div class="mt-5">
        <CombinedSales v-if="checkRoleAndPermissions('admin.dashboard.combinedData')"/>
      </div>
    </v-container>
    <div>
      <MarketingStatistics v-if="checkRoleAndPermissions('admin.dashboard.marketingStatistics')"/>
    </div>
    <div class=" ">
      <v-row justify="center">
        <v-col cols="12" lg="6">
          <TestStatisticsDashboard v-if="checkRoleAndPermissions('admin.dashboard.psy_test+_statistics')"/>
        </v-col>
      </v-row>
    </div>
    <v-container class="mt-10">
      <div>
        <DoctorsListDashboard v-if="checkRoleAndPermissions('admin.dashboard.doctorList')"/>
      </div>
      <div class="mt-10 mb-10">
        <ReportSection v-if="checkRoleAndPermissions('admin.dashboard.report_section')"/>
      </div>
    </v-container>

  </div>
</template>

<script>
import MarketingStatistics from "@/components/dashboards/marketingStatistics";
import TestStatisticsDashboard from "@/components/dashboards/TestStatisticsDashboard";
import DoctorsListDashboard from "@/components/Admin/DoctorsListDashboard";
import CombinedSales from "@/components/dashboards/combinedSales";
import ReportSection from "@/components/dashboards/ReportSection";

export default {
  components: {ReportSection, CombinedSales, DoctorsListDashboard, TestStatisticsDashboard, MarketingStatistics}
}
</script>

<style scoped>

</style>
<template>
  <div>
    <v-container class="mt-10">
      <v-row>
        <div id="chart"

             style="width:100%;border: 1px solid rgba(0,0,0,.3);border-radius: 12px;box-shadow: 0 5px 10px -3px rgba(0,0,0,.3);padding:5px;"
        >
          <TableOptions
              v-if="renderOptions"
              :searchFilters="searchFilters"
              @applyFilters="applyFilters"
              :action-required-permission="{
                  download:'admin.expressentry'
                }"

          ></TableOptions>
          <v-fade-transition>
            <apexchart v-if="scores.length != 0" type="line" height="350" :options="chartOptions"
                       :series="series"></apexchart>
          </v-fade-transition>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
/*eslint-disable*/
import apexchart from 'vue-apexcharts';
import moment from 'moment';
import AdminDatePicker from "@/components/AdminDatePicker";
import TableOptions from "@/components/TableOptions";
import {sessionAverageReport} from "@/searchFilters";
import {mapGetters} from "vuex";
import momentJalali from "moment-jalaali";
import {EventBus} from "@/event-bus";

export default {
  components: {
    apexchart,
    TableOptions
  },
  data() {
    return {

      categories: [],
      scores: [],
      searchFilters: [],
      renderOptions: false,
      filters: null,
      doctors: [],
      addDoctors: [],
      defaultDoctorExcludes: [8, 12, 89, 107, 13],


    }
  },
//
// {
//   name: "Mobile",
//       data: [10, 20, 0, 51, 10, 62, 58, 91, 148]
// }
  computed: {
    ...mapGetters({
      allDoctors: 'getAdminAllDoctors'
      // sessions: 'getAdminSessions',
      // page: 'page',
      // total: 'total',
    }),
    series() {
      return this.scores
    },
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#803396', '#0ce7ce'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Session Average Chart',
          align: 'center'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: this.categories,
          title: {
            text: ''
          }
        },
        yaxis: {
          title: {
            text: 'میانگین جلسات/کاربر'
          },
          // min: 3,
          // max: 40
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        },
      }
    }
  },
  methods: {
    getSessionAverageData() {
      EventBus.$emit('setLoading', true)
      if (!this.filters.hasOwnProperty('from_date')) {
        this.filters.from_date = moment().subtract('7', 'months').format('YYYY-MM-DD')
      }
      this.filters.window = 'monthly'

      this.$store.dispatch('getSessionAverage', this.filters).then((resp) => {
        let temp = []
        let temp_score = []
        let temp_users = []
        resp.data.forEach((item) => {
          temp.push(`${momentJalali(item.from_date).format('jYYYY/jMM/jDD')} - ${momentJalali(item.from_date).format('YYYY/MM/DD')}`)
          temp_score.push(item.data.avg_session)
          temp_users.push(item.data.users_count)
        })
        this.categories = temp
        this.scores = [
          {
            name: "میانگین جلسات ",
            data: temp_score
          },
          {
            name: "تعداد کاربران ",
            data: temp_users
          },
        ]
      }).finally(() => {
        EventBus.$emit('setLoading', true)
      })
    },
    fillDoctors() {
      let promise = new Promise((resolve, reject) => {
        if (!this.allDoctors.length) {
          this.$store.dispatch("getAllDoctors").then(res => {
            this.doctors = res.data.doctors;
            resolve();
          }).catch(err => {
            console.log(err);
          });
        } else {
          this.doctors = this.allDoctors;
          resolve();
        }
      })

      promise.then(() => {


        let mappedDoctors = this.doctors.map((doctor) => {
          let data = {
            title: doctor.full_name, value: doctor.doctor_profile.id
          };
          this.addDoctors.push(JSON.parse(JSON.stringify(data)));
          if (this.defaultDoctorExcludes.includes(doctor.doctor_profile.id)) data.default = true;
          return data;
        });

        sessionAverageReport.find((item) => {
          if (item.key == 'doctor_ids') {
            item.options = mappedDoctors;
          } else if (item.key == 'filter_doctor_ids') {
            item.options = this.addDoctors;
          }
        });
        this.searchFilters = sessionAverageReport;

      }).finally(() => {
        this.renderOptions = true;
      });
    },
    applyFilters(filters) {
      this.filters = filters
      this.getSessionAverageData()
    }
  },
  mounted() {
    if (!this.$route.query.hasOwnProperty('from_date')) {
      this.$router.push({
        name: 'adminAverageSessionReport',
        query: {from_date: moment().subtract('7', 'months').format('YYYY-MM-DD')}
      })
    }
    //
    // this.getSessionAverageData()
    this.fillDoctors()
  },
}
</script>

<style scoped>

</style>